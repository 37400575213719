import React from 'react';
import { styled, Box, Typography, Grid, useTheme, makeStyles, Link } from "@material-ui/core";
import logo from '../assets/logos/obv-logo-small-text.svg'
import { LandingContainer } from './skeleton';

const GreyFooter = styled(Box)({
  backgroundColor: "rgba(50, 50, 69, 0.1)",

})

const FooterLink = styled(Link)({
  '&:hover': {
    opacity: "0.7",
    textDecoration: "none",
  },
});

export function Footer() {

  const theme = useTheme();

  const useStyles = makeStyles({
    sectionPadding: {
      [theme.breakpoints.up('md')]: {
        marginTop: "150px"
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: "100px"
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: "70px"
      },
    },
    footerContainer: {
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down('xs')]: {
        flexDirection: "column-reverse",
        textAlign: "center"
      },
    },
    footerLogo: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block"
      }
    },
    linkText: {
      fontFamily: "Inter",
      fontDisplay: "swap",
      fontWeight: "600",
      fontSize: "16px",
      color: "#323245",
      opacity: "0.9",
      textAlign: "right",
      [theme.breakpoints.down('xs')]: {
        textAlign: "center"
      }
    }
  })

  const classes = useStyles();

  return (
    <GreyFooter>
      <LandingContainer className={classes.sectionPadding}>
        <Box paddingTop="60px" paddingBottom="60px">
          <Grid container className={classes.footerContainer} spacing={2}>
            <Grid item md={6}>
              <img draggable="false" src={logo} className={classes.footerLogo} alt="Logo" />
            </Grid>
            <Grid item md={3}>
              <Grid container alignItems="flex-end" spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <FooterLink href="mailto:hello@tryobvious.com">
                    <Typography className={classes.linkText}>Contact</Typography>
                  </FooterLink>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FooterLink href="https://twitter.com/tryobvious" target="_blank" rel="noopener noreferrer">
                    <Typography className={classes.linkText}>Twitter</Typography>
                  </FooterLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </LandingContainer>
    </GreyFooter>

  )
}