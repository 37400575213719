// Incoming
export const REVENUE = "revenue";
export const FUNDING = "investments-grants";

// Outgoing
export const EQUIPMENT = "equipment";
export const LOANS = "loans";
export const MARKETING = "marketing";
export const MEALS_DRINKS = "meals-drinks";
export const OFFICE_COSTS = "office-costs";
export const PAYROLL = "payroll-perks";
export const PROFESSIONAL_SERVICES = "professional-services";
export const RENT = "rent";
export const SOFTWARE = "software";
export const TAXES = "taxes";
export const TRAVEL = "travel";
export const UTILITIES = "utilities";
export const MAIL_SHIPPING = "mail-shipping";
export const FEES = "fees";
export const OTHER = "other";

// Other
export const INTERNAL_TRANSFERS = "internal-transfers";

const incomingCategories = [REVENUE, FUNDING];

const outgoingCategories = [
  EQUIPMENT,
  LOANS,
  MARKETING,
  MEALS_DRINKS,
  OFFICE_COSTS,
  PAYROLL,
  PROFESSIONAL_SERVICES,
  RENT,
  SOFTWARE,
  TAXES,
  TRAVEL,
  UTILITIES,
  MAIL_SHIPPING,
  FEES,
  OTHER,
];

const nonBrowsableCategories = [INTERNAL_TRANSFERS];

export const isBrowsable = (category) =>
  category != null && !nonBrowsableCategories.includes(category.id);

export const isOutgoing = (category) =>
  category != null && outgoingCategories.includes(category.id);

export const isIncoming = (category) =>
  category != null && incomingCategories.includes(category.id);
