import { css } from "emotion";
import React from "react";

const Box = ({
  shadowed = true,
  shadowOpacity = "10%",
  padding = "3rem",
  background = "none",
  border,
  component = "div",
  style,
  children,
  ...props
}) =>
  React.createElement(
    component,
    {
      className: css({
        padding,
        background,
        boxShadow: shadowed ? `0 0.4rem 1rem rgb(0 0 0 / ${shadowOpacity})` : 0,
        // boxShadow: 'rgba(0, 0, 0, 0.07) 0px 0px 24px',
        border: border ? "0.2rem solid" : undefined,
        borderColor: border,
        borderRadius: "1rem",
        ...style,
      }),
      ...props,
    },
    children
  );

export default Box;
