import { ApiUrl } from "./constants";

const request = (url, { headers, searchParams = {}, ...options } = {}) => {
  const urlSearchParams = new URLSearchParams(Object.entries(searchParams));

  const parseBody = (response) => {
    const contentType = response.headers.get("content-type");
    if (contentType?.includes("application/json")) return response.json();
    return response.text();
  };

  return fetch(
    [`${ApiUrl}${url}`, urlSearchParams.toString()].filter(Boolean).join("?"),
    {
      headers: new Headers(headers),
      credentials: "include",
      ...options,
    }
  ).then((response) =>
    parseBody(response)
      .catch(() => null)
      .then((body) => {
        if (response.ok) return body;

        const requestError = new Error(response.statusText);
        requestError.response = response;
        requestError.body = body;

        return Promise.reject(requestError);
      })
  );
};

export default request;
