import formatDate from "date-fns/format";
import subtractMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PRIMARY_COLOR } from "../constants/colors";
import {
  isBrowsable as isBrowsableCategory,
  isOutgoing as isOutgoingCategory,
  isIncoming as isIncomingCategory,
} from "../utils/categories";
import useMetricData from "../hooks/metric-data";
import useTransactions from "../hooks/transactions-data";
import useStore from "../hooks/stores";
import AnalysisPage from "./AnalysisPage";
import Text from "./Text";
import Divider from "./Divider";

const frequency = "monthly";

const useCategoryTransactions = (categoryId) => {
  const categories = React.useMemo(() => [categoryId], [categoryId]);

  const [{ data, meta }] = useTransactions({ categories });

  return { data, meta };
};

const CategoryPage = ({ category, location, history }) => {
  const isIncomeCategory = !isOutgoingCategory(category);

  const categories = useStore("categories");

  const to = React.useMemo(() => endOfMonth(subtractMonths(new Date(), 1)), []);
  const from = React.useMemo(() => startOfMonth(subtractMonths(to, 12)), [to]);

  const {
    data: transactions,
    meta: transactionsMeta,
  } = useCategoryTransactions(category.id);

  const allCategoriesNetQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      categories: categories
        .filter(isIncomeCategory ? isIncomingCategory : isOutgoingCategory)
        .map((c) => c.id),
      metric: "money_net",
    }),
    [from, to, isIncomeCategory, categories]
  );
  const uncategorizedQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      categories: ["unknown"],
      metric: isIncomeCategory ? "money_in" : "money_out",
    }),
    [from, to, isIncomeCategory]
  );

  const categoryNetQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      metric: "money_net",
      categories: [category.id],
    }),
    [from, to, category.id]
  );

  const categoryGroupByVendorQuery = React.useMemo(
    () => ({
      from: startOfMonth(subtractMonths(to, 3)),
      to,
      categories: [category.id],
      metric: "grouped_transactions",
      groupBy: "merchant",
    }),
    [to, category.id]
  );

  const {
    allCategoriesNet: allCategoriesNetData,
    uncategorized,
    categoryNet: categoryNetDataRaw,
    categoryVendors: categoryVendorsRaw,
  } = useMetricData({
    allCategoriesNet: allCategoriesNetQuery,
    categoryNet: categoryNetQuery,
    uncategorized: uncategorizedQuery,
    categoryVendors: categoryGroupByVendorQuery,
  });

  const categoryNetData = React.useMemo(() => {
    if (isIncomeCategory) return categoryNetDataRaw;

    return categoryNetDataRaw?.map((d) => ({
      ...d,
      // Flip sign for outgoing categories
      value: d.value === 0 ? 0 : d.value * -1,
    }));
  }, [categoryNetDataRaw, isIncomeCategory]);

  const allCategoriesData = React.useMemo(() => {
    const uncategorizedByDate = (uncategorized ?? []).reduce(
      (acc, d) => ({ ...acc, [d.date]: d }),
      {}
    );

    return allCategoriesNetData
      ?.map((d) => {
        const uncategorizedAmount = uncategorizedByDate[d.date]?.value ?? 0;
        return { ...d, value: d.value + uncategorizedAmount };
      })
      .map((d) => {
        const amount =
          d.value === 0
            ? 0
            : isIncomeCategory
            ? d.value
            : // We don't want to have negative values for spend, could technically happen
              Math.max(d.value * -1, 0);

        return { ...d, value: amount };
      });
  }, [isIncomeCategory, allCategoriesNetData, uncategorized]);

  const categoryVendors = React.useMemo(() => {
    if (isIncomeCategory) return categoryVendorsRaw;
    return categoryVendorsRaw?.map((d) => ({
      ...d,
      // Flip sign for outgoing categories
      value: d.value === 0 ? 0 : d.value * -1,
    }));
  }, [isIncomeCategory, categoryVendorsRaw]);

  return (
    <AnalysisPage
      history={history}
      location={location}
      options={categories
        .filter(isBrowsableCategory)
        .map((c) => ({ value: c.id, label: c.description }))}
      selectedOption={{ value: category.id, label: category.description }}
      onSelectOption={(categoryId) =>
        history.push(`/dashboard/categories/${categoryId}`)
      }
      isIncomeQuery={isIncomeCategory}
      range={[from, to]}
      queriedAmounts={categoryNetData}
      totalAmounts={allCategoriesData}
      queriedVendorAmounts={categoryVendors}
      transactions={transactions}
      totalTransactionCount={transactionsMeta?.count}
      createTransactionListLink={({ from, to } = {}) =>
        [
          `/dashboard/transactions`,
          new URLSearchParams(
            [
              ["categories", category.id],
              ["from", from == null ? null : formatDate(from, "yyyy-MM-dd")],
              ["to", to == null ? null : formatDate(to, "yyyy-MM-dd")],
            ].filter(([_, value]) => value != null)
          ).toString(),
        ].join("?")
      }
      noDataPlaceholder={
        <NoDataPlaceholder categoryDescription={category.description} />
      }
    />
  );
};

const NoDataPlaceholder = ({ categoryDescription }) => (
  <div
    style={{
      padding: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    }}
  >
    <div
      style={{
        paddingBottom: "20vh",
        textAlign: "center",
        fontSize: "1.6rem",
      }}
    >
      <Text weight="500" size="1.6rem">
        Looks like you don't have any transactions categorized as "
        {categoryDescription}".
      </Text>
      <Divider />
      <RouterLink
        to={`/dashboard/transactions?categories=unknown`}
        style={{
          color: PRIMARY_COLOR,
          fontWeight: "700",
          textDecoration: "none",
        }}
      >
        <span style={{ textDecoration: "underline" }}>
          See uncategorized transactions
        </span>{" "}
        &rarr;
      </RouterLink>
    </div>
  </div>
);

export default CategoryPage;
