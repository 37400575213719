import { css } from "emotion";
import React from "react";
import JsonView from "react-json-view";
import { getTransactionById } from "../../api/transactions";
import Text from "../Text";
import Divider from "../Divider";

const useTransaction = (id) => {
  const [transaction, setTransation] = React.useState(null);

  React.useEffect(() => {
    getTransactionById(id).then((t) => setTransation(t));
  }, [id]);

  return transaction;
};

const Transaction = ({ match }) => {
  const transaction = useTransaction(match.params.id);

  if (!transaction) return null;

  return (
    <div
      className={css({
        padding: "5rem 0 12rem",
        margin: "0 auto",
        maxWidth: "100%",
        width: "90rem",
      })}
    >
      <Text variant="headline-large" component="h1">
        Transaction details
      </Text>

      <Divider size="4rem" />

      <JsonView
        src={transaction}
        name={false}
        iconStyle="triangle"
        indentWidth={2}
        displayObjectSize={false}
        displayDataTypes={false}
        collapsed={1}
        enableClipboard={false}
      />
    </div>
  );
};

export default Transaction;
