import React from "react";
import { css, keyframes } from "emotion";

const animation = keyframes`
0% {
  stroke-dashoffset: 0;
  stroke-dasharray: 150.6 100.4;
}
50% {
  stroke-dasharray: 1 250;
}
100% {
  stroke-dashoffset: 502;
  stroke-dasharray: 150.6 100.4;
}`;

const Spinner = ({ size = "2rem", speed = "1.2s", color, style }) => (
  <div
    className={css({
      color,
      width: size,
      svg: { display: "block", width: "100%", height: "auto" },
    })}
    style={style}
    aria-hidden
  >
    <svg
      width="100"
      height="100"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="currentColor"
        fill="none"
        strokeWidth="7"
        className={css({ animation: `${animation} ${speed} linear infinite` })}
      />
    </svg>
  </div>
);

export default Spinner;
