import { handleErrorResponse, apiInstance } from "./common";

export const fetchAll = ({ bankAccounts, sort = "-date" }) => {
  const params = Object.fromEntries(
    [
      ["sort", sort],
      ["expand", "transaction"],
      ["bank_accounts", bankAccounts.join(",")],
    ].filter(([, value]) => value != null)
  );
  return apiInstance.get("/insights", { params }).catch(handleErrorResponse);
};

export const update = (id, properties) =>
  apiInstance.put(`/insights/${id}`, properties).catch(handleErrorResponse);

export const dismiss = (id) => update(id, { dismissed: true });
