export const ApiUrl =
  process.env.REACT_APP_PROXY_TARGET != null
    ? window.location.origin + "/api"
    : process.env.REACT_APP_API_HOST || "http://localhost:5000";

export const LandingUsersEndpoint = "/landing_users";
export const UsersEndpoint = "/users";
export const TransactionsEndpoint = "/transactions";
export const CompaniesEndpoint = "/companies";
export const GraphsEndpoint = "/graphs";
export const RunwayEndpoint = "/runway";

export const LoginEndpoint = UsersEndpoint + "/login";
export const ForgotPasswordEndpoint = UsersEndpoint + "/forgot_password";
export const ResetPasswordEndpoint = UsersEndpoint + "/reset_password";
export const RegisterUserEndpoint = UsersEndpoint + "/register";

export const PlaidEnvironment = "production"
  // process.env.REACT_APP_PLAID_ENVIRONMENT ?? "sandbox";

export const CATEGORY_MAPPING = {
  equipment: "Equipment",
  fees: "Fees",
  "investments-grants": "Investment / Grants",
  loans: "Loans",
  marketing: "Marketing / Advertisement",
  "meals-drinks": "Meals & Drinks",
  "office-costs": "Office supplies",
  other: "Other",
  "payroll-perks": "Payroll",
  "professional-services": "Professional services",
  rent: "Rent",
  revenue: "Revenue",
  software: "Software",
  taxes: "Taxes",
  travel: "Travel",
  utilities: "Utilities",
  undefined: "Unknown",
};
