import React from "react";
import useWindowSize from "../hooks/window-size";
import T from "./Text";

export const Container = ({ width = "auto", children }) => {
  const windowSize = useWindowSize();
  return (
    <div
      style={{
        width,
        maxWidth: "100%",
        margin: "0 auto",
        background: "white",
        borderRadius: "0.5rem",
        boxShadow: "0 1rem 2rem 0 rgb(0 0 0 / 20%)",
        maxHeight: `calc(${windowSize.height}px - 8rem)`,
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
};

export const Title = ({ children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      minHeight: "5rem",
      width: "100%",
    }}
  >
    <T
      block
      variant="headline-small"
      color="rgb(0 0 0 / 54%)"
      style={{ padding: "1rem 3rem", flex: 1 }}
    >
      {children}
    </T>
  </div>
);

export const Section = ({ divider = true, style, children }) => (
  <div
    style={{
      padding: "2rem 3rem",
      borderTop: divider ? "0.1rem solid rgb(0 0 0 / 10%)" : undefined,
      ...style,
    }}
  >
    {children}
  </div>
);
