import React from "react";
import Spinner from "./Spinner";

const GraphLoader = ({ height }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height,
    }}
  >
    <Spinner size="3rem" color="#ccc" />
  </div>
);

export default GraphLoader;
