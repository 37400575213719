import React from "react";
import useHover from "../hooks/hover";

const HiddenOverlay = ({ renderOverlay, children, style, ...props }) => {
  const [isHovered, hoverHandlers] = useHover();
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        alignItems: "stretch",
        ...style,
      }}
      {...props}
    >
      {renderOverlay({ isHovered })}
      {children({
        ...hoverHandlers,
        style: {
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0,
          width: "100%",
          height: "100%",
        },
      })}
    </div>
  );
};

export default HiddenOverlay;
