import React from "react";
import { pie, arc } from "d3-shape";
import { PRIMARY_COLOR } from "../constants/colors";

const Pie = ({ size, weight, data, color = PRIMARY_COLOR }) => {
  const radius = size / 2;

  const createArc = arc()
    .cornerRadius(0)
    .innerRadius(radius - weight)
    .outerRadius(radius)
    .padAngle(0);

  const createPieData = pie();

  return (
    <svg height={size} width={size} style={{ display: "block" }}>
      <g transform={`translate(${radius},${radius})`}>
        {createPieData(data).map((d, i) => (
          <path
            key={i}
            d={createArc(d)}
            fill={typeof color === "function" ? color(d, i) : color}
          />
        ))}
      </g>
    </svg>
  );
};

export default Pie;
