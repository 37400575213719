import React, { useEffect } from 'react';
import { FullScreenPage, FullScreenGrid } from '../../common/skeleton';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { BaseForm } from '../../common/auth/forms';
import { userActions } from '../../actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../utils/history';

const LoginForm = (props) => {
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const loggingInError = useSelector(state => state.authentication.loggingInError);
  const dispatch = useDispatch()

  async function handleSubmit(values) {
    let redirect = history.location.state ? history.location.state.from : null
    dispatch(userActions.login(values.email, values.password, redirect))
  }

  useEffect(() => {
    dispatch(userActions.logout(false))
  }, [dispatch]);


  function ForgotTip() {
    return (
      <Box>
        <Box>
          <Typography variant="subtitle2" align="center">
            <Link to="/reset">Forgot your password?</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" align="center">
            Don’t have an account? <a href="https://tryobvious.typeform.com/to/UqZcnYfn">Request Early Access</a>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <BaseForm
      email={true}
      password={true}
      handleSubmit={handleSubmit}
      title="Sign in"
      buttonText="Continue"
      isLoading={loggingIn}
      success={loggedIn}
      error={loggingInError}
      hint={ForgotTip()}
      isLoginError={loggingInError}
    />
  )
}


const LoginComponent = () => {
  return (
    <FullScreenPage>
      <FullScreenGrid>
        <LoginForm />
      </FullScreenGrid>
    </FullScreenPage>
  )
}

export default LoginComponent
