import React from 'react';
import { ThemeProvider, createMuiTheme, responsiveFontSizes, styled, Container, Grid, makeStyles, useTheme } from '@material-ui/core';
import { Header } from './header';
import { Footer } from './footer';


export let landingTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        paddingRight: "15px !important",
        "&:focus": {
          backgroundColor: "#ffffff"
        }
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#6280EA",
      },
      current: {
        backgroundColor: "rgba(98, 128, 234, 0.1)",
      },
    },
  },
  typography: {
    fontFamily: [
      '"Muli"',
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 12,
    h1: {
      fontFamily: "Muli",
      fontSize: "5.8rem",
      fontWeight: "800",
      color: "#323245"
    },
    h2: {
      fontFamily: "Muli",
      fontSize: "45px",
      fontWeight: "800",
      color: "#323245"
    },
    h3: {
      fontFamily: "Muli",
      fontSize: "30px",
      fontWeight: "800",
      color: "#323245"
    },
    subtitle1: {
      fontFamily: "Inter",
      fontDisplay: "swap",
      fontWeight: "400",
      fontSize: "1.975rem",
      lineHeight: 1.6,
      color: "#323245",
      opacity: "0.9"
    },
    subtitle2: {
      fontFamily: "Inter",
      fontDisplay: "swap",
      fontWeight: "500",
      fontSize: "1.2rem",
      lineHeight: 1.6,
      color: "#323245",
      opacity: "0.5"
    },
  },
  palette: {
    background: {
      default: "#FFFEFC"
    }
  }

});

landingTheme = responsiveFontSizes(landingTheme);

export const LandingContainer = styled(Container)({
  maxWidth: "1030px"
})

export const FullScreenGrid = (props) => {
  const theme = useTheme()
  const useStyles = makeStyles({
    fullScreenGridContainer: {
      background: "#F5F6FA",
      minHeight: '100vh',
    },
    fullScreenGridItem: {
      maxWidth: "600px",
      minWidth: "400px",
      [theme.breakpoints.down('xs')]: {
        minWidth: "100%",
      },
    }
  });

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.fullScreenGridContainer}
    >
      <Grid item className={classes.fullScreenGridItem}>
        {props.children}
      </Grid>
    </Grid>
  )
}


export function MainScreen(props) {
  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <ThemeProvider theme={landingTheme}>
        <Header />
        {props.children}
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  )
}

export function MainScreenWithoutFooter(props) {
  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <ThemeProvider theme={landingTheme}>
        <Header />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  )
}

export function FullScreenPage(props) {
  return (
    <React.Fragment>
      <ThemeProvider theme={landingTheme}>
        {props.children}
      </ThemeProvider>
    </React.Fragment >
  )
}
