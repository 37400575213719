import React from "react";
import { css } from "emotion";
import Box from "./Box";
import Icon from "./Icon";

const SearchModal = ({ onSubmit, initialQuery }) => {
  const [query, setQuery] = React.useState(initialQuery || "");
  const inputRef = React.useRef();

  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  return (
    <Box shadowed background="white" padding={0}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(query);
        }}
        className={css({ position: "relative" })}
      >
        <Icon
          name="search"
          size="1.8rem"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "3rem",
            pointerEvents: "none",
          }}
        />
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className={css({
            padding: "3rem",
            paddingLeft: "7.2rem",
            display: "block",
            width: "100%",
            fontSize: "1.6rem",
            fontWeight: "500",
            "::placeholder": { color: "currentColor" },
          })}
          placeholder="Search your transaction history..."
          ref={inputRef}
        />
        <input type="submit" hidden />
      </form>
    </Box>
  );
};

export default SearchModal;
