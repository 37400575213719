import React from "react";
import FormattedNumber, {
  useFormatter as useNumberFormatter,
} from "./FormattedNumber";

export const useFormatter = ({
  style = "currency",
  currencyDisplay = "symbol",
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...formatOptions
}) =>
  useNumberFormatter({
    style,
    currencyDisplay,
    minimumFractionDigits:
      maximumFractionDigits == null
        ? minimumFractionDigits
        : Math.min(minimumFractionDigits, maximumFractionDigits),
    maximumFractionDigits,
    ...formatOptions,
  });

const FormattedCurrency = ({
  style = "currency",
  currencyDisplay = "symbol",
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...formatOptions
}) => (
  <FormattedNumber
    style={style}
    currencyDisplay={currencyDisplay}
    minimumFractionDigits={
      maximumFractionDigits == null
        ? minimumFractionDigits
        : Math.min(minimumFractionDigits, maximumFractionDigits)
    }
    maximumFractionDigits={maximumFractionDigits}
    {...formatOptions}
  />
);

export default FormattedCurrency;
