import React, { } from 'react';
import { LandingContainer, MainScreen } from '../common/skeleton'
import { Typography, Box, Container, makeStyles, useTheme, Grid } from '@material-ui/core';

import step1Svg from '../assets/thanks/step1.svg'
import step2Svg from '../assets/thanks/step2.svg'
import step3Svg from '../assets/thanks/step3.svg'

const Thanks = () => {
  const theme = useTheme();
  const useStyles = makeStyles({
    whiteText: {
      color: '#FFFEFC'
    },
    payContainer: {
      maxWidth: "815px"
    },
    sectionPadding: {
      [theme.breakpoints.up('md')]: {
        paddingTop: "100px"
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: "100px"
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: "70px"
      },
    },
    mainSectionPadding: {
      [theme.breakpoints.up('md')]: {
        paddingTop: "160px"
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: "100px"
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: "70px"
      },
    },
    mainContainer: {
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        justifyContent: "center"
      }
    },
    stepGridContainer: {
      justifyContent: "space-between",
    },
    stepGridItem: {
      textAlign: "center",
    },
    stepText: {
      fontFamily: "Inter",
      fontDisplay: "swap",
      fontSize: "18px",
      lineHeight: 1.6,
      color: "#323245"
    },
  });

  const classes = useStyles();


  return (
    <MainScreen>
      <LandingContainer>
        <Container className={classes.payContainer}>
          <Box className={classes.mainSectionPadding} align="center">
            <Typography variant="h2" component="h2">Account successfully connected!</Typography>
            <Box paddingTop="20px">
              <Typography variant="subtitle1">We’re processing your transaction data to identify what kind of insights we can bring you. You can close this now and we'll reach out via email when ready.</Typography>
            </Box>
          </Box>
        </Container>

        <Box paddingTop="100px">
          <Grid container spacing={6} className={classes.stepGridContainer}>
            <Grid item xs={12} sm={6} md={4} className={classes.stepGridItem}>
              <Box>
                <img draggable="false" src={step1Svg} alt="Step 1" />
              </Box>
              <Box paddingTop="20px">
                <Typography variant="h3">Account connected</Typography>
              </Box>
              <Box paddingTop="15px">
                <Typography className={classes.stepText}>We’ve successfully retrieved your transaction data.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.stepGridItem}>
              <Box>
                <img draggable="false" src={step2Svg} alt="Step 2" />
              </Box>
              <Box paddingTop="20px">
                <Typography variant="h3">Analyzing data</Typography>
              </Box>
              <Box paddingTop="15px">
                <Typography className={classes.stepText}>The transaction data is being analyzed.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.stepGridItem}>
              <Box>
                <img draggable="false" src={step3Svg} alt="Step 3" />
              </Box>
              <Box paddingTop="20px">
                <Typography variant="h3">Onboarding done</Typography>
              </Box>
              <Box paddingTop="15px">
                <Typography className={classes.stepText}>Once we’ve processed the data, onboarding is complete.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LandingContainer>
    </MainScreen>
  )

}

export default Thanks
