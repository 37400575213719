import React from "react";

const FractionBar = ({
  orientation = "horizontal",
  justfify, // top | bottom / left | right
  width,
  height,
  background,
  color,
  fraction,
}) => {
  const isHorizontal = orientation === "horizontal";
  return (
    <div style={{ position: "relative", height, width, background }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          background: color,
          willChange: "transform",
          transition: "0.2s ease-out transform, 0.1s background",
          transformOrigin: justfify ?? isHorizontal ? "left" : "bottom",
          transform: isHorizontal
            ? `scaleX(${fraction})`
            : `scaleY(${fraction})`,
        }}
      />
    </div>
  );
};

export default FractionBar;
