import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error.message, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { renderFallback, children } = this.props;

    if (!error) return children;

    return renderFallback({ error });
  }
}

export default ErrorBoundary;
