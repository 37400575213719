import React from "react";

const PageContainer = ({ header, children }) => (
  <>
    {header != null && (
      <div
        style={{
          height: "6rem",
          display: "flex",
          alignItems: "stretch",
          borderBottom: "0.1rem solid",
          borderColor: "rgb(239, 241, 244)",
        }}
      >
        {header}
      </div>
    )}
    <div
      style={{
        flex: "1 1",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  </>
);

export default PageContainer;
