import React, { useState, useEffect } from 'react';
import { FullScreenPage, FullScreenGrid } from '../../common/skeleton';
import { fillProfile } from '../../api/users';
import { Box, Grid, Typography, makeStyles, Select, MenuItem, FormControl, useTheme } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { BlackBetaButton } from '../../common/buttons'
import { history } from '../../utils/history';
import queryString from 'query-string'
import { companyService } from '../../api/companies';
import { useDispatch } from 'react-redux';
import { companyActions } from '../../actions/company.actions';


const RegisterProfileForm = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    emailInput: {
      fontFamily: "Muli",
      fontSize: "16px",
      width: "100%",
      maxWidth: "400px",
      borderRadius: "3px",
      outline: "none",
      border: "0px",
      height: "100%",
      boxSizing: "border-box",
      padding: "10px 10px",
      background: "#FFFFFF",
      '&::placeholder': {
        opacity: 0.5,
      },
    },
    emailButton: {
      width: "100%",
      maxWidth: "400px"
    },
    hintBox: {
      maxWidth: "400px"
    },
    emailSelect: {
      fontFamily: "Muli",
      fontSize: "16px",
      lineHeight: "20px",
      width: "100%",
      maxWidth: "400px",
      borderRadius: "3px",
      outline: "none",
      border: "0px",
      height: "40px",
      boxSizing: "border-box",
      background: "#FFFFFF",
      padding: "10px 10px",
      textAlign: "left",
    },
    formControl: {
      width: "100%",
      alignItems: "center"
    },
    formBox: {
      [theme.breakpoints.down('xs')]: {
        padding: "0px 10px"
      },
    },
    formGridContainer: {
      [theme.breakpoints.up('xs')]: {
        textAlign: "center",
      }
    }
  });


  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [role, setRole] = useState('');
  const [hasError, setHasError] = useState(props.hasError)
  let { invite } = queryString.parse(window.location.search);
  const dispatch = useDispatch()

  useEffect(() => {
    if (success) {
      history.push('/dashboard')
    }
    return () => {

    };
  }, [success])



  async function handleOnChange(event) {
    let value = event.target.value
    if (value !== "") {
      setHasError(false)
    }
    setRole(value)
  }

  async function handleSubmit(values) {
    setIsLoading(true)
    fillProfile(values.given_name, values.family_name, values.company_name, role)
      .then(function (result) {
        if (invite) {
          // need to confirm company
          companyService.confirmInvitation(invite)
            .then(response => {
              dispatch(companyActions.setCurrentCompany(response))
            }).then(_ => {
              setSuccess(true)
            })
        } else {
          setSuccess(true)
        }
      }).catch(function (error) {
        console.error(error)
      }).finally(function () {
        setIsLoading(false)
      })
  }

  return (
    <Box className={classes.formBox}>
      <Typography variant="h3" align="center">Tell us a bit about yourself</Typography>
      <Box paddingTop="15px">
        <Typography align="center">It makes it easier for us to address you and your colleagues.</Typography>
      </Box>

      <Box paddingTop="40px">
        <Formik
          initialValues={{ given_name: '', family_name: '', company_name: '', company_role: '' }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Grid container spacing={2} direction="column" className={classes.formGridContainer}>
                <Grid item xs={12}>
                  <Field type="text" name="given_name" autoComplete="given-name" placeholder="First name" required className={classes.emailInput} />
                </Grid>

                <Grid item>
                  <Field type="text" name="family_name" autoComplete="family-name" placeholder="Last name" required className={classes.emailInput} />
                </Grid>

                {!invite &&

                  <Grid item>
                    <Field type="text" name="company_name" placeholder="Company name" autoComplete="organization" required className={classes.emailInput} />
                  </Grid>
                }

                {!invite &&
                  <Grid item>
                    <FormControl required error={hasError} className={classes.formControl}>
                      <Select
                        required
                        disableUnderline
                        displayEmpty
                        value={role}
                        name="company_role"
                        onChange={handleOnChange}
                        className={classes.emailSelect}
                      >
                        <MenuItem value="" disabled selected>Role at company</MenuItem>
                        <MenuItem value="founder">Founder</MenuItem>
                        <MenuItem value="ceo">CEO</MenuItem>
                        <MenuItem value="cfo">CFO</MenuItem>
                        <MenuItem value="accountant">Accountant</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem>
                        <MenuItem value="office_manager">Office manager</MenuItem>
                        <MenuItem value="other">Other</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                }

                <Grid item>
                  <BlackBetaButton type="submit" disabled={isLoading} className={classes.emailButton}>Finish setup</BlackBetaButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box >
  )

}



const RegisterProfileComponent = () => {
  return (
    <FullScreenPage>
      <FullScreenGrid>
        <RegisterProfileForm />
      </FullScreenGrid>
    </FullScreenPage>
  )
}

export default RegisterProfileComponent
