import React from "react";
import Popover from "@reach/popover";
import { Link as RouterLink } from "react-router-dom";
import { Bar as VictoryBar } from "victory";
import {
  GRID as GRID_COLOR,
  AXIS as AXIS_COLOR,
} from "../constants/chart-colors";
import { useChart, Grid, XAxis, YAxis } from "./chart-utils";
import FormattedDate from "./FormattedDate";
import FormattedNumber from "./FormattedNumber";

const BarChart = ({
  data,
  domain: domainProp,
  width = "100%",
  height = "18rem",
  xTicks: xTicksProp,
  yTicks: yTicksProp,
  renderXTick,
  renderYTick,
  onClick,
  createDatumLink,
  hoverPopoverPosition,
  renderHoverPopoverContent,
  gridColor = GRID_COLOR,
  axisColor = AXIS_COLOR,
  tickColor = "rgb(0 0 0 / 54%)",
  xAxis = true,
  yAxis = true,
  children,
}) => {
  const [hoveredX, setHoveredX] = React.useState(null);
  const hoveredXRef = React.useRef();

  const {
    containerRef,
    containerRect,
    domain,
    extent,
    scaleX,
    scaleY,
    xTicks,
    yTicks,
  } = useChart({
    data: data.flatMap((d) => (d.type === "group" ? d.data : d)),
    domain: domainProp,
    xTicks: xTicksProp,
    yTicks: yTicksProp,
  });

  const [[xDomainStart, xDomainEnd], [yDomainStart, yDomainEnd]] = domain;

  const defaultHoverPopoverPosition = React.useCallback(
    (hoveredRect, popoverRect) => {
      const hoveredRectCenter = hoveredRect.left + hoveredRect.width / 2;

      const top = `calc(${hoveredRect.top + window.scrollY}px + 2rem)`;
      const left =
        hoveredRectCenter + popoverRect.width + 40 > window.innerWidth
          ? `calc(${hoveredRectCenter - popoverRect.width}px - 2rem)`
          : `calc(${hoveredRectCenter}px + 2rem)`;

      return { top, left };
    },
    []
  );

  return (
    <div
      ref={containerRef}
      style={{ width, maxWidth: "100%", position: "relative" }}
    >
      <Grid
        xTicks={xTicks}
        yTicks={yTicks}
        scaleX={scaleX}
        scaleY={scaleY}
        color={({ x }) => (x != null ? "transparent" : gridColor)}
        style="solid" // eslint-disable-line react/style-prop-object
      />

      {yAxis && (
        <YAxis
          ticks={yTicks}
          scale={scaleY}
          orientation="right"
          axisColor={axisColor}
          labelColor={tickColor}
          renderTick={(y) => {
            if (typeof renderYTick === "function") return renderYTick(y);

            return (
              <FormattedNumber
                value={y}
                style="decimal" // eslint-disable-line react/style-prop-object
                notation="compact"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            );
          }}
        />
      )}

      {xAxis && (
        <XAxis
          ticks={xTicks}
          scale={scaleX}
          axisColor={axisColor}
          labelColor={tickColor}
          renderTick={(x) => {
            if (typeof renderXTick === "function") return renderXTick(x);
            return <FormattedDate value={x} day="numeric" month="short" />;
          }}
        />
      )}

      <svg
        width="100%"
        height={height}
        style={{
          display: "block",
          position: "relative",
          zIndex: 1,
          // overflow: "visible",
          overflow: "hidden",
        }}
      >
        {hoveredX != null && (
          <line
            x1={scaleX(hoveredX)}
            y1={scaleY(yDomainStart)}
            x2={scaleX(hoveredX)}
            y2={scaleY(yDomainEnd)}
            stroke="rgb(0 0 0 / 25%)"
            strokeDasharray="4 2"
          />
        )}

        <Bars data={data} hoveredX={hoveredX} scaleX={scaleX} scaleY={scaleY} />

        {containerRect != null && (
          <g
            clipPath={`view-box polygon(0 -1, ${containerRect.width} -1, ${
              containerRect.width
            } ${containerRect.height + 2}, 0 ${containerRect.height + 2})`}
            // style={{
            //   clipPath: `view-box polygon(0 -1, ${containerRect.width} -1, ${
            //     containerRect.width
            //   } ${containerRect.height + 2}, 0 ${containerRect.height + 2})`,
            //   overflow: "hidden",
            // }}
            style={{ pointerEvents: "none" }}
          >
            {typeof children === "function" &&
              children({ scaleX, scaleY, domain, extent, hoveredX })}
          </g>
        )}
      </svg>

      {data[0].data.map(([x]) => {
        // This assumes that datums are evenly spaced
        const full = scaleX(xDomainEnd) - scaleX(xDomainStart);
        const width = full / data[0].data.length;

        const link =
          typeof createDatumLink === "function" ? createDatumLink([x]) : null;
        const Element = link == null ? "div" : RouterLink;

        return (
          <Element
            key={x.getTime()}
            style={{
              position: "absolute",
              left: scaleX(x) - width / 2,
              top: 0,
              width,
              height: "100%",
            }}
            to={link ?? undefined}
            onClick={
              typeof onClick === "function" ? (e) => onClick(x, e) : undefined
            }
            onMouseMove={(e) => {
              hoveredXRef.current = e.target;
              setHoveredX(x);
            }}
            onMouseLeave={() => {
              hoveredXRef.current = null;
              setHoveredX(null);
            }}
          />
        );
      })}

      {hoveredX != null && typeof renderHoverPopoverContent === "function" && (
        <Popover
          targetRef={hoveredXRef}
          style={{
            zIndex: 2,
            position: "absolute",
            pointerEvents: "none",
          }}
          position={hoverPopoverPosition ?? defaultHoverPopoverPosition}
        >
          {renderHoverPopoverContent(hoveredX)}
        </Popover>
      )}
    </div>
  );
};

const Bars = ({ data, hoveredX, scaleY, scaleX }) => {
  return (
    <>
      {data.map(({ type, ...rest }) => {
        if (type !== "group") {
          const { data, color, barWidth, cornerRadius } = rest;
          return data.map(([x, y]) => {
            const scaledY = scaleY(y);
            const scaled0 = scaleY(0);

            return (
              <Bar
                key={x.getTime()}
                barWidth={barWidth}
                color={
                  typeof color === "function" ? color([x, y], hoveredX) : color
                }
                x={scaleX(x)}
                y0={scaled0}
                y={scaledY}
                cornerRadius={
                  cornerRadius ?? { top: scaled0 !== scaledY ? 2 : 0 }
                }
              />
            );
          });
        }

        const { data: datasets, barWidth = 8, gutter = 0, cornerRadius } = rest;

        const groupWidth =
          barWidth * datasets.length + gutter * datasets.length - 1;

        return datasets.map(({ id, data, color }, index) => {
          return data.map(([x, y]) => {
            const scaledY = scaleY(y);
            const scaled0 = scaleY(0);

            return (
              <Bar
                key={`${id ?? index}-${x?.getTime() ?? x}`}
                alignment="start"
                barWidth={barWidth}
                color={
                  typeof color === "function" ? color([x, y], hoveredX) : color
                }
                x={scaleX(x) + index * (barWidth + gutter) - groupWidth / 2}
                y0={scaled0}
                y={scaledY}
                cornerRadius={
                  cornerRadius ?? { top: scaled0 !== scaledY ? 2 : 0 }
                }
              />
            );
          });
        });
      })}
    </>
  );
};

const Bar = React.memo(
  ({ alignment = "middle", barWidth = 8, color, ...rest }) => (
    <VictoryBar
      alignment={alignment}
      barWidth={barWidth}
      style={{ fill: color, stroke: "transparent" }}
      horizontal={false}
      {...rest}
    />
  )
);

export default BarChart;
