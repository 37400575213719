export const mapEntries = (mapper, object) =>
  Object.fromEntries(Object.entries(object).map(mapper));

export const mapValues = (mapper, object) =>
  mapEntries(([key, value]) => [key, mapper(value)], object);

export const omit = (keyToOmit, object) =>
  Object.fromEntries(
    Object.entries(object).filter(([key]) => key !== keyToOmit)
  );
