import formatDate from "date-fns/format";
import subtractMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { GREY_BACKGROUND, RED, GREEN } from "../../constants/colors";
import { pluralise } from "../../utils/string";
import useMetric from "../../hooks/metric-data";
import G from "../Grid";
import T from "../Text";
import Divider from "../Divider";
import FormattedDate from "../FormattedDate";
import Circle from "../Circle";
import Select from "../Select";
import Tooltip, { positionTopCenter } from "../Tooltip";
import InlineTag from "../InlineTag";
import FormattedNumber from "../FormattedNumber";
import FormattedCurrencyWithSmallDecimals from "../FormattedCurrencyWithSmallDecimals";

const runwayCalculationRollingAverageWindowOptions = [
  { value: 1, label: "Last month", shortLabel: "last month" },
  { value: 3, label: "3-month rolling average", shortLabel: "3-month avg." },
  { value: 6, label: "6-month rolling average", shortLabel: "6-month avg." },
  { value: 12, label: "12-month rolling average", shortLabel: "12-month avg." },
];

const RunwayBlock = ({ bankBalance, currency, categories }) => {
  const [
    burnRollingAverageWindow,
    setBurnRollingAverageWindow,
  ] = React.useState(3);
  const [
    revenueRollingAverageWindow,
    setRevenueRollingAverageWindow,
  ] = React.useState(3);
  const [scenario, setScenario] = React.useState("net-burn");

  const to = React.useMemo(() => endOfMonth(subtractMonths(new Date(), 1)), []);
  const burnRange = [
    startOfMonth(subtractMonths(to, burnRollingAverageWindow - 1)),
    to,
  ];
  const revenueRange = [
    startOfMonth(subtractMonths(to, revenueRollingAverageWindow - 1)),
    to,
  ];
  const burnCategories = categories.filter((c) => c.id !== "internal-transfer");
  const revenueCategories = categories.filter(
    (c) => !["internal-transfer", "loans", "investments-grants"].includes(c.id)
  );

  const runwayQuery = React.useMemo(
    () => ({
      metric: "runway",
      frequency: "monthly",
      from: startOfMonth(to),
      to,
      burnWindow: burnRollingAverageWindow,
      revenueWindow: revenueRollingAverageWindow,
    }),
    [to, burnRollingAverageWindow, revenueRollingAverageWindow]
  );

  const { runway: dataset } = useMetric({ runway: runwayQuery });

  const {
    pessimistic_runway: grossBurnRunwayInMonths,
    pessimistic_runway_end: grossBurnRunwayEndDateTimestamp,
    realistic_runway: netBurnRunwayInMonths,
    realistic_runway_end: netBurnRunwayEndDateTimestamp,
    rolling_burn: averageMonthlyBurn,
    rolling_revenue: averageMonthlyRevenue,
  } = dataset?.slice(-1)[0] ?? {};

  const [runwayInMonths, runwayEndDateTimestamp] =
    scenario === "gross-burn"
      ? [grossBurnRunwayInMonths, grossBurnRunwayEndDateTimestamp]
      : [netBurnRunwayInMonths, netBurnRunwayEndDateTimestamp];

  const runwayEndDate =
    runwayEndDateTimestamp == null
      ? null
      : new Date(runwayEndDateTimestamp * 1000);

  const averageMonthlyNetBurn = [
    averageMonthlyRevenue,
    averageMonthlyBurn,
  ].some((n) => n == null)
    ? null
    : averageMonthlyRevenue - averageMonthlyBurn;

  const isProfitable = scenario === "net-burn" && averageMonthlyNetBurn > 0;

  const exactRunwayInMonths = [bankBalance, dataset].some((n) => n == null)
    ? null
    : bankBalance /
      (scenario === "gross-burn"
        ? averageMonthlyBurn
        : Math.abs(averageMonthlyNetBurn));

  return (
    <div
      style={{
        background: GREY_BACKGROUND,
        borderRadius: "0.3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "4rem 2rem",
        textAlign: "center",
      }}
    >
      <T>
        Runway for{" "}
        <Select
          value={scenario}
          options={[
            {
              value: "net-burn",
              label: "Default (net burn)",
              shortLabel: "default scenario",
            },
            {
              value: "gross-burn",
              label: "Expenses only (gross burn)",
              shortLabel: "expenses-only scenario",
            },
          ]}
          onChange={(e) => setScenario(e.target.value)}
          renderTrigger={(o) => (
            <T block={false} variant="link">
              {o.shortLabel}
            </T>
          )}
          style={{ display: "inline-flex" }}
        />
      </T>

      <T
        variant="number-large"
        size="10rem"
        lineHeight={1}
        margin="4rem 0 0"
        hide={runwayInMonths == null}
      >
        {isProfitable ? <>&infin;</> : runwayInMonths ?? "-"}
      </T>
      <T
        block
        color="rgb(0 0 0 / 54%)"
        lineHeight={1.3}
        weight="500"
        hide={runwayInMonths == null}
      >
        {isProfitable ? "Profitable" : pluralise("month", runwayInMonths)}
      </T>

      <Divider size="5rem" />

      <Tooltip
        label={
          <T block color="rgb(255 255 255 / 60%)" lineHeight={1.6}>
            Gross burn calculated using all{" "}
            <span style={{ fontStyle: "italic" }}>outgoing</span>
            <br />
            transactions, excluding{" "}
            <InlineTag dark>Internal transfer</InlineTag>.<br />
            <br />
            Click to see transactions &rarr;
          </T>
        }
        position={positionTopCenter}
        offset={5}
      >
        <div>
          <T block={false}>
            <T
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                gridAutoFlow: "column",
                gridGap: "0.7rem",
              }}
            >
              <Circle size="0.7rem" background={RED} />
              <span>
                Burn{" "}
                <Select
                  value={burnRollingAverageWindow}
                  options={runwayCalculationRollingAverageWindowOptions}
                  onChange={(e) =>
                    setBurnRollingAverageWindow(parseInt(e.target.value))
                  }
                  renderTrigger={(option) => (
                    <T block={false} variant="link">
                      {option.shortLabel}
                    </T>
                  )}
                  style={{ display: "inline-flex" }}
                />
              </span>
            </T>
          </T>
          <RouterLink
            to={{
              pathname: "/dashboard/transactions",
              search: new URLSearchParams(
                Object.entries({
                  from: formatDate(burnRange[0], "yyyy-MM-dd"),
                  to: formatDate(burnRange[1], "yyyy-MM-dd"),
                  categories: [...burnCategories, "unknown"]
                    .map((c) => c.id)
                    .join(","),
                  "max-amount": 0,
                })
              ).toString(),
            }}
            style={{ display: "block" }}
          >
            <T
              block
              variant="number-large"
              size="2.6rem"
              hide={averageMonthlyBurn == null}
              margin="0.8rem 0"
            >
              {averageMonthlyBurn == null ? (
                "-"
              ) : (
                <FormattedCurrencyWithSmallDecimals
                  value={averageMonthlyBurn}
                  currency={currency}
                />
              )}
            </T>
          </RouterLink>
        </div>
      </Tooltip>

      <Divider size="2rem" />

      <Tooltip
        label={
          <T block color="rgb(255 255 255 / 60%)" lineHeight={1.6}>
            Gross revenue calculated using all{" "}
            <span style={{ fontStyle: "italic" }}>incoming</span>
            <br />
            transactions, excluding <InlineTag dark>Funding</InlineTag>,
            <br />
            <InlineTag dark>Loans</InlineTag>, and{" "}
            <InlineTag dark>Internal transfer</InlineTag>.<br />
            <br />
            Click to see transactions &rarr;
          </T>
        }
        position={positionTopCenter}
        offset={5}
      >
        <div
          style={{
            transition: "0.1s opacity",
            opacity: scenario === "gross-burn" ? 0.4 : 1,
          }}
        >
          <T block={false}>
            <T
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "flex-end",
                gridAutoFlow: "column",
                gridGap: "0.7rem",
              }}
            >
              <Circle size="0.7rem" background={GREEN} />
              <span>
                Revenue{" "}
                <Select
                  value={revenueRollingAverageWindow}
                  options={runwayCalculationRollingAverageWindowOptions}
                  onChange={(e) =>
                    setRevenueRollingAverageWindow(parseInt(e.target.value))
                  }
                  renderTrigger={(option) => (
                    <T block={false} variant="link">
                      {option.shortLabel}
                    </T>
                  )}
                  style={{ display: "inline-flex" }}
                />
              </span>
            </T>
          </T>
          <RouterLink
            to={{
              pathname: "/dashboard/transactions",
              search: new URLSearchParams(
                Object.entries({
                  from: formatDate(revenueRange[0], "yyyy-MM-dd"),
                  to: formatDate(revenueRange[1], "yyyy-MM-dd"),
                  categories: [...revenueCategories, "unknown"]
                    .map((c) => c.id)
                    .join(","),
                  "min-amount": 0,
                })
              ).toString(),
            }}
            style={{ display: "block" }}
          >
            <T
              block
              size="2.6rem"
              variant="number-large"
              hide={averageMonthlyRevenue == null}
              margin="0.8rem 0"
            >
              {averageMonthlyRevenue == null ? (
                "-"
              ) : (
                <FormattedCurrencyWithSmallDecimals
                  value={averageMonthlyRevenue}
                  currency={currency}
                />
              )}
            </T>
          </RouterLink>
        </div>
      </Tooltip>

      {averageMonthlyRevenue !== 0 && (
        <>
          <Divider />
          <Tooltip
            position={positionTopCenter}
            offset={5}
            label={
              <T block color="rgb(255 255 255 / 60%)" lineHeight={1.2}>
                <CalculationTable
                  rows={
                    averageMonthlyNetBurn > 0
                      ? [
                          ["Net revenue", "=", "Gross revenue - Gross burn"],
                          [
                            <FormattedNumber value={averageMonthlyNetBurn} />,
                            "=",
                            <>
                              <FormattedNumber value={averageMonthlyRevenue} />{" "}
                              - <FormattedNumber value={averageMonthlyBurn} />
                            </>,
                          ],
                        ]
                      : [
                          ["Net burn", "=", "Gross burn - Gross revenue"],
                          [
                            <FormattedNumber
                              value={Math.abs(averageMonthlyNetBurn)}
                            />,
                            "=",
                            <>
                              <FormattedNumber value={averageMonthlyBurn} /> -{" "}
                              <FormattedNumber value={averageMonthlyRevenue} />
                            </>,
                          ],
                        ]
                  }
                />
              </T>
            }
          >
            <div
              style={{
                transition: "0.1s opacity",
                opacity: scenario === "gross-burn" ? 0.4 : 1,
              }}
            >
              <T hide={averageMonthlyNetBurn == null}>
                <T
                  style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    gridAutoFlow: "column",
                    gridGap: "0.7rem",
                  }}
                >
                  <Circle
                    size="0.7rem"
                    background={averageMonthlyNetBurn > 0 ? GREEN : RED}
                  />
                  <span>
                    {averageMonthlyNetBurn > 0 ? "Net revenue" : "Net burn"}
                  </span>
                </T>
              </T>
              <T
                block
                variant="number-large"
                size="2.6rem"
                hide={averageMonthlyNetBurn == null}
                margin="0.8rem 0"
              >
                {averageMonthlyNetBurn == null ? (
                  "-"
                ) : (
                  <FormattedCurrencyWithSmallDecimals
                    value={Math.abs(averageMonthlyNetBurn)}
                    currency={currency}
                  />
                )}
              </T>
            </div>
          </Tooltip>
        </>
      )}

      {!isProfitable && (
        <>
          <Divider size="5rem" />

          <div
            style={{
              transition: "0.1s opacity",
              opacity: runwayEndDate == null ? 0 : 1,
            }}
          >
            {runwayEndDate != null && (
              <>
                <T block>Lasts until</T>
                <T block variant="number-large" size="1.8rem" margin="0.8rem 0">
                  <FormattedDate
                    value={runwayEndDate}
                    month="long"
                    year="numeric"
                  />
                </T>
              </>
            )}
          </div>
        </>
      )}

      <T
        block
        size="1.2rem"
        lineHeight={1.5}
        weight="500"
        hide={[bankBalance, averageMonthlyNetBurn].some((n) => n == null)}
        color="rgb(0 0 0 / 54%)"
      >
        {[bankBalance, averageMonthlyNetBurn].some((d) => d == null) ? (
          "-"
        ) : (
          <>
            <Divider size="1rem" />

            {scenario === "net-burn" ? (
              <>
                {averageMonthlyNetBurn < 0 ? (
                  <G
                    template="repeat(3,max-content)"
                    gap="0.2rem 0.5rem"
                    style={{ textAlign: "left" }}
                  >
                    <div style={{ textAlign: "right" }}>Runway</div>
                    <div>=</div>
                    <div>Total balance / Net burn</div>
                    <div style={{ textAlign: "right" }}>
                      <FormattedNumber
                        value={exactRunwayInMonths}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </div>
                    <div>=</div>
                    <div>
                      <FormattedNumber
                        value={bankBalance}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />{" "}
                      /{" "}
                      <FormattedNumber
                        value={Math.abs(averageMonthlyNetBurn)}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </div>
                  </G>
                ) : (
                  <div />
                )}
              </>
            ) : (
              <G
                template="repeat(3,max-content)"
                gap="0.2rem 0.5rem"
                style={{ textAlign: "left" }}
              >
                <div style={{ textAlign: "right" }}>Runway</div>
                <div>=</div>
                <div>Total balance / Gross burn</div>
                <div style={{ textAlign: "right" }}>
                  <FormattedNumber
                    value={exactRunwayInMonths}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </div>
                <div>=</div>
                <div>
                  <FormattedNumber
                    value={bankBalance}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />{" "}
                  /{" "}
                  <FormattedNumber
                    value={averageMonthlyBurn}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </G>
            )}
          </>
        )}
      </T>

      <div style={{ flex: 1 }} />

      <Divider size="4rem" />
      <RouterLink to="/dashboard/runway">
        <T color="rgb(0 0 0 / 54%)">Custom runway projections &rarr;</T>
      </RouterLink>
    </div>
  );
};

const CalculationTable = ({ rows }) => (
  <G
    template="repeat(3,max-content)"
    gap="0.2rem 0.5rem"
    style={{ textAlign: "left" }}
  >
    {rows.map((columns) =>
      columns.map((content, i) => (
        <div key={i} style={{ textAlign: i === 0 ? "right" : "left" }}>
          {content}
        </div>
      ))
    )}
  </G>
);

export default RunwayBlock;
