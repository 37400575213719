import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings'],
  stateReconciler: autoMergeLevel1
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer