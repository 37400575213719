export const companyConstants = {
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',

  FETCH_COMPANIES_REQUEST: 'FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'FETCH_COMPANIES_FAILURE',

  FETCH_COMPANY_REQUEST: 'FETCH_COMPANY_REQUEST',
  FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',
  FETCH_COMPANY_FAILURE: 'FETCH_COMPANY_FAILURE',
};