import getWeek from "date-fns/getWeek";
import { css } from "emotion";
import React from "react";
import { useRect } from "@reach/rect";
import Popover from "@reach/popover";
import { scaleLinear, scaleTime } from "d3-scale";
import { line } from "d3-shape";
import { extent } from "d3-array";
import { PRIMARY_COLOR, GREEN_TEXT } from "../constants/colors";
import {
  ONE_DAY_IN_MILLIS,
  ONE_WEEK_IN_MILLIS,
  ONE_MONTH_IN_MILLIS,
} from "../constants/time";
import { selectDataByQuery as selectMetricDataByQuery } from "../hooks/metrics";
import { selectRequestStatusByQuery as selectMetricRequestStatusByQuery } from "../hooks/metrics";
import { XAxis, YAxis, Grid, HorizontalLine } from "./chart-utils";
import Divider from "./Divider";
import Text from "./Text";
import GraphLoader from "./GraphLoader";
import GraphError from "./GraphError";
import FormattedDate from "./FormattedDate";
import FormattedNumber from "./FormattedNumber";
import FormattedCurrency from "./FormattedCurrency";

const padLinear = ([x0, x1], k) => {
  const dx = ((x1 - x0) * k) / 2;
  return [x0 - dx, x1 + dx];
};

const dataPointLengthInMillisByFrequency = {
  daily: ONE_DAY_IN_MILLIS,
  weekly: ONE_WEEK_IN_MILLIS,
  monthly: ONE_MONTH_IN_MILLIS,
  quarterly: ONE_MONTH_IN_MILLIS * 3,
  yearly: ONE_MONTH_IN_MILLIS * 12,
};

const frequencyConfig = [
  {
    key: "daily",
    formatXTick: (x) => <FormattedDate value={x} day="numeric" month="short" />,
  },
  {
    key: "weekly",
    formatXTick: (x) => getWeek(x, { weekStartsOn: 1 }),
  },
  {
    key: "monthly",
    formatXTick: (x) => <FormattedDate value={x} month="short" />,
  },
  {
    key: "quarterly",
    formatXTick: (x) => <FormattedDate value={x} month="short" />,
  },
  {
    key: "yearly",
    formatXTick: (x) => <FormattedDate value={x} year="numeric" />,
  },
];

const CashFlowGraph = ({
  getMetricState,
  frequency,
  fromDate,
  toDate,
  currency,
  renderDate,
  selectedDataPoints,
  onClickDataPoint,
  fetchMetricData,
}) => {
  const [hoveredDate, setHoveredDate] = React.useState(null);

  const hoveredRef = React.useRef();

  const containerRef = React.useRef();
  const containerRect = useRect(containerRef, true);

  const balanceChartRef = React.useRef();
  const balanceChartRect = useRect(balanceChartRef, true);

  const cashFlowChartRef = React.useRef();
  const cashFlowChartRect = useRect(cashFlowChartRef, true);

  const metrics = ["money_in", "money_out", "money_net", "close_balance"];

  const [
    { data: inData, error: inDataError },
    { data: outData, error: outDataError },
    { data: netData, error: netDataError },
    { data: balanceData, error: balanceDataError },
  ] = metrics.map((metric) => {
    const query = { metric, frequency, from: fromDate, to: toDate };

    const data = getMetricState(selectMetricDataByQuery(query));

    const requestState = getMetricState(
      selectMetricRequestStatusByQuery(query)
    );

    return {
      data: data?.map(({ date, value }) => [date, value]) ?? [],
      error: requestState?.error ?? null,
    };
  });

  const cashFlowError = [inDataError, outDataError, netDataError].find(
    (e) => e != null
  );

  const cashFlowChartDataSets = [
    {
      color: GREEN_TEXT,
      style: {
        borderTopLeftRadius: "0.3rem",
        borderTopRightRadius: "0.3rem",
      },
      data: inData,
    },
    {
      color: "#c4c4c4",
      style: {
        borderBottomLeftRadius: "0.3rem",
        borderBottomRightRadius: "0.3rem",
      },
      data: outData,
    },
    {
      color: "black",
      data: netData,
    },
  ];

  const { formatXTick } = frequencyConfig.find(({ key }) => key === frequency);
  const formatYTick = (n) => <FormattedNumber value={n} notation="compact" />;

  const nonEmptyDataset = [outData, outData, netData, balanceData].find(
    (d) => d.length !== 0
  );

  const [xStart, xEnd] = extent(nonEmptyDataset ?? [], (d) => d[0]);

  const hasAllCashFlowData = [inData, outData, netData].every(
    (d) => d.length !== 0
  );
  const hasAllData = [inData, outData, netData, balanceData].every(
    (d) => d.length !== 0
  );

  const scaleX =
    xStart != null
      ? scaleTime()
          .domain([
            new Date(
              xStart.getTime() -
                dataPointLengthInMillisByFrequency[frequency] / 2
            ),
            new Date(
              xEnd.getTime() + dataPointLengthInMillisByFrequency[frequency] / 2
            ),
          ])
          .range([0, balanceChartRect?.width || 0])
      : null;

  const xTicks =
    typeof scaleX === "function"
      ? scaleX.ticks(Math.min(netData.length, 12))
      : [];

  const balanceChartScaleY = scaleLinear()
    .domain(
      padLinear(
        extent(balanceData, (d) => d[1]),
        0.5
      )
    )
    .range([balanceChartRect?.height || 0, 0]);

  const balanceChartYTicks = balanceChartScaleY.ticks(3);

  const cashFlowChartScaleY = scaleLinear()
    .domain(
      padLinear(
        extent(
          cashFlowChartDataSets.flatMap((ds) => ds.data),
          (d) => d[1]
        ),
        0.2
      )
    )
    .range([cashFlowChartRect?.height || 0, 0]);

  const cashFlowChartYTicks = cashFlowChartScaleY.ticks(5);

  const handleHoverDate = (date, e) => {
    hoveredRef.current = e?.target;
    setHoveredDate(date);
  };

  const highlightedDataPoints =
    hoveredDate != null
      ? [balanceData, netData, inData, outData]
          .map((data) =>
            data.find(([date]) => hoveredDate.getTime() === date.getTime())
          )
          .filter(Boolean)
      : [];

  return (
    <>
      <Text
        block
        size="1.1rem"
        color="rgb(0 0 0 / 54%)"
        weight="500"
        style={{ margin: "0 0 0.5rem" }}
      >
        Bank balance
      </Text>
      <div ref={containerRef} style={{ position: "relative" }}>
        <div className={css({ padding: "0 4rem 0 0" })}>
          <div ref={balanceChartRef}>
            {balanceDataError != null ? (
              <GraphError
                height="8rem"
                retry={() => fetchMetricData("close_balance")}
              />
            ) : balanceData.length === 0 ? (
              <GraphLoader height="8rem" />
            ) : (
              <LineChart
                scaleX={scaleX}
                scaleY={balanceChartScaleY}
                xTicks={xTicks}
                yTicks={balanceChartYTicks}
                data={balanceData}
                height="8rem"
                color={PRIMARY_COLOR}
                background="rgb(0 0 0 / 2.5%)"
                highlightedX={hoveredDate}
                currency={currency}
              />
            )}
          </div>
        </div>

        <Divider size="2rem" />

        <Text
          block
          size="1.1rem"
          color="rgb(0 0 0 / 54%)"
          weight="500"
          style={{ margin: "0 0 0.5rem" }}
        >
          Cash flow
        </Text>

        <div className={css({ padding: "0 4rem 2rem 0" })}>
          <div ref={cashFlowChartRef}>
            {cashFlowError != null ? (
              <GraphError
                height="18rem"
                retry={() =>
                  Promise.all(
                    ["in_data", "out_data", "net_data"].map(fetchMetricData)
                  )
                }
              />
            ) : !hasAllCashFlowData ? (
              <GraphLoader height="18rem" />
            ) : (
              <BarChart
                scaleX={scaleX}
                scaleY={cashFlowChartScaleY}
                xTicks={xTicks}
                yTicks={cashFlowChartYTicks}
                formatXTick={formatXTick}
                formatYTick={formatYTick}
                dataSets={cashFlowChartDataSets}
                height="18rem"
                background="rgb(0 0 0 / 2.5%)"
                highlightedX={hoveredDate}
              />
            )}
          </div>
        </div>

        {hasAllData && (
          <div
            className={css({
              paddingRight: "4rem",
              position: "absolute",
              top: 0,
              left: 0,
            })}
          >
            <div className={css({ position: "relative" })}>
              {netData.map(([x]) => (
                <button
                  key={x}
                  className={css({
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: scaleX(x),
                    width: `calc(${cashFlowChartRect?.width ?? 0}px / ${
                      netData.length
                    })`,
                    transform: "translateX(-50%)",
                    height: containerRect?.height ?? 0,
                  })}
                  onMouseEnter={(e) => handleHoverDate(x, e)}
                  onMouseLeave={() => handleHoverDate(null)}
                  onClick={() => onClickDataPoint(x)}
                />
              ))}

              {[hoveredDate, ...selectedDataPoints]
                .filter(Boolean)
                .filter(
                  (d, i, ds) =>
                    ds.findIndex((_d) => _d.getTime() === d.getTime()) === i
                )
                .map((date) => (
                  <div
                    key={date.getTime()}
                    style={{
                      opacity: date ? 1 : 0,
                      transition: "0.1s opacity",
                    }}
                  >
                    <div
                      style={{
                        width: "4rem",
                        height:
                          containerRect?.height == null
                            ? 0
                            : `calc(${containerRect.height}px + 1.3rem)`,
                        opacity: 0.5,
                        position: "absolute",
                        top: "-1rem",
                        left: 0,
                        transition: "0.1s transform ease-out",
                        transform: `translateX(calc(${scaleX(date)}px - 2rem))`,
                        border: "0.1rem solid #999",
                        borderRadius: "0.5rem",
                        pointerEvents: "none",
                        backdropFilter: "contrast(1.2) saturate(1.2)",
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        {highlightedDataPoints.length !== 0 && (
          <Popover
            targetRef={hoveredRef}
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "1rem 1.2rem",
              boxShadow: "2px 2px 10px hsla(0, 0%, 0%, 0.1)",
              whiteSpace: "nowrap",
              fontSize: "1.3rem",
              background: "#2D2E33",
              color: "white",
              borderRadius: "0.5rem",
              pointerEvents: "none",
            }}
            position={(hoveredRect, popoverRect) => {
              const hoveredRectCenter =
                hoveredRect.left + hoveredRect.width / 2;

              const top = `calc(${hoveredRect.top + window.scrollY}px + 2rem)`;
              const left =
                hoveredRectCenter + popoverRect.width + 40 > window.innerWidth
                  ? `calc(${hoveredRectCenter - popoverRect.width}px - 4rem)`
                  : `calc(${hoveredRectCenter}px + 4rem)`;

              return { top, left };
            }}
          >
            <Text
              block
              align="right"
              truncate
              size="1.1rem"
              color="#999"
              margin="0 0 0.8rem"
            >
              {renderDate(highlightedDataPoints[0]?.[0])}
            </Text>
            <div
              className={css({
                display: "grid",
                alignItems: "center",
                gridColumnGap: "1.2rem",
                gridRowGap: "0.8rem",
                gridTemplateColumns: "auto 1fr",
                fontVariantNumeric: "tabular-nums",
                textAlign: "right",
              })}
            >
              {[
                {
                  icon: (
                    <div
                      className={css({
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "50%",
                        background: PRIMARY_COLOR,
                      })}
                    />
                  ),
                  amount: highlightedDataPoints[0]?.[1],
                },
                {
                  icon: (
                    <div
                      className={css({
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "50%",
                        background: GREEN_TEXT,
                      })}
                    />
                  ),
                  amount: highlightedDataPoints[2]?.[1],
                },
                {
                  icon: (
                    <div
                      className={css({
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "50%",
                        background: "#c4c4c4",
                      })}
                    />
                  ),
                  amount: highlightedDataPoints[3]?.[1],
                },
                {
                  icon: (
                    <div
                      className={css({
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "50%",
                        background: "black",
                        boxShadow: "0 0 0 0.1rem rgb(255 255 255 / 35%)",
                      })}
                    />
                  ),
                  amount: highlightedDataPoints[1]?.[1],
                },
              ]
                .filter((e) => e.amount != null)
                .map(({ icon, amount }, i) => (
                  <React.Fragment key={i}>
                    <span
                      className={css({
                        height: "1.4rem",
                        display: "flex",
                        alignItems: "center",
                      })}
                    >
                      {icon}
                    </span>
                    <FormattedCurrency value={amount} currency={currency} />
                  </React.Fragment>
                ))}
            </div>
          </Popover>
        )}
      </div>
    </>
  );
};

// const axisColor = "#eaeaea";
const gridColor = "#eaeaea";

const LineChart = ({
  data,
  scaleX,
  scaleY,
  xTicks,
  yTicks,
  width = "100%",
  height = "10rem",
  color,
  background,
  highlightedX,
}) => {
  return (
    <div
      className={css({
        width,
        maxWidth: "100%",
        background,
        position: "relative",
        borderRadius: "0.5rem",
      })}
    >
      <Grid
        xTicks={xTicks}
        yTicks={yTicks}
        scaleX={scaleX}
        scaleY={scaleY}
        color={({ y }) => (y === 0 ? "transparent" : gridColor)}
        style="solid" // eslint-disable-line react/style-prop-object
      />

      <YAxis
        ticks={yTicks}
        scale={scaleY}
        orientation="right"
        axisColor="transparent" // {axisColor}
        labelColor="rgb(0 0 0 / 54%)"
        renderTick={(value) => (
          <FormattedCurrency
            value={value}
            style="decimal" // eslint-disable-line react/style-prop-object
            currency={undefined}
            currencyDisplay={undefined}
            notation="compact"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        )}
      />

      <svg
        width="100%"
        height={height}
        className={css({ display: "block", position: "relative", zIndex: 1 })}
      >
        <Line
          data={data}
          scaleX={scaleX}
          scaleY={scaleY}
          stroke={color}
          strokeWidth={3}
        />
      </svg>

      {data.map(([x, y]) => (
        <div
          key={[x, y].join("")}
          className={css({
            width: "1.4rem",
            height: "1.4rem",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            border: "0.3rem solid white",
            boxShadow: "0 0 0.2rem 0.2rem rgb(0 0 0 / 4%)",
          })}
          style={{
            background: color,
            opacity:
              highlightedX != null && x.getTime() === highlightedX.getTime()
                ? 1
                : 0,
            top: scaleY(y),
            left: scaleX(x),
          }}
        />
      ))}
    </div>
  );
};

const Line = ({
  data,
  scaleX,
  scaleY,
  stroke = "black",
  fill = "none",
  strokeWidth = "0.1rem",
  strokeLinecap = "round",
  strokeLinejoin = "round",
  ...props
}) => {
  const createLine = line()
    .x((d) => scaleX(d[0]))
    .y((d) => scaleY(d[1]));
  return (
    <path
      d={createLine(data)}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
      {...props}
    />
  );
};

const BarChart = ({
  dataSets,
  scaleX,
  scaleY,
  xTicks,
  yTicks,
  formatXTick,
  formatYTick,
  width = "100%",
  height = "10rem",
  background,
}) => {
  const ref = React.useRef();
  const rect = useRect(ref, true);

  return (
    <div
      className={css({
        maxWidth: "100%",
        position: "relative",
        borderRadius: "0.5rem",
      })}
      style={{ background, width }}
      ref={ref}
    >
      <Grid
        xTicks={xTicks}
        yTicks={yTicks}
        scaleX={scaleX}
        scaleY={scaleY}
        color={gridColor}
        style="solid" // eslint-disable-line react/style-prop-object
      />

      <HorizontalLine
        x={0}
        y={scaleY(0)}
        length="100%"
        zIndex={0}
        color="rgb(0 0 0 / 20%)"
        style="dashed" // eslint-disable-line react/style-prop-object
      />

      <XAxis
        ticks={xTicks}
        scale={scaleX}
        axisColor="transparent" // {axisColor}
        labelColor="rgb(0 0 0 / 54%)"
        renderTick={formatXTick}
      />

      <YAxis
        ticks={yTicks}
        scale={scaleY}
        orientation="right"
        axisColor="transparent" // {axisColor}
        labelColor="rgb(0 0 0 / 54%)"
        renderTick={formatYTick}
      />

      <svg
        width="100%"
        height={height}
        className={css({ display: "block", position: "relative", zIndex: 1 })}
      ></svg>

      {dataSets.map(({ color, style, data }) =>
        data.map(([x, y]) => {
          const scaled0 = scaleY(0);
          const scaledY = scaleY(y);
          const height = Math.abs(scaled0 - scaledY);
          const isNegative = y < 0;
          return (
            <div
              key={[x, y].join("")}
              style={{
                position: "absolute",
                top: isNegative ? scaled0 : undefined,
                bottom: isNegative ? undefined : `calc(100% - ${scaled0}px)`,
                height,
                left: scaleX(x),
                width: `min(calc(${
                  (rect?.width || 0) / data.length
                }px / 2), 1.5rem)`,
                minWidth: "0.2rem",
                transform: "translateX(-50%)",
                background: color,
                ...style,
              }}
            />
          );
        })
      )}
    </div>
  );
};

export default CashFlowGraph;
