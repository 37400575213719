import React from "react";
import { mapValues } from "../utils/object";
import { indexBy } from "../utils/array";
import {
  fetchAll as fetchInsights,
  dismiss as dismissInsight,
  update as updateInsight,
} from "../api/insights";

const createQueryCacheKey = ({ sort, bankAccounts }) =>
  [sort, bankAccounts?.join(",")].join("");

const initialState = {
  elementsById: {},
  idListsByQuery: [],
};

const useInsights = ({ companyId, bankAccounts }) => {
  const defaultBankAccountIds = React.useMemo(
    () => bankAccounts.map((a) => a.id),
    [bankAccounts]
  );

  const createQuery = React.useCallback(
    ({ bankAccounts, ...rest }) => ({
      ...rest,
      companyId,
      bankAccounts: bankAccounts ?? defaultBankAccountIds,
    }),
    [defaultBankAccountIds, companyId]
  );

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "fetch-init":
        return { ...state, isFetching: true };
      case "fetch-success": {
        const queryCacheKey = createQueryCacheKey(action.query);
        const insights = action.payload.data.data;
        return {
          ...state,
          elementsById: {
            ...state.elementsById,
            ...indexBy((i) => i.uuid, insights),
          },
          idListsByQuery: {
            ...state.idListsByQuery,
            [queryCacheKey]: insights.map((i) => i.uuid),
          },
          isFetching: false,
        };
      }
      case "dismiss":
        return {
          ...state,
          elementsById: {
            ...state.elementsById,
            [action.id]: { ...state.elementsById[action.id], dismissed: true },
          },
        };
      case "dismiss-all":
        return {
          ...state,
          elementsById: mapValues(
            (element) => ({ ...element, dismissed: true }),
            state.elementsById
          ),
        };
      default:
        return state;
    }
  }, initialState);

  const fetchAll = React.useCallback(
    (rawQuery) => {
      dispatch({ type: "fetch-init" });
      const query = createQuery(rawQuery);
      return fetchInsights(query).then((res) => {
        dispatch({
          type: "fetch-success",
          payload: res,
          query,
        });
      });
    },
    [createQuery]
  );

  const dismissById = (id) => {
    dispatch({ type: "dismiss", id });
    return dismissInsight(id);
  };

  const dismissAll = (rawQuery) =>
    Promise.all(
      selectNonDismissed(rawQuery)(state, { createQuery }).map((i) =>
        dismissInsight(i.uuid)
      )
    ).then(() => {
      dispatch({ type: "dismiss-all" });
    });

  const resetDismissed = () =>
    Promise.all(
      Object.values(state.elementsById).map((i) =>
        updateInsight(i.uuid, { dismissed: false })
      )
    ).then(fetchAll);

  const actions = {
    fetchAll,
    dismissAll,
    dismissById,
    resetDismissed,
  };

  const getState = React.useCallback(
    (selector) => selector(state, { createQuery }),
    [state, createQuery]
  );

  return [getState, actions];
};

export const selectById = (id) => (state) => state.elementsById[id];

export const selectNonDismissed = (rawQuery) => (state, { createQuery }) => {
  const cacheKey = createQueryCacheKey(createQuery(rawQuery));
  return (state.idListsByQuery[cacheKey] ?? [])
    .map((id) => selectById(id)(state))
    .filter((i) => !i.dismissed);
};

export const selectDismissed = (rawQuery) => (state, { createQuery }) => {
  const cacheKey = createQueryCacheKey(createQuery(rawQuery));
  return (state.idListsByQuery[cacheKey] ?? [])
    .map((id) => selectById(id)(state))
    .filter((i) => i.dismissed);
};

export default useInsights;
