import { userConstants } from "../constants/user.constants";

let user = JSON.parse(localStorage.getItem("user"));
let initialState = {};

if (user) {
  initialState = {
    user: user,
    loggedIn: true,
  };
} else {
  initialState = {
    loggedIn: false,
    loggingIn: false,
    loggingInError: false,
  };
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.data.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
        loggingInError: true,
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        loggingIn: false,
        user: null,
      };
    case userConstants.SIGNUP_REQUEST:
      return {
        email: action.email,
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
      };
    case userConstants.FETCH_USER_REQUEST:
      return Object.assign({}, state, {});
    case userConstants.FETCH_USER_SUCCESS:
      return Object.assign({}, state, {
        user: action.user,
      });
    case userConstants.FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        user: null,
        loggedIn: false,
      });
    default:
      return state;
  }
}

