import React from "react";
import useStore from "./stores";
import usePrevious from "./previous";
import useInterval from "./interval";
import { selectSyncStatus as selectBankAccountsSyncStatus } from "./bank-accounts";
import { selectDataByQuery as selectMetricDataByQuery } from "./metrics";

const useMetricData = (queriesByName) => {
  const [getBankAccountsState] = useStore("bank-accounts");
  const { isSyncingNewConnection } = getBankAccountsState(
    selectBankAccountsSyncStatus
  );

  const [getMetricState, { fetch: fetchMetricData }] = useStore("metrics");

  const prevQueriesByName = usePrevious(queriesByName);
  const prevFetchMetricData = usePrevious(fetchMetricData);

  const dataByName = Object.entries(queriesByName).reduce(
    (data, [key, query]) => ({
      ...data,
      [key]: getMetricState(selectMetricDataByQuery(query)),
    }),
    {}
  );

  React.useEffect(() => {
    const queries = Object.entries(queriesByName);
    const hasChanged = (name) =>
      prevQueriesByName == null ||
      prevQueriesByName[name] !== queriesByName[name] ||
      prevFetchMetricData !== fetchMetricData;

    for (let [name, query] of queries) {
      if (!hasChanged(name)) return;
      fetchMetricData(query);
    }
  }, [fetchMetricData, queriesByName, prevQueriesByName, prevFetchMetricData]);

  // Poll the data continuously while syncing new bank accounts
  useInterval(
    () => {
      const queries = Object.values(queriesByName);
      for (let query of queries) fetchMetricData(query);
    },
    isSyncingNewConnection ? 8000 : null
  );

  return dataByName;
};

export default useMetricData;
