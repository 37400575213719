import React from "react";
import useWindowSize from "../hooks/window-size";
import JsonView from "react-json-view";
import { PRIMARY_COLOR } from "../constants/colors";

let prev = 0;
const genId = () => {
  prev += 1;
  return prev;
};

const Debugger = ({ children }) => {
  const [tab, setTab] = React.useState("console");
  const [isOpen, setOpen] = React.useState(false);
  const [consoleInput, setConsoleInput] = React.useState("");
  const [log, setLog] = React.useState([]);
  const [requests, setRequests] = React.useState([]);
  const containerRef = React.useRef();
  const inputRef = React.useRef();
  const windowSize = useWindowSize();

  React.useEffect(() => {
    const orgLog = console.log;
    const orgWarn = console.warn;
    const orgError = console.error;
    const orgFetch = window.fetch;
    console.log = (...args) => {
      setLog((s) => [args, ...s]);
      orgLog(...args);
    };
    console.warn = (...args) => {
      setLog((s) => [args, ...s]);
      orgWarn(...args);
    };
    console.error = (...args) => {
      // setLog((s) => [args, ...s]);
      // orgError(...args);
    };
    window.fetch = (url, options) => {
      const id = genId();
      setRequests((s) => [...s, { id, url: new URL(url), options }]);
      return orgFetch(url, options).then((response) => {
        const clonedResponse = response.clone();

        clonedResponse
          .json()
          .catch(() => clonedResponse.text())
          .then((body) =>
            setRequests((s) => {
              const index = s.findIndex((r) => r.id === id);
              return [
                ...s.slice(0, index),
                { ...s[index], response, body },
                ...s.slice(index + 1),
              ];
            })
          );

        return response;
      });
    };
    return () => {
      console.log = orgLog;
      console.warn = orgWarn;
      console.error = orgError;
      window.fetch = orgFetch;
    };
  }, []);

  React.useEffect(() => {
    const handleKeydown = (e) => {
      if (e.metaKey && e.key === "i") setOpen((s) => !s);
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);

  React.useEffect(() => {
    const handler = () => setOpen((s) => !s);
    document.addEventListener("dblclick", handler);
    return () => document.removeEventListener("dblclick", handler);
  }, []);

  React.useEffect(() => {
    if (!isOpen) return;
    if (tab === "console") inputRef.current.focus();
    else containerRef.current.focus();
  }, [isOpen, tab]);

  return (
    <>
      {children}

      {isOpen && (
        <div
          ref={containerRef}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "c") setTab("console");
            if (e.key === "n") setTab("network");
          }}
          style={{
            textAlign: "left",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            maxHeight: `${windowSize.height}px`,
            background: "white",
            zIndex: 10,
            lineHeight: 1.3,
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "monospace",
            display: "flex",
            flexDirection: "column",
            borderBottom: "0.1rem solid #eee",
            boxShadow: "0 0.2rem 1rem 0 rgb(0 0 0 / 5%)",
            transition: "0.1s all",
            transform: `translateX(${isOpen ? 0 : `${windowSize.width}px`})`,
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? "all" : "none",
          }}
        >
          <div
            style={{
              display: "grid",
              justifyContent: "flex-start",
              gridAutoFlow: "column",
              gridGap: "2rem",
              padding: "0 2rem",
              borderBottom: "0.1rem solid #eee",
            }}
          >
            {["console", "network"].map((tab_) => (
              <button
                key={tab_}
                onClick={() => setTab(tab_)}
                style={{
                  display: "block",
                  padding: "1rem 0",
                  borderBottom: "0.2rem solid",
                  borderColor: tab === tab_ ? PRIMARY_COLOR : "transparent",
                }}
              >
                {tab_}
              </button>
            ))}
          </div>
          {tab === "console" && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                try {
                  const result = eval(consoleInput); // eslint-disable-line no-eval
                  setLog((l) => [[result], ...l]);
                  setConsoleInput("");
                } catch (e) {}
              }}
            >
              <input
                ref={inputRef}
                style={{
                  display: "block",
                  width: "100%",
                  padding: "1rem 2rem",
                  borderRadius: 0,
                  border: 0,
                  borderBottom: "0.1rem solid #ddd",
                  boxShadow: 0,
                }}
                value={consoleInput}
                onChange={(e) => setConsoleInput(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <input type="submit" hidden />
            </form>
          )}
          <div style={{ flex: 1, overflowY: "scroll" }}>
            <div
              style={{ width: `${window.innerWidth}px`, overflow: "hidden" }}
            >
              <div
                style={{
                  display: "flex",
                  width: `calc(${window.innerWidth}px * 2)`,
                  transition: "0.1s all",
                  transform: `translateX(${
                    tab === "network" ? `${window.innerWidth * -1}px` : 0
                  })`,
                }}
              >
                <div style={{ flex: 1 }}>
                  {log.map((ls, i) => (
                    <div
                      key={i}
                      style={{
                        padding: "1rem 2rem",
                        borderTop: i === 0 ? undefined : "0.1rem solid #eee",
                      }}
                    >
                      {ls.map((l, innerIndex) => {
                        if (l == null) return String(l);
                        const type = typeof l;
                        const content = [
                          "string",
                          "number",
                          "boolean",
                        ].includes(type) ? (
                          l
                        ) : type === "function" ? (
                          <pre>{l.toString()}</pre>
                        ) : l != null && l?.outerHTML != null ? (
                          JSON.stringify(l.outerHTML)
                        ) : (
                          <JsonView
                            src={l}
                            name={false}
                            iconStyle="triangle"
                            indentWidth={2}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            collapsed={0}
                            enableClipboard={false}
                          />
                        );
                        return (
                          <div
                            key={innerIndex}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {content}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>

                <div style={{ flex: 1 }}>
                  {requests.map(({ id, url, body, options, response }, i) => (
                    <div
                      key={id}
                      style={{
                        padding: "1rem 2rem",
                        borderTop: i === 0 ? undefined : "0.1rem solid #eee",
                      }}
                    >
                      <b>
                        {options?.method ?? "GET"} {response?.status}{" "}
                        {url.pathname}
                        <span style={{ color: "rgb(0 0 0 / 40%)" }}>
                          {url.search}
                        </span>
                      </b>
                      <div style={{ marginTop: "1rem" }}>
                        {body == null ? (
                          "No body"
                        ) : typeof body === "string" ? (
                          body
                        ) : (
                          <JsonView
                            src={body}
                            name={false}
                            iconStyle="triangle"
                            indentWidth={2}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            collapsed={0}
                            enableClipboard={false}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Debugger;
