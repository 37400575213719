import React from "react";
import { usePlaidLink } from "react-plaid-link";
import { PlaidEnvironment } from "../utils/constants";
import useStore from "../hooks/stores";

const usePlaid = ({ onSuccess }) => {
  const [
    _, // eslint-disable-line
    {
      fetchPlaidLinkToken,
      initializePlaidConnection,
      fetchAllUntilSynced: fetchAllBankAccountsUntilSynced,
    },
  ] = useStore("bank-accounts");

  const [linkToken, setLinkToken] = React.useState(null);

  React.useEffect(() => {
    if (typeof onSuccess !== "function") return;

    // No need to request a new token is we aready have one
    if (linkToken != null) return;

    fetchPlaidLinkToken().then(({ linkToken }) => {
      setLinkToken(linkToken);
    }, alert); // TODO: Error handling
  }, [fetchPlaidLinkToken, onSuccess, linkToken]);

  // This will be called when the user successfully goes through the flow
  // connect banks in Plaid's UI
  const linkSuccessHandler = React.useCallback(
    (publicToken, metadata) => {
      onSuccess();
      setLinkToken(null);
      // Sending public token to server to initialize bank connection
      return initializePlaidConnection({
        publicToken,
        metadata,
      }).then(() => {
        // Start polling bank accounts and don't stop until everything is synched
        fetchAllBankAccountsUntilSynced().then(() => {
          // Yay all done! TODO something cool
        }); // TODO: Error handling
      }); // TODO: Error handling
    },
    [fetchAllBankAccountsUntilSynced, initializePlaidConnection, onSuccess]
  );

  const { open, ready, error } = usePlaidLink({
    env: PlaidEnvironment,
    token: linkToken ?? "",
    onSuccess: linkSuccessHandler,
    onLoad: console.log,
    onEvent: console.log,
    onExit: console.log,
  });

  return {
    openConnectionUI: linkToken != null && ready ? open : null,
    error,
  };
};

export default usePlaid;
