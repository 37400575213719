import formatDate from "date-fns/format";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { css } from "emotion";
import { Switch, Route, matchPath } from "react-router";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRect } from "@reach/rect";
import useWindowSize from "../../hooks/window-size";
import { PRIMARY_COLOR } from "../../constants/colors";
import { StoresProvider } from "../../hooks/stores";
import useRequest from "../../hooks/request";
import useMetric from "../../hooks/metrics";
import useInsights from "../../hooks/insights";
import useTransactions from "../../hooks/transactions";
import useScenarios from "../../hooks/scenarios";
import useBankAccounts, {
  selectAllEnabled as selectAllEnabledBankAccounts,
  selectSyncStatus as selectBankAccountsSyncStatus,
} from "../../hooks/bank-accounts";
import { companyActions } from "../../actions/company.actions";
import { userActions } from "../../actions/user.actions";
import Modal from "../Modal";
import {
  Container as DialogContainer,
  Section as DialogSection,
} from "../dialog";
import SearchModal from "../SearchModal";
import DropdownMenu from "../DropdownMenu";
import Text from "../Text";
import Divider from "../Divider";
import Spinner from "../Spinner";
import Icon from "../Icon";
import Button from "../Button";
import Dashboard from "../dashboard/index";
import Scenarios from "../scenarios/Index";
import ScenarioListing from "../ScenarioListing";
import Insights from "./Insights";
import Settings from "./Settings";
import Balance from "./Balance";
import CashOverview from "./CashOverview";
import CashFlowHeatMap from "./CashFlowHeatMap";
import Transactions from "./Transactions";
import Transaction from "./Transaction";
import CategoryPage from "../CategoryPage";
import VendorPage from "../VendorPage";
import Onboarding from "../Onboarding";
import Connect from "../Connect";

// const OBVIOUS_COMPANY_ID = "6f834c8b-a8de-4ea9-b089-88415cc0475e";
// const LEETIFY_COMPANY_ID = "0f28703c-9987-4a96-ba2e-0cb9480ff3b2";

const useVendors = () => {
  const request = useRequest();
  const companyId = useSelector((s) => s.companies.currentUuid);
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    request("/vendors", { headers: { "Company-Id": companyId } }).then((res) =>
      setState(res.data)
    );
  }, [request, companyId]);

  return state;
};

const useCategories = () => {
  const request = useRequest();
  const companyId = useSelector((s) => s.companies.currentUuid);
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    request("/categories", {
      headers: { "Company-Id": companyId },
    }).then((res) => setState(res.data));
  }, [request, companyId]);

  return state;
};

const Index = ({ location, history }) => {
  const request = useRequest();
  const dispatch = useDispatch();

  const settingsState = useSelector((s) => s.settings);
  const companiesState = useSelector((s) => s.companies);
  const user = useSelector((s) => s.authentication.user);

  const { data: companies, currentUuid: selectedCompanyId } = companiesState;
  const { currency } = settingsState.table;

  const [searchVisible, setSearchVisible] = React.useState(false);
  const [
    isNewBankConnectionSyncStatusMinimized,
    setNewBankConnectionSyncStatusMinimized,
  ] = React.useState(false);

  const isTransactionsPage = matchPath(location.pathname, {
    path: "/dashboard/transactions",
  });

  const vendors = useVendors();
  const categories = useCategories();

  const windowSize = useWindowSize();

  // TODO move from redux to their own separate "store hook" like the rest
  const getSettingsState = React.useCallback(() => settingsState, [
    settingsState,
  ]);
  const getCompaniesState = React.useCallback(() => companiesState, [
    companiesState,
  ]);

  const [getBankAccountsState, bankAccountActions] = useBankAccounts({
    companyId: selectedCompanyId,
    getSettingsState,
    getCompaniesState,
  });

  const {
    fetchAll: fetchAllBankAccounts,
    fetchAllUntilSynced: fetchAllBankAccountsUntilSynced,
    initializeAiiaConnection,
  } = bankAccountActions;

  useEffect(() => {
    dispatch(userActions.fetchUser());
    dispatch(companyActions.fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompanyId) return;
    fetchAllBankAccounts();
  }, [fetchAllBankAccounts, selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId && companies.length > 0) {
      dispatch(
        companyActions.setCurrentCompany(
          companies.find((c) => c.uuid === selectedCompanyId)
        )
      );
    }
  }, [dispatch, companies, selectedCompanyId]);

  useEffect(() => {
    if (isTransactionsPage) return;

    const handleKeydown = (e) => {
      if (e.metaKey && e.key === "k") {
        setSearchVisible((s) => !s);
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, [isTransactionsPage]);

  // const allBankAccounts = React.useMemo(
  //   () => getBankAccountsState(selectAllBankAccounts),
  //   [getBankAccountsState]
  // );
  const allEnabledBankAccounts = React.useMemo(
    () => getBankAccountsState(selectAllEnabledBankAccounts),
    [getBankAccountsState]
  );
  const {
    isFetching: isFetchingBankAccounts,
    didSuccessfulFetch: didSuccessfullyFetchBankAccounts,
    isSyncingNewConnection: isSyncingNewBankConnection,
  } = getBankAccountsState(selectBankAccountsSyncStatus);

  const [getTransactionsState, transactionActions] = useTransactions({
    companyId: selectedCompanyId,
    bankAccounts: allEnabledBankAccounts,
  });

  const [getMetricState, metricActions] = useMetric({
    companyId: selectedCompanyId,
    bankAccounts: allEnabledBankAccounts,
  });
  const [getInsightsState, insightActions] = useInsights({
    bankAccounts: allEnabledBankAccounts,
  });
  const [getScenarioState, scenarioActions] = useScenarios({
    companyId: selectedCompanyId,
  });

  const selectCompany = (uuid) =>
    dispatch(
      companyActions.setCurrentCompany(companies.find((c) => c.uuid === uuid))
    );

  const selectedCompany = companies.find((c) => c.uuid === selectedCompanyId);

  const containerRef = React.useRef();
  const containerRect = useRect(containerRef);

  if (!user)
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;

  if (!didSuccessfullyFetchBankAccounts) return null; // TODO error handling

  // if (categories.length === 0 || vendors.length === 0) return null; // TODO

  const showOnboarding =
    allEnabledBankAccounts.length === 0 && !isFetchingBankAccounts;

  return (
    <>
      <StoresProvider
        stores={{
          transactions: [getTransactionsState, transactionActions],
          metrics: [getMetricState, metricActions],
          scenarios: [getScenarioState, scenarioActions],
          "bank-accounts": [getBankAccountsState, bankAccountActions],
          vendors,
          categories,
          settings: {
            defaultCurrency: currency,
          },
        }}
      >
        <div
          ref={containerRef}
          className={css({
            minHeight: `${windowSize.height}px`,
            height: "100%",
            display: "flex",
          })}
        >
          {containerRect?.width != null && (
            <>
              <SideMenu
                useFloatingContainer={containerRect?.width < 900}
                user={user}
                companies={companies}
                selectedCompany={selectedCompany}
                onSelectCompany={selectCompany}
                onToggleSearchDialog={() => setSearchVisible((s) => !s)}
                logOut={() =>
                  dispatch(userActions.logout()).then(() => history.push("/"))
                }
              />

              <div
                className={css({
                  flex: "1 1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  minWidth: 0,
                  height: `${windowSize.height}px`,
                  maxHeight: "100%",
                })}
              >
                <Switch>
                  <Route
                    path="/dashboard/settings"
                    render={(props) => (
                      <Settings
                        {...props}
                        toggleEnableBankAccount={(accountId) => {
                          dispatch({
                            type: "toggle-enable-bank-account",
                            id: accountId,
                          });
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/dashboard/connect"
                    render={(props) => (
                      // Move all of this to <Settings /> ?
                      <Connect
                        {...props}
                        initializeConnection={(...args) =>
                          initializeAiiaConnection(...args).then(() => {
                            fetchAllBankAccountsUntilSynced(); // TODO: Success/error handling
                            history.replace("/dashboard/settings");
                          })
                        }
                      />
                    )}
                  />
                  {showOnboarding && (
                    <Route
                      render={() => (
                        <div
                          className={css({
                            margin: "0 auto",
                            maxWidth: "100%",
                            width: "90rem",
                            padding: "5rem 2rem",
                          })}
                        >
                          <Onboarding user={user} />
                        </div>
                      )}
                    />
                  )}
                  <Route
                    path="/dashboard"
                    exact
                    render={(props) => (
                      <Dashboard
                        {...props}
                        user={user}
                        companyId={selectedCompanyId}
                        vendors={vendors}
                        categories={categories}
                        enabledBankAccounts={allEnabledBankAccounts}
                        currency={currency}
                        getTransactionsState={getTransactionsState}
                        fetchTransactions={transactionActions.fetchData}
                        updateTransaction={transactionActions.update}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/runway/:scenarioId"
                    render={(props) => {
                      if (categories.length === 0) return null;

                      return (
                        <Scenarios
                          {...props}
                          currency={currency}
                          categories={categories}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/dashboard/runway"
                    render={(props) => (
                      <ScenarioListing {...props} categories={categories} />
                    )}
                  />

                  <Route
                    path="/dashboard/insights/archive"
                    exact
                    render={(props) => (
                      <Insights
                        archive
                        {...props}
                        {...insightActions}
                        user={user}
                        vendors={vendors}
                        enabledBankAccounts={allEnabledBankAccounts}
                        companyId={selectedCompanyId}
                        getInsightsState={getInsightsState}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/insights"
                    exact
                    render={(props) => (
                      <Insights
                        {...props}
                        {...insightActions}
                        user={user}
                        vendors={vendors}
                        enabledBankAccounts={allEnabledBankAccounts}
                        companyId={selectedCompanyId}
                        getInsightsState={getInsightsState}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/balance"
                    render={(props) => (
                      <Balance
                        {...props}
                        enabledBankAccounts={allEnabledBankAccounts}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/cash-overview"
                    render={(props) => (
                      <CashOverview
                        {...props}
                        vendors={vendors}
                        categories={categories}
                        getMetricState={getMetricState}
                        fetchMetricData={metricActions.fetch}
                        fetchVarianceBreakdown={({
                          kind,
                          primaryRange,
                          comparedRange,
                        }) =>
                          request("/breakdowns/variance", {
                            headers: { "Company-Id": selectedCompanyId },
                            searchParams: Object.fromEntries(
                              [
                                ["kind", kind],
                                [
                                  "from_date",
                                  formatDate(primaryRange[0], "yyyy-MM-dd"),
                                ],
                                [
                                  "to_date",
                                  formatDate(primaryRange[1], "yyyy-MM-dd"),
                                ],
                                [
                                  "compared_from_date",
                                  formatDate(comparedRange[0], "yyyy-MM-dd"),
                                ],
                                [
                                  "compared_to_date",
                                  formatDate(comparedRange[1], "yyyy-MM-dd"),
                                ],
                                [
                                  "bank_accounts",
                                  allEnabledBankAccounts.map((a) => a.id),
                                ],
                              ].filter(([_, value]) => value != null)
                            ),
                          })
                        }
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/cash-heat-map"
                    render={(props) => (
                      <CashFlowHeatMap
                        {...props}
                        getMetricState={getMetricState}
                        fetchMetricData={metricActions.fetch}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/transactions/:id"
                    component={Transaction}
                  />
                  <Route
                    path="/dashboard/transactions"
                    render={(props) => (
                      <Transactions
                        {...props}
                        vendors={vendors}
                        categories={categories}
                        companyId={selectedCompanyId}
                        getTransactionsState={getTransactionsState}
                        fetchTransactions={transactionActions.fetchData}
                        updateTransaction={transactionActions.update}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/categories/:identifier"
                    render={(props) => {
                      const category = categories.find(
                        (c) => c.id === props.match.params.identifier
                      );
                      if (category == null) return null;
                      return (
                        <CategoryPage
                          {...props}
                          vendors={vendors}
                          categories={categories}
                          category={category}
                          currency={currency}
                          fetchMetric={metricActions.fetch}
                          getMetricState={getMetricState}
                          fetchTransactions={transactionActions.fetchData}
                          getTransactionsState={getTransactionsState}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/dashboard/vendors/:identifier"
                    render={(props) => {
                      const vendor = vendors.find(
                        (c) => c.id === props.match.params.identifier
                      );
                      if (vendor == null) return null;
                      return (
                        <VendorPage
                          {...props}
                          vendor={vendor}
                          fetchMetric={metricActions.fetch}
                          getMetricState={getMetricState}
                          fetchTransactions={transactionActions.fetchData}
                          getTransactionsState={getTransactionsState}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/dashboard/categories"
                    render={(props) => (
                      <Transactions
                        {...props}
                        vendors={vendors}
                        categories={categories}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard/vendors"
                    render={(props) => (
                      <Transactions
                        {...props}
                        vendors={vendors}
                        categories={categories}
                      />
                    )}
                  />
                  <Route render={() => null} />
                </Switch>
              </div>
            </>
          )}
        </div>

        <Modal
          isOpen={searchVisible}
          onDismiss={() => setSearchVisible(false)}
          label="Search transaction history"
        >
          {searchVisible && (
            <SearchModal
              onSubmit={(query) => {
                history.push(
                  `/dashboard/transactions?q=${encodeURIComponent(query)}`
                );
                setSearchVisible(false);
              }}
              initialQuery={new URLSearchParams(location.search).get("q")}
            />
          )}
        </Modal>

        <Modal
          isOpen={
            isSyncingNewBankConnection &&
            !isNewBankConnectionSyncStatusMinimized
          }
          backdropOpacity={0.5}
          label="Bank connection status dialog"
        >
          <NewBankConnectionSyncStatusDialog
            minimize={() => {
              setNewBankConnectionSyncStatusMinimized(true);
              history.push("/dashboard");
            }}
            hasSyncedAccounts={allEnabledBankAccounts.some(
              (a) => a.sync_status === "done"
            )}
          />
        </Modal>
      </StoresProvider>
    </>
  );
};

const NewBankConnectionSyncStatusDialog = ({ minimize, hasSyncedAccounts }) => {
  const [numberOfSecondsElapsed, setNumberOfSecondsElapsed] = React.useState(0);
  const allowMinimize = numberOfSecondsElapsed >= 30;

  React.useEffect(() => {
    const start = new Date();

    const intervalHandle = window.setInterval(() => {
      const numberOfSecondsElapsed =
        (new Date().getTime() - start.getTime()) / 1000;
      setNumberOfSecondsElapsed(Math.floor(numberOfSecondsElapsed));
    }, 5000);

    return () => {
      window.clearInterval(intervalHandle);
    };
  }, []);

  const getMessage = () => {
    if (hasSyncedAccounts)
      return (
        <>
          <Text variant="headline-small" margin="0 0 2rem">
            Syncing data
          </Text>
          <Text>
            This might take a little while. You can go hang out in the dashboard
            while we finish things up if you like.
          </Text>
          <Divider size="3rem" />
          <Button onClick={() => minimize()} variant="primary">
            Ok, let's go &rarr;
          </Button>
          <Divider />
        </>
      );

    if (allowMinimize)
      return (
        <>
          <Text>
            There's still some data left to sync, but it looks like we might
            have enough to get going.
          </Text>
          <Divider size="3rem" />
          <Button onClick={() => minimize()} variant="primary">
            Go to the dashboard &rarr;
          </Button>
        </>
      );

    if (numberOfSecondsElapsed < 10)
      return "Syncing bank data. This might take a little while.";

    if (numberOfSecondsElapsed < 20) return "Any day now...";

    if (numberOfSecondsElapsed < 30)
      return (
        <span>
          <span role="img" aria-label="coffee" style={{ fontSize: "1.2em" }}>
            {"\u{2615}\u{1F3C3}\u{1F4A8}"}
          </span>
          ?
        </span>
      );
  };

  return (
    <DialogContainer width="42rem">
      <DialogSection divider={false} style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "10rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: "500",
              lineHeight: 1.5,
              textAlign: "center",
            }}
          >
            <Divider />
            <Spinner
              size="3rem"
              color={allowMinimize ? "#bbb" : PRIMARY_COLOR}
            />
            <Divider size="3rem" />
            {getMessage()}
          </div>
        </div>
      </DialogSection>
    </DialogContainer>
  );
};

const MenuNavLink = ({ to, exact, children, disabled = false }) => (
  <NavLink
    to={to}
    exact={exact}
    className={css({
      display: "block",
      color: "black",
      fontSize: "1.6rem",
      textDecoration: "none",
      fontWeight: "500",
      lineHeight: 1.2,
      opacity: disabled ? 0.3 : 1,
      pointerEvents: disabled ? "none" : undefined,
      ":not(:last-of-type)": { marginBottom: "1.6rem" },
    })}
    activeStyle={{ fontWeight: "700", pointerEvents: "none" }}
  >
    {children}
  </NavLink>
);

const SideMenu = ({
  user,
  companies,
  selectedCompany,
  onSelectCompany,
  onToggleSearchDialog,
  logOut,
  useFloatingContainer,
}) => {
  // TODO phones etc.
  const hasCommandKey = /(Mac|iPad)/i.test(navigator.platform);

  const [isHoverExpanded, setHoverExpanded] = React.useState(false);

  const isExpanded = !useFloatingContainer || isHoverExpanded;

  return (
    <div
      className={css({
        position: "relative",
        zIndex: 2,
        transition: "0.2s width ease-out",
        width: useFloatingContainer ? 0 : isExpanded ? "26rem" : 0,
      })}
      onMouseLeave={() => setHoverExpanded(false)}
    >
      <div
        className={css({
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
        })}
      >
        <div
          style={{
            position: "relative",
            width: "26rem",
            display: "flex",
            flexDirection: "column",
            height: useFloatingContainer ? "calc(100% - 4rem)" : "100%",
            maxHeight: "100%",
            pointerEvents: isExpanded ? "all" : "none",
            transition: "0.2s all ease-out",
            opacity: isExpanded ? 1 : 0,
            transform: `translateX(${
              isExpanded ? 0 : "calc(-100% + 2rem)"
            }) translateY(${
              useFloatingContainer ? "2rem" : 0
            }) translateZ(0px)`,
          }}
          onMouseMove={() => setHoverExpanded(true)}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "white",
              boxShadow: useFloatingContainer
                ? "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px"
                : "none",
              borderTopRightRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              borderRight: "0.1rem solid",
              borderColor: useFloatingContainer ? "transparent" : "#eee",
            }}
          />
          <div
            className={css({
              padding: "0 2.5rem",
              height: "6rem",
              margin: "0 0 1rem",
              display: "grid",
              gridTemplateColumns: "minmax(0, 1fr) auto",
              gridGap: "0.5rem",
              alignItems: "center",
            })}
          >
            <DropdownMenu
              renderButton={({ component }) => (
                <Button
                  component={component}
                  variant="transparent"
                  size="small"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "0 0.5rem",
                    border: 0,
                    minHeight: "3.8rem",
                    textAlign: "left",
                  }}
                >
                  <div
                    className={css({
                      display: "grid",
                      gridTemplateColumns: "auto minmax(0, 1fr)",
                      gridGap: "1rem",
                      alignItems: "center",
                    })}
                  >
                    <div
                      className={css({
                        background: "rgb(0 0 0 / 7%)",
                        borderRadius: "0.5rem",
                        padding: "0.4rem",
                      })}
                    >
                      <svg
                        width="22"
                        height="28"
                        viewBox="0 0 22 28"
                        fill="none"
                        className={css({
                          display: "block",
                          width: "2rem",
                          height: "2rem",
                        })}
                      >
                        <circle
                          cx="11"
                          cy="10"
                          r="8.5"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                        <circle
                          cx="9"
                          cy="8"
                          r="4.5"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                        <path
                          d="M1 21.9982V21.9982C7.24291 25.3598 14.7571 25.3598 21 21.9982V21.9982"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                      </svg>
                    </div>

                    {selectedCompany != null && (
                      <Text block weight="500" style={{ whiteSpace: "normal" }}>
                        {selectedCompany.name}
                      </Text>
                    )}
                  </div>
                </Button>
              )}
              items={[
                { type: "heading", label: "Switch company" },
                ...companies.map((c) => ({
                  type: "button",
                  label: c.name,
                  onSelect: () => onSelectCompany(c.uuid),
                  style: {
                    fontWeight:
                      selectedCompany?.uuid === c.uuid ? "700" : "500",
                  },
                })),
              ]}
            />
            <DropdownMenu
              renderButton={({ isExpanded, component }) => (
                <Button
                  component={component}
                  variant="transparent"
                  size="small"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "0 0.5rem",
                    minHeight: "3.8rem",
                  }}
                >
                  <Icon
                    name="caret-up"
                    size="1rem"
                    color="rgb(0 0 0 / 50%)"
                    style={{
                      marginRight: "0.5rem",
                      transition: "0.15s transform",
                      transform: `scaleY(${isExpanded ? 1 : -1})`,
                    }}
                  />
                  <div
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: "2.8rem",
                      height: "2.8rem",
                      color: "black",
                      background: "rgb(0 0 0 / 7%)",
                      lineHeight: 1,
                      fontWeight: "700",
                    })}
                  >
                    {user.first_name[0]}
                  </div>
                </Button>
              )}
              items={[
                {
                  type: "link",
                  to: "/dashboard/settings",
                  label: "Settings",
                },
                { type: "divider" },
                {
                  type: "button",
                  onSelect: () => logOut(),
                  label: "Sign out",
                  // style: { color: "#E73E67" },
                },
              ]}
            />
          </div>
          <div className={css({ padding: "0 2rem" })}>
            <button
              className={css({
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontWeight: "500",
                fontSize: "1.6rem",
                cursor: "pointer",
                background: "white",
                padding: "0 1.2rem",
                height: "4rem",
                textAlign: "left",
                borderRadius: "1rem",
                transition: "0.1s box-shadow",
                boxShadow: "0 0.4rem 1.2rem 0 rgb(0 0 0 / 8%)",
                ":active": { color: "currentColor" },
                ":hover": {
                  boxShadow: "0 0.4rem 1.2rem 0 rgba(0 0 0 / 12%)",
                },
              })}
              onClick={onToggleSearchDialog}
            >
              <div style={{ width: "2.8rem", marginRight: "1rem" }}>
                <Icon
                  name="search"
                  size="2rem"
                  style={{ display: "flex", margin: "auto" }}
                />
              </div>
              <span style={{ flex: 1 }}>
                Search{" "}
                <span className={css({ opacity: 0.5 })}>
                  ({hasCommandKey ? <>&#8984;</> : "Ctrl"} + K)
                </span>
              </span>
            </button>
          </div>
          <div style={{ flex: 1, minWidth: 0, overflow: "auto" }}>
            <div
              className={css({
                padding: "3rem",
                display: "grid",
                gridAutoFlow: "row",
                gridAutoRows: "auto",
                gridGap: "4rem",
              })}
            >
              {[
                {
                  title: "Mission control",
                  items: [
                    { label: "Dashboard", url: "", exact: true },
                    {
                      label: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Runway projections{" "}
                          <span
                            style={{
                              color: PRIMARY_COLOR,
                              fontSize: "1.2rem",
                              fontWeight: "500",
                              marginLeft: "0.8rem",
                              position: "relative",
                              top: "0.1rem",
                            }}
                          >
                            Beta
                          </span>
                        </span>
                      ),
                      url: "/runway",
                    },
                    { label: "Insights", url: "/insights" },
                  ],
                },
                {
                  title: "Cash overview",
                  items: [
                    { label: "Cash flow", url: "/cash-overview" },
                    { label: "Heat map", url: "/cash-heat-map" },
                  ],
                },
                {
                  title: "Bank balance",
                  items: [{ label: "Total balance", url: "/balance" }],
                },
                {
                  title: "Transactions",
                  items: [
                    { label: "All transactions", url: "/transactions" },
                    { label: "Categories", url: "/categories/software" },
                  ],
                },
              ].map(({ title, items }) => (
                <div key={title}>
                  <Text block variant="label-light" margin="0 0 1.6rem">
                    {title}
                  </Text>
                  {items.map(({ url, label, exact, disabled = false }) => (
                    <MenuNavLink
                      key={url}
                      to={`/dashboard${url}`}
                      exact={exact}
                      disabled={disabled}
                    >
                      {label}
                    </MenuNavLink>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <Text
            component="a"
            href="/changelog"
            target="_blank"
            size="1.6rem"
            weight="500"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2rem 3rem",
              borderTop: "0.1rem solid",
              borderColor: "#eee",
            }}
          >
            <div>What's new</div>
            <div
              className={css({
                width: "0.7rem",
                height: "0.7rem",
                borderRadius: "50%",
                transition: "0.1s all",
                background: PRIMARY_COLOR,
              })}
            />
          </Text>
        </div>
      </div>
      {useFloatingContainer && (
        <button
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "2rem",
          }}
          onMouseMove={() => setHoverExpanded(true)}
        />
      )}
    </div>
  );
};

export default Index;
