import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { history } from "./utils/history";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistStore } from "redux-persist";

import ReactGA from "react-ga";
import store from "./store";
import { RequestProvider } from "./hooks/request";
import ExceptionInformationBlock from "./components/ExceptionInformationBlock";

const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
  ReactGA.initialize("UA-161439574-1");
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

let persistor = persistStore(store);

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <RequestProvider>
          <App />
        </RequestProvider>
      </Router>
    </PersistGate>
  </Provider>
);

const ExceptionFallback = () => (
  <div
    style={{
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <ExceptionInformationBlock />
  </div>
);

let Debugger = ({ children }) => children;

if (!isProduction && process.env.REACT_APP_IPAD_DEBUGGER)
  Debugger = require("./components/Debugger").default;

ReactDOM.render(
  isProduction ? (
    <Sentry.ErrorBoundary fallback={ExceptionFallback}>
      {app}
    </Sentry.ErrorBoundary>
  ) : (
    <Debugger>{app}</Debugger>
  ),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
