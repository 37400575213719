import { userService } from "../api/users";
import { history } from "../utils/history";
import { userConstants } from "../constants/user.constants";

export const userActions = {
  login,
  logout,
  register,
  fetchUser,
};

function login(email, password, redirect) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.loginUser(email, password).then(
      (data) => {
        const user = data.user;
        if (!user.has_early_access) {
          localStorage.removeItem("user");
          window.location.replace(
            "https://tryobvious.typeform.com/to/UqZcnYfn"
          );
          return;
        }

        dispatch(success(data));

        if (!redirect || redirect === "/login") {
          redirect = "/dashboard";
        }

        history.push(redirect);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function register(email, password, redirect) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.registerUser(email, password).then(
      (user) => {
        dispatch(login(email, password, redirect));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(email) {
    return { type: userConstants.SIGNUP_REQUEST, email };
  }
  function failure(error) {
    return { type: userConstants.SIGNUP_FAILURE, error };
  }
}

function logout() {
  return (dispatch) =>
    userService.logout().then((res) => {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      sessionStorage.removeItem("cid");
      dispatch({ type: userConstants.LOGOUT });
      return res;
    });
}

function fetchUser() {
  return (dispatch) => {
    dispatch(request());
    return userService.getUser().then(
      (user) => {
        dispatch(success(user));
        dispatch(setCurrency(user));
        return user;
      },
      (error) => {
        dispatch(failure());
        console.error(error);
      }
    );
  };

  function request(user) {
    return { type: userConstants.FETCH_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FETCH_USER_SUCCESS, user };
  }
  function failure(user) {
    return { type: userConstants.FETCH_USER_FAILURE, user };
  }
  function setCurrency(user) {
    return { type: "SET_USER_CURRENCY", user };
  }
}
