import formatDate from "date-fns/format";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  PRIMARY_COLOR,
  PRIMARY_COLOR_LIGHT_TINT,
} from "../../constants/colors";
import { sum, sort, partition } from "../../utils/array";
import { descending } from "../../utils/comparators";
import { identity } from "../../utils/function";
import { isOutgoing as isOutgoingCategory } from "../../utils/categories";
import useHover from "../../hooks/hover";
import useStore from "../../hooks/stores";
import useMetric from "../../hooks/metric-data";
import FormattedNumber from "../FormattedNumber";
import FormattedCurrency from "../FormattedCurrency";
import T from "../Text";
import G from "../Grid";
import Divider from "../Divider";
import FractionBar from "../FractionBar";
import Tooltip, { positionTopCenter } from "../Tooltip";

const useMostRecentNonNull = (value) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    if (value == null) return;
    ref.current = value;
  });

  return value ?? ref.current;
};

const unique = (list) => [...new Set(list)];

const groupedTransactionsQueryBase = {
  metric: "grouped_transactions",
  groupBy: "category",
};

export const useMetricQueries = (range) => {
  const categories = useStore("categories");

  const outgoingCategories = React.useMemo(() => {
    const [from, to] = range;
    return {
      ...groupedTransactionsQueryBase,
      from,
      to,
      categories: categories.filter(isOutgoingCategory).map((c) => c.id),
    };
  }, [range, categories]);

  const uncategorizedOutgoing = React.useMemo(() => {
    const [from, to] = range;
    return {
      ...groupedTransactionsQueryBase,
      from,
      to,
      maxAmount: 0,
      categories: ["unknown"],
    };
  }, [range]);

  return { outgoingCategories, uncategorizedOutgoing };
};

const useUncategorizedCashOutQuery = (range) =>
  React.useMemo(() => {
    const [from, to] = range;
    return {
      ...groupedTransactionsQueryBase,
      from,
      to,
      maxAmount: 0,
      categories: ["unknown"],
    };
  }, [range]);

const useOutgoingCategoriesCashNetQuery = (range) => {
  const categories = useStore("categories");

  return React.useMemo(() => {
    const [from, to] = range;
    return {
      ...groupedTransactionsQueryBase,
      from,
      to,
      categories: categories.filter(isOutgoingCategory).map((c) => c.id),
    };
  }, [range, categories]);
};

export const useData = ({ range, previousRange }) => {
  const [_, { fetch: fetchMetricData }] = useStore("metrics"); // eslint-disable-line no-unused-vars

  const periodOutgoingCategories = useOutgoingCategoriesCashNetQuery(range);
  const previousPeriodOutgoingCategories = useOutgoingCategoriesCashNetQuery(
    previousRange
  );
  const periodUncategorizedOutgoing = useUncategorizedCashOutQuery(range);
  const previousPeriodUncategorizedOutgoing = useUncategorizedCashOutQuery(
    previousRange
  );

  const data = useMetric({
    periodOutgoingCategories,
    previousPeriodOutgoingCategories,
    periodUncategorizedOutgoing,
    previousPeriodUncategorizedOutgoing,
  });

  const fetchAll = () =>
    Promise.all(
      [
        periodOutgoingCategories,
        previousPeriodOutgoingCategories,
        periodUncategorizedOutgoing,
        previousPeriodUncategorizedOutgoing,
      ].map(fetchMetricData)
    );

  return [data, { fetchAll }];
};

const CostBreakdownSection = ({
  periodSelector,
  selectedPeriodRange: range,
  selectedPeriodLabel,
  periodOutgoingCategories,
  periodUncategorizedOutgoing,
  previousPeriodOutgoingCategories,
  previousPeriodUncategorizedOutgoing,
}) => {
  // This makes it so that we'll keep the previous data while fetching, after
  // having switched the selected period
  const period = useMostRecentNonNull(periodOutgoingCategories);
  const previousPeriod = useMostRecentNonNull(previousPeriodOutgoingCategories);

  const { defaultCurrency: currency } = useStore("settings");
  const categories = useStore("categories");

  const categoryLabelsById = categories.reduce(
    (acc, c) => ({ ...acc, [c.id]: c.description }),
    {}
  );

  if (period == null || previousPeriod == null) return null;

  const mergedCategories = unique(
    [...period, ...previousPeriod].map((d) => d.id)
  );

  const data = mergedCategories
    .map((id) => {
      const selectedPeriodAmount = period.find((d) => d.id === id)?.value;
      const previousPeriodAmount = previousPeriod.find((d) => d.id === id)
        ?.value;

      return {
        id,
        amount: selectedPeriodAmount,
        previousAmount: previousPeriodAmount,
      };
    })
    .filter((d) =>
      [d.amount, d.previousAmount].some(
        (amount) => amount != null && amount < 0
      )
    )
    .map((d) => ({
      id: d.id,
      amount: Math.max(0, Math.abs(d.amount || 0)),
      previousAmount: Math.max(0, Math.abs(d.previousAmount || 0)),
    }));

  const periodAmounts = data.map((d) => d.amount);
  const previousPeriodAmounts = data.map((d) => d.previousAmount);
  const periodUncategorizedAmount = Math.abs(
    periodUncategorizedOutgoing?.[0]?.value ?? 0
  );
  const previousPeriodUncategorizedAmount = Math.abs(
    previousPeriodUncategorizedOutgoing?.[0]?.value ?? 0
  );

  const periodTotal = sum(identity, [
    ...periodAmounts,
    periodUncategorizedAmount,
  ]);

  const previousPeriodTotal = sum(identity, [
    ...previousPeriodAmounts,
    previousPeriodUncategorizedAmount,
  ]);

  // Group categories less than 2% under "Rest"
  const [relevantCategories, restCategories] = partition((c) => {
    const [periodFraction, previousPeriodFraction] = [
      c.amount / periodTotal,
      c.previousAmount / previousPeriodTotal,
    ];

    return [periodFraction, previousPeriodFraction].some(
      (fraction) => fraction >= 0.02
    );
  }, data);

  const largestAmount = Math.max(
    ...periodAmounts,
    ...previousPeriodAmounts,
    periodUncategorizedAmount,
    previousPeriodUncategorizedAmount
  );

  return (
    <>
      <G
        auto="minmax(0,1fr)"
        gap="2rem"
        style={{
          width: "52rem",
          maxWidth: "100%",
          margin: "0 auto",
          paddingTop: "4rem",
        }}
      >
        <div>
          <T block align="center">
            Cost breakdown for {periodSelector}
          </T>

          <Divider size="3rem" />

          <G
            template="repeat(3, max-content) minmax(0,1fr)"
            gap="0 2rem"
            align="center"
          >
            {sort(
              (d1, d2) =>
                descending(d1.amount, d2.amount) ||
                descending(d1.previousAmount, d2.previousAmount),
              relevantCategories
            ).map(({ id, amount, previousAmount }) => (
              <SpendCategory
                key={id}
                categoryId={id}
                label={categoryLabelsById[id]}
                amount={amount}
                total={periodTotal}
                comparisonAmount={previousAmount}
                comparisonTotal={previousPeriodTotal}
                largestAmount={largestAmount}
                from={range[0]}
                to={range[1]}
                currency={currency}
                periodLabel={selectedPeriodLabel}
              />
            ))}

            <SpendCategory
              categoryId="unknown"
              label="Uncategorized"
              amount={periodUncategorizedAmount}
              total={periodTotal}
              comparisonAmount={previousPeriodUncategorizedAmount}
              comparisonTotal={previousPeriodTotal}
              largestAmount={largestAmount}
              from={range[0]}
              to={range[1]}
              currency={currency}
              periodLabel={selectedPeriodLabel}
            />

            {restCategories.length !== 0 && (
              <SpendCategory
                label="Rest"
                amount={sum((c) => c.amount, restCategories)}
                total={periodTotal}
                comparisonTotal={previousPeriodTotal}
                comparisonAmount={sum((c) => c.previousAmount, restCategories)}
                largestAmount={largestAmount}
                currency={currency}
                periodLabel={selectedPeriodLabel}
              />
            )}
          </G>
        </div>
      </G>

      <Divider size="3rem" />

      <G
        template="repeat(2,max-content)"
        gap="3rem"
        justify="center"
        style={{ margin: "0 auto" }}
      >
        <G gap="1rem" align="center">
          <div
            style={{
              width: "1.2rem",
              height: "0.5rem",
              background: PRIMARY_COLOR,
            }}
          />
          <T color="rgb(0 0 0 / 54%)" size="1.2rem">
            {selectedPeriodLabel}
          </T>
        </G>
        <G gap="1rem" align="center">
          <div
            style={{
              width: "1.2rem",
              height: "0.5rem",
              background: "#ddd",
            }}
          />
          <T color="rgb(0 0 0 / 54%)" size="1.2rem">
            Previous period
          </T>
        </G>
      </G>
      <Divider />
    </>
  );
};

const SpendCategory = ({
  label,
  amount,
  comparisonAmount,
  total,
  comparisonTotal,
  largestAmount,
  categoryId,
  from,
  to,
  currency,
  periodLabel,
}) => {
  const [isHovered, eventHandlers] = useHover();

  const fraction = amount / total;
  const comparisonFraction = comparisonAmount / comparisonTotal;
  const growthFraction =
    comparisonAmount === 0
      ? null
      : (amount - comparisonAmount) / comparisonAmount;

  const link =
    categoryId == null
      ? null
      : categoryId === "unknown"
      ? `/dashboard/transactions?categories=${categoryId}&from=${formatDate(
          from,
          "yyyy-MM-dd"
        )}&to=${formatDate(to, "yyyy-MM-dd")}`
      : `/dashboard/categories/${categoryId}`;

  return (
    <React.Fragment>
      <CategoryTooltip
        periodLabel={periodLabel}
        amount={amount}
        comparisonAmount={comparisonAmount}
        fraction={fraction}
        comparisonFraction={comparisonFraction}
        growthFraction={growthFraction}
        currency={currency}
      >
        <T
          component={link == null ? "div" : RouterLink}
          to={link ?? undefined}
          block={false}
          weight="700"
          lineHeight={1.5}
          style={{
            padding: "0.5rem 0",
            color:
              link != null && isHovered
                ? "#666"
                : categoryId === "unknown"
                ? "rgb(0 0 0 / 50%)"
                : undefined,
          }}
          {...eventHandlers}
        >
          {label}
        </T>
      </CategoryTooltip>
      <T color="rgb(0 0 0 / 54%)" size="1.2rem">
        <FormattedNumber percent value={fraction} maximumFractionDigits={1} />
      </T>
      <T block color="rgb(0 0 0 / 54%)" size="1.2rem">
        <FormattedCurrency
          value={amount}
          currency={currency}
          currencyDisplay="narrowSymbol"
        />
      </T>
      <CategoryTooltip
        periodLabel={periodLabel}
        amount={amount}
        comparisonAmount={comparisonAmount}
        fraction={fraction}
        comparisonFraction={comparisonFraction}
        growthFraction={growthFraction}
        currency={currency}
      >
        <RouterLink
          to={link ?? undefined}
          style={{
            borderLeft: "0.1rem solid #ccc",
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
          {...eventHandlers}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "25%",
              borderLeft: "0.1rem solid #eee",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "50%",
              borderLeft: "0.1rem solid #eee",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "75%",
              borderLeft: "0.1rem solid #eee",
            }}
          />
          <div style={{ flex: 1 }}>
            <FractionBar
              height="0.7rem"
              width="100%"
              fraction={amount / largestAmount}
              color={isHovered ? PRIMARY_COLOR_LIGHT_TINT : PRIMARY_COLOR}
            />
            <FractionBar
              height="0.7rem"
              width="100%"
              fraction={comparisonAmount / largestAmount}
              color="#ddd"
            />
          </div>
        </RouterLink>
      </CategoryTooltip>
    </React.Fragment>
  );
};

const CategoryTooltip = ({
  children,
  periodLabel,
  amount,
  fraction,
  comparisonAmount,
  comparisonFraction,
  growthFraction,
  currency,
}) => (
  <Tooltip
    position={positionTopCenter}
    label={
      <T color="rgb(255 255 255 / 60%)">
        <G template="repeat(3, max-content)" gap="0.5rem 2rem">
          <T align="right">{periodLabel}</T>
          <T color="white">
            <FormattedCurrency value={amount} currency={currency} />
          </T>
          <T color="white">
            <FormattedNumber
              percent
              value={fraction}
              maximumFractionDigits={1}
            />
          </T>
          <T align="right">Previous period</T>
          <T color="white">
            <FormattedCurrency value={comparisonAmount} currency={currency} />
          </T>
          <T color="white">
            <FormattedNumber
              percent
              value={comparisonFraction}
              maximumFractionDigits={1}
            />
          </T>
          {growthFraction != null && (
            <>
              <T align="right">Difference</T>
              <T color="white" style={{ gridColumn: "span 2" }}>
                {growthFraction === 0 ? (
                  "None"
                ) : (
                  <>
                    {growthFraction > 10 ? (
                      ">1000%"
                    ) : (
                      <FormattedNumber
                        percent
                        value={Math.abs(growthFraction)}
                        maximumFractionDigits={1}
                      />
                    )}{" "}
                    {growthFraction > 0 ? "increase" : "decrease"}
                  </>
                )}
              </T>
            </>
          )}
        </G>
      </T>
    }
  >
    {children}
  </Tooltip>
);

export default CostBreakdownSection;
