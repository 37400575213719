import formatDate from "date-fns/format";
import { TransactionsEndpoint } from "../utils/constants";
import { handleErrorResponse, apiInstance } from "./common";
import request from "../utils/request";

export const transactionService = {
  getTransactions,
  getAllTransactions,
  getTransactionById,
};

export async function getTransactions({
  page = 0,
  size = 10,
  search = null,
  range: [fromDate, toDate] = [],
  minAmount,
  maxAmount,
  merchant = null,
  categories = null,
  vendors = null,
  sorting = "-date",
  amount = null,
  bankAccounts = null,
}) {
  const params = {
    page,
    size,
    sort: sorting,
    search,
    merchant,
    amount,
    min_amount: minAmount,
    max_amount: maxAmount,
    bank_accounts: bankAccounts?.join(","),
    from_date: fromDate ? formatDate(fromDate, "yyyy-MM-dd") : undefined,
    to_date: toDate ? formatDate(toDate, "yyyy-MM-dd") : undefined,
    vendors: vendors?.join(","),
    categories: categories?.join(","),
  };

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value != null)
  );

  return apiInstance
    .get(TransactionsEndpoint, { params: filteredParams })
    .then((response) => response.data)
    .catch(handleErrorResponse);
}

export async function getTransactionById(transactionId) {
  let url = TransactionsEndpoint + "/" + transactionId;
  let params = {
    expand: "bank_account",
  };

  return apiInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      return response.data;
    })
    .catch(handleErrorResponse);
}

export async function getAllTransactions(params, data = []) {
  return getTransactions(params).then(function (response) {
    let hasMore = response.has_more;
    data = data.concat(response.data);
    if (hasMore) {
      let page = params["page"] || 0;
      page += 1;
      params["page"] = page;
      return getAllTransactions(params, data);
    } else {
      response.data = data;
      return response;
    }
  });
}

export const update = (id, { category, companyId }) =>
  request(`/transactions/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json", "Company-Id": companyId },
    body: JSON.stringify({ category }),
  });
