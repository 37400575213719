import React from "react";

const OverlayCard = ({ children, padding = "0.8rem 1rem", style }) => (
  <div
    style={{
      boxShadow: "0.2rem 0.2rem 1rem rgb(0 0 0 / 10%)",
      background: "#333",
      color: "white",
      border: "none",
      borderRadius: "0.4rem",
      whiteSpace: "nowrap",
      fontSize: "1.2rem",
      padding,
      ...style,
    }}
  >
    {children}
  </div>
);

export default OverlayCard;
