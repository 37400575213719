import React from "react";

const Grid = React.forwardRef(
  (
    { column = false, auto, template, gap, align, justify, style, ...props },
    ref
  ) => {
    const templateProperty = column
      ? "gridTemplateRows"
      : "gridTemplateColumns";
    const autoProperty = column ? "gridAutoRows" : "gridAutoColumns";
    return (
      <div
        ref={ref}
        style={{
          display: "grid",
          [templateProperty]: template,
          [autoProperty]: auto,
          gridAutoFlow:
            template == null ? (column ? "row" : "column") : undefined,
          gridGap: gap,
          alignItems: align,
          justifyContent: justify,
          ...style,
        }}
        {...props}
      />
    );
  }
);

export default Grid;
