import React, { useState } from "react";
import { css } from "emotion";
import {
  Container as DialogContainer,
  Section as DialogSection,
} from "./dialog";
import Button from "./Button";
import Text from "./Text";
import Spinner from "./Spinner";

const BankSelectorModal = ({
  dismiss,
  navigateToAiiaExternalConnectionFlow,
  openPlaidConnectionUI,
}) => {
  const [didClickRegion, setClickedRegion] = useState(false);

  const isReady = typeof openPlaidConnectionUI === "function";

  const handleClickSweden = () => {
    setClickedRegion(true);
    navigateToAiiaExternalConnectionFlow();
  };

  const handleClickUs = () => {
    dismiss();
    openPlaidConnectionUI();
  };

  return (
    <DialogContainer width="52rem">
      <DialogSection divider={false} style={{ padding: "3rem" }}>
        {!isReady ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "10rem",
            }}
          >
            <Spinner size="3rem" color="#bbb" />
          </div>
        ) : (
          <>
            <Text component="h2" size="1.8rem" margin="0 0 3rem">
              Pick a region...
            </Text>

            <div
              className={css({
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0,1fr))",
                gridGap: "1.4rem",
              })}
            >
              <Button
                size="large"
                onClick={handleClickUs}
                disabled={
                  typeof openPlaidConnectionUI !== "function" || didClickRegion
                }
                style={{ justifyContent: "center" }}
              >
                <span
                  role="img"
                  aria-label="flag-us"
                  style={{ fontSize: "1.6rem", marginRight: "1rem" }}
                >
                  🇺🇸
                </span>
                US
              </Button>

              <Button
                size="large"
                onClick={handleClickSweden}
                disabled={didClickRegion}
                style={{ justifyContent: "center" }}
              >
                <span
                  style={{ marginRight: "1rem", fontSize: "1.6rem" }}
                  role="img"
                  aria-label="flag-se"
                >
                  🇸🇪
                </span>
                {"  "}
                Sweden
              </Button>
            </div>
          </>
        )}
      </DialogSection>
    </DialogContainer>
  );
};

export default BankSelectorModal;
