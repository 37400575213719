import memoize from "mem";
import debounce from "debounce-fn";
import { css } from "emotion";
import formatDate from "date-fns/format";
import addMonths from "date-fns/addMonths";
import subtractMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isWithinInterval from "date-fns/isWithinInterval";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import React from "react";
import { PRIMARY_COLOR, GREY_BACKGROUND } from "../../constants/colors";
import { pluralise } from "../../utils/string";
import { partition, sort, sum } from "../../utils/array";
import { ascending } from "../../utils/comparators";
import { padLinear } from "../../utils/extent";
import { identity, mapArguments } from "../../utils/function";
import {
  PAYROLL as PAYROLL_CATEGORY,
  isOutgoing as isOutgoingCategory,
  isIncoming as isIncomingCategory,
} from "../../utils/categories";
import { projectInputEntryCashFlow } from "../../utils/runway-scenarios";
import {
  createLivePreset as createLivePresetInputEntry,
  isComplete as isCompleteInputEntry,
  isVisible as isVisibleInputEntry,
  isStatic as isStaticInputEntry,
  getLivePresetName as getLivePresetInputEntryName,
  getLivePresetMetricQuery as getLivePresetInputEntryMetricQuery,
  getLivePresetStartAmount as getLivePresetInputEntryStartAmount,
  assignLivePresetDefaults as assignLivePresetInputEntryDefaults,
  livePresetIncomeTypes as livePresetInputEntryIncomeTypes,
  livePresetExpenseTypes as livePresetInputEntryExpenseTypes,
} from "../../utils/projection-input-entries";
import useStore from "../../hooks/stores";
import useBalanceProjection, {
  calculateAmountsByDate,
} from "../../hooks/balance-projection";
import { selectDataByQuery as selectMetricDataByQuery } from "../../hooks/metrics";
import {
  selectAll as selectAllScenarios,
  selectById as selectScenarioById,
  selectPendingById as selectPendingScenarioById,
} from "../../hooks/scenarios";
import usePrevious from "../../hooks/previous";
import Tooltip, { positionBottomCenter, positionTopCenter } from "../Tooltip";
import FormattedDate, {
  useFormatter as useDateFormatter,
} from "../FormattedDate";
import FormattedCurrency from "../FormattedCurrency";
import FormattedNumber from "../FormattedNumber";
import PageContainer from "../PageContainer";
import BarChart from "../BarChart";
import OverlayCard from "../OverlayCard";
import {
  Container as DialogContainer,
  Title as DialogTitle,
  Section as DialogSection,
} from "../dialog";
import Divider from "../Divider";
import Select from "../Select";
import DatePicker from "../DatePicker";
import FilterableListbox from "../FilterableListbox";
import ErrorBoundary from "../ErrorBoundary";
import ExceptionInformationBlock from "../ExceptionInformationBlock";
import T from "../Text";
import Spinner from "../Spinner";
import Grid from "../Grid";
import Button from "../Button";
import AutoWidthInput from "../AutoWidthInput";
import Icon from "../Icon";
import Modal from "../Modal";
import { Chart } from "../chart-utils";
import { Line, Area } from "../LineChart";
import MainChartDatumHoverPopover from "./MainChartHoverPopover";

const isProduction = process.env.NODE_ENV === "production";

const MAIN_CHART_HEIGHT = "50rem";

export const GREEN = "rgb(120 210 170)";
export const RED = "rgb(255 180 190)";

const green = (o) => `rgb(90 200 135 / ${(100, parseInt(o * 100))}%)`;
const red = (o) => `rgb(240 130 140 / ${parseInt(o * 100)}%)`;
const blue = (o) => `rgb(150 200 255 / ${parseInt(o * 100)}%)`;
export const lightBlue = (o = 1) => `rgb(160 200 255 / ${o * 100}%)`;

export const createDefaultScenarioProperties = ({ categories }) => {
  return {
    name: "Untitled projection",
    inputEntries: [
      createLivePresetInputEntry("money-in-previous-month-to-date", {
        name: "Revenue",
        categories: ["revenue"],
        growthType: "exponential",
        growth: [0, 0.05],
      }),
      createLivePresetInputEntry("money-out-previous-month-to-date", {
        name: "Payroll",
        categories: [PAYROLL_CATEGORY],
        growthType: null,
        growth: 0,
      }),
      createLivePresetInputEntry("money-out-previous-month-to-date", {
        name: "Other expenses",
        categories: categories
          .filter(isOutgoingCategory)
          .filter((c) => c.id !== PAYROLL_CATEGORY)
          .map((c) => c.id),
        growthType: null,
        growth: 0,
      }),
    ],
  };
};

const useMetric = (queriesByName) => {
  const [getMetricState, { fetch: fetchMetricData }] = useStore("metrics");

  const dataByName = React.useMemo(
    () =>
      Object.entries(queriesByName).reduce(
        (data, [key, query]) => ({
          ...data,
          [key]: getMetricState(selectMetricDataByQuery(query)),
        }),
        {}
      ),
    [queriesByName, getMetricState]
  );

  const prevQueriesByName = usePrevious(queriesByName);

  React.useEffect(() => {
    const queries = Object.entries(queriesByName);
    const hasChanged = (name) =>
      prevQueriesByName == null ||
      prevQueriesByName[name] !== queriesByName[name];

    for (let [name, query] of queries) {
      if (!hasChanged(name)) return;
      fetchMetricData(query);
    }
  }, [fetchMetricData, queriesByName, prevQueriesByName]);

  return dataByName;
};

const useInitialBalance = () => {
  const [customBalance = 0, setCustomBalance] = React.useState(undefined);
  const [selectedBalanceType, setSelectedBalanceType] = React.useState(
    "current"
  );

  const closeBalanceQuery = React.useMemo(
    () => ({
      metric: "close_balance",
      frequency: "monthly",
      from: subtractMonths(new Date(), 1),
      to: endOfMonth(new Date()),
    }),
    []
  );

  const closeBalanceQueries = React.useMemo(
    () => ({ closeBalance: closeBalanceQuery }),
    [closeBalanceQuery]
  );

  const { closeBalance } = useMetric(closeBalanceQueries);

  const getBalance = (balanceType) => {
    switch (balanceType) {
      case "current":
        return closeBalance?.slice(-1)[0].value;
      case "last-month":
        return closeBalance?.slice(-2)[0].value;
      case "custom":
        return customBalance;
      default:
        throw new Error();
    }
  };

  const initialBalance = getBalance(selectedBalanceType);

  const getMonth = useDateFormatter({ month: "long" });

  const balanceTypes = [
    { id: "current", label: "Current balance", amount: getBalance("current") },
    {
      id: "last-month",
      label: `${getMonth(subtractMonths(new Date(), 1))} close balance`,
      amount: getBalance("last-month"),
    },
    { id: "custom", label: "Custom balance", amount: getBalance("custom") },
  ];

  return {
    initialBalance,
    balanceTypes,
    selectedBalanceType,
    selectBalanceType: setSelectedBalanceType,
    setCustomBalance,
  };
};

const calculateEndDate = ({ period, startDate }) => {
  switch (period) {
    case "6-months":
      return addMonths(startOfMonth(startDate), 6);
    case "1-years":
      return addYears(startOfMonth(startDate), 1);
    case "2-years":
      return addYears(startOfMonth(startDate), 2);
    case "3-years":
      return addYears(startOfMonth(startDate), 3);
    case "5-years":
      return addYears(startOfMonth(startDate), 5);
    case "dynamic":
      return undefined;
    default:
      throw new Error();
  }
};

const useLivePresetData = (inputEntries) => {
  const livePresetInputEntryQueries = React.useMemo(() => {
    if (inputEntries == null) return {};

    return inputEntries
      .filter((r) => r.livePreset != null)
      .reduce(
        (acc, r) => ({
          ...acc,
          [r.id]: getLivePresetInputEntryMetricQuery(r.livePreset, {
            categories: r.categories,
          }),
        }),
        {}
      );
  }, [inputEntries]);

  const dataByInputEntryId = useMetric(livePresetInputEntryQueries);

  return dataByInputEntryId;
};

const assignLivePresetProjectionData = memoize((inputEntry) => ({
  ...inputEntry,
  data: projectInputEntryCashFlow(inputEntry),
}));

const useInputEntryProjections = (scenario, { dataByInputEntryId }) => {
  const scenarioWithProjection = React.useMemo(() => {
    return scenario.inputEntries
      .map((inputEntry) =>
        assignLivePresetInputEntryDefaults(
          inputEntry,
          dataByInputEntryId[inputEntry.id]
        )
      )
      .map(assignLivePresetProjectionData);
  }, [scenario, dataByInputEntryId]);

  return scenarioWithProjection;
};

const Scenarios = ({
  scenario,
  scenarios,
  onSelectScenario,
  addInputEntry,
  updateInputEntry,
  removeInputEntry,
  addInputEntryModifier,
  updateInputEntryModifier,
  removeInputEntryModifier,
  categories,
  currency,
  duplicateScenario,
  toggleCreateDialog,
  toggleEditDialog,
  toggleDeleteDialog,
}) => {
  const smartInputEntryDataById = useLivePresetData(scenario.inputEntries);
  // const hasPendingLiveData = Object.values(smartInputEntryDataById).some(
  //   (d) => d == null
  // );
  const inputEntries = useInputEntryProjections(scenario, {
    dataByInputEntryId: smartInputEntryDataById,
  });

  // const [mode, setMode] = React.useState("present");
  const [period, setPeriod] = React.useState("dynamic");
  const [projectionMode, setProjectionMode] = React.useState("in-out");
  const [editingInputEntryIds, setEditingInputEntryIds] = React.useState([]);

  const [projectionStartDate, staticProjectionEndDate] = React.useMemo(() => {
    const startDate = startOfMonth(new Date());
    return [startDate, calculateEndDate({ startDate, period })];
  }, [period]);

  const visibleInputEntries = React.useMemo(
    () => inputEntries.filter(isCompleteInputEntry).filter(isVisibleInputEntry),
    [inputEntries]
  );

  const dataByMonth = calculateAmountsByDate(visibleInputEntries);

  const {
    initialBalance,
    selectedBalanceType,
    selectBalanceType,
    balanceTypes,
    setCustomBalance,
  } = useInitialBalance();

  const balanceProjection = useBalanceProjection({
    initialBalance,
    startDate: projectionStartDate,
    endDate: staticProjectionEndDate,
    inputEntries: visibleInputEntries,
  });

  // const runwayEndDate = balanceProjection.find((d) => d.balance <= 0)?.date;
  const projectionEndDate =
    staticProjectionEndDate ?? balanceProjection.slice(-1)[0]?.date;
  const lastRunwayMonthDatum = balanceProjection.find(
    (d) => d.balance + d.net < 0
  );
  const runwayMonthsCount =
    lastRunwayMonthDatum == null
      ? null
      : differenceInCalendarMonths(
          lastRunwayMonthDatum.date,
          projectionStartDate // TODO now?
        );

  const [incomeInputEntries, expenseInputEntries] = partition(
    (r) => r.flow === "income",
    inputEntries
  );

  if (initialBalance == null) return <LoadingScreen />;

  return (
    <PageContainer
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 2rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridGap: "2rem",
              alignItems: "center",
            }}
          >
            <Select
              renderTrigger={(o) => (
                <T
                  variant="headline-small"
                  component="h2"
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  <span style={{ marginRight: "0.6rem" }}>{o?.label}</span>
                  <Icon name="caret-up" style={{ transform: "scaleY(-1)" }} />
                </T>
              )}
              value={scenario.id}
              options={sort(
                mapArguments((s) => s.name.toLowerCase(), ascending),
                scenarios
              ).map((s) => ({ value: s.id, label: s.name }))}
              onChange={(e) => onSelectScenario(e.target.value)}
            />
          </div>

          <div
            style={{ display: "grid", gridAutoFlow: "column", gridGap: "1rem" }}
          >
            <Button
              size="tiny"
              variant="primary"
              onClick={toggleCreateDialog}
              style={{ fontWeight: "700" }}
            >
              New
            </Button>
            <Button size="tiny" onClick={toggleEditDialog}>
              Rename
            </Button>
            <Button size="tiny" onClick={duplicateScenario}>
              Duplicate
            </Button>
            <Button size="tiny" onClick={toggleDeleteDialog}>
              Delete
            </Button>
          </div>
        </div>
      }
    >
      <div style={{ background: GREY_BACKGROUND, padding: "2rem" }}>
        <Grid
          template="minmax(0,1fr) repeat(2, auto)"
          gap="2rem"
          style={{ alignItems: "flex-end", fontWeight: "500" }}
        >
          <div>
            {lastRunwayMonthDatum != null ? (
              <Tooltip
                label={
                  lastRunwayMonthDatum == null ? null : (
                    <T color="rgb(255 255 255 /60%)">
                      Missing{" "}
                      <T color="white" weight="700">
                        <FormattedCurrency
                          currency={currency}
                          value={Math.abs(
                            lastRunwayMonthDatum.balance +
                              lastRunwayMonthDatum.net
                          )}
                          maximumFractionDigits={0}
                        />
                      </T>{" "}
                      to cover forecasted burn for{" "}
                      <FormattedDate
                        value={lastRunwayMonthDatum.date}
                        month="long"
                      />{" "}
                      (
                      <T color={RED} weight="700">
                        <FormattedCurrency
                          value={Math.abs(lastRunwayMonthDatum.net)}
                          currency={currency}
                          maximumFractionDigits={0}
                        />
                      </T>
                      )
                    </T>
                  )
                }
              >
                <span>
                  <T weight="700">
                    {runwayMonthsCount} {pluralise("month", runwayMonthsCount)}
                  </T>{" "}
                  of runway{" "}
                  {lastRunwayMonthDatum != null && (
                    <>
                      (lasts until{" "}
                      <FormattedDate
                        value={lastRunwayMonthDatum.date}
                        month="long"
                        year="numeric"
                      />
                      )
                    </>
                  )}
                </span>
              </Tooltip>
            ) : (
              <Tooltip label={<T weight="500">No end of runway projected</T>}>
                <span>
                  <T weight="700">Infinite</T> runway
                </span>
              </Tooltip>
            )}
          </div>
          <Grid gap="2rem" align="center" style={{ fontWeight: "500" }}>
            {/*/<Select */}
            {/*   value={mode} */}
            {/*   onChange={(e) => setMode(e.target.value)} */}
            {/*   options={[ */}
            {/*     { value: "present", label: "Test" }, */}
            {/*     { value: "edit", label: "FUN edit" }, */}
            {/*   ]} */}
            {/*   renderTrigger={(o) => <Link>{o.label}</Link>} */}
            {/* /> */}
            <div>
              Show{" "}
              <Select
                value={projectionMode}
                onChange={(e) => setProjectionMode(e.target.value)}
                options={[
                  { value: "in-out", label: "Income & expenses" },
                  { value: "net", label: "Net cash" },
                  // { value: "row-split", label: "Split by income/expense" },
                  {
                    value: "balance-forecast-only",
                    label: "Balance forecast only",
                  },
                ]}
                renderTrigger={(o, isHovered) => (
                  <FormLabel link isHovered={isHovered}>
                    {o.label}
                  </FormLabel>
                )}
              />
            </div>
            <div>
              Time horizon{" "}
              <Select
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                options={[
                  { value: "6-months", label: "6 months" },
                  { value: "1-years", label: "1 year" },
                  { value: "2-years", label: "2 years" },
                  { value: "3-years", label: "3 years" },
                  { value: "5-years", label: "5 years" },
                  { value: "dynamic", label: "Adapt to runway" },
                ]}
                renderTrigger={(o, isHovered) => (
                  <FormLabel link isHovered={isHovered}>
                    {o.label}{" "}
                  </FormLabel>
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Divider size="2rem" />

        <div style={{ padding: "0 2rem" }}>
          <MainChart
            mode={projectionMode}
            startDate={projectionStartDate}
            endDate={projectionEndDate}
            balanceProjection={balanceProjection}
            inputEntries={visibleInputEntries}
            dataByMonth={dataByMonth}
            currency={currency}
            // onClickX={(date, e) => {
            //   expandedDateContainerRef.current = e.target;
            //   setExpandedDate(date);
            // }}
          />
        </div>
      </div>

      <div style={{ padding: "2rem" }}>
        <FormattedCurrency currency={currency}>
          {({ format: formatCurrency }) => (
            <>
              <div>
                <T weight="500">Start with</T>{" "}
                <Select
                  value={selectedBalanceType}
                  options={balanceTypes.map((t) => ({
                    value: t.id,
                    label:
                      t.id === "custom"
                        ? t.label
                        : `${t.label} (${formatCurrency(t.amount)})`,
                  }))}
                  onChange={(e) => selectBalanceType(e.target.value)}
                  renderTrigger={(o, isHovered) => {
                    const balanceType = balanceTypes.find(
                      (t) => t.id === o.value
                    );
                    return (
                      <FormLabel link isHovered={isHovered}>
                        {balanceType.label}
                      </FormLabel>
                    );
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  fontSize: "1.8rem",
                  fontWeight: "500",
                }}
              >
                <Editable
                  autoFocus={selectedBalanceType === "custom"}
                  value={initialBalance}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setCustomBalance(undefined);
                      selectBalanceType("current");
                      return;
                    }
                    setCustomBalance(parseFloat(e.target.value) || undefined);
                    selectBalanceType("custom");
                  }}
                  format={(n) =>
                    typeof n === "number" ? formatCurrency(n) : n
                  }
                />
              </div>
            </>
          )}
        </FormattedCurrency>

        <Divider size="3rem" />

        <div>
          {[
            {
              inputEntries: incomeInputEntries,
              label: "Income",
              flow: "income",
            },
            {
              inputEntries: expenseInputEntries,
              label: "Expenses",
              flow: "expense",
            },
          ].map(({ inputEntries, label, flow }, sectionIndex) => {
            const isExpense = flow === "expense";
            return (
              <div>
                <div
                  style={{
                    margin: "0 0 1rem",
                    marginTop: sectionIndex === 0 ? 0 : "3rem",
                  }}
                >
                  <T variant="label-light">{label}</T>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, 24rem)",
                    gridGap: "1rem",
                    alignItems: "stretch",
                  }}
                >
                  {inputEntries.map((inputEntry) => {
                    const update = (properties) =>
                      updateInputEntry(inputEntry.id, properties);
                    const remove = () => removeInputEntry(inputEntry.id);
                    const addModifier = (properties) => {
                      const latestStartDate =
                        [
                          inputEntry.start,
                          ...inputEntry.modifiers.map((m) => m.start),
                        ]
                          .filter(Boolean)
                          .slice(-1)[0] ?? projectionStartDate;

                      return addInputEntryModifier(inputEntry.id, {
                        ...properties,
                        start:
                          properties.start ?? addMonths(latestStartDate, 1),
                      });
                    };

                    const removeModifier = (modifierIndex) =>
                      removeInputEntryModifier(inputEntry.id, modifierIndex);

                    const updateModifier = (index, properties) =>
                      updateInputEntryModifier(
                        inputEntry.id,
                        index,
                        properties
                      );

                    const isEditing = editingInputEntryIds.includes(
                      inputEntry.id
                    );
                    const toggleEditing = () =>
                      setEditingInputEntryIds((ids) =>
                        ids.includes(inputEntry.id)
                          ? ids.filter(
                              (editingId) => inputEntry.id !== editingId
                            )
                          : [...ids, inputEntry.id]
                      );
                    const normalizeAmount = (amount) => {
                      if (!isExpense) return amount;
                      return amount === 0 ? 0 : amount * -1;
                    };

                    const flowCategories = categories.filter(
                      isExpense ? isOutgoingCategory : isIncomingCategory
                    );

                    const availableCategories = [
                      ...sort(
                        (c1, c2) => ascending(c1.description, c2.description),
                        flowCategories
                      ),
                      { id: "other", description: "Other" },
                    ];

                    return (
                      <React.Fragment key={inputEntry.id}>
                        <EditableInputEntryBlock
                          key={inputEntry.id}
                          inputEntry={inputEntry}
                          toggleEditing={toggleEditing}
                          toggleVisibility={() =>
                            update({ hidden: !inputEntry.hidden })
                          }
                          isEditing={isEditing}
                          projectionRange={[
                            projectionStartDate,
                            projectionEndDate,
                          ]}
                          normalizeAmount={normalizeAmount}
                          currency={currency}
                        />

                        <Modal
                          isOpen={isEditing}
                          onDismiss={toggleEditing}
                          backdropOpacity={0}
                        >
                          {isEditing && (
                            <InputEntryEditDialog
                              inputEntry={inputEntry}
                              update={update}
                              remove={remove}
                              addModifier={addModifier}
                              removeModifier={removeModifier}
                              updateModifier={updateModifier}
                              normalizeAmount={normalizeAmount}
                              projectionRange={[
                                projectionStartDate,
                                projectionEndDate,
                              ]}
                              currency={currency}
                              categories={availableCategories}
                              dismiss={toggleEditing}
                              allDataByMonth={dataByMonth}
                            />
                          )}
                        </Modal>
                      </React.Fragment>
                    );
                  })}
                  <button
                    // onClick={() => setPendingInputEntryFlow(flow)}
                    onClick={() => {
                      const entry = addInputEntry({
                        name: `New ${flow}`,
                        flow,
                        type: "one-time",
                        start: startOfMonth(new Date()),
                      });
                      setEditingInputEntryIds([entry.id]);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: GREY_BACKGROUND, // `rgb(0 0 0 / 4%)`,
                      padding: "1rem",
                      borderRadius: "0.5rem",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                      color: "rgb(0 0 0 / 70%)",
                      minHeight: "7.6rem",
                      width: "5rem",
                    }}
                    className={css({
                      // border: "0.1rem dashed rgb(0 0 0 / 30%)",
                      // background: "rgb(0 0 0 / 2.3%)", // `rgb(0 0 0 / 4%)`,
                      ":focus,:hover": {
                        // background: GREY_BACKGROUND, // `rgb(0 0 0 / 4%)`,
                        // borderColor: "transparent",
                        background: GREY_BACKGROUND, // `rgb(0 0 0 / 4%)`,
                        boxShadow: `0 0 0 0.3rem ${blue(0.8)} inset`,
                      },
                    })}
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <Divider />
      </div>
    </PageContainer>
  );
};

const Editable = ({
  value,
  autoFocus = false,
  format = identity,
  inputComponent: Input = AutoWidthInput,
  interactable = true,
  style,
  ...props
}) => {
  const [pendingValue, setPendingValue] = React.useState(value);
  const [isEditing, setEditing] = React.useState(autoFocus);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (isEditing) inputRef.current.focus();
  }, [isEditing]);

  return isEditing ? (
    <Input
      ref={inputRef}
      autoFocus={autoFocus}
      value={pendingValue}
      style={style}
      {...props}
      onChange={(e) => {
        setPendingValue(e.target.value);
      }}
      onBlur={(e) => {
        if (props.onBlur) props.onBlur(e);
        setEditing(false);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") inputRef.current.blur();
      }}
    />
  ) : (
    <button
      onClick={() => {
        setPendingValue(value);
        setEditing(true);
      }}
      style={{ textAlign: "left", padding: 0, ...style }}
      className={css(
        interactable
          ? {
              borderRadius: "0.2rem",
              ":hover,:focus": { background: "rgb(0 0 0 / 5%)" },
            }
          : {}
      )}
    >
      {value == null || value === "" ? (
        <T truncate>{props.placeholder}</T>
      ) : (
        format(value)
      )}
    </button>
  );
};

const PreviewChart = ({ width, height = "1.6rem", hide, data }) => (
  <div
    style={{
      width,
      opacity: hide ? 0 : 1,
      transition: "0.1s opacity",
      pointerEvents: hide ? "none" : "all",
      // background: "rgb(150 200 255 / 20%)",
      borderRadius: "0.2rem",
      // padding: "0.4rem",
    }}
  >
    <Chart width="100%" height={height} data={[{ data }]}>
      {({ data, scaleX, scaleY }) => (
        <>
          {
            // {Array.isArray(r.growth) && (
            //   <Area
            //     scaleX={scaleX}
            //     scaleY={scaleY}
            //     fill="rgb(150 200 255 / 15%)"
            //     data={r.data.map((d) => [
            //       d.date,
            //       normalize(d.amountRange[0]),
            //       normalize(d.amountRange[1]),
            //     ])}
            //   />
            // )}
          }
          <Line
            scaleX={scaleX}
            scaleY={scaleY}
            stroke={PRIMARY_COLOR}
            // stroke="rgb(150 200 255 / 50%)"
            data={data[0].data}
            strokeWidth="0.2rem"
            // strokeDasharray="2 4"
          />

          {/* <circle */}
          {/*   cx={scaleX( */}
          {/*     data[0].data.slice(-1)[0][0] */}
          {/*   )} */}
          {/*   cy={scaleY( */}
          {/*     data[0].data.slice(-1)[0][1] */}
          {/*   )} */}
          {/*   r={3} */}
          {/*   fill={PRIMARY_COLOR} */}
          {/* /> */}
        </>
      )}
    </Chart>
  </div>
);

const DateSelect = ({
  interactable,
  value,
  placeholder,
  onChange,
  ...props
}) => (
  <DatePicker
    value={value}
    onChange={(d) => onChange(d == null ? null : startOfMonth(d))}
    renderTrigger={(props) => (
      <button
        {...props}
        style={{ textAlign: "left", lineHeight: 1.2 }}
        className={css(
          interactable
            ? {
                borderRadius: "0.2rem",
                ":hover": { background: "rgb(0 0 0 / 5%)" },
              }
            : {}
        )}
      >
        {value == null ? (
          placeholder
        ) : (
          <FormattedDate value={value} month="short" year="numeric" />
        )}
      </button>
    )}
    {...props}
  />
);

const TextButton = React.forwardRef(
  ({ component: Component = "button", style, ...props }, ref) => (
    <Component
      ref={ref}
      style={{
        textAlign: "left",
        padding: 0,
        fontWeight: "500",
        color: "rgb(0 0 0 / 54%)",
        lineHeight: 1.3,
        ...style,
      }}
      className={css({ ":hover": { textDecoration: "underline" } })}
      {...props}
    />
  )
);

const GrowthTypeSelect = ({ value, onChange, renderTrigger, ...props }) => (
  <Select
    value={value ?? "none"}
    onChange={({ target: { value } }) =>
      onChange(value === "none" ? null : value)
    }
    options={[
      {
        value: "none",
        label: "No growth",
      },
      { value: "linear", label: "Month-to-month growth by set amount" },
      {
        value: "exponential",
        label: "Month-to-month compound growth",
      },
    ]}
    renderTrigger={(o, isHovered) => {
      const getLabel = (o) => {
        switch (o.value) {
          case "linear":
            return "Growth by set amount";
          case "exponential":
            return "Compound growth";
          default:
            return o.label;
        }
      };
      const label = getLabel(o);

      if (typeof renderTrigger !== "function") return label;

      return renderTrigger(o, isHovered, label);
    }}
    style={{ display: "inline-flex" }}
    {...props}
  />
);

const EditableGrowthInput = ({
  value,
  growthType,
  onChange,
  currency,
  ...props
}) => {
  const usePercent = growthType !== "linear";
  const formatSingle = (n) => (usePercent ? n * 100 : n);

  const formatSingleOrRange = (value) => {
    if (!Array.isArray(value)) return formatSingle(value);
    return value.map(formatSingle).join(" to ");
  };

  const parseSingle = (string) => {
    const number = parseFloat(string.replace(/%/g, "").trim());
    const isValid = typeof number === "number" && !isNaN(number);
    return !isValid ? 0 : usePercent ? number / 100 : number;
  };

  const parseSingleOrRange = (string) => {
    const rangeSeparators = ["to", "-", "–"];
    const trimmedValue = string.split(" ").join("").trim();

    const isRange =
      trimmedValue.length >= 3 &&
      rangeSeparators.some((s) => trimmedValue.slice(1).includes(s));

    if (!isRange) return parseSingle(string);

    const separator = rangeSeparators.find((s) =>
      trimmedValue.slice(1).includes(s)
    );

    return string.split(separator).slice(0, 2).map(parseSingle);
  };

  return (
    <Editable
      value={formatSingleOrRange(value)}
      onBlur={({ target: { value } }) => {
        onChange(parseSingleOrRange(value));
      }}
      format={(growth) => {
        if (typeof growth === "number")
          return usePercent ? (
            <FormattedNumber percent value={growth / 100} />
          ) : (
            <FormattedCurrency
              value={growth}
              currency={currency}
              currencyDisplay="narrowSymbol"
            />
          );

        const [lower, upper] = growth
          .split("to")
          .map((s) => parseFloat(s.trim()));

        return usePercent ? (
          <>
            <FormattedNumber percent value={lower / 100} /> to{" "}
            <FormattedNumber percent value={upper / 100} />
          </>
        ) : (
          <>
            <FormattedCurrency
              value={lower}
              currency={currency}
              currencyDisplay="narrowSymbol"
              notation="compact"
              maximumFractionDigits={lower > 1000 ? 1 : 0}
            />{" "}
            to{" "}
            <FormattedCurrency
              value={upper}
              currency={currency}
              currencyDisplay="narrowSymbol"
              notation="compact"
              maximumFractionDigits={upper > 1000 ? 1 : 0}
            />
          </>
        );
      }}
      {...props}
    />
  );
};

const InputEntryEditDialog = ({
  inputEntry,
  update,
  remove,
  addModifier,
  updateModifier,
  removeModifier,
  projectionRange: [projectionStart, projectionEnd],
  normalizeAmount,
  dismiss,
  currency,
  categories,
  allDataByMonth,
}) => {
  const visibleData = React.useMemo(() => {
    const filteredData = inputEntry.data.filter((d) =>
      isWithinInterval(d.date, { start: projectionStart, end: projectionEnd })
    );
    return filteredData.map(({ amount, amountRange, ...rest }) => ({
      ...rest,
      amount: normalizeAmount(amount),
      amountRange: amountRange.map(normalizeAmount),
    }));
  }, [inputEntry.data, projectionEnd, projectionStart, normalizeAmount]);

  const total = sum((d) => d.amount, visibleData);
  const isExpense = inputEntry.flow === "expense";

  const hasChangeOverTime = !isStaticInputEntry(inputEntry, {
    range: [projectionStart, projectionEnd],
  });

  return (
    <DialogContainer>
      <DialogTitle>
        <Grid gap="2rem" justify="space-between" align="center">
          <div>Edit {inputEntry.flow}</div>
          <Tooltip
            position={positionTopCenter}
            label={
              inputEntry.hidden
                ? "Include in projection"
                : "Exclude from projection"
            }
          >
            <button
              onClick={() => update({ hidden: !inputEntry.hidden })}
              className={css({
                color: "rgb(0 0 0)",
                ":hover": { color: "rgb(0 0 0 / 35%)" },
              })}
            >
              {inputEntry.hidden ? (
                <ClosedEye size="1.6rem" />
              ) : (
                <Eye size="1.6rem" />
              )}
            </button>
          </Tooltip>
        </Grid>
      </DialogTitle>
      <DialogSection>
        {/* <T block variant="headline-small" margin="0 0 0.7rem"> */}
        {/*   Inputs */}
        {/* </T> */}
        {/* <T block variant="paragraph-light"> */}
        {/*   Helpful text. very helpful. */}
        {/* </T> */}
        {/* <Divider /> */}
        <Grid
          template="repeat(2, minmax(0,1fr))"
          gap="2rem"
          style={{ alignItems: "flex-start" }}
        >
          <div>
            <NameInput
              name={inputEntry.name}
              categoryIds={inputEntry.categories}
              onNameChange={(name) => update({ name })}
              onCategoryChange={(categoryIds) =>
                update({ categories: categoryIds })
              }
              allAvailableCategories={categories}
            />
          </div>
          <div>
            <TypeAndTimeRangeInput
              type={inputEntry.type}
              start={inputEntry.start}
              end={inputEntry.end}
              flow={inputEntry.flow}
              onTypeChange={(type) => {
                update((properties) => {
                  if (type !== "one-time") return { type };

                  return {
                    type,
                    livePreset: null,
                    flow: inputEntry.flow,
                    start: properties.start ?? startOfMonth(new Date()),
                    amount: properties.amount ?? 0,
                  };
                });
              }}
              onStartChange={(date) => update({ start: date })}
              onEndChange={(date) => update({ end: date })}
            />
          </div>
        </Grid>

        <Divider />

        <Grid
          template="repeat(2, minmax(0,1fr))"
          gap="2rem"
          style={{ alignItems: "flex-start" }}
        >
          <div>
            <LiveOrCustomAmountInput
              flow={inputEntry.flow}
              type={inputEntry.type}
              livePreset={inputEntry.livePreset}
              amount={inputEntry.amount}
              categoryIds={inputEntry.categories}
              currency={currency}
              onLivePresetChange={(preset) =>
                update(() => {
                  if (preset != null)
                    return {
                      livePreset: preset,
                      amount: null,
                      type: null,
                      flow: null,
                      start: null,
                    };

                  return {
                    livePreset: null,
                    amount: 0,
                    type: "reccuring",
                    flow: isExpense ? "expense" : "income",
                  };
                })
              }
              onAmountChange={(amount) => update({ amount })}
              dataOnProjectionStartDate={
                allDataByMonth[formatDate(projectionStart, "yyyy-MM")]
              }
            />
          </div>
          <div>
            {inputEntry.type === "reccuring" && (
              <GrowthInput
                growthType={inputEntry.growthType}
                growth={inputEntry.growth}
                onGrowthTypeChange={(growthType) =>
                  update({ growthType, growth: 0 })
                }
                onGrowthChange={(growth) => update({ growth })}
                currency={currency}
              />
            )}
          </div>
        </Grid>
      </DialogSection>

      {inputEntry.type === "reccuring" && (
        <DialogSection>
          <EditableModifierList
            modifiers={inputEntry.modifiers}
            updateModifier={updateModifier}
            removeModifier={removeModifier}
            currency={currency}
          />

          {inputEntry.modifiers?.length > 0 && <Divider />}
          <TextButton onClick={addModifier}>+ Add growth modifier</TextButton>
        </DialogSection>
      )}

      {inputEntry.type === "reccuring" && (
        <DialogSection>
          <T block variant="headline-small" margin="0 0 1rem">
            Projection
          </T>
          <LargeFormText block={false}>
            <FormattedCurrency
              value={total}
              currency={currency}
              notation={total >= 1000000 ? "compact" : undefined}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </LargeFormText>{" "}
          <FormattedDate day="numeric" month="short" year="numeric">
            {(formatDate) => (
              <T variant="paragraph-light">
                total from {formatDate(visibleData[0].date)} to{" "}
                {formatDate(endOfMonth(visibleData.slice(-1)[0].date))}
              </T>
            )}
          </FormattedDate>
          {hasChangeOverTime && (
            <>
              <Divider />
              <T block variant="paragraph-light">
                Hover a month for more details.
              </T>
              <Divider size="3rem" />
              <InputEntryProjectionChart
                startDate={projectionStart}
                endDate={projectionEnd}
                data={visibleData}
                addModifier={addModifier}
                currency={currency}
              />
            </>
          )}
        </DialogSection>
      )}

      <DialogSection divider={false}>
        <Grid template="auto 1fr auto" gap="1rem">
          <Button variant="primary" onClick={dismiss}>
            Close
          </Button>
          <div />
          <Button
            variant="secondary"
            style={{ color: "rgb(200 0 20)" }}
            onClick={() => {
              if (confirm("Are you sure?")) remove(); // eslint-disable-line
            }}
          >
            Remove
          </Button>
        </Grid>
      </DialogSection>
      <Divider size="1rem" />
    </DialogContainer>
  );
};

const EditableInputEntryBlock = ({
  inputEntry,
  isEditing,
  toggleEditing,
  toggleVisibility,
  normalizeAmount,
  currency,
  projectionRange: [projectionStart, projectionEnd],
}) => {
  const { name, livePreset, start, end, type, amount, hidden } = inputEntry;
  const visibleData = React.useMemo(() => {
    return inputEntry.data
      .filter((d) =>
        isWithinInterval(d.date, { start: projectionStart, end: projectionEnd })
      )
      .map(({ amount, amountRange, ...rest }) => ({
        ...rest,
        amount: normalizeAmount(amount),
        amountRange: amountRange.map(normalizeAmount),
      }));
  }, [normalizeAmount, inputEntry.data, projectionStart, projectionEnd]);

  const total = sum((d) => d.amount, visibleData);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <button
        onClick={toggleEditing}
        style={{
          width: "100%",
          textAlign: "left",
          background: GREY_BACKGROUND, // `rgb(0 0 0 / 4%)`,
          padding: "1rem",
          borderRadius: "0.5rem",
          fontWeight: "500",
          lineHeight: 1.2,
          opacity: hidden ? 0.5 : 1,
        }}
        className={css({
          ":focus,:hover": { boxShadow: `0 0 0 0.3rem ${blue(0.8)} inset` },
        })}
      >
        {!isStaticInputEntry(inputEntry, {
          range: [projectionStart, projectionEnd],
        }) && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              padding: "1rem",
            }}
          >
            <PreviewChart
              // width="10rem"
              width="5rem"
              height="1.6rem"
              hide={isEditing}
              data={inputEntry.data
                .filter((d) =>
                  isWithinInterval(d.date, {
                    start: projectionStart,
                    end: projectionEnd,
                  })
                )
                .map((d) => [d.date, normalizeAmount(d.amount)])}
            />
          </div>
        )}
        <T
          block
          lineHeight={1.3}
          style={{
            display: "inline-grid",
            gridAutoFlow: "column",
            gridGap: "0.3rem",
            alignItems: "center",
            paddingRight: "2rem",
          }}
        >
          <T block truncate lineHeight="1.8rem" style={{ height: "1.8rem" }}>
            {name ?? "..."}
          </T>
          {/* {livePreset && <Circle size="0.7rem" background={PRIMARY_COLOR} />} */}
          {livePreset && <Flash size="1.2rem" color={PRIMARY_COLOR} />}
        </T>
        <T
          block
          size="1.8rem"
          margin="0.4rem 0"
          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          <FormattedCurrency
            // currencyDisplay="narrowSymbol"
            currency={currency}
            notation={amount >= 1000000 ? "compact" : undefined}
            value={amount} // totalNet === 0 ? 0 : normalizeAmount(totalNet)}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
          {total !== amount && (
            <T
              truncate
              color="rgb(0 0 0 / 54%)"
              size="1.4rem"
              style={{ marginLeft: "0.5rem" }}
            >
              <FormattedNumber
                // currencyDisplay="narrowSymbol"
                // currency={currency}
                notation={total >= 10000 ? "compact" : undefined}
                value={total}
                minimumFractionDigits={0}
                maximumFractionDigits={2}
              />{" "}
              projected
            </T>
          )}
        </T>
        <T block size="1.4rem">
          {type === "one-time" ? (
            <FormattedDate value={start} month="short" year="numeric" />
          ) : (
            <T block>
              <FormattedDate
                value={start ?? startOfMonth(new Date())}
                month="short"
                year="numeric"
              />{" "}
              –{" "}
              {end == null ? (
                <T color="rgb(0 0 0 / 54%)">No end date</T>
              ) : (
                <FormattedDate value={end} month="short" year="numeric" />
              )}
            </T>
          )}
        </T>
      </button>
      <Tooltip
        position={positionTopCenter}
        label={hidden ? "Include in projection" : "Exclude from projection"}
      >
        <button
          onClick={toggleVisibility}
          style={{ position: "absolute", top: 0, right: 0, padding: "1rem" }}
          className={css({
            color: "rgb(0 0 0)",
            ":hover": { color: "rgb(0 0 0 / 35%)" },
          })}
        >
          {hidden ? <ClosedEye size="1.6rem" /> : <Eye size="1.6rem" />}
        </button>
      </Tooltip>
    </div>
  );
};

const MainChart = ({
  mode,
  startDate,
  endDate,
  balanceProjection,
  inputEntries: visibleInputEntries,
  dataByMonth,
  currency,
  // onClickX,
}) => {
  const barWidth = Math.min(8, 160 / balanceProjection.length);
  return (
    <>
      <div style={{ minWidth: MAIN_CHART_HEIGHT, padding: "0 2rem 2rem 0" }}>
        <BarChart
          style={{ overflow: "hidden" }}
          axisColor="rgb(0 0 0 / 20%)"
          gridColor="rgb(0 0 0 / 10%)"
          height="28rem"
          // height="max(28rem,30vh)"
          data={[
            {
              data: balanceProjection.map((d) => [d.date, d.balance]),
              color: "transparent",
            },
            mode === "row-split" && {
              type: "group",
              barWidth: barWidth / 2,
              gutter: 0,
              cornerRadius: 0,
              data: visibleInputEntries.map(({ name, flow, data }, i) => ({
                id: `${flow}-${name ?? i}`,
                color: `rgb(0 0 0 / ${
                  80 - i * (60 / visibleInputEntries.length)
                }%)`,
                data: data
                  .filter((d) =>
                    isWithinInterval(d.date, { start: startDate, end: endDate })
                  )
                  .map((d) => [
                    d.date,
                    d.amount * (flow === "expense" ? -1 : 1),
                  ]),
              })),
            },
            mode === "net" && {
              barWidth,
              color: PRIMARY_COLOR,
              data: balanceProjection.map((d) => [d.date, d.net]),
            },
            mode === "in-out" && {
              type: "group",
              barWidth,
              // gutter: 3,
              cornerRadius: 0,
              data: [
                {
                  color: green(0.5),
                  data: balanceProjection.map((d) => [d.date, d.inRange[1]]),
                },
                {
                  color: red(0.5),
                  data: balanceProjection.map((d) => [d.date, d.outRange[1]]),
                },
              ],
            },
            mode === "in-out" && {
              type: "group",
              barWidth,
              // gutter: 3,
              cornerRadius: 0,
              data: [
                {
                  // color: green(1),
                  color: ([date], hoveredDate) =>
                    green(date.getTime() === hoveredDate?.getTime() ? 1 : 0.8),
                  data: balanceProjection.map((d) => [d.date, d.in]),
                },
                {
                  color: ([date], hoveredDate) =>
                    red(date.getTime() === hoveredDate?.getTime() ? 1 : 0.8),
                  data: balanceProjection.map((d) => [d.date, d.out]),
                },
              ],
            },
          ].filter(Boolean)}
          domain={([[xMin, xMax], [yMin, yMax]]) => {
            const paddedYExtent = padLinear([yMin, yMax], 0.2);
            return [
              endDate == null
                ? [addDays(xMin, -15), addDays(xMax, 15)]
                : [addDays(startDate, -15), addDays(endDate, 15)],
              mode === "net" ? paddedYExtent : [0, paddedYExtent[1]],
            ];
          }}
          // onClick={onClickX}
          renderXTick={(d) => (
            <div style={{ position: "relative" }}>
              <FormattedDate value={d} month="short" />
              {d.getMonth() === 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    paddingTop: "0.4rem",
                  }}
                >
                  <FormattedDate value={d} year="numeric" />
                </div>
              )}
            </div>
          )}
          hoverPopoverPosition={positionBottomCenter}
          renderHoverPopoverContent={(hoveredDate) => {
            return (
              <MainChartDatumHoverPopover
                date={hoveredDate}
                balanceProjection={balanceProjection}
                inputEntries={visibleInputEntries}
                dataByMonth={dataByMonth}
                currency={currency}
              />
            );
          }}
        >
          {({ scaleX, scaleY, hoveredX }) => {
            const hoveredDatum = balanceProjection.find(
              (d) => d.date.getTime() === hoveredX?.getTime()
            );
            return (
              <>
                {/* <line */}
                {/*   x1={scaleX(domain[0][0]) - 5} */}
                {/*   y1={scaleY(0)} */}
                {/*   x2={scaleX(domain[0][1]) + 5} */}
                {/*   y2={scaleY(0)} */}
                {/*   stroke="rgb(0 0 0 / 50%)" */}
                {/* /> */}
                <Area
                  data={balanceProjection.map((d) => [
                    d.date,
                    d.balanceRange?.[1] ?? d.balance,
                    d.balanceRange?.[0] ?? d.balance,
                  ])}
                  scaleX={scaleX}
                  scaleY={scaleY}
                  fill="rgb(150 200 255 / 15%)"
                />
                <Line
                  data={balanceProjection.map((d) => [d.date, d.balance])}
                  scaleX={scaleX}
                  scaleY={scaleY}
                  stroke={PRIMARY_COLOR}
                  strokeWidth="0.2rem"
                  // strokeDasharray="3 6"
                />
                {hoveredDatum != null && (
                  <circle
                    cy={scaleY(hoveredDatum.balance)}
                    cx={scaleX(hoveredDatum.date)}
                    r={3}
                    fill={PRIMARY_COLOR}
                  />
                )}
              </>
            );
          }}
        </BarChart>
      </div>
      <Divider />
      <Grid
        gap="3rem"
        justify="flex-start"
        align="center"
        style={{ lineHeight: 1 }}
      >
        <Grid gap="0.7rem" align="center">
          <div
            style={{
              background: PRIMARY_COLOR,
              width: "1.4rem",
              height: "0.2rem",
            }}
          />
          <T size="1.2rem" weight="500">
            Balance
          </T>
        </Grid>
        <Grid gap="0.7rem" align="center">
          <div
            style={{
              background: lightBlue(0.5),
              width: "1.2rem",
              height: "1.2rem",
            }}
          />
          <T size="1.2rem" weight="500">
            Uncertainty range
          </T>
        </Grid>
        <Grid gap="0.7rem" align="center">
          <div
            style={{ background: GREEN, width: "0.6rem", height: "1.4rem" }}
          />
          <T block size="1.2rem" weight="500">
            Cash in
          </T>
        </Grid>
        <Grid gap="0.7rem" align="center">
          <div style={{ background: RED, width: "0.6rem", height: "1.4rem" }} />
          <T size="1.2rem" weight="500">
            Cash out
          </T>
        </Grid>
      </Grid>
    </>
  );
};

const InputEntryTypeSelect = ({ value, onChange, ...props }) => (
  <Select
    value={value}
    onChange={({ target: { value } }) => onChange(value)}
    options={[
      { value: "reccuring", label: "Monthly" },
      { value: "one-time", label: "Once" },
    ]}
    style={{ display: "inline-flex" }}
    {...props}
  />
);

const TypeAndTimeRangeInput = ({
  type,
  start,
  end,
  flow,
  onTypeChange,
  onStartChange,
  onEndChange,
}) => (
  <>
    <InputEntryTypeSelect
      value={type}
      onChange={onTypeChange}
      renderTrigger={(option, isHovered) => (
        <FormLabel link isHovered={isHovered}>
          {option?.value === "one-time" ? `One-time ${flow}` : option?.label}
        </FormLabel>
      )}
    />
    <LargeFormText margin="0.6rem 0 0">
      {type === "one-time" ? (
        <DateSelect
          interactable
          value={start}
          onChange={onStartChange}
          allowClear={false}
          placeholder="Now"
        />
      ) : (
        <>
          <DateSelect
            interactable
            value={start}
            onChange={onStartChange}
            placeholder="Now"
          />{" "}
          –{" "}
          <DateSelect
            interactable
            value={end}
            placeholder={<T color="rgb(0 0 0 / 54%)">No end date</T>}
            onChange={onEndChange}
          />
        </>
      )}
    </LargeFormText>
  </>
);

const mapEntries = (mapper, obj) =>
  Object.fromEntries(Object.entries(obj).map(mapper));

const LiveOrCustomAmountInput = ({
  flow,
  type,
  amount,
  livePreset,
  categoryIds,
  onAmountChange,
  onLivePresetChange,
  currency,
  // dataOnProjectionStartDate = [],
}) => {
  const livePresetTypes =
    flow === "expense"
      ? livePresetInputEntryExpenseTypes
      : livePresetInputEntryIncomeTypes;

  const livePresetQueries = React.useMemo(
    () =>
      livePresetTypes.reduce(
        (queries, preset) => ({
          ...queries,
          [preset.id]: getLivePresetInputEntryMetricQuery(preset.id, {
            categories: categoryIds,
          }),
        }),
        {}
      ),
    [livePresetTypes, categoryIds]
  );

  const dataByLivePresetIdentifier = useMetric(livePresetQueries);
  const amountByLivePresetIdentifier = mapEntries(
    ([id, data]) => [id, getLivePresetInputEntryStartAmount(id, { data })],
    dataByLivePresetIdentifier
  );

  // // console.log(dataOnProjectionStartDate);

  // const amountByCategoryId = dataOnProjectionStartDate.reduce(
  //   (amountByCategoryId, { amount, categories }) => {
  //     const splitAmount =
  //       categories.length === 0 ? amount : amount / categories.length;

  //     return categories.reduce((amountByCategoryId, categoryId) => {
  //       const categoryAmount = amountByCategoryId[categoryId] ?? 0;
  //       return {
  //         ...amountByCategoryId,
  //         [categoryId]: categoryAmount + splitAmount,
  //       };
  //     }, amountByCategoryId);
  //   },
  //   {}
  // );

  // const totalFirstMonth = sum(
  //   (d) => Math.max(d.amount, 0),
  //   dataOnProjectionStartDate
  // );
  // const totalSameCategory = categoryIds.reduce(
  //   (sum, categoryId) => sum + amountByCategoryId[categoryId],
  //   0
  // );
  // // console.log(amountByCategoryId);

  // const rateOfTotal = amount / totalFirstMonth;
  // const rateOfCategory = amount / totalSameCategory;
  // // console.log(rateOfCategory, rateOfTotal);

  return (
    <>
      {type === "one-time" ? (
        <>
          <FormLabel>Amount</FormLabel>
          <LargeFormText margin="0.6rem 0 0">
            <FormattedCurrency
              currencyDisplay="narrowSymbol"
              currency={currency}
            >
              {({ format }) => (
                <Editable
                  value={amount}
                  onBlur={(e) =>
                    onAmountChange(parseFloat(e.target.value) || 0)
                  }
                  format={format}
                />
              )}
            </FormattedCurrency>
          </LargeFormText>
        </>
      ) : (
        <>
          <Select
            value={livePreset != null ? "live" : "custom"}
            onChange={({ target: { value } }) =>
              onLivePresetChange(
                value === "custom" ? null : livePresetTypes[0].id
              )
            }
            options={[
              { value: "custom", label: "Custom start amount" },
              { value: "live", label: "Start amount from live actuals" },
            ]}
            renderTrigger={(o, isHovered) => (
              <FormLabel link isHovered={isHovered}>
                {o.label}
              </FormLabel>
            )}
          />
          <div style={{ margin: "0.6rem 0 0" }}>
            <FormattedCurrency
              currencyDisplay="narrowSymbol"
              currency={currency}
            >
              {({ format }) =>
                livePreset != null ? (
                  <Select
                    value={livePreset}
                    onChange={({ target: { value } }) =>
                      onLivePresetChange(value)
                    }
                    options={livePresetTypes.map((a) => ({
                      value: a.id,
                      label: `${getLivePresetInputEntryName(a.id)} (${format(
                        amountByLivePresetIdentifier[a.id]
                      )})`,
                    }))}
                    renderTrigger={(_, isHovered) => (
                      <Grid
                        gap="0.2rem"
                        justify="flex-start"
                        align="center"
                        style={{
                          borderRadius: "0.2rem",
                          background: isHovered ? "rgb(0 0 0 / 5%)" : undefined,
                        }}
                      >
                        <LargeFormText>{format(amount)}</LargeFormText>
                        <Flash color={PRIMARY_COLOR} size="1.4rem" />
                        <T size="1.2rem" color="rgb(0 0 0 / 54%)">
                          ({getLivePresetInputEntryName(livePreset)})
                        </T>
                      </Grid>
                    )}
                  />
                ) : (
                  <LargeFormText>
                    <Editable
                      value={amount}
                      onBlur={(e) =>
                        onAmountChange(parseFloat(e.target.value) || 0)
                      }
                      format={format}
                    />
                  </LargeFormText>
                )
              }
            </FormattedCurrency>
          </div>
        </>
      )}
    </>
  );
};

const GrowthInput = ({
  growthType,
  growth,
  onGrowthTypeChange,
  onGrowthChange,
  currency,
}) => (
  <>
    <GrowthTypeSelect
      value={growthType}
      onChange={onGrowthTypeChange}
      renderTrigger={(_, isHovered, label) => (
        <FormLabel link isHovered={isHovered}>
          {label}
        </FormLabel>
      )}
    />
    <LargeFormText margin="0.6rem 0 0">
      {growthType == null ? (
        "-"
      ) : (
        <EditableGrowthInput
          value={growth}
          growthType={growthType}
          onChange={onGrowthChange}
          currency={currency}
        />
      )}
    </LargeFormText>
  </>
);

const EditableModifierList = ({
  modifiers,
  updateModifier,
  removeModifier,
  currency,
}) => (
  <Grid template="minmax(0,1fr) max-content" gap="2rem">
    {modifiers.map(({ start, amount, growth, growthType }, modifierIndex) => (
      <React.Fragment
        key={[amount, growthType, growth, modifierIndex].map(String).join("")}
      >
        <div style={{ lineHeight: 1.4, fontWeight: "500" }}>
          From{" "}
          <DateSelect
            interactable
            value={start}
            onChange={(d) => updateModifier(modifierIndex, { start: d })}
            renderTrigger={(props) => (
              <FormLabel link {...props}>
                <FormattedDate value={start} month="short" year="numeric" />
              </FormLabel>
            )}
          />{" "}
          {amount == null ? "use" : "start with"}{" "}
          <T weight="700">
            <FormattedCurrency currency={currency}>
              {({ format: formatCurrency }) => (
                <Editable
                  value={amount}
                  placeholder="incoming amount"
                  onBlur={(e) =>
                    updateModifier(modifierIndex, {
                      amount:
                        e.target.value.trim() === ""
                          ? null
                          : parseFloat(e.target.value),
                    })
                  }
                  format={(n) =>
                    typeof n === "number" ? formatCurrency(n) : n
                  }
                />
              )}
            </FormattedCurrency>
          </T>{" "}
          and{" "}
          {growthType != null && (
            <EditableGrowthInput
              value={growth}
              growthType={growthType}
              onChange={(growth) => updateModifier(modifierIndex, { growth })}
              currency={currency}
              style={{ fontWeight: "700" }}
            />
          )}{" "}
          <GrowthTypeSelect
            value={growthType}
            onChange={(growthType) =>
              updateModifier(modifierIndex, { growthType, growth: 0 })
            }
            renderTrigger={(_, isHovered, label) => (
              <FormLabel
                link
                isHovered={isHovered}
                style={{ textTransform: "lowercase" }}
              >
                {label}
              </FormLabel>
            )}
          />
        </div>
        <button onClick={() => removeModifier(modifierIndex)}>x</button>
      </React.Fragment>
    ))}
  </Grid>
);

const NameInput = ({
  name,
  categoryIds = [], // TODO
  onNameChange,
  onCategoryChange,
  allAvailableCategories,
}) => (
  <>
    <FormLabel>Name</FormLabel>
    <LargeFormText margin="0.6rem 0 0">
      <Editable
        autoFocus
        value={name}
        onBlur={(e) => onNameChange(e.target.value || null)}
        placeholder="..."
        inputComponent="input"
        style={{ width: "100%" }}
      />
    </LargeFormText>

    <FilterableListbox
      dark
      filterable
      onSelect={(optionValue) => {
        if (optionValue === "none") {
          onCategoryChange([]);
          return;
        }

        if (optionValue === "all") {
          onCategoryChange(allAvailableCategories.map((c) => c.id));
          return;
        }

        onCategoryChange(
          categoryIds?.includes(optionValue)
            ? categoryIds?.filter((id) => id !== optionValue)
            : [...(categoryIds ?? []), optionValue]
        );
      }}
      options={[
        { value: "all", label: "Select all", stayOpen: true },
        { value: "none", label: "Deselect all", stayOpen: true },
        { type: "divider" },
        ...allAvailableCategories.map((c) => ({
          value: c.id,
          label: `${categoryIds?.includes(c.id) ? "[x]" : "[ ]"} ${
            c.description
          }`,
          stayOpen: true,
        })),
      ]}
      renderButton={({ props }) => {
        const all = categoryIds?.length === allAvailableCategories.length; // TODO

        return (
          <TextButton
            style={{ fontSize: "1.2rem", margin: "0.3rem 0 0" }}
            {...props}
          >
            {categoryIds?.length === 0 // TODO
              ? "No category specified"
              : all
              ? "All categories"
              : categoryIds
                  ?.map(
                    (id) =>
                      allAvailableCategories.find((c) => id === c.id)
                        ?.description
                  )
                  .join(", ")}
            <Icon
              size="0.5rem"
              name="caret-up"
              style={{ marginLeft: "0.5rem", transform: "scaleY(-1)" }}
            />
          </TextButton>
        );
      }}
    />
  </>
);

const LargeFormText = ({ ...props }) => (
  <T block lineHeight={1.3} size="1.8rem" weight="500" {...props} />
);

const FormLabel = React.forwardRef(
  ({ link = false, isHovered = false, style, ...props }, ref) => (
    <T
      ref={ref}
      weight="700"
      color={link ? PRIMARY_COLOR : "rgb(0 0 0 / 54%)"}
      underline={link}
      style={{
        ...(link
          ? {
              cursor: "pointer",
              opacity: isHovered ? 0.7 : undefined,
              ":hover": { opacity: 0.7 },
            }
          : {}),
        transition: "0.1s opacity",
        ...style,
      }}
      {...props}
    />
  )
);

const InputEntryProjectionChart = ({
  startDate,
  endDate,
  data,
  addModifier,
  currency,
}) => {
  const parsedData = React.useMemo(
    () => [
      {
        color: blue(0.5),
        barWidth: Math.min(8, Math.ceil(160 / data.length)),
        data: data.map((d) => [d.date, d.amountRange[1]]),
      },
      {
        color: PRIMARY_COLOR,
        barWidth: Math.min(8, Math.ceil(160 / data.length)),
        data: data.map((d) => [d.date, d.amount]),
      },
    ],
    [data]
  );

  return (
    <div style={{ padding: "0 0 1rem 0" }}>
      <BarChart
        yAxis={false}
        gridColor="rgb(0 0 0 / 10%)"
        axisColor="rgb(0 0 0 / 10%)"
        height="10rem"
        domain={([_, [yMin, yMax]]) => [
          [addDays(startDate, -15), addDays(endDate, 15)],
          [Math.min(0, yMin), yMax],
        ]}
        onClick={
          typeof addModifier === "function"
            ? (date) => {
                addModifier({ start: date });
              }
            : undefined
        }
        renderXTick={(d) => (
          <div style={{ position: "relative" }}>
            <FormattedDate value={d} month="short" />
            {d.getMonth() === 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  kaddingTop: "0.4rem",
                }}
              >
                <FormattedDate value={d} year="numeric" />
              </div>
            )}
          </div>
        )}
        data={parsedData}
        hoverPopoverPosition={positionTopCenter}
        renderHoverPopoverContent={(hoveredDate) => {
          const hoveredDatumIndex = data.findIndex(
            (d) => d.date.getTime() === hoveredDate.getTime()
          );
          const hoveredDatum = data[hoveredDatumIndex];
          const previousDatum = data[hoveredDatumIndex - 1];
          const hasRange = hoveredDatum.amountRange.some(
            (a) => a !== hoveredDatum.amount
          );

          const amount = () => {
            if (!hasRange)
              return (
                <FormattedCurrency
                  value={hoveredDatum.amount}
                  currency={currency}
                />
              );

            return (
              <>
                <FormattedCurrency
                  value={hoveredDatum.amount}
                  notation={
                    hoveredDatum.amount >= 1000000 ? "compact" : undefined
                  }
                  currency={currency}
                />{" "}
                <T size="1.2rem" color="rgb(255 255 255 / 54%)">
                  <FormattedNumber
                    notation="compact"
                    value={hoveredDatum.amountRange[0]}
                  />
                  {" – "}
                  <FormattedNumber
                    notation="compact"
                    value={hoveredDatum.amountRange[1]}
                  />
                </T>
              </>
            );
          };

          const change = () => hoveredDatum.amount - previousDatum.amount;
          return (
            <OverlayCard padding={0}>
              <div style={{ padding: "1rem 1rem" }}>
                <T block color="rgb(255 255 255 / 60%)" weight="500">
                  <FormattedDate
                    value={hoveredDatum.date}
                    month="long"
                    year="numeric"
                  />{" "}
                  forecast
                </T>
                <T block size="1.6rem" weight="700" margin="0.8rem 0 0">
                  {amount()}
                </T>

                {previousDatum != null && (
                  <T
                    block
                    weight="500"
                    margin="0.8rem 0 0"
                    color="rgb(255 255 255 / 60%)"
                  >
                    <T color="white">
                      <FormattedCurrency
                        value={Math.abs(change())}
                        notation={
                          Math.abs(change()) >= 1000000 ? "compact" : undefined
                        }
                        currency={currency}
                      />
                    </T>{" "}
                    {change() < 0 ? "decrease" : "increase"} from{" "}
                    <FormattedDate value={previousDatum.date} month="long" />
                  </T>
                )}
              </div>

              <T
                block
                // color="rgb(255 255 255 / 60%)"
                weight="500"
                lineHeight={1.3}
                color={lightBlue()}
                style={{
                  padding: "0.6rem 1rem",
                  // background: "rgb(255 255 255 / 10%)",
                  background: blue(0.1),
                }}
              >
                Click to change growth from{" "}
                <T weight="700">
                  <FormattedDate value={hoveredDatum.date} month="long" />
                </T>
              </T>
            </OverlayCard>
          );
        }}
      />
    </div>
  );
};

const Flash = ({ color = "black", size = "2rem" }) => (
  <svg viewBox="0 0 20 20" fill="none" style={{ width: size, color }}>
    <path
      d="M9.167 12.5H5L10.833.833V7.5H15L9.167 19.167V12.5z"
      fill="currentColor"
    />
  </svg>
);

const Eye = ({ color, size = "2rem" }) => (
  <svg viewBox="0 0 20 20" fill="none" style={{ width: size, color }}>
    <path
      d="M10 7.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0-3.75c4.166 0 7.725 2.592 9.166 6.25-1.441 3.658-5 6.25-9.166 6.25-4.167 0-7.725-2.592-9.167-6.25 1.442-3.658 5-6.25 9.167-6.25zM2.65 10a8.184 8.184 0 0014.7 0 8.184 8.184 0 00-14.7 0z"
      fill="currentColor"
    />
  </svg>
);

const ClosedEye = ({ color, size = "2rem" }) => (
  <svg viewBox="0 0 20 20" fill="none" style={{ width: size, color }}>
    <path
      d="M7.785 15.652l-1.609-.432.656-2.45a9.157 9.157 0 01-2.697-1.56L2.34 13.007l-1.179-1.18 1.795-1.793A9.131 9.131 0 01.98 5.807l1.64-.299a7.502 7.502 0 0014.76 0l1.64.299a9.131 9.131 0 01-1.974 4.226l1.794 1.794-1.18 1.179-1.794-1.795a9.157 9.157 0 01-2.697 1.56l.656 2.45-1.61.43-.656-2.45a9.223 9.223 0 01-3.117 0l-.657 2.45z"
      fill="currentColor"
    />
  </svg>
);

const ScenariosContainer = ({ match, companyId, ...props }) => {
  const { scenarioId } = match.params;

  const [
    getScenarioState,
    {
      fetch: fetchScenario,
      fetchAll: fetchAllScenarios,
      create: createScenario,
      remove: removeScenario,
      replace: persistScenario,
      localUpdate: updateScenario,
      addInputEntry,
      updateInputEntry,
      removeInputEntry,
      addModifier: addInputEntryModifier,
      updateModifier: updateInputEntryModifier,
      removeModifier: removeInputEntryModifier,
    },
  ] = useStore("scenarios");

  const [isCreating, setCreating] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);

  const [hasPendingDelete, setPendingDelete] = React.useState(false);
  const [hasPendingCreate, setPendingCreate] = React.useState(false);
  const [hasPendingEdit, setPendingEdit] = React.useState(false);

  const scenarios = getScenarioState(selectAllScenarios);
  const persistedScenario = getScenarioState(selectScenarioById(scenarioId));
  const pendingScenario = getScenarioState(
    selectPendingScenarioById(scenarioId)
  );

  React.useEffect(() => {
    fetchAllScenarios();
  }, [fetchAllScenarios]);

  React.useEffect(() => {
    let switchedScenario = false;

    fetchScenario(scenarioId).catch(() => {
      if (switchedScenario) return;
      props.history.push(`/dashboard`);
    });

    return () => {
      switchedScenario = true;
    };
  }, [scenarioId, props.history, fetchScenario]);

  const debouncedUpdateScenario = React.useCallback(
    debounce(persistScenario, { wait: 500 }),
    [persistScenario]
  );

  React.useEffect(() => {
    // Still loading
    if (persistedScenario == null) return;

    // No changes
    if (persistedScenario === pendingScenario) return;

    // Something changed, save it!
    debouncedUpdateScenario(scenarioId, pendingScenario);
  }, [pendingScenario, persistedScenario, scenarioId, debouncedUpdateScenario]);

  if (persistedScenario == null) return <LoadingScreen />;

  return (
    <ErrorBoundaryContainer
      renderFallback={() => (
        <div
          style={{
            minHeight: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ExceptionInformationBlock />
        </div>
      )}
    >
      <Scenarios
        {...props}
        scenario={pendingScenario}
        scenarios={scenarios}
        addInputEntry={(...args) => addInputEntry(scenarioId, ...args)}
        updateInputEntry={(...args) => updateInputEntry(scenarioId, ...args)}
        removeInputEntry={(...args) => removeInputEntry(scenarioId, ...args)}
        addInputEntryModifier={(...args) =>
          addInputEntryModifier(scenarioId, ...args)
        }
        updateInputEntryModifier={(...args) =>
          updateInputEntryModifier(scenarioId, ...args)
        }
        removeInputEntryModifier={(...args) =>
          removeInputEntryModifier(scenarioId, ...args)
        }
        duplicateScenario={() => {
          const { id, name, ...properties } = pendingScenario;
          setCreating(true);
          createScenario({ ...properties, name: `${name} duplicate` }).then(
            (response) => {
              setCreating(false);
              props.history.push(`/dashboard/runway/${response.uuid}`);
            },
            () => {
              setCreating(false);
            }
          );
        }}
        toggleCreateDialog={() => {
          setPendingCreate(true);
        }}
        toggleEditDialog={() => {
          setPendingEdit(true);
        }}
        toggleDeleteDialog={() => {
          setPendingDelete(true);
        }}
        onSelectScenario={(id) => props.history.push(`/dashboard/runway/${id}`)}
      />
      <Modal isOpen={hasPendingEdit} onDismiss={() => setPendingEdit(false)}>
        <EditScenarioDialog
          scenario={pendingScenario}
          update={(properties) => {
            updateScenario(scenarioId, properties);
            setPendingEdit(false);
            return Promise.resolve();
          }}
          dismiss={() => setPendingEdit(false)}
        />
      </Modal>
      <Modal
        isOpen={hasPendingCreate}
        onDismiss={() => setPendingCreate(false)}
      >
        <CreateScenarioDialog
          create={(properties) =>
            createScenario({
              ...createDefaultScenarioProperties({
                categories: props.categories,
              }),
              ...properties,
            }).then((response) => {
              setPendingCreate(false);
              props.history.push(`/dashboard/runway/${response.uuid}`);
            })
          }
          dismiss={() => setPendingCreate(false)}
        />
      </Modal>
      <Modal
        isOpen={hasPendingDelete}
        onDismiss={() => setPendingDelete(false)}
      >
        <DeleteScenarioConfirmationDialog
          scenario={pendingScenario}
          confirm={() => {
            setDeleting(true);
            return removeScenario(scenarioId).then(
              () => {
                setDeleting(false);

                const remainingScenarios = scenarios.filter(
                  (s) => s.id !== scenarioId
                );

                // Redirect to dashboard when deleting the last scenario
                const redirectLink =
                  remainingScenarios.length === 0
                    ? "/dashboard"
                    : `/dashboard/runway/${remainingScenarios[0].id}`;
                props.history.push(redirectLink);

                setPendingDelete(false);
              },
              () => {
                setDeleting(false);
              }
            );
          }}
          dismiss={() => setPendingDelete(false)}
        />
      </Modal>
      <Modal isOpen={isDeleting || isCreating} backdropOpacity={0.5}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size="3rem" color={PRIMARY_COLOR} />
        </div>
      </Modal>
    </ErrorBoundaryContainer>
  );
};

const DeleteScenarioConfirmationDialog = ({ scenario, confirm, dismiss }) => {
  const [isPending, setPending] = React.useState(false);

  const handleClickConfirm = () => {
    setPending(true);
    confirm().then(
      () => setPending(false),
      () => setPending(false)
    );
  };

  return (
    <DialogContainer>
      <DialogTitle>Confirm</DialogTitle>
      <DialogSection>
        <T size="1.6rem" lineHeight={1.3}>
          Are you sure you want to delete <T weight="700">{scenario.name}</T>?
        </T>
      </DialogSection>

      <DialogSection divider={false}>
        <Grid gap="1rem" justify="flex-start">
          <Button
            variant="secondary"
            style={{ color: "rgb(200 0 20)" }}
            onClick={handleClickConfirm}
            disabled={isPending}
          >
            Yes, delete this projection
          </Button>
          <Button variant="secondary" onClick={dismiss}>
            Close
          </Button>
        </Grid>
      </DialogSection>
      <Divider size="1rem" />
    </DialogContainer>
  );
};
const CreateScenarioDialog = ({ create, dismiss }) => {
  const [isPending, setPending] = React.useState(false);
  const [name, setName] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setPending(true);
    create({ name: name.trim() || "Untitled projection" }).then(
      () => setPending(false),
      () => setPending(false)
    );
  };

  return (
    <DialogContainer>
      <DialogTitle>New projection</DialogTitle>
      <DialogSection>
        <T
          component="label"
          htmlFor="name-input"
          block
          variant="headline-small"
          margin="0 0 0.7rem"
        >
          Enter projection name
        </T>
        <T block variant="paragraph-light">
          This is how you'll identify the projection
        </T>
        <Divider size="1rem" />
        <form onSubmit={handleSubmit} id="scenario-create-form">
          <input
            id="name-input"
            autoFocus
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`E.g. "Best-case scenario"`}
            disabled={isPending}
            style={{
              width: "100%",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          />
        </form>
      </DialogSection>

      <DialogSection divider={false}>
        <Grid gap="1rem" justify="flex-start">
          <Button
            variant="primary"
            type="submit"
            form="scenario-create-form"
            disabled={isPending}
          >
            Create projection
          </Button>
          <Button variant="secondary" onClick={dismiss}>
            Close
          </Button>
        </Grid>
      </DialogSection>
      <Divider size="1rem" />
    </DialogContainer>
  );
};
const EditScenarioDialog = ({ scenario, update, dismiss }) => {
  const [isPending, setPending] = React.useState(false);
  const [name, setName] = React.useState(scenario.name ?? "");

  const hasChanges = scenario.name !== name.trim();

  const handleSubmit = (e) => {
    e.preventDefault();

    setPending(true);
    update({ name: name.trim() || "Untitled projection" }).then(
      () => setPending(false),
      () => setPending(false)
    );
  };

  return (
    <DialogContainer>
      <DialogTitle>Edit projection</DialogTitle>
      <DialogSection>
        <T
          component="label"
          htmlFor="name-input"
          block
          variant="headline-small"
          margin="0 0 0.7rem"
        >
          Projection name
        </T>
        {/* <T block variant="paragraph-light"> */}
        {/*   This is how you'll identify the scenario */}
        {/* </T> */}
        {/* <Divider size="1rem" /> */}
        <form onSubmit={handleSubmit} id="scenario-edit-form">
          <input
            id="name-input"
            required
            autoFocus
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`E.g. "Best-case scenario"`}
            disabled={isPending}
            style={{
              width: "100%",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          />
        </form>
      </DialogSection>

      <DialogSection divider={false}>
        <Grid gap="1rem" justify="flex-start">
          <Button
            variant="primary"
            type="submit"
            form="scenario-edit-form"
            disabled={isPending || !hasChanges}
          >
            Update projection
          </Button>
          <Button variant="secondary" onClick={dismiss}>
            Close
          </Button>
        </Grid>
      </DialogSection>
      <Divider size="1rem" />
    </DialogContainer>
  );
};

const LoadingScreen = () => (
  <div
    style={{
      minHeight: "90vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spinner size="3rem" color={PRIMARY_COLOR} />
  </div>
);

const ErrorBoundaryContainer = ({ children, ...props }) =>
  !isProduction ? (
    <ErrorBoundary {...props}>{children}</ErrorBoundary>
  ) : (
    <>{children}</>
  );

export default ScenariosContainer;
