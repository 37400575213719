import { css } from "emotion";
import React from "react";

const AutoWidthInput = React.forwardRef(({ value, ...props }, ref) => (
  <div
    className={css({
      display: "inline-grid",
      alignItems: "center",
      "::after, input, textarea": {
        width: "auto",
        margin: "-100% 0",
        gridArea: "1 / 2",
        font: "inherit",
        padding: 0,
        resize: "none",
        background: "none",
        appearance: "none",
        border: 0,
      },
      "::after": {
        content: "attr(data-value) ' '",
        visibility: "hidden",
        whiteSpace: "pre-wrap",
      },
    })}
    data-value={value || props.placeholder || ""}
  >
    <input ref={ref} size={1} value={value} {...props} type="text" />
  </div>
));

export default AutoWidthInput;
