export const transactionConstants = {
  FETCH_ALL_TRANSACTIONS_REQUEST: 'TRANSACTIONS_FETCH_ALL_REQUEST',
  FETCH_ALL_TRANSACTIONS_SUCCESS: 'TRANSACTIONS_FETCH_ALL_SUCCESS',
  SEARCH_TRANSACTIONS_SUCCESS: 'TRANSACTIONS_SEARCH_SUCCESS',
  SEARCH_TRANSACTIONS_REQUEST: 'TRANSACTIONS_SEARCH_REQUEST',



  TABLE_COLUMN_LOGO: 0,
  TABLE_COLUMN_DESCRIPTION: 1,
  TABLE_COLUMN_STATUS: 2,
  TABLE_COLUMN_DATE: 3,
  TABLE_COLUMN_AMOUNT: 4,
  TABLE_COLUMN_DETAILS: 5,
};