import React, { useState } from "react";
import { FullScreenPage, FullScreenGrid } from "../../common/skeleton";
import { resetPassword } from "../../api/users";
import { BaseForm } from "../../common/auth/forms";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { userActions } from "../../actions/user.actions";
import { handleErrorResponse } from "../../api/common";

const ChoosePasswordForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let { token, email } = queryString.parse(window.location.search);

  async function handleSubmit(values) {
    setIsLoading(true);
    resetPassword(values.password, token)
      .then(function (result) {
        const redirectUrl =
          "first_name" in result.data ? "/dashboard" : "/welcome/profile";
        dispatch(userActions.login(email, values.password, redirectUrl));
      })
      .catch(handleErrorResponse)
      .finally(function () {
        setIsLoading(false);
      });
  }

  return (
    <BaseForm
      email={false}
      password={true}
      handleSubmit={handleSubmit}
      title="Welcome! Please choose a password"
      subtitle="We recommend using a password manager to generate a strong and unique password."
      buttonText="Continue"
      isLoading={isLoading}
      newPassword={true}
      resetPassword={true}
    />
  );
};

const RegisterOnboardingComponent = () => {
  return (
    <FullScreenPage>
      <FullScreenGrid>
        <ChoosePasswordForm />
      </FullScreenGrid>
    </FullScreenPage>
  );
};

export default RegisterOnboardingComponent;
