import formatDate from "date-fns/format";
import subtractDays from "date-fns/subDays";
import subtractMonths from "date-fns/subMonths";
import subtractQuarters from "date-fns/subQuarters";
import subtractYears from "date-fns/subYears";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfYear from "date-fns/startOfYear";
import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import endOfQuarter from "date-fns/endOfQuarter";
import endOfYear from "date-fns/endOfYear";
import isBefore from "date-fns/isBefore";
import isToday from "date-fns/isToday";
import differenceInDays from "date-fns/differenceInDays";
import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useRect } from "@reach/rect";
import {
  GREY_BACKGROUND,
  PRIMARY_COLOR,
  GREEN_TEXT,
  RED_TEXT,
  YELLOW_TEXT,
  GREEN,
  RED,
} from "../../constants/colors";
import { SUPPORT_EMAIL } from "../../constants/obvious";
import { unfold, fit, sum } from "../../utils/array";
import { padLinear as padLinearExtent } from "../../utils/extent";
import { update as updateSearchParams } from "../../utils/query-string";
import { getSeverityFromBank } from "../../utils/banks";
import { getMostRecentLoginDateExcludingToday } from "../../utils/user";
import useMetric from "../../hooks/metric-data";
import useTransactions from "../../hooks/transactions-data";
import T from "../Text";
import G from "../Grid";
import Spinner from "../Spinner";
import Divider from "../Divider";
import FractionBar from "../FractionBar";
import OverlayCard from "../OverlayCard";
import FormattedNumber from "../FormattedNumber";
import FormattedCurrency from "../FormattedCurrency";
import FormattedCurrencyWithSmallDecimals from "../FormattedCurrencyWithSmallDecimals";
import FormattedDate from "../FormattedDate";
import FormattedRelativeDate from "../FormattedRelativeDate";
import Circle from "../Circle";
import Select from "../Select";
import Tooltip, {
  positionLeft,
  positionRight,
  positionTopCenter,
} from "../Tooltip";
import PageContainer from "../PageContainer";
import Section from "../PageSubSection";
import TransactionList from "../TransactionList";
import LineChart, { Line } from "../LineChart";
import BarChart from "../BarChart";
import Button from "../Button";
import Icon from "../Icon";
import RunwayBlock from "./runway-section";
import CostBreakdown, {
  useData as useCostBreakdownData,
} from "./cost-breakdown-section";
import CategorizationSection from "./categorization-section";

const colorByAccountSyncSeverity = {
  0: GREEN_TEXT,
  1: YELLOW_TEXT,
  2: RED_TEXT,
};

const sumDataset = (dataset) => {
  if (dataset == null) return null;
  return sum((datum) => datum.value, dataset);
};

const dateFormattingOptionsByFrequency = {
  daily: { month: "short", day: "numeric" },
  "daily-long": { month: "long", day: "numeric" },
  monthly: { month: "short" },
  "monthly-long": { month: "long" },
};

const Dashboard = ({
  user,
  vendors,
  location,
  history,
  categories,
  currency,
  enabledBankAccounts,
  updateTransaction,
}) => {
  const containerRef = React.useRef();
  const containerRect = useRect(containerRef, false);

  const [isHoveringCashFlowChart, setHoveringCashFlowChart] = React.useState(
    false
  );
  const endOfToday = React.useMemo(() => endOfDay(new Date()), []);

  const periodOptions = React.useMemo(
    () => [
      {
        id: "30-days",
        label: "Last 30 days",
        frequency: "daily",
        range: () => [startOfDay(subtractDays(endOfToday, 29)), endOfToday],
        previousRange: ([start, end]) =>
          [start, end].map((d) => subtractDays(d, 30)),
      },
      {
        id: "60-days",
        label: "Last 60 days",
        frequency: "daily",
        range: () => [startOfDay(subtractDays(endOfToday, 59)), endOfToday],
        previousRange: ([start, end]) =>
          [start, end].map((d) => subtractDays(d, 60)),
      },
      {
        id: "90-days",
        label: "Last 90 days",
        frequency: "daily",
        range: () => [startOfDay(subtractDays(endOfToday, 89)), endOfToday],
        previousRange: ([start, end]) =>
          [start, end].map((d) => subtractDays(d, 90)),
      },
      {
        id: "month-to-date",
        label: "Month-to-date",
        frequency: "daily",
        range: () => [startOfMonth(endOfToday), endOfToday],
        previousRange: ([start]) => {
          const previousMonthStart = subtractMonths(start, 1);
          return [previousMonthStart, endOfMonth(previousMonthStart)];
        },
      },
      {
        id: "prev-month-to-date",
        label: "Previous month-to-date",
        frequency: "daily",
        range: () => {
          const previousMonthStart = startOfMonth(
            subtractMonths(endOfToday, 1)
          );
          return [previousMonthStart, endOfMonth(previousMonthStart)];
        },
        previousRange: ([start]) => {
          const previousMonthStart = subtractMonths(start, 1);
          return [previousMonthStart, endOfMonth(previousMonthStart)];
        },
      },
      {
        id: "quarter-to-date",
        label: "Quarter-to-date",
        frequency: "daily",
        range: () => [startOfQuarter(endOfToday), endOfToday],
        previousRange: ([start]) => {
          const previousQuarterStart = subtractQuarters(start, 1);
          return [previousQuarterStart, endOfQuarter(previousQuarterStart)];
        },
      },
      {
        id: "year-to-date",
        label: "Year-to-date",
        frequency: "monthly",
        range: () => [startOfYear(endOfToday), endOfToday],
        previousRange: ([start]) => {
          const previousYearStart = subtractYears(start, 1);
          return [previousYearStart, endOfYear(previousYearStart)];
        },
      },
    ],
    [endOfToday]
  );

  const { period: selectedPeriod = "30-days" } = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );
  const setSelectedPeriod = (period) =>
    history.push({ search: updateSearchParams(location.search, { period }) });

  const {
    currentPeriodRange,
    previousPeriodRange,
    label: periodLabel,
    frequency,
  } = React.useMemo(() => {
    const { range, previousRange, ...config } = periodOptions.find(
      (p) => p.id === selectedPeriod
    );
    const currentPeriodRange = range();
    const previousPeriodRange = previousRange(currentPeriodRange);
    return {
      ...config,
      currentPeriodRange,
      previousPeriodRange,
    };
  }, [selectedPeriod, periodOptions]);

  const [
    currentPeriodCloseBalanceQuery,
    currentPeriodMoneyNetQuery,
    currentPeriodMoneyInQuery,
    currentPeriodMoneyOutQuery,
  ] = React.useMemo(
    () =>
      ["close_balance", "money_net", "money_in", "money_out"].map((metric) => ({
        metric,
        frequency,
        from: currentPeriodRange[0],
        to: currentPeriodRange[1],
      })),
    [currentPeriodRange, frequency]
  );

  const [
    previousPeriodMoneyNetQuery,
    previousPeriodMoneyInQuery,
    previousPeriodMoneyOutQuery,
  ] = React.useMemo(
    () =>
      [
        currentPeriodMoneyNetQuery,
        currentPeriodMoneyInQuery,
        currentPeriodMoneyOutQuery,
      ].map((query) => ({
        ...query,
        from: previousPeriodRange[0],
        to: previousPeriodRange[1],
      })),
    [
      previousPeriodRange,
      currentPeriodMoneyNetQuery,
      currentPeriodMoneyInQuery,
      currentPeriodMoneyOutQuery,
    ]
  );

  const [{ data: recentTransactions }] = useTransactions({ size: 25 });

  const {
    closeBalance,
    moneyNet,
    moneyIn,
    moneyOut,
    previousMoneyNet,
    previousMoneyIn,
    previousMoneyOut,
  } = useMetric({
    closeBalance: currentPeriodCloseBalanceQuery,
    moneyNet: currentPeriodMoneyNetQuery,
    moneyIn: currentPeriodMoneyInQuery,
    moneyOut: currentPeriodMoneyOutQuery,
    previousMoneyNet: previousPeriodMoneyNetQuery,
    previousMoneyIn: previousPeriodMoneyInQuery,
    previousMoneyOut: previousPeriodMoneyOutQuery,
  });

  const [
    costBreakdownData,
    { fetchAll: fetchCostBreakdownData },
  ] = useCostBreakdownData({
    range: currentPeriodRange,
    previousRange: previousPeriodRange,
  });

  const mostRecentLoginDateExcludingToday = React.useMemo(
    () => getMostRecentLoginDateExcludingToday(user),
    [user]
  );

  const accountSyncSeverity = Math.max(
    ...enabledBankAccounts.map(getSeverityFromBank)
  );
  const lastSyncedDate = Math.max(
    ...enabledBankAccounts.map((d) => d.sync_date)
  );
  const outOfSync =
    differenceInDays(new Date(), new Date(lastSyncedDate * 1000)) > 30;

  if (outOfSync) return <OutOfSyncPlaceholder />;

  const transactionsSinceLastLogin = recentTransactions?.filter(
    (t) => new Date(t.date * 1000) > mostRecentLoginDateExcludingToday
  );

  const bankBalance = closeBalance?.slice(-1)[0]?.value;
  const moneyNetSum = sumDataset(moneyNet);
  const moneyInSum = sumDataset(moneyIn);
  const moneyOutSum = Math.abs(sumDataset(moneyOut));
  const previousMoneyNetSum = sumDataset(previousMoneyNet);
  const previousMoneyInSum = sumDataset(previousMoneyIn);
  const previousMoneyOutSum = Math.abs(sumDataset(previousMoneyOut));

  const moneyNetDiff = moneyNetSum - previousMoneyNetSum;
  const moneyNetChangeFraction = moneyNetDiff / previousMoneyNetSum;
  const moneyInDiff = moneyInSum - previousMoneyInSum;
  const moneyInChangeFraction = moneyInDiff / previousMoneyInSum;
  const moneyOutDiff = moneyOutSum - previousMoneyOutSum;
  const moneyOutChangeFraction = moneyOutDiff / previousMoneyOutSum;

  const useSingleColumnLayout = containerRect?.width < 800;

  const noCashflowActivity = [moneyIn, moneyOut].every(
    (d) => d != null && d.length === 0
  );

  return (
    <PageContainer
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "0 2rem",
          }}
        >
          <T variant="headline-small">Dashboard</T>
          <div style={{ flex: 1 }} />
          <G auto="auto" align="center" gap="1rem" style={{ minWidth: 0 }}>
            {enabledBankAccounts.length !== 0 && (
              <Button
                size="small"
                variant="transparent"
                component={RouterLink}
                to="/dashboard/settings"
                style={{ alignItems: "center" }}
              >
                {enabledBankAccounts.some(
                  (a) => a.sync_status === "pending"
                ) ? (
                  <>
                    <Spinner
                      size="2rem"
                      color="#bbb"
                      style={{ marginRight: "1rem" }}
                    />{" "}
                    Syncing data
                  </>
                ) : (
                  <>
                    <div
                      className={css({
                        background:
                          colorByAccountSyncSeverity[accountSyncSeverity],
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      })}
                    />
                    Last synced{" "}
                    <FormattedRelativeDate
                      value={new Date(lastSyncedDate * 1000)}
                    />
                  </>
                )}
              </Button>
            )}
          </G>
        </div>
      }
    >
      <div
        ref={containerRef}
        style={{
          padding: "2rem",
          opacity: containerRect == null ? 0 : 1,
          transition: "0.3s opacity",
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <G
          template={useSingleColumnLayout ? "100%" : "repeat(3,minmax(0,1fr))"}
          gap="2rem"
          style={{ width: "100%" }}
        >
          <div
            style={{
              gridColumn: useSingleColumnLayout ? "span 1" : "span 2",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "3rem 0 0",
            }}
          >
            <G
              template="minmax(0,1fr) minmax(0,max-content) minmax(0,1fr)"
              gap="2rem"
            >
              <div />
              <div style={{ textAlign: "center" }}>
                <T block>Available balance</T>
                <Number
                  size="3.6rem"
                  margin="1rem 0"
                  hide={bankBalance == null}
                >
                  {bankBalance == null ? (
                    "-"
                  ) : (
                    <FormattedCurrencyWithSmallDecimals
                      value={bankBalance}
                      currency={currency}
                    />
                  )}
                </Number>
              </div>
              <div />
            </G>
            <div
              style={{
                width: "52rem",
                maxWidth: "100%",
                padding: "0 2rem 2rem",
              }}
            >
              {closeBalance != null && closeBalance.length < 2 ? (
                <div />
              ) : closeBalance != null ? (
                <LineChart
                  height="6rem"
                  data={[
                    {
                      color: "black",
                      data: closeBalance.map((d) => [d.date, d.value]),
                    },
                  ]}
                  domain={([xExtent, yExtent]) => [
                    xExtent,
                    padLinearExtent(yExtent, 0.4),
                  ]}
                  yTicks={[]}
                  xTicks={([startDate, endDate], width) => {
                    if (width == null) return [];

                    const step =
                      frequency !== "daily" ? 1 : width < 500 ? 7 : 3;

                    const ticks = unfold(
                      (prev) => {
                        const next =
                          frequency === "daily"
                            ? subtractDays(prev, step)
                            : subtractMonths(startOfMonth(prev), step);
                        return isBefore(next, startDate) ? null : next;
                      },
                      frequency === "daily" ? endDate : startOfMonth(endDate)
                    );

                    return fit(
                      (ticks) => ticks.filter((_, i) => i % 2 === 0),
                      8,
                      ticks
                    );
                  }}
                  hoverPopoverPosition={positionTopCenter}
                  renderXTick={(date) => {
                    return isToday(date) ? (
                      "Today"
                    ) : (
                      <FormattedDate
                        value={date}
                        {...dateFormattingOptionsByFrequency[frequency]}
                      />
                    );
                  }}
                  gridColor="transparent"
                  axisColor="transparent"
                  renderHoverPopoverContent={(hoveredX) => {
                    const datum = closeBalance.find(
                      ({ date }) => hoveredX.getTime() === date.getTime()
                    );

                    return (
                      <OverlayCard>
                        <T
                          block
                          color="rgb(255 255 255 / 60%)"
                          margin="0 0 0.5rem"
                        >
                          <FormattedDate
                            value={datum.date}
                            {...dateFormattingOptionsByFrequency[
                              `${frequency}-long`
                            ]}
                          />
                        </T>
                        <T color="white" size="1.4rem">
                          <FormattedCurrency
                            value={datum.value}
                            currency={currency}
                          />
                        </T>
                      </OverlayCard>
                    );
                  }}
                />
              ) : (
                <div
                  style={{
                    height: "6rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner size="3rem" color="#ddd" />
                </div>
              )}
            </div>
            <Divider />
            <div>
              <RouterLink to="/dashboard/balance">
                <T block color="rgb(0 0 0 / 54%)">
                  Bank balance &rarr;
                </T>
              </RouterLink>
            </div>
            <Divider size="4rem" />
            <div style={{ width: "100%", borderBottom: "0.1rem solid #eee" }} />
            <Divider size="4rem" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <T>
                  Balance change for{" "}
                  <Select
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    options={periodOptions.map((p) => ({
                      value: p.id,
                      label: p.label,
                    }))}
                    renderTrigger={(selectedOption) => (
                      <T variant="link">{selectedOption?.label}</T>
                    )}
                    style={{ display: "inline-flex" }}
                  />
                </T>
                <Number size="3.6rem" margin="1rem 0" hide={moneyNet == null}>
                  {moneyNet == null ? (
                    "-"
                  ) : (
                    <FormattedCurrencyWithSmallDecimals
                      value={moneyNetSum}
                      currency={currency}
                    />
                  )}
                </Number>
                <Info
                  hide={
                    [moneyNet, previousMoneyNet].some((d) => d == null) ||
                    isNaN(moneyNetChangeFraction)
                  }
                >
                  {[moneyNet, previousMoneyNet].some((d) => d == null) ||
                  isNaN(moneyNetChangeFraction) ? (
                    "-"
                  ) : (
                    <>
                      <FormattedNumber
                        percent
                        value={moneyNetChangeFraction}
                        signDisplay="exceptZero"
                      />{" "}
                      vs previous period [
                      <FormattedCurrency
                        value={previousMoneyNetSum}
                        currency={currency}
                      />
                      ]
                    </>
                  )}
                </Info>
              </div>
              <Divider />
              <div
                style={{
                  width: "52rem",
                  maxWidth: "100%",
                  padding: "0 2rem 2rem",
                }}
                onMouseMove={() => setHoveringCashFlowChart(true)}
                onMouseLeave={() => setHoveringCashFlowChart(false)}
              >
                {noCashflowActivity ? (
                  <div />
                ) : [moneyNet, moneyIn, moneyOut].every((d) => d != null) ? (
                  <BarChart
                    height="8rem"
                    data={[
                      {
                        color: "rgb(240 130 140)",
                        barWidth: 2,
                        cornerRadius: {},
                        data: moneyOut.map((d) => [d.date, d.value]),
                      },
                      {
                        color: "rgb(90 200 135)",
                        barWidth: 2,
                        cornerRadius: {},
                        data: moneyIn.map((d) => [d.date, d.value]),
                      },
                    ]}
                    domain={([xExtent, yExtent]) => [
                      xExtent,
                      padLinearExtent(yExtent, 0.4),
                    ]}
                    yTicks={[]}
                    xTicks={([startDate, endDate], width) => {
                      if (width == null) return [];

                      const step =
                        frequency !== "daily" ? 1 : width < 500 ? 7 : 3;

                      const ticks = unfold(
                        (prev) => {
                          const next =
                            frequency === "daily"
                              ? subtractDays(prev, step)
                              : subtractMonths(startOfMonth(prev), step);
                          return isBefore(next, startDate) ? null : next;
                        },
                        frequency === "daily" ? endDate : startOfMonth(endDate)
                      );

                      return fit(
                        (ticks) => ticks.filter((_, i) => i % 2 === 0),
                        8,
                        ticks
                      );
                    }}
                    renderXTick={(date) => {
                      return isToday(date) ? (
                        "Today"
                      ) : (
                        <FormattedDate
                          value={date}
                          {...dateFormattingOptionsByFrequency[frequency]}
                        />
                      );
                    }}
                    createDatumLink={([date]) => {
                      const createRange = (frequency, date) => {
                        switch (frequency) {
                          case "daily":
                            return [startOfDay(date), endOfDay(date)];
                          case "monthly":
                            return [startOfMonth(date), endOfMonth(date)];
                          default:
                            throw new Error();
                        }
                      };
                      const range = createRange(frequency, date);
                      const searchParams = new URLSearchParams(
                        Object.entries({
                          from: formatDate(range[0], "yyyy-MM-dd"),
                          to: formatDate(range[1], "yyyy-MM-dd"),
                        })
                      );

                      return [
                        "/dashboard/transactions",
                        searchParams.toString(),
                      ].join("?");
                    }}
                    gridColor="transparent"
                    axisColor="transparent"
                    hoverPopoverPosition={positionTopCenter}
                    renderHoverPopoverContent={(hoveredX) => {
                      if (
                        [moneyIn, moneyOut, moneyNet, closeBalance].some(
                          (d) => d == null
                        )
                      )
                        return "...";

                      const [
                        moneyInDatum,
                        moneyOutDatum,
                        moneyNetDatum,
                        closeBalanceDatum,
                      ] = [
                        moneyIn,
                        moneyOut.map((d) => ({
                          ...d,
                          value: Math.abs(d.value),
                        })),
                        moneyNet,
                        closeBalance,
                      ].map((dataset) =>
                        dataset.find(
                          ({ date }) => hoveredX.getTime() === date.getTime()
                        )
                      );

                      return (
                        <OverlayCard>
                          <T
                            block
                            color="rgb(255 255 255 / 60%)"
                            margin="0 0 1rem"
                            align="right"
                          >
                            <FormattedDate
                              value={hoveredX}
                              {...dateFormattingOptionsByFrequency[
                                `${frequency}-long`
                              ]}
                            />
                          </T>
                          <G template="1rem repeat(2, max-content)" gap="1rem">
                            {[
                              {
                                datum: moneyInDatum,
                                label: "In",
                                icon: (
                                  <div
                                    style={{
                                      width: "0.2rem",
                                      height: "1rem",
                                      background: GREEN,
                                    }}
                                  />
                                ),
                              },
                              {
                                datum: moneyOutDatum,
                                label: "Out",
                                icon: (
                                  <div
                                    style={{
                                      width: "0.2rem",
                                      height: "1rem",
                                      background: RED,
                                    }}
                                  />
                                ),
                              },
                              {
                                datum: moneyNetDatum,
                                label: "Net",
                                icon: (
                                  <div
                                    style={{
                                      width: "1rem",
                                      height: "0.1rem",
                                      background: "#999",
                                    }}
                                  />
                                ),
                              },
                              {
                                datum: closeBalanceDatum,
                                label: "Balance",
                              },
                            ]
                              .filter(({ datum }) => datum != null)
                              .map(({ datum, label, icon }) => (
                                <React.Fragment key={label}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {icon}
                                  </div>
                                  <T
                                    block
                                    color="rgb(255 255 255 / 60%)"
                                    style={{ paddingRight: "1rem" }}
                                  >
                                    {label}
                                  </T>

                                  <T color="white" align="right">
                                    <FormattedCurrency
                                      value={datum.value}
                                      currency={currency}
                                    />
                                  </T>
                                </React.Fragment>
                              ))}
                          </G>
                        </OverlayCard>
                      );
                    }}
                  >
                    {({ domain, scaleX, scaleY }) => {
                      return (
                        <>
                          <line
                            x1={scaleX(domain[0][0]) - 5}
                            y1={scaleY(0)}
                            x2={scaleX(domain[0][1]) + 5}
                            y2={scaleY(0)}
                            stroke={`rgb(200 200 200 / ${
                              isHoveringCashFlowChart ? "50%" : "100%"
                            })`}
                            style={{ transition: "0.1s stroke" }}
                          />

                          <Line
                            data={moneyNet.map((d) => [d.date, d.value])}
                            scaleX={scaleX}
                            scaleY={scaleY}
                            stroke={`rgb(0 0 0 / ${
                              isHoveringCashFlowChart ? "20%" : 0
                            })`}
                            style={{ transition: "0.1s stroke" }}
                          />
                        </>
                      );
                    }}
                  </BarChart>
                ) : (
                  <div style={{ height: "8rem" }} />
                )}
              </div>
            </div>
            <Divider />
            <G
              template="minmax(0,1fr) minmax(0,1fr)"
              gap="1rem"
              align="flex-end"
            >
              <Tooltip
                label={
                  <AmountChangeTooltip
                    label="Cash in"
                    amount={moneyInSum}
                    previousAmount={previousMoneyInSum}
                    periodRange={currentPeriodRange}
                    previousPeriodRange={previousPeriodRange}
                    currency={currency}
                  />
                }
                position={(targetRect, tooltipRect) => {
                  return {
                    ...positionRight(targetRect, tooltipRect),
                    top: `${targetRect.top - tooltipRect.height - 10}px`,
                  };
                }}
              >
                <G template="minmax(0,1fr) 5.5rem" gap="2rem" align="flex-end">
                  <div
                    style={{
                      textAlign: "right",
                      transition: "0.1s opacity",
                      opacity: [moneyIn, moneyOut].some((d) => d == null)
                        ? 0
                        : 1,
                    }}
                  >
                    <T
                      block
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gridAutoFlow: "column",
                        gridGap: "0.7rem",
                      }}
                    >
                      <Circle size="0.7rem" background={GREEN} />
                      Cash in
                    </T>
                    <Number hide={moneyInSum == null}>
                      {moneyInSum == null ? (
                        "-"
                      ) : (
                        <FormattedCurrencyWithSmallDecimals
                          value={moneyInSum}
                          currency={currency}
                        />
                      )}
                    </Number>
                    <Info
                      hide={![moneyIn, previousMoneyIn].every((d) => d != null)}
                    >
                      {[moneyIn, previousMoneyIn].every((d) => d != null) &&
                      !isNaN(moneyInChangeFraction) ? (
                        <>
                          <FormattedNumber
                            percent
                            value={moneyInChangeFraction}
                            signDisplay="exceptZero"
                          />{" "}
                          vs previous period
                        </>
                      ) : (
                        "-"
                      )}
                    </Info>
                  </div>
                  <FractionBar
                    orientation="vertical"
                    width="100%"
                    height="10rem"
                    background={GREY_BACKGROUND}
                    color={GREEN}
                    fraction={
                      [moneyOut, moneyIn].some((d) => d == null)
                        ? 0
                        : moneyInSum > moneyOutSum
                        ? 1
                        : moneyInSum / moneyOutSum
                    }
                  />
                </G>
              </Tooltip>
              <Tooltip
                label={
                  <AmountChangeTooltip
                    label="Cash out"
                    amount={moneyOutSum}
                    previousAmount={previousMoneyOutSum}
                    periodRange={currentPeriodRange}
                    previousPeriodRange={previousPeriodRange}
                    currency={currency}
                  />
                }
                position={(targetRect, tooltipRect) => {
                  return {
                    ...positionLeft(targetRect, tooltipRect),
                    top: `${targetRect.top - tooltipRect.height - 10}px`,
                  };
                }}
              >
                <G template="5.5rem minmax(0,1fr)" gap="2rem" align="flex-end">
                  <FractionBar
                    orientation="vertical"
                    width="100%"
                    height="10rem"
                    background={GREY_BACKGROUND}
                    color={RED}
                    fraction={
                      [moneyOut, moneyIn].some((d) => d == null)
                        ? 0
                        : moneyOutSum > moneyInSum
                        ? 1
                        : moneyOutSum / moneyInSum
                    }
                  />
                  <div
                    style={{
                      transition: "0.1s opacity",
                      opacity: [moneyIn, moneyOut].some((d) => d == null)
                        ? 0
                        : 1,
                    }}
                  >
                    <T
                      block
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gridAutoFlow: "column",
                        gridGap: "0.7rem",
                      }}
                    >
                      Cash out
                      <Circle size="0.7rem" background={RED} />
                    </T>
                    <Number hide={moneyOutSum == null}>
                      {moneyOutSum == null ? (
                        "-"
                      ) : (
                        <FormattedCurrencyWithSmallDecimals
                          value={moneyOutSum}
                          currency={currency}
                        />
                      )}
                    </Number>
                    <Info
                      hide={
                        ![moneyOut, previousMoneyOut].every((d) => d != null)
                      }
                    >
                      {[moneyOut, previousMoneyOut].every((d) => d != null) &&
                      !isNaN(moneyOutChangeFraction) ? (
                        <>
                          <FormattedNumber
                            percent
                            value={moneyOutChangeFraction}
                            signDisplay="exceptZero"
                          />{" "}
                          vs previous period
                        </>
                      ) : (
                        "-"
                      )}
                    </Info>
                  </div>
                </G>
              </Tooltip>
            </G>
            <Divider size="3rem" />
            <RouterLink to="/dashboard/cash-overview">
              <T block color="rgb(0 0 0 / 54%)">
                Cashflow &rarr;
              </T>
            </RouterLink>

            <div style={{ flex: 1 }} />
            <Divider size="4rem" />
            <Divider size="0.1rem" color="#eee" />
          </div>
          <RunwayBlock
            bankBalance={bankBalance}
            currency={currency}
            categories={categories}
          />
          <div
            style={{ gridColumn: useSingleColumnLayout ? "span 1" : "span 2" }}
          >
            <CostBreakdown
              selectedPeriodRange={currentPeriodRange}
              selectedPeriodLabel={periodLabel}
              periodSelector={
                <Select
                  value={selectedPeriod}
                  onChange={(e) => setSelectedPeriod(e.target.value)}
                  options={periodOptions.map((p) => ({
                    value: p.id,
                    label: p.label,
                  }))}
                  renderTrigger={(selectedOption) => (
                    <T variant="link">{selectedOption?.label}</T>
                  )}
                  style={{ display: "inline-flex" }}
                />
              }
              {...costBreakdownData}
            />
          </div>

          <CategorizationSection
            selectedPeriodRange={currentPeriodRange}
            periodSelector={
              <Select
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                options={periodOptions.map((p) => ({
                  value: p.id,
                  label: p.label,
                }))}
                renderTrigger={(selectedOption) => (
                  <T variant="link">{selectedOption?.label}</T>
                )}
                style={{ display: "inline-flex" }}
              />
            }
            onTransactionCategorized={fetchCostBreakdownData}
          />
        </G>
      </div>

      <Section
        title={
          transactionsSinceLastLogin?.length === 0
            ? "Recent transactions"
            : transactionsSinceLastLogin?.length <= 4
            ? "Recent transactions"
            : "Transactions since last login"
        }
        padding={0}
      >
        <div style={{ padding: "1.5rem 1rem 0", overflow: "auto" }}>
          <TransactionList
            transactions={
              transactionsSinceLastLogin?.length === 0
                ? recentTransactions?.slice(0, 10) ?? []
                : transactionsSinceLastLogin?.length <= 4
                ? recentTransactions?.slice(0, 5) ?? []
                : transactionsSinceLastLogin ?? []
            }
            vendors={vendors}
            categories={categories}
            history={history}
            location={location}
            updateTransaction={updateTransaction}
          />
        </div>
        <div style={{ padding: "2rem" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <RouterLink
              to="/dashboard/transactions"
              style={{
                color: PRIMARY_COLOR,
                fontWeight: "700",
                textDecoration: "none",
              }}
            >
              <span style={{ textDecoration: "underline" }}>
                Complete transaction history
              </span>{" "}
              &rarr;
            </RouterLink>
          </div>
        </div>
      </Section>
    </PageContainer>
  );
};

const Info = ({ children, hide, ...props }) => (
  <T
    block
    color="rgb(0 0 0 / 54%)"
    lineHeight={1.3}
    style={{ transition: "0.1s opacity", opacity: hide ? 0 : 1 }}
    {...props}
  >
    {children}
  </T>
);

const Number = ({ children, size = "2.6rem", hide, ...props }) => (
  <T
    block
    weight="800"
    size={size}
    truncate
    lineHeight={1.2}
    margin="0.8rem 0"
    style={{ transition: "0.1s opacity", opacity: hide ? 0 : 1 }}
    {...props}
  >
    {children}
  </T>
);

const OutOfSyncPlaceholder = () => (
  <div
    className={css({
      flex: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    })}
  >
    <div
      className={css({
        width: "56rem",
        maxWidth: "100%",
        margin: "0 auto",
        padding: "2rem",
      })}
    >
      <Icon name="exclamation" size="3rem" color="#F55900" />
      <Divider />
      <T block variant="headline-large" margin="0 0 2rem">
        Data out of sync
      </T>
      <T variant="paragraph-large">
        We detected that your bank data is more than 30 days out-of-sync. Please
        reconnect with your primary bank account to continue using Obvious. We
        do apologize for the inconvenience.
      </T>
      <Divider size="2em" />
      <T variant="paragraph-large">
        Don’t hesitate to reach out to us if this problem persists:{" "}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </T>
      <Divider size="2em" />
      <Button
        size="large"
        component={RouterLink}
        to="/dashboard/settings"
        style={{ background: PRIMARY_COLOR, color: "#FFFFFF" }}
      >
        Reconnect
      </Button>
    </div>
  </div>
);

const AmountChangeTooltip = ({
  label,
  amount,
  previousAmount,
  currency,
  periodRange,
}) => {
  const diff = amount - previousAmount;
  const fraction = diff / previousAmount;
  return (
    <div
      style={{
        lineHeight: 1.3,
        fontWeight: "500",
        color: "rgb(255 255 255 / 60%)",
      }}
    >
      <T block margin="0 0 1rem">
        {label} between{" "}
        <FormattedDate value={periodRange[0]} month="short" day="numeric" /> and{" "}
        <FormattedDate value={periodRange[1]} month="short" day="numeric" />
      </T>
      {!isNaN(fraction) && (
        <T size="1.6rem" weight="700" color="white">
          <FormattedNumber percent value={fraction} signDisplay="never" />{" "}
          {fraction > 0 ? "increase" : "decrease"}
        </T>
      )}
      <T block>
        Previous period:{" "}
        <FormattedCurrency value={previousAmount} currency={currency} />
      </T>
    </div>
  );
};

export default Dashboard;
