// Query string updater working similarly to React’s setState. An object
// payload is shallowly merged with the current query, and a function payload
// is called with the current query object and expected to return a new query
// object.
export const update = (queryString, payload) => {
  const current = Object.fromEntries(
    new URLSearchParams(queryString).entries()
  );

  const next =
    typeof payload === "function"
      ? payload(current)
      : { ...current, ...payload };

  return new URLSearchParams(
    Object.entries(next).filter(
      ([_, value]) => value != null && value.length !== 0
    )
  ).toString();
};
