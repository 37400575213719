import { css } from "emotion";
import React from "react";
import { GREY_BACKGROUND } from "../constants/colors";
import Text from "./Text";

const PageSubSection = ({
  title,
  subtitle,
  actions,
  children,
  padding = "2rem",
}) => (
  <>
    <div
      className={css({
        background: GREY_BACKGROUND,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "4rem",
        padding: "0.2rem 2rem",
      })}
    >
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          gridGap: "1rem",
        }}
      >
        <Text variant="headline" component="h2" size="1.4rem">
          {title}
        </Text>
        {subtitle && (
          <Text size="1.4rem" weight="500" style={{ opacity: 0.5 }}>
            {subtitle}
          </Text>
        )}
      </div>

      {actions}
    </div>

    <div className={css({ padding })}>{children}</div>
  </>
);

export default PageSubSection;
