import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import request from "../utils/request";

const RequestContext = React.createContext(null);

export const RequestProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const requestWithUnauthenticatedRedirect = React.useCallback(
    (url, options) =>
      request(url, options).catch((error) => {
        if (error.response?.status === 401)
          history.replace({
            pathname: "/login",
            state: { from: location },
          });

        return Promise.reject(error);
      }),
    [history, location]
  );

  return (
    <RequestContext.Provider value={requestWithUnauthenticatedRedirect}>
      {children}
    </RequestContext.Provider>
  );
};

const useRequest = () => React.useContext(RequestContext);

export default useRequest;
