export const pluralise = (word, count) => {
  switch (word) {
    case "item":
      return { 1: "item" }[count] || "items";
    case "week":
      return { 1: "week" }[count] || "weeks";
    case "month":
      return { 1: "month" }[count] || "months";
    case "year":
      return { 1: "year" }[count] || "years";
    default:
      throw new Error(`Unknown word "${word}"`);
  }
};

export const title = (sentence) => {
  return sentence
    .split(/(-|\s)/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
};

export const humanReadable = (text) => {
  const spacedText = text.replace(/-|_/g, " ");
  return spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
};
