export const PRIMARY_COLOR = "#365eeb";
export const PRIMARY_COLOR_LIGHT_TINT = "#466eff"; // "#5277ff"

export const GREEN_TEXT = "#1FBE7B";
export const RED_TEXT = "#e81834";
export const YELLOW_TEXT = "#b98000";
export const GREY_BACKGROUND = "#f3f3f3"; // "rgb(247 246 244)"; // "#f3f3f3";

export const GREEN = "rgb(120 220 170)";
export const RED = "rgb(255 180 190)";
