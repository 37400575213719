import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  greenBox: {
    width: "10px",
    height: "10px",
    background: "#4EE1A3",
    boxShadow: "0px 0px 12px rgba(78, 225, 163, 0.3), 0px 0px 6px rgba(78, 225, 163, 0.7)",
    transform: "rotate(-180deg)",
    borderRadius: "50%",
  },
  redBox: {
    width: "10px",
    height: "10px",
    background: "#E95888",
    boxShadow: "0px 0px 12px rgba(233, 88, 136, 0.3), 0px 0px 6px rgba(233, 88, 136, 0.7);",
    transform: "rotate(-180deg)",
    borderRadius: "50%",
  },
  yellowBox: {
    width: "10px",
    height: "10px",
    background: "#F4C650",
    boxShadow: "0px 0px 12px rgba(244, 198, 80, 0.3), 0px 0px 6px rgba(244, 198, 80, 0.7)",
    transform: "rotate(-180deg)",
    borderRadius: "50%",
  },
});

export const GreenIcon = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.greenBox} />
  )
}

export const RedIcon = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.redBox} />
  )
}

export const YellowIcon = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.yellowBox} />
  )
}