
const initialState = {
  rowsPerPage: 15,  // TODO: config
  page: 0,
}

export default function table(state = initialState, action) {
  switch (action.type) {
    case "SET_ROWS_PER_PAGE":
      return Object.assign({}, state, {
        rowsPerPage: action.rowsPerPage,
        page: 0
      })
    case "SET_PAGE":
      return Object.assign({}, state, {
        page: action.page
      })
    case "SET_USER_CURRENCY":
      if (action.user.default_currency) {
        return Object.assign({}, state, {
          currency: action.user.default_currency
        })
      }
      else {
        return state
      }
    case "SET_COMPANY_CURRENCY":
      return Object.assign({}, state, {
        currency: action.company.default_currency
      })
    default:
      return state
  }
}
