import React from "react";
import useStore from "./stores";
import useInterval from "./interval";
import { selectSyncStatus as selectBankAccountsSyncStatus } from "./bank-accounts";
import {
  selectByQuery as selectTransactionsByQuery,
  selectMetadataByQuery as selectTransactionsMetaByQuery,
} from "./transactions";

const useTransactions = ({
  size,
  range: [from, to] = [],
  sorting,
  categories,
  vendors,
  maxAmount,
}) => {
  const [getBankAccountsState] = useStore("bank-accounts");
  const { isSyncingNewConnection } = getBankAccountsState(
    selectBankAccountsSyncStatus
  );

  const [
    getTransactionsState,
    { fetchData: fetchTransactions, update },
  ] = useStore("transactions");

  const query = React.useMemo(
    () => ({
      size,
      range: [from, to],
      sorting,
      categories,
      vendors,
      maxAmount,
    }),
    [size, from, to, sorting, categories, vendors, maxAmount]
  );

  const data = getTransactionsState(selectTransactionsByQuery(query));
  const meta = getTransactionsState(selectTransactionsMetaByQuery(query));

  const fetchQueriedData = React.useCallback(() => fetchTransactions(query), [
    fetchTransactions,
    query,
  ]);

  React.useEffect(() => {
    fetchQueriedData();
  }, [fetchQueriedData]);

  // Poll the data continuously while syncing new bank accounts
  useInterval(() => fetchQueriedData(), isSyncingNewConnection ? 8000 : null);

  return [
    { data, meta },
    {
      fetch: fetchQueriedData,
      update,
    },
  ];
};

export default useTransactions;
