import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Menu,
  MenuPopover,
  MenuButton,
  MenuItems,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import { positionRight } from "@reach/popover";
import Text from "./Text";

const DropdownMenu = ({
  renderButton,
  width = "18rem",
  height = "auto",
  items,
}) => (
  <Menu>
    {({ isExpanded }) => (
      <>
        {renderButton({ isExpanded, component: MenuButton })}
        <MenuPopover position={positionRight} style={{ zIndex: 2 }}>
          <MenuItems
            style={{
              position: "relative",
              top: "0.5rem",
              boxShadow: "0 0.4rem 1.2rem rgba(0,0,0,0.1)",
              borderRadius: "0.5rem",
              background: "white",
              width,
              height,
              maxWidth: "calc(100vw - 2rem)",
              maxHeight: "calc(100vh - 2rem)",
              overflow: "auto",
              padding: "0.5rem 0",
            }}
          >
            {items.map(({ type, label, style, ...props }, i) => {
              const itemStyle = {
                display: "block",
                padding: "0.8rem 2rem",
                lineHeight: 1.2,
                fontWeight: "500",
                cursor: "pointer",
                color: "black",
                ":hover": { background: "rgba(0,0,0,0.05)" },
                ...style,
              };

              switch (type) {
                case "heading":
                  return (
                    <Text
                      key={i}
                      block
                      variant="label"
                      color="#888"
                      style={{ padding: "1.3rem 2rem 1rem" }}
                    >
                      {label}
                    </Text>
                  );
                case "divider":
                  return (
                    <div
                      key={i}
                      className={css({
                        height: "0.1rem",
                        background: "#eee",
                        margin: "0.5rem 0",
                      })}
                    />
                  );
                case "link":
                  return (
                    <MenuLink
                      key={i}
                      as={RouterLink}
                      {...props}
                      className={css(itemStyle)}
                    >
                      {label}
                    </MenuLink>
                  );
                case "button":
                  return (
                    <MenuItem key={i} {...props} className={css(itemStyle)}>
                      {label}
                    </MenuItem>
                  );
                default:
                  throw new Error(`Unknown type "${type}"`);
              }
            })}
          </MenuItems>
        </MenuPopover>
      </>
    )}
  </Menu>
);

export default DropdownMenu;
