import React from "react";

const useHover = () => {
  const [isHovered, setHovered] = React.useState(false);
  const handlers = {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
    onMouseMove: () => setHovered(true),
  };
  return [isHovered, handlers];
};

export default useHover;
