import React from "react";

const StoresContext = React.createContext(null);

export const StoresProvider = ({ stores, children }) => {
  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  );
};

const useStore = (identifier) => React.useContext(StoresContext)[identifier];

export default useStore;
