import { css } from "emotion";
import React from "react";
import { useWindowSize } from "@reach/window-size";

const widthsByName = {
  wide: [1000, "94vw"],
};

const Container = ({ size, children }) => {
  const { width: windowWidth } = useWindowSize();

  if (widthsByName[size] == null) throw new Error(`Invalid size "${size}"`);

  const [breakpoint, width] = widthsByName[size];

  const useFullWidth = windowWidth < breakpoint;

  return (
    <div
      className={css({ maxWidth: "100%", margin: "0 auto" })}
      style={{ width: useFullWidth ? "100%" : width }}
    >
      {children}
    </div>
  );
};

export default Container;
