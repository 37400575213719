import React from "react";
import { css } from "emotion";
import { DialogOverlay, DialogContent } from "@reach/dialog";

const Modal = ({
  width = "60rem",
  isOpen,
  onDismiss,
  backdropOpacity = 0.1,
  label,
  children,
}) => (
  <DialogOverlay
    isOpen={isOpen}
    onDismiss={onDismiss}
    className={css({
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: "hidden",
      zIndex: 2,
      background: `rgb(164 181 239 / ${backdropOpacity})`,
      display: "flex",
      alignItems: "center",
    })}
  >
    <DialogContent
      aria-label={label}
      className={css({
        width,
        maxWidth: "calc(100% - 2rem)",
        margin: "0 auto",
        outline: "none",
      })}
    >
      {children}
    </DialogContent>
  </DialogOverlay>
);

export default Modal;
