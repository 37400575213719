import { useMemo } from "react";

export const useFormatter = ({ locale, ...numberFormatOptions }) => {
  const formatter = useMemo(
    () => new Intl.DateTimeFormat(locale ?? "en-SE", numberFormatOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, ...Object.values(numberFormatOptions)]
  );

  return formatter.format;
};

const FormattedDate = ({ value, children, ...formatOptions }) => {
  const format = useFormatter(formatOptions);

  if (typeof children === "function") return children(format);

  if (value == null) throw new Error("Missing `value` prop");

  return format(value);
};

export default FormattedDate;
