import memoize from "mem";
import addMonths from "date-fns/addMonths";
import formatDate from "date-fns/format";
import differenceInYears from "date-fns/differenceInYears";
import React from "react";
import { unfold, sum } from "../utils/array";

export const calculateAmountsByDate = memoize((inputEntries) =>
  inputEntries.reduce(
    (amountsByMonth, inputEntry) =>
      inputEntry.data.reduce((amountsByMonth, datum) => {
        const monthString = formatDate(datum.date, "yyyy-MM");
        const datumWithEntryReference = {
          ...datum,
          inputEntryId: inputEntry.id,
        };

        if (amountsByMonth[monthString] == null) {
          amountsByMonth[monthString] = [datumWithEntryReference];
        } else {
          amountsByMonth[monthString] = [
            ...amountsByMonth[monthString],
            datumWithEntryReference,
          ];
        }

        return amountsByMonth;
      }, amountsByMonth),
    {}
  )
);

const useBalanceProjection = ({
  initialBalance,
  startDate,
  endDate,
  inputEntries,
}) => {
  const amountsByMonth = calculateAmountsByDate(inputEntries);

  const balanceProjection = React.useMemo(() => {
    const getMean = (data) => sum(({ amount }) => amount, data);
    const getUpperBound = (data) =>
      sum(
        (datum) =>
          datum.amountRange == null
            ? datum.amount
            : Math.max(...datum.amountRange),
        data
      );

    const getLowerBound = (data) =>
      sum(
        (datum) =>
          datum.amountRange == null
            ? datum.amount
            : Math.min(...datum.amountRange),
        data
      );

    const getData = (date) => amountsByMonth[formatDate(date, "yyyy-MM")] ?? [];

    const getMonthNet = (date) => {
      const data = getData(date);
      return [getMean(data), [getLowerBound(data), getUpperBound(data)]];
    };
    const getMonthIn = (date) => {
      const inData = getData(date).filter((d) => d.amount > 0);
      return [getMean(inData), [getLowerBound(inData), getUpperBound(inData)]];
    };
    const getMonthOut = (date) => {
      const outData = getData(date)
        .filter((d) => d.amount < 0)
        .map((d) => ({
          ...d,
          amount: d.amount * -1,
          amountRange:
            d.amountRange == null ? null : d.amountRange.map((n) => n * -1),
        }));

      return [
        getMean(outData),
        [getLowerBound(outData), getUpperBound(outData)],
      ];
    };

    const [inStart, outStart, netStart] = [
      getMonthIn,
      getMonthOut,
      getMonthNet,
    ].map((getData) => getData(startDate)[0]);

    const balanceStart = initialBalance; //  + netStart;

    const startDatum = {
      date: startDate,
      in: inStart,
      out: outStart,
      net: netStart,
      balance: balanceStart,
      inRange: [inStart, inStart],
      outRange: [outStart, outStart],
      netRange: [netStart, netStart],
      balanceRange: [balanceStart, balanceStart],
    };

    return unfold(
      (
        {
          date: prevDate,
          balance: prevBalance,
          balanceRange: prevBalanceRange,
        },
        list
      ) => {
        const date = addMonths(prevDate, 1);
        const years = differenceInYears(date, startDate);

        if (
          endDate == null
            ? // Adapt to runway
              years >= 5 || // 5 years
              (years >= 1 && prevBalance < 0) || // 0
              (years >= 1 && list.slice(-6).every((d) => d.net > 0)) // 6 months profitable
            : endDate.getTime() < date.getTime()
        )
          return null;

        const calculateBalance = (base, increase) => base + increase;
        // Math.max(0, base + increase);

        const [dateNet, dateNetRange] = getMonthNet(date);
        const [dateIn, dateInRange] = getMonthIn(date);
        const [dateOut, dateOutRange] = getMonthOut(date);
        const [balance, balanceRange] = [
          calculateBalance(prevBalance, dateNet),
          [
            calculateBalance(prevBalanceRange[0], dateNetRange[0]),
            calculateBalance(prevBalanceRange[1], dateNetRange[1]),
          ],
        ];

        return {
          date,
          balance,
          net: dateNet,
          in: dateIn,
          out: dateOut,
          netRange: dateNetRange,
          inRange: dateInRange,
          outRange: dateOutRange,
          balanceRange: balanceRange,
        };
      },
      startDatum
    );
  }, [initialBalance, startDate, endDate, amountsByMonth]);

  return balanceProjection;
};

export default useBalanceProjection;
