import { companyConstants } from "../constants/company.constants";
import { companyService } from "../api/companies";
import { history } from "../utils/history";

export const companyActions = {
  fetchCompanies,
  setCurrentCompany
};


function fetchCompanies() {
  return dispatch => {
    dispatch(request())
    companyService.getCompanies()
      .then(
        response => {
          dispatch(success(response.data))
          if (response.data.length === 0) {
            history.push('/welcome/profile')
          }
        },
        error => {
          dispatch(failure())
          console.error(error)
        }
      );
  };

  function request() { return { type: companyConstants.FETCH_COMPANIES_REQUEST } }
  function success(response) { return { type: companyConstants.FETCH_COMPANIES_SUCCESS, response } }
  function failure() { return { type: companyConstants.FETCH_COMPANIES_FAILURE } }

}


export function setCurrentCompany(company) {
  return dispatch => {
    dispatch(success(company))
    dispatch(setCurrency(company))
  };

  function success(response) { return { type: companyConstants.SET_CURRENT_COMPANY, company: response.uuid } }
  function setCurrency(company) { return { type: "SET_COMPANY_CURRENCY", company } }

}
