import memoize from "mem";
import ManyKeysMap from "many-keys-map";
import addMonths from "date-fns/addMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { sum } from "./array";

export const livePresetIncomeTypes = [
  {
    id: "money-in-month-to-date",
    name: "Month-to-date",
    defaults: { flow: "income", type: "reccuring" },
  },
  {
    id: "money-in-previous-month-to-date",
    name: "Last month",
    defaults: { flow: "income", type: "reccuring" },
  },
  {
    id: "money-in-3-month-avg",
    name: "3-month-avg",
    defaults: { flow: "income", type: "reccuring" },
  },
  {
    id: "money-in-6-month-avg",
    name: "6-month-avg",
    defaults: { flow: "income", type: "reccuring" },
  },
];

export const livePresetExpenseTypes = [
  {
    id: "money-out-month-to-date",
    name: "Month-to-date",
    defaults: { flow: "expense", type: "reccuring" },
  },
  {
    id: "money-out-previous-month-to-date",
    name: "Last month",
    defaults: { flow: "expense", type: "reccuring" },
  },
  {
    id: "money-out-3-month-avg",
    name: "3-month-avg",
    defaults: { flow: "expense", type: "reccuring" },
  },
  {
    id: "money-out-6-month-avg",
    name: "6-month-avg",
    defaults: { flow: "expense", type: "reccuring" },
  },
];

export const livePresetTypes = [
  ...livePresetIncomeTypes,
  ...livePresetExpenseTypes,
];

let n = 0;
const genId = () => {
  n += 1;
  return `${n}-${Math.random()}`;
};

export const create = ({
  flow,
  type = "reccuring",
  start = null,
  end = null,
  name,
  amount = 0,
  growth = 0,
  growthType = null,
  hidden = false,
  livePreset = null,
  modifiers = [],
  categories = [],
}) => {
  const base = {
    id: genId(),
    flow,
    type,
    amount,
    name,
    start,
    end,
    growth,
    growthType,
    hidden,
    modifiers,
    categories,
  };
  if (livePreset) return { ...base, livePreset };
  if (flow == null) throw new Error();
  return base;
};

export const createModifier = ({ start, amount, growth, growthType }) => {
  if (start == null) throw new Error();
  return { start, amount, growth, growthType };
};

export const createLivePreset = (identifier, options) =>
  create({ livePreset: identifier, ...options });

export const assignLivePresetDefaults = memoize(
  (inputEntry, data) => {
    if (inputEntry.livePreset == null) return inputEntry;
    const livePresetType = livePresetTypes.find(
      (p) => p.id === inputEntry.livePreset
    );

    switch (livePresetType.id) {
      case "money-in-month-to-date":
      case "money-in-previous-month-to-date":
      case "money-in-3-month-avg":
      case "money-in-6-month-avg":
      case "money-out-month-to-date":
      case "money-out-previous-month-to-date":
      case "money-out-3-month-avg":
      case "money-out-6-month-avg":
        return {
          ...inputEntry,
          ...livePresetType.defaults,
          // start: null,
          amount: getLivePresetStartAmount(inputEntry.livePreset, { data }),
        };
      default:
        throw new Error();
    }
  },
  {
    cache: new ManyKeysMap(),
    cacheKey: (args) => args,
  }
);

export const getLivePresetMetricQuery = memoize(
  (id, { categories }) => {
    const base = {
      frequency: "monthly",
      categories,
    };

    switch (id) {
      case "money-in-month-to-date":
        return {
          ...base,
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
          metric: "money_in",
        };
      case "money-in-previous-month-to-date":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -1)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_in",
        };
      case "money-in-3-month-avg":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -4)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_in",
        };
      case "money-in-6-month-avg":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -7)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_in",
        };

      case "money-out-month-to-date":
        return {
          ...base,
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
          metric: "money_out",
        };
      case "money-out-previous-month-to-date":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -1)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_out",
        };
      case "money-out-3-month-avg":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -4)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_out",
        };
      case "money-out-6-month-avg":
        return {
          ...base,
          from: startOfMonth(addMonths(new Date(), -7)),
          to: endOfMonth(addMonths(new Date(), -1)),
          metric: "money_out",
        };
      default:
        throw new Error(`Unrecognized live preset "${id}"`);
    }
  },
  {
    cache: new ManyKeysMap(),
    cacheKey: ([identifier, { categories }]) => [identifier, categories],
  }
);

export const getLivePresetStartAmount = (identifier, { data }) => {
  switch (identifier) {
    case "money-in-month-to-date": {
      const value = data?.slice(-1)[0]?.value;
      return value == null || value === 0 ? 0 : value;
    }
    case "money-in-previous-month-to-date": {
      const value = data?.slice(-1)[0]?.value;
      return value == null || value === 0 ? 0 : value;
    }
    case "money-in-3-month-avg": {
      const valueSum = sum((d) => d.value, data?.slice(-3) ?? []);
      return valueSum === 0 ? 0 : valueSum / 3;
    }
    case "money-in-6-month-avg": {
      const valueSum = sum((d) => d.value, data?.slice(-6) ?? []);
      return valueSum === 0 ? 0 : valueSum / 6;
    }
    case "money-out-month-to-date": {
      const value = data?.slice(-1)[0]?.value;
      return value == null || value === 0 ? 0 : value * -1;
    }
    case "money-out-previous-month-to-date": {
      const value = data?.slice(-1)[0]?.value;
      return value == null || value === 0 ? 0 : value * -1;
    }
    case "money-out-3-month-avg": {
      const valueSum = sum((d) => d.value, data?.slice(-3) ?? []);
      return valueSum === 0 ? 0 : (valueSum / 3) * -1;
    }
    case "money-out-6-month-avg": {
      const valueSum = sum((d) => d.value, data?.slice(-6) ?? []);
      return valueSum === 0 ? 0 : (valueSum / 6) * -1;
    }
    default:
      throw new Error();
  }
};

export const getLivePresetName = (presetIdentifier) =>
  livePresetTypes.find((a) => a.id === presetIdentifier)?.name;

export const isComplete = () => true;

export const isVisible = ({ hidden }) => !hidden;

export const isStatic = (
  { type, amount, growthType, growth, modifiers, start, end },
  { range: [projectionStart, projectionEnd] }
) => {
  // Only reccuring stuff can have change over time
  if (type !== "reccuring") return true;

  // Let's assume modifiers does something, even though the technically might not.
  if (Array.isArray(modifiers) && modifiers.length !== 0) return false;

  const hasLateStart = start != null && start > projectionStart;
  const hasEarlyEnd = end != null && end < projectionEnd;

  // If the projected range is longer than the input entry's range, that means
  // there's some change over time.
  if (hasLateStart || hasEarlyEnd) return false;

  switch (growthType) {
    case "linear":
      return typeof growth === "number" && growth === 0;
    case "exponential":
      return amount === 0 || (typeof growth === "number" && growth === 0);
    case null:
      return true;
    default:
      throw new Error();
  }
};
