import React from 'react';
import { Link } from 'react-router-dom'
import { MainScreen, LandingContainer } from '../common/skeleton'

import runwayMatrixSvg from '../assets/landing/landing_runway_matrix.png'
import { Typography, Box, Container, makeStyles, useTheme } from '@material-ui/core';
import { BlackBetaButton } from '../common/buttons'

const RunwayMatrixLanding = () => {
  const theme = useTheme();
  const useStyles = makeStyles({
    whiteText: {
      color: '#FFFEFC'
    },
    whiteSubText: {
      color: '#FFFEFC',
      fontSize: "18px"
    },
    noOpacity: {
      opacity: 1
    },
    // Convert this to styled component
    yellowChip: {
      backgroundColor: "#F2E09F",
      borderRadius: "3px",
      fontFamily: "Muli",
      fontWeight: "800",
      fontSize: "13px",
      letterSpacing: "0.05em",
      color: "#323245",
      marginBottom: "20px",
      textTransform: "uppercase"
    },
    relativeImage: {
      position: "relative",
      top: "80px",
      boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.2)",
      maxWidth: "800px"
    },
    mainContainer: {
      maxWidth: "815px"
    },
    emailGrid: {
      flexDirection: "row",
      alignItems: "stretch",
      alignContent: "center",
      [theme.breakpoints.down('xs')]: {
        flexDirection: "column",
        width: "100%"
      },
    },
    emailInput: {
      fontFamily: "Muli",
      fontSize: "16px",
      width: "100%",
      borderRadius: "3px",
      outline: "none",
      border: "0px",
      height: "100%",
      boxSizing: "border-box",
      padding: "0px 10px",
      background: "#EFF2FD",
      '&::placeholder': {
        opacity: 0.5,
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        height: "46px",
      },
    },
    emailInputGrid: {
      [theme.breakpoints.down('xs')]: {
        width: "100%",
      },
    },
    buttonInputGrid: {
      [theme.breakpoints.down('xs')]: {
        width: "100%",
      },
    },
    emailContainer: {
      alignItems: "center",
      [theme.breakpoints.down('sm')]: {
        textAlign: "center"
      },
    },
    emailButton: {
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },
    sectionPadding: {
      [theme.breakpoints.up('md')]: {
        paddingTop: "100px"
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: "80px"
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: "50px"
      },
    },
    mainSectionPadding: {
      [theme.breakpoints.up('md')]: {
        paddingTop: "150px"
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: "100px",
        paddingBottom: "50px"
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: "70px"
      },
    },
    mainImage: {
      paddingRight: "0px !important",
      [theme.breakpoints.down('sm')]: {
        maxWidth: "400px",
        marginTop: "20px",
      }
    },
    imgGridItem: {
      textAlign: "center",
      [theme.breakpoints.down('sm')]: {
        textAlign: "right",
        paddingRight: "20px !important"
      }
    },
    imgGridItemTop: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: "35px"
      }
    },
    imgText: {
      fontFamily: "Inter",
      fontDisplay: "swap",
      fontSize: "18px",
      lineHeight: 1.6,
      color: "#323245",
    },
    mockImage: {
      width: "100%",
      maxWidth: "960px",
      height: "auto",
      boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.2)",
      borderRadius: "4px",
      align: "center"
    },
    mockRightImage: {
      width: "100%",
      height: "auto",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        maxWidth: "500px",
        paddingTop: "50px",
      }
      // boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.1)",
    },
    mainSectionContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        textAlign: "center",
      }
    },
    earlyAccessBox: {
      background: "#323245 !important",
      marginTop: "200px !important",

      [theme.breakpoints.down('xs')]: {
        marginBottom: "50px !important",
      }
    },
    runwayLink: {
      color: "inherit",
      opacity: "inherit",
      textDecoration: "none",

      '&:hover': {
        opacity: 0.8
      },
      '&:active': {
        color: "inherit"
      }
    }
  });


  const classes = useStyles();



  return (
    <MainScreen>
      <LandingContainer>
        <Container className={classes.mainContainer}>
          <Box className={classes.sectionPadding} align="center">
            <Typography variant="h2">Understand how COVID-19 impacts your runway</Typography>
            <Box paddingTop="20px">
              <Typography variant="subtitle1">Get an overview of your cash situation in seconds. Automatically fetch accurate data from your bank account or use  manual estimates.</Typography>
            </Box>
            <Box paddingTop="40px">
              <Link to="/register" className={classes.runwayLink}>
                <BlackBetaButton>Check your runway</BlackBetaButton>
              </Link>
            </Box>
          </Box>
        </Container>

        <Box paddingTop="60px" textAlign="center">
          <img draggable="false" src={runwayMatrixSvg} alt="Insights" className={classes.mockImage} />
        </Box>

      </LandingContainer>
    </MainScreen >
  )

}

export default RunwayMatrixLanding
