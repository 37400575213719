export const combine = (reducers) => (state, action) => {
  const nextState = {};
  let hasChanged = false;

  for (let [name, reducer] of Object.entries(reducers)) {
    const nextSubState = reducer(state[name], action);
    nextState[name] = nextSubState;
    hasChanged = hasChanged || nextSubState !== state[name];
  }

  return hasChanged ? nextState : state;
};
