import React from "react";
import ReachTooltip from "@reach/tooltip";
import { positionRight as right, positionDefault } from "@reach/popover";

export const positionLeft = positionDefault;
export const positionRight = right;
export const positionTopCenter = (
  triggerRect,
  tooltipRect,
  { offset } = { offset: 5 }
) => {
  const {
    innerWidth: windowWidth,
    // innerHeight: windowHeight
  } = window;

  const top = triggerRect.top - tooltipRect.height - offset;
  const left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;

  const collisions = {
    right: windowWidth < left + tooltipRect.width,
    left: left < 0,
  };

  // const directionRight = collisions.right && !collisions.left;
  // const directionUp = collisions.bottom && !collisions.top;

  if (collisions.left) return { top, left: 10 };
  if (collisions.right)
    return { top, left: windowWidth - tooltipRect.width - 10 };

  return {
    top,
    left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
  };
};

export const positionBottomCenter = (
  triggerRect,
  tooltipRect,
  { offset } = { offset: 5 }
) => {
  if (triggerRect == null || tooltipRect == null) return {};
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  const top = triggerRect.top + triggerRect.height + offset;
  const left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;

  const collisions = {
    right: windowWidth < left + tooltipRect.width,
    left: left < 0,
    top: top < 0,
    bottom: top + tooltipRect.height > windowHeight,
  };

  // const directionRight = collisions.right && !collisions.left;
  // const directionUp = collisions.bottom && !collisions.top;
  //
  const adjustedTop = collisions.bottom
    ? windowHeight - tooltipRect.height - 10
    : top;

  if (collisions.left) return { top, left: 10 };
  if (collisions.right)
    return { top, left: windowWidth - tooltipRect.width - 10 };

  return {
    top: adjustedTop,
    left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
  };
};

export const positionBottomRight = (
  triggerRect,
  tooltipRect,
  { offset } = { offset: 5 }
) => {
  if (triggerRect == null || tooltipRect == null) return {};
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  const top = triggerRect.top + triggerRect.height + offset;
  const right = windowWidth - triggerRect.right;

  const collisions = {
    bottom: top + tooltipRect.height > windowHeight,
  };

  const spaceAbove = triggerRect.top;
  const spaceBelow = windowHeight - (triggerRect.top + triggerRect.height);

  const flipVertical = collisions.bottom && spaceAbove > spaceBelow;

  if (flipVertical)
    return { right, bottom: windowHeight - (triggerRect.top - offset) };

  return { top, right };
};

const Tooltip = ({
  label,
  children,
  position,
  offset = 5,
  style,
  ...props
}) => (
  <ReachTooltip
    label={label}
    style={{
      position: "absolute",
      zIndex: 3,
      pointerEvents: "none",
      boxShadow: "0.2rem 0.2rem 1rem rgb(0 0 0 / 10%)",
      background: "#333",
      color: "white",
      border: "none",
      borderRadius: "0.4rem",
      whiteSpace: "nowrap",
      padding: "0.8rem 1rem",
      fontSize: "1.2rem",
      ...style,
    }}
    position={
      position == null
        ? undefined
        : (triggerRect, tooltipRect) => {
            if (!triggerRect || !tooltipRect) return {};
            return position(triggerRect, tooltipRect, { offset });
          }
    }
    {...props}
  >
    {children}
  </ReachTooltip>
);

export default Tooltip;
