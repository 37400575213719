import React from "react";
import { parse as parseQueryString } from "query-string";

const Connect = ({ initializeConnection }) => {
  React.useEffect(() => {
    const {
      code,
      state,
      consentId,
      // error, // TODO: react to error?
    } = parseQueryString(window.location.search);

    initializeConnection({ code, state, consentId }); // TODO: Error handling?
  }, []); // eslint-disable-line

  return null;
};

export default Connect;
