import React from "react";

export const useFormatter = ({ locale, signDisplay, ...formatOptions }) => {
  const formatter = React.useMemo(
    () => new Intl.NumberFormat(locale, formatOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, ...Object.values(formatOptions)]
  );

  const format = (value) => {
    if (signDisplay == null) return formatter.format(value);

    switch (signDisplay) {
      case "auto":
        return formatter.format(value);
      case "never":
        return formatter.format(Math.abs(value));
      case "exceptZero": {
        const sign = value === 0 ? "" : value > 0 ? "+" : "-";
        return `${sign}${formatter.format(Math.abs(value))}`;
      }
      default:
        throw new Error(`Unrecognized option "${signDisplay}"`);
    }
  };

  return {
    resolvedOptions: (...args) => formatter.resolvedOptions(...args),
    formatToParts: (...args) => formatter.formatToParts(...args),
    format,
  };
};

const FormattedNumber = ({
  value,
  children,
  percent,
  maximumFractionDigits = 2,
  ...formatOptions
}) => {
  const formatter = useFormatter({
    maximumFractionDigits,
    ...formatOptions,
    // Eslint doesn't like `style` string props
    style: percent ? "percent" : formatOptions.style,
  });

  if (typeof children === "function") return children(formatter);

  if (value == null) throw new Error("Missing `value` prop");

  return formatter.format(value);
};

export default FormattedNumber;
