import { css } from "emotion";
import React from "react";
import Popover from "@reach/popover";
import Text from "./Text";

const FilterableListbox = ({
  renderButton,
  value,
  options,
  onSelect,
  onClose,
  dark,
  filterable,
  filterInputPlaceholder = "Filter...",
  popverPosition,
  // test
  buttonRef: buttonRefProp,
  isExpanded: isExpandedProp,
  mode = "listbox",
  children,
}) => {
  const internalButtonRef = React.useRef();
  // Test
  const buttonRef = buttonRefProp ?? internalButtonRef;

  const inputRef = React.useRef();
  const containerRef = React.useRef();
  const optionButtonElementsByValueRef = React.useRef({});
  const buttonClickedRef = React.useRef(false);

  const keyDownHandlerRef = filterable ? inputRef : containerRef;

  const [isExpandedInternal, setIsExpandedInternal] = React.useState(false);
  // Test
  const isExpanded = isExpandedProp ?? isExpandedInternal;

  const onCloseRef = React.useRef(onClose);
  React.useEffect(() => {
    onCloseRef.current = onClose;
  });

  // Test
  const setIsExpanded = (isExpanded) => {
    setIsExpandedInternal(isExpanded);
    if (!isExpanded && typeof onCloseRef.current === "function")
      onCloseRef.current();
  };

  const [query, setQuery] = React.useState("");
  const [highlightedValue, setHighlightedValue] = React.useState(null);

  const filteredOptions = options
    .filter((o) => o.type !== "divider")
    .filter((o) => {
      const sanitizedQuery = query.trim().toLowerCase();
      if (sanitizedQuery === "") return true;

      return sanitizedQuery
        .split(" ")
        .some((word) =>
          (o.stringLabel ?? o.label).toLowerCase().includes(word)
        );
    });
  const selectableOptions = filteredOptions.filter((o) => o.type == null);

  const selectOption = (option) => {
    onSelect(option.value);
    if (!option.stayOpen) {
      setIsExpanded(false);
      if (typeof buttonRef.current === "function") buttonRef.current.focus();
      return;
    }

    setQuery("");
  };

  const handleButtonKeyDown = (event) => {
    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
        event.preventDefault(); // prevent scroll
        setIsExpandedInternal(true);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowUp": {
        event.preventDefault(); // prevent scroll
        setHighlightedValue((highlightedValue) => {
          const visibleHighlightedValue = selectableOptions.find(
            (o) => o.value === highlightedValue
          )?.value;

          if (visibleHighlightedValue == null)
            return selectableOptions.slice(-1)[0].value;

          const index = selectableOptions.findIndex(
            (o) => o.value === visibleHighlightedValue
          );
          return (
            selectableOptions[index - 1]?.value ??
            selectableOptions.slice(-1)[0].value
          );
        });
        break;
      }
      case "ArrowDown": {
        event.preventDefault(); // prevent scroll
        setHighlightedValue((highlightedValue) => {
          const visibleHighlightedValue = selectableOptions.find(
            (o) => o.value === highlightedValue
          )?.value;

          if (visibleHighlightedValue == null)
            return selectableOptions[0].value;

          const index = selectableOptions.findIndex(
            (o) => o.value === visibleHighlightedValue
          );
          return (
            selectableOptions[index + 1]?.value ?? selectableOptions[0].value
          );
        });
        break;
      }
      case "Enter": {
        const visibleHighlightedOption = selectableOptions.find(
          (o) => o.value === highlightedValue
        );

        if (visibleHighlightedOption != null) {
          // Prevent the `buttonRef.current` getting clicked by 'Enter'. I
          // honestly don't quite understand why this happens.
          event.preventDefault();
          selectOption(visibleHighlightedOption);
        }
        break;
      }
      case "Escape": {
        event.preventDefault(); // prevent closing modals etc
        setIsExpanded(false);
        buttonRef.current.focus();
        break;
      }
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (!isExpanded) return;

    if (typeof keyDownHandlerRef.current?.focus === "function") {
      keyDownHandlerRef.current.focus();
    }

    const highlightedOptionButtonElement =
      optionButtonElementsByValueRef.current[highlightedValue];

    if (highlightedOptionButtonElement != null)
      highlightedOptionButtonElement.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
  }, [keyDownHandlerRef, isExpanded, highlightedValue]);

  const filteredOptionValues = selectableOptions.map((o) => o.value);

  React.useEffect(() => {
    if (filteredOptionValues.includes(highlightedValue)) return;
    setHighlightedValue(selectableOptions[0]);
  }, [highlightedValue, filteredOptionValues, selectableOptions]);

  return (
    <>
      {typeof renderButton === "function" &&
        renderButton({
          isExpanded,
          props: {
            onClick() {
              setIsExpandedInternal((s) => !s);
            },
            onMouseDown() {
              buttonClickedRef.current = true;
            },
            onKeyDown: handleButtonKeyDown,
            ref: buttonRef,
          },
        })}

      <ListboxPopover
        position={popverPosition}
        isExpanded={isExpanded}
        onClose={() => setIsExpanded(false)}
        buttonRef={buttonRef}
        buttonClickedRef={buttonClickedRef}
      >
        {mode === "listbox" ? (
          <Container
            dark={dark}
            ref={containerRef}
            tabIndex={-1}
            onKeyDown={filterable ? undefined : handleKeyDown}
          >
            {filterable && (
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={filterInputPlaceholder}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                className={css({
                  display: "block",
                  width: "100%",
                  padding: "1rem 2rem",
                  color: "rgb(255 255 255 / 80%)",
                  borderBottom: "0.1rem solid",
                  borderColor: dark
                    ? "rgb(255 255 255 / 10%)"
                    : "rgb(0 0 0 / 5%)",
                  borderRadius: 0,
                  "::placeholder": { color: "rgb(255 255 255 / 50%)" },
                })}
              />
            )}
            <div
              style={{
                padding: "0.5rem 0",
                maxHeight: "30rem",
                overflow: "auto",
              }}
            >
              {selectableOptions.length === 0 && (
                <SmallText
                  dark={dark}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "3rem",
                  }}
                >
                  No results
                </SmallText>
              )}

              {filteredOptions.map((option, i) => {
                if (option.type === "divider")
                  return (
                    <div style={{ width: "100%", padding: "0.5rem 0" }}>
                      <div
                        style={{
                          width: "100%",
                          borderBottom: "0.1rem solid rgb(255 255 255 / 10%)",
                        }}
                      />
                    </div>
                  );

                if (option.type === "text")
                  return (
                    <SmallText
                      dark={dark}
                      key={`text-${i}`}
                      size="1.2rem"
                      style={{ padding: "0.5rem 2rem" }}
                    >
                      {option.content}
                    </SmallText>
                  );

                const isSelected = value === option.value;
                const isHighlighted =
                  isSelected || highlightedValue === option.value;
                return (
                  <button
                    key={option.value}
                    ref={(node) => {
                      optionButtonElementsByValueRef.current[
                        option.value
                      ] = node;
                    }}
                    onClick={() => selectOption(option)}
                    type="button"
                    onMouseEnter={() => setHighlightedValue(option.value)}
                    style={{
                      display: "block",
                      width: "100%",
                      textAlign: "left",
                      padding: "0.8rem 2rem",
                      lineHeight: 1.2,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: dark
                        ? isHighlighted
                          ? "white"
                          : "rgb(255 255 255 / 80%)"
                        : "black",
                      background: isHighlighted
                        ? dark
                          ? "rgb(255 255 255 / 5%)"
                          : "rgb(0 0 0 / 5%)"
                        : "transparent",
                    }}
                  >
                    {option.label}
                  </button>
                );
              })}
            </div>
          </Container>
        ) : (
          <Container dark={dark}>{children}</Container>
        )}
      </ListboxPopover>
    </>
  );
};

const ListboxPopover = ({
  position,
  isExpanded,
  onClose,
  buttonRef,
  buttonClickedRef,
  children,
}) => {
  const popoverRef = React.useRef();

  React.useEffect(() => {
    const listener = (event) => {
      if (buttonClickedRef.current) {
        buttonClickedRef.current = false;
        return;
      }
      // We on want to close only if focus rests outside the menu
      if (isExpanded && popoverRef.current) {
        if (!popoverRef.current.contains(event.target)) {
          onClose();
        }
      }
    };

    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [buttonClickedRef, buttonRef, onClose, isExpanded, popoverRef]);

  return (
    <Popover
      ref={popoverRef}
      hidden={!isExpanded}
      targetRef={buttonRef}
      position={position}
      style={{ zIndex: 2 }}
    >
      {children}
    </Popover>
  );
};

const Container = React.forwardRef(({ dark, ...props }, ref) => (
  <div
    className={css({
      minWidth: "20rem",
      maxWidth: "calc(100vw - 2rem)",
      position: "relative",
      top: "0.5rem",
      boxShadow: "0 0.4rem 1.2rem rgba(0,0,0,0.1)",
      borderRadius: "0.5rem",
      background: dark ? "rgb(58 58 68)" : "white",
      color: "rgb(255 255 255 / 80%)", // TODO dark
    })}
    ref={ref}
    {...props}
  />
));

const SmallText = ({ dark, style, ...props }) => (
  <Text
    block
    color={dark ? "rgb(255 255 255 / 54%)" : "rgb(0 0 0 / 54%)"}
    size="1.3rem"
    style={{
      padding: "0.5rem 2rem",
      ...style,
    }}
    {...props}
  />
);

export default FilterableListbox;
