import React, { useState, useEffect } from 'react';
import { FullScreenPage, FullScreenGrid } from '../../common/skeleton';
import { resetPassword, forgotPassword } from '../../api/users';
import { BaseForm } from '../../common/auth/forms';
import queryString from 'query-string'
import { Link } from 'react-router-dom';
import { history } from '../../utils/history';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { userActions } from '../../actions/user.actions';

const ForgotPasswordForm = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  let { email } = queryString.parse(window.location.search);

  async function handleSubmit(values) {
    setIsLoading(true)
    forgotPassword(values.email)
      .then(function (result) {
        setSuccess(true)
      }).catch(function (error) {
        console.error(error)
      }).finally(function () {
        // setIsLoading(false)
      })
  }

  useEffect(() => {
    if (success) {
      history.push('/reset?status=sent')
    }
    return () => {

    };
  }, [success])


  return (
    <BaseForm
      handleSubmit={handleSubmit}
      title="Reset your password"
      subtitle="Enter the email associated with your account and we’ll send you a link to choose a new password."
      buttonText="Send me the link"
      email={true}
      queryEmail={email}
      password={false}
      isLoading={isLoading}
      success={success}
    />
  )
}

const ResetForm = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  let { token } = queryString.parse(window.location.search);

  async function handleSubmit(values) {
    setIsLoading(true)
    resetPassword(values.password, token)
      .then(function (result) {
        let user = result.data
        let email = user.email
        let redirect;

        if (!user.first_name) {
          redirect = "/welcome/profile"
        }

        dispatch(userActions.login(email, values.password, redirect))

      }).catch(function (error) {
        console.error(error)
      }).finally(function () {
        setIsLoading(false)
      })
  }

  return (
    <BaseForm
      handleSubmit={handleSubmit}
      title="Please choose a new password"
      subtitle="We recommend using a password manager to generate a strong and unique password."
      buttonText="Confirm"
      email={false}
      password={true}
      isLoading={isLoading}
      newPassword={true}
      resetPassword={true}
    />
  )
}

const ResetPasswordEmail = (props) => {

  function ResendHint() {
    return (
      <Box>
        <Box>
          <Typography variant="subtitle2" align="center">
            Didn't get the email? Check your spam folder or <Link to="/reset">resend</Link>.
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <BaseForm
      title="We’ve sent you an email"
      subtitle="Check your email for instructions to reset your password. You may close this window."
      hint={ResendHint()}
      email={false}
      password={false}
    />
  )
}

const ResetPasswordComponent = ({ location }) => {
  const UrlSearch = new URLSearchParams(location.search);
  const childProps = {
    token: UrlSearch.get('token'),
    status: UrlSearch.get('status')
  };

  const getForm = () => {
    if (childProps.token) {
      return <ResetForm />;
    } else {
      if (childProps.status) {
        return <ResetPasswordEmail />;
      } else {
        return <ForgotPasswordForm />;
      }
    }
  };

  return (
    <FullScreenPage>
      <FullScreenGrid>
        {getForm()}
      </FullScreenGrid>
    </FullScreenPage>
  )
}

export default ResetPasswordComponent
