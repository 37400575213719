import { identity } from "./function";

export const sort = (comparator, list) => [...list.sort(comparator)];

export const indexBy = (createKey, list) =>
  list.reduce((indexed, el) => ({ ...indexed, [createKey(el)]: el }), {});

export const groupBy = (mapItemToKey, list) =>
  list.reduce((listByKey, item) => {
    const key = mapItemToKey(item);

    if (listByKey[key]) {
      listByKey[key].push(item);
    } else {
      listByKey[key] = [item];
    }

    return listByKey;
  }, {});

// Unfolds a list from a seed number. Useful to create simple ranges where the
// next element can be derived from the previous one.
//
// unfold(n => n > 20 ? null : n + 5, 10) -> [10,15,20]
export const unfold = (iteratorFn, seed, list = []) => {
  // Start case
  if (list.length === 0) return unfold(iteratorFn, seed, [seed]);

  const next = iteratorFn(seed, list);
  if (next == null) return list;

  return unfold(iteratorFn, next, [...list, next]);
};

// Shrinks an array in steps using a given shink function, and stops at set max
// threshold. Useful for fitting ticks on an axis according to some fancy
// pattern for example.
export const fit = (shrinkFn, maxLength, list) => {
  if (list.length <= maxLength) return list;
  return fit(shrinkFn, maxLength, shrinkFn(list));
};

export const sum = (getTerm = identity, data) =>
  data.reduce((sum, d) => sum + getTerm(d), 0);

export const partition = (predicate, list) =>
  list.reduce(
    ([list1, list2], el) =>
      predicate(el) ? [[...list1, el], list2] : [list1, [...list2, el]],
    [[], []]
  );
