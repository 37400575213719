import isWithinInterval from "date-fns/isWithinInterval";
import startOfMonth from "date-fns/startOfMonth";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import isDateToday from "date-fns/isToday";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";
import { css } from "emotion";
import React from "react";
import Popover from "@reach/popover";
import { PRIMARY_COLOR } from "../constants/colors";
import Text from "./Text";
import Button from "./Button";
import Icon from "./Icon";
import FormattedDate from "./FormattedDate";

const getMonthFirstWeekStartDate = (date, { startOfWeekDayIndex }) => {
  return date.getDay() === startOfWeekDayIndex
    ? date
    : subDays(date, (7 + (date.getDay() - startOfWeekDayIndex)) % 7);
};

const getVisibleDays = (startOfMonthDate, { startOfWeekDayIndex }) => {
  const startDate = getMonthFirstWeekStartDate(startOfMonthDate, {
    startOfWeekDayIndex,
  });
  const endDate = addDays(startDate, 7 * 6);

  return Array.from({
    length: differenceInCalendarDays(endDate, startDate),
  }).map((_, i) => addDays(startDate, i));
};

const DatePicker = ({
  value,
  range,
  label,
  onChange,
  startOfWeekDayIndex = 1,
  renderTrigger,
  allowClear = true,
}) => {
  const buttonRef = React.useRef();
  const gridRef = React.useRef();
  const buttonClickedRef = React.useRef(false);
  const dateButtonsRef = React.useRef({});

  const startOfCurrentMonthDate = startOfMonth(new Date());

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [pendingDate, setPendingDate] = React.useState(null);
  const [{ offset, highlightedIndex }, setPosition] = React.useState(() => {
    const refDate =
      value || range?.[0] || pendingDate || startOfCurrentMonthDate;
    const offset = differenceInCalendarMonths(refDate, startOfCurrentMonthDate);
    const visibleDates = getVisibleDays(
      addMonths(startOfCurrentMonthDate, offset),
      { startOfWeekDayIndex }
    );
    const highlightedIndex = visibleDates.findIndex((d) =>
      isSameDay(d, refDate)
    );

    return {
      offset,
      highlightedIndex: highlightedIndex === -1 ? null : highlightedIndex,
    };
  });

  const startOfMonthDate = addMonths(startOfCurrentMonthDate, offset);

  const visibleDates = React.useMemo(
    () => getVisibleDays(startOfMonthDate, { startOfWeekDayIndex }),
    [startOfMonthDate.getTime(), startOfWeekDayIndex] // eslint-disable-line
  );

  const highlightedDate = visibleDates[highlightedIndex];

  const handleClickDate = (date) => {
    if (!Array.isArray(range)) {
      onChange(date);
      setIsExpanded(false);
      return;
    }

    if (pendingDate == null) {
      if (range?.[0] && isSameDay(range?.[0], date)) {
        setPendingDate(range?.[1] || date);
        return;
      }
      if (range?.[1] && isSameDay(range?.[1], date)) {
        setPendingDate(range?.[0] || date);
        return;
      }
      setPendingDate(date);
      return;
    }

    onChange(pendingDate > date ? [date, pendingDate] : [pendingDate, date]);
    setPendingDate(null);
    setIsExpanded(false);
  };

  const [rangeStart, rangeEnd] =
    range == null
      ? []
      : pendingDate == null
      ? range
      : highlightedDate == null || isSameDay(pendingDate, highlightedDate)
      ? []
      : [
          Math.min(pendingDate, highlightedDate),
          Math.max(pendingDate, highlightedDate),
        ];

  const handleButtonKeyDown = (event) => {
    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
        event.preventDefault(); // prevent scroll
        setIsExpanded(true);
        break;
      default:
        break;
    }
  };

  const handleMenuKeyDown = (event) => {
    switch (event.key) {
      case "ArrowUp": {
        event.preventDefault(); // prevent scroll

        setPosition((s) => {
          const nextIndex = s.highlightedIndex - 7;
          if (nextIndex >= 0) return { ...s, highlightedIndex: nextIndex };
          return {
            offset: s.offset - 1,
            highlightedIndex: 6 * 7 + nextIndex,
          };
        });
        break;
      }
      case "ArrowDown": {
        event.preventDefault(); // prevent scroll

        setPosition((s) => {
          const nextIndex = s.highlightedIndex + 7;
          if (nextIndex < 6 * 7) return { ...s, highlightedIndex: nextIndex };
          return {
            offset: s.offset + 1,
            highlightedIndex: nextIndex % (6 * 7),
          };
        });
        break;
      }
      case "ArrowLeft":
        setPosition((s) => {
          const nextIndex = s.highlightedIndex - 1;
          if (nextIndex >= 0) return { ...s, highlightedIndex: nextIndex };
          return {
            offset: s.offset - 1,
            highlightedIndex: 6 * 7 - 1,
          };
        });
        break;
      case "ArrowRight":
        setPosition((s) => {
          const nextIndex = s.highlightedIndex + 1;
          if (nextIndex < 6 * 7) return { ...s, highlightedIndex: nextIndex };
          return {
            offset: s.offset + 1,
            highlightedIndex: 0,
          };
        });
        break;
      case "Escape":
        setIsExpanded(false);
        buttonRef.current.focus();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (!isExpanded) return;

    if (highlightedIndex == null) {
      window.requestAnimationFrame(() => {
        if (gridRef.current == null) return;
        gridRef.current.focus();
      });
      return;
    }

    window.requestAnimationFrame(() => {
      const buttonEl = dateButtonsRef.current[highlightedIndex];
      if (buttonEl == null) return;
      buttonEl.focus();
    });
  }, [isExpanded, highlightedIndex]);

  const triggerProps = {
    ref: buttonRef,
    onClick: () => setIsExpanded((s) => !s),
    onMouseDown: () => {
      buttonClickedRef.current = true;
    },
    onKeyDown: handleButtonKeyDown,
  };

  return (
    <>
      {renderTrigger == null ? (
        <Button
          variant="transparent"
          size="small"
          style={{ display: "inline-flex" }}
          {...triggerProps}
        >
          {label}
        </Button>
      ) : (
        renderTrigger(triggerProps)
      )}

      <DatePickerPopover
        isExpanded={isExpanded}
        onClose={() => setIsExpanded(false)}
        buttonRef={buttonRef}
        buttonClickedRef={buttonClickedRef}
      >
        <div
          className={css({
            width: "25rem",
            maxWidth: "calc(100vw - 2rem)",
            boxShadow: "0 0.4rem 1.2rem rgba(0,0,0,0.1)",
            borderRadius: "0.5rem",
            background: "white",
            padding: "1rem 0.8rem",
            "*:focus-visible": {
              boxShadow: "0 0 0 0.3rem rgb(54 94 235 / 7.5%)",
            },
          })}
          onKeyDown={handleMenuKeyDown}
        >
          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "1fr auto auto",
              alignItems: "center",
              height: "2.8rem",
            })}
          >
            <div className={css({ paddingLeft: "0.7rem", fontWeight: "700" })}>
              <FormattedDate
                value={startOfMonthDate}
                month="long"
                year="numeric"
              />
            </div>

            {[
              {
                onClick: () =>
                  setPosition((s) => ({ ...s, offset: s.offset - 1 })),
                icon: "caret-left",
              },
              {
                onClick: () =>
                  setPosition((s) => ({ ...s, offset: s.offset + 1 })),
                icon: "caret-right",
              },
            ].map(({ onClick, icon }, i) => (
              <Button
                key={i}
                size="small"
                variant="transparent"
                onClick={onClick}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.4rem",
                  height: "2.4rem",
                  padding: 0,
                  // cursor: "pointer",
                  // color: "black",
                }}
              >
                <Icon size="1.3rem" name={icon} />
              </Button>
            ))}
          </div>

          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "repeat(7, minmax(3rem,1fr))",
              alignItems: "center",
              height: "2.8rem",
              color: "rgba(0,0,0,0.54)",
            })}
          >
            {visibleDates.slice(0, 7).map((date) => (
              <Text
                key={date.getTime()}
                size="1.2rem"
                weight="500"
                align="center"
              >
                <FormattedDate weekday="short">
                  {(format) => format(date).slice(0, 2)}
                </FormattedDate>
              </Text>
            ))}
          </div>

          <div
            ref={gridRef}
            className={css({
              display: "grid",
              gridTemplateColumns: "repeat(7, minmax(0,1fr))",
            })}
          >
            {visibleDates.map((date, i) => {
              const isToday = isDateToday(date);
              const isSelectedMonth = isSameMonth(startOfMonthDate, date);

              const isHighlighted =
                highlightedDate != null && isSameDay(highlightedDate, date);

              const isRangeStartDate =
                rangeStart != null && isSameDay(rangeStart, date);
              const isRangeEndDate =
                rangeEnd != null && isSameDay(rangeEnd, date);

              const isSelectedDate =
                (value != null && isSameDay(value, date)) ||
                (Array.isArray(range) && pendingDate == null
                  ? range.some((d) => isSameDay(d, date))
                  : isSameDay(pendingDate, date));

              const isContainedInRange =
                [rangeStart, rangeEnd].every(Boolean) &&
                isWithinInterval(date, {
                  start: rangeStart,
                  end: rangeEnd,
                });

              return (
                <button
                  key={date.getTime()}
                  ref={(el) => (dateButtonsRef.current[i] = el)}
                  onClick={() => handleClickDate(date)}
                  className={css({
                    textAlign: "center",
                    display: "block",
                    minWidth: "3rem",
                    lineHeight: "2.8rem",
                    fontWeight: "500",
                  })}
                  style={{
                    borderTopLeftRadius:
                      isRangeStartDate || !isContainedInRange ? "0.5rem" : 0,
                    borderBottomLeftRadius:
                      isRangeStartDate || !isContainedInRange ? "0.5rem" : 0,
                    borderTopRightRadius:
                      isRangeEndDate || !isContainedInRange ? "0.5rem" : 0,
                    borderBottomRightRadius:
                      isRangeEndDate || !isContainedInRange ? "0.5rem" : 0,
                    cursor: "pointer",
                    background: isSelectedDate
                      ? PRIMARY_COLOR
                      : isHighlighted || isContainedInRange
                      ? "rgb(54 94 235 / 7.5%)"
                      : undefined,
                    color: isSelectedDate
                      ? "white"
                      : isHighlighted
                      ? PRIMARY_COLOR
                      : isSelectedMonth
                      ? undefined
                      : "rgb(0 0 0 / 54%)",
                  }}
                  onMouseEnter={() =>
                    setPosition((s) => ({ ...s, highlightedIndex: i }))
                  }
                  tabIndex={isSelectedDate || isHighlighted ? 0 : -1}
                >
                  <Text size="1.3rem" weight={isToday ? "800" : "500"}>
                    <FormattedDate value={date} day="numeric" />
                  </Text>
                </button>
              );
            })}
          </div>
          {allowClear && (
            <div style={{ lineHeight: "2.8rem" }}>
              <Text
                component="button"
                size="1.3rem"
                color={PRIMARY_COLOR}
                lineHeight={1.2}
                weight="500"
                onClick={() => {
                  onChange(null);
                  setIsExpanded(false);
                }}
                style={{ padding: "0 0.7rem" }}
              >
                Clear
              </Text>
            </div>
          )}
        </div>
      </DatePickerPopover>
    </>
  );
};

const DatePickerPopover = ({
  position,
  isExpanded,
  onClose,
  buttonRef,
  buttonClickedRef,
  children,
}) => {
  const popoverRef = React.useRef();

  React.useEffect(() => {
    const listener = (event) => {
      if (buttonClickedRef.current) {
        buttonClickedRef.current = false;
        return;
      }
      // We on want to close only if focus rests outside the menu
      if (isExpanded && popoverRef.current) {
        if (!popoverRef.current.contains(event.target)) {
          onClose();
        }
      }
    };

    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [buttonClickedRef, buttonRef, onClose, isExpanded, popoverRef]);

  return (
    <Popover
      ref={popoverRef}
      hidden={!isExpanded}
      targetRef={buttonRef}
      position={position}
      style={{ zIndex: 2 }}
    >
      {children}
    </Popover>
  );
};

export default DatePicker;
