import formatDate from "date-fns/format";
import startOfYear from "date-fns/startOfYear";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import startOfDay from "date-fns/startOfDay";
import React from "react";
import useRequest from "./request";

const dateParserByFrequency = {
  daily: (timestamp) => startOfDay(new Date(timestamp * 1000)),
  weekly: (timestamp) =>
    startOfWeek(new Date(timestamp * 1000), { weekStartsOn: 1 }),
  monthly: (timestamp) => startOfMonth(new Date(timestamp * 1000)),
  quarterly: (timestamp) => startOfQuarter(new Date(timestamp * 1000)),
  yearly: (timestamp) => startOfYear(new Date(timestamp * 1000)),
};

const createQueryCacheKey = ({
  metric,
  frequency,
  groupBy,
  burnWindow,
  revenueWindow,
  categories,
  vendors,
  bankAccounts,
  from,
  to,
  minAmount,
  maxAmount,
  companyId,
}) =>
  [
    metric,
    frequency,
    groupBy,
    burnWindow,
    revenueWindow,
    categories?.join(""),
    vendors?.join(""),
    from?.getTime(),
    to?.getTime(),
    minAmount,
    maxAmount,
    bankAccounts?.join(""),
    companyId,
  ]
    .filter(Boolean)
    .join("");

const initialState = {
  dataByQuery: {},
  metadataByQuery: {},
  requestStatusByQuery: {},
};

const useMetric = ({ companyId, bankAccounts }) => {
  const request = useRequest();

  const fetchMetric = React.useCallback(
    ({
      metric,
      companyId,
      frequency,
      groupBy,
      categories,
      vendors,
      bankAccounts,
      burnWindow,
      revenueWindow,
      from,
      to,
      minAmount,
      maxAmount,
    }) => {
      return request(`/metrics/${metric}`, {
        headers: { "Company-Id": companyId },
        searchParams: Object.fromEntries(
          [
            ["from_date", from ? formatDate(from, "yyyy-MM-dd") : undefined],
            ["to_date", to ? formatDate(to, "yyyy-MM-dd") : undefined],
            ["frequency", frequency],
            ["group_by", groupBy],
            ["burn_window_size", burnWindow],
            ["revenue_window_size", revenueWindow],
            ["min_amount", minAmount],
            ["max_amount", maxAmount],
            ["categories", categories?.join(",")],
            ["vendors", vendors?.join(",")],
            ["bank_accounts", bankAccounts?.join(",")],
          ].filter(([_, value]) => value != null)
        ),
      }).then((res) => {
        const parseDate = dateParserByFrequency[frequency];
        return {
          ...res,
          data: res.data.map(({ date, ...rest }) => {
            if (date == null) return rest;
            return { ...rest, date: parseDate(date) };
          }),
        };
      });
    },
    [request]
  );

  const defaultBankAccountIds = React.useMemo(
    () => bankAccounts.map((a) => a.id),
    [bankAccounts]
  );

  const createQuery = React.useCallback(
    ({ bankAccounts, ...rest }) => ({
      ...rest,
      companyId,
      bankAccounts: bankAccounts ?? defaultBankAccountIds,
    }),
    [defaultBankAccountIds, companyId]
  );

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "fetch-dispatch": {
        const queryCacheKey = createQueryCacheKey(action.query);
        return {
          ...state,
          requestStatusByQuery: {
            ...state.requestStatusByQuery,
            [queryCacheKey]: { error: null, isPending: true },
          },
        };
      }
      case "fetch-success": {
        const queryCacheKey = createQueryCacheKey(action.query);
        return {
          ...state,
          dataByQuery: {
            ...state.dataByQuery,
            [queryCacheKey]: action.payload.data,
          },
          metadataByQuery: {
            ...state.metadataByQuery,
            [queryCacheKey]: action.payload.metadata,
          },
          requestStatusByQuery: {
            ...state.requestStatusByQuery,
            [queryCacheKey]: { error: null, isPending: false },
          },
        };
      }
      case "fetch-error": {
        const queryCacheKey = createQueryCacheKey(action.query);
        return {
          ...state,
          dataByQuery: {
            ...state.dataByQuery,
            [queryCacheKey]: null,
          },
          metadataByQuery: {
            ...state.metadataByQuery,
            [queryCacheKey]: action.payload.metadata,
          },
          requestStatusByQuery: {
            ...state.requestStatusByQuery,
            [queryCacheKey]: { error: action.payload.error, isPending: true },
          },
        };
      }
      default:
        return state;
    }
  }, initialState);

  const fetchData = React.useCallback(
    (rawQuery) => {
      const query = createQuery(rawQuery);

      dispatch({ type: "fetch-dispatch", query });
      return fetchMetric(query).then(
        (res) => {
          dispatch({ type: "fetch-success", query, payload: res });
          return res;
        },
        (error) => dispatch({ type: "fetch-error", query, payload: { error } })
      );
    },
    [fetchMetric, createQuery]
  );

  const getState = React.useCallback(
    (selector) => selector(state, { companyId, createQuery }),
    [state, companyId, createQuery]
  );

  return [getState, { fetch: fetchData }];
};

export const selectDataByQuery = (query) => (state, { createQuery }) =>
  state.dataByQuery[createQueryCacheKey(createQuery(query))];

export const selectMetadataByQuery = (query) => (state, { createQuery }) =>
  state.metadataByQuery[createQueryCacheKey(createQuery(query))];

export const selectRequestStatusByQuery = (query) => (state, { createQuery }) =>
  state.requestStatusByQuery[createQueryCacheKey(createQuery(query))];

export default useMetric;
