import React from "react";

import { GreenIcon, RedIcon, YellowIcon } from "../common/sync/icons";
import moment from "moment";

const iconSeverityMapping = {
  0: <GreenIcon />,
  1: <YellowIcon />,
  2: <RedIcon />,
};

export function getIconFromBanks(banks) {
  let severity = 0;

  if (banks.length === 0) {
    severity = 1;
  }

  for (const bank of banks) {
    let bankSeverity = getSeverityFromBank(bank);
    if (bankSeverity > severity) {
      severity = bankSeverity;
    }
  }

  return iconSeverityMapping[severity];
}

export function getIconFromBank(bank) {
  return iconSeverityMapping[getSeverityFromBank(bank)];
}

export function getSeverityFromBank(bank) {
  let synced = bank.sync_status === "done";
  let failedSync = bank.sync_status === "failed";
  let syncedMomentDate = moment(bank.sync_date * 1000);
  var currentTime = moment();
  let timeDiff = currentTime.diff(syncedMomentDate, "h");

  if (bank.sync_status !== "done" && bank.sync_status !== "failed") return 1;

  if (failedSync) {
    if (timeDiff > 120) {
      // failing for 1 week (5 days)
      return 2; // RED
    } else {
      return 1; // YELLOW
    }
  } else if (!synced || timeDiff > 24) {
    return 1; // YELLOW
  }
  return 0; // GREEN
}
