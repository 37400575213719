import { css } from "emotion";
import React from "react";
import { PRIMARY_COLOR } from "../constants/colors";
import { SUPPORT_EMAIL } from "../constants/obvious";
import Divider from "./Divider";
import Button from "./Button";
import Text from "./Text";
import Icon from "./Icon";

const ExceptionInformationBlock = () => (
  <div
    className={css({
      width: "56rem",
      maxWidth: "100%",
      margin: "0 auto",
      padding: "2rem",
    })}
  >
    <Icon name="exclamation" size="3rem" color="#F55900" />
    <Divider />
    <Text block variant="headline-large" margin="0 0 2rem">
      Exception detected
    </Text>
    <Text variant="paragraph-large">
      We detected an exception and an engineer has been notified about this
      issue. We do apologize for the inconvenience.
    </Text>
    <Divider size="2em" />
    <Text variant="paragraph-large">
      Don’t hesitate to reach out to us if this problem persists:{" "}
      <a
        href={`mailto:${SUPPORT_EMAIL}`}
        style={{ color: PRIMARY_COLOR, textDecoration: "underline" }}
      >
        {SUPPORT_EMAIL}
      </a>
    </Text>
    <Divider size="2em" />
    <Button
      size="large"
      component="a"
      href="/dashboard"
      style={{ background: PRIMARY_COLOR, color: "#FFFFFF" }}
    >
      Reload Obvious
    </Button>
  </div>
);

export default ExceptionInformationBlock;
