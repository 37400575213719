import deepEquals from "fast-deep-equal";
import React from "react";
import { sort } from "../utils/array";
import { ascending } from "../utils/comparators";
import { omit } from "../utils/object";
import useRequest from "./request";
import {
  create as createInputEntry,
  createModifier as createInputEntryModifier,
} from "../utils/projection-input-entries";

const indexById = (scenarios) =>
  scenarios.reduce((acc, s) => ({ ...acc, [s.id]: s }), {});

const parse = (scenario) => {
  const inputEntries =
    scenario.data.inputEntries?.map((r) => ({
      ...r,
      start: r.start == null ? null : new Date(r.start),
      end: r.end == null ? null : new Date(r.end),
      modifiers: r.modifiers.map((m) => ({
        ...m,
        start: new Date(m.start),
      })),
    })) ?? [];

  return { ...scenario.data, id: scenario.uuid, inputEntries };
};

const useScenarios = ({ companyId }) => {
  const request = useRequest();

  const [state, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "local-update":
          return {
            ...state,
            pendingEntryById: {
              ...state.pendingEntryById,
              [action.id]: action.properties,
            },
          };
        case "delete-success":
          return {
            ...state,
            entriesById: omit(action.id, state.entriesById),
            pendingEntryById: omit(action.id, state.pendingEntryById),
          };
        case "create-response":
        case "update-response": {
          const scenario = parse(action.response);
          const pendingEntry = state.pendingEntryById[scenario.id];
          const hasPendingChanges =
            pendingEntry != null && !deepEquals(scenario, pendingEntry);

          return {
            ...state,
            entriesById: {
              ...state.entriesById,
              [scenario.id]: scenario,
            },
            pendingEntryById: {
              ...state.pendingEntryById,
              [scenario.id]: hasPendingChanges ? pendingEntry : scenario,
            },
          };
        }
        case "fetch-single-response": {
          const scenario = parse(action.response);
          return {
            ...state,
            entriesById: {
              ...state.entriesById,
              [scenario.id]: scenario,
            },
            pendingEntryById: {
              ...state.pendingEntryById,
              [scenario.id]: scenario,
            },
          };
        }
        case "fetch-all-response": {
          const scenariosById = indexById(action.response.data.map(parse));

          return {
            ...state,
            entriesById: {
              ...state.entriesById,
              ...scenariosById,
            },
            pendingEntryById: {
              ...state.pendingEntryById,
              ...scenariosById,
            },
          };
        }
        default:
          return state;
      }
    },
    null,
    () => ({
      entriesById: {},
      pendingEntryById: {},
    })
  );

  const fetchAll = React.useCallback(() => {
    return request("/scenarios_draft", {
      headers: { "Company-Id": companyId },
    }).then((response) => {
      dispatch({ type: "fetch-all-response", response });
      return response;
    });
  }, [request, companyId]);

  const fetchById = React.useCallback(
    (id) => {
      return request(`/scenarios_draft/${id}`, {
        method: "GET",
        headers: { "Company-Id": companyId },
      }).then((response) => {
        dispatch({ type: "fetch-single-response", response });
        return response;
      });
    },
    [request, companyId]
  );

  const create = React.useCallback(
    (properties) =>
      request("/scenarios_draft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Id": companyId,
        },
        body: JSON.stringify(properties),
      }).then((response) => {
        dispatch({ type: "create-response", response });
        return response;
      }),
    [request, companyId]
  );

  const replace = React.useCallback(
    (id, properties) =>
      request(`/scenarios_draft/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Company-Id": companyId,
        },
        body: JSON.stringify(properties),
      }).then((response) => {
        dispatch({ type: "update-response", response });
        return response;
      }),
    [request, companyId]
  );

  const remove = React.useCallback(
    (id) => {
      return request(`/scenarios_draft/${id}`, {
        method: "DELETE",
        headers: { "Company-Id": companyId },
      }).then(() => {
        dispatch({ type: "delete-success", id });
      });
    },
    [request, companyId]
  );

  const localUpdate = (id, properties) => {
    const pendingProperties = selectPendingById(id)(state);
    dispatch({
      type: "local-update",
      id,
      properties: {
        ...pendingProperties,
        ...(typeof properties === "function"
          ? properties(pendingProperties)
          : properties),
      },
    });
  };
  const setInputEntries = (id, entries) => {
    localUpdate(id, (scenario) => ({
      inputEntries:
        typeof entries === "function"
          ? entries(scenario.inputEntries)
          : entries,
    }));
  };

  const addInputEntry = (id, properties) => {
    const entry = createInputEntry(properties);
    setInputEntries(id, (rs) => [...rs, entry]);
    return entry;
  };

  const updateInputEntry = (scenarioId, inputEntryId, properties) =>
    setInputEntries(scenarioId, (inputEntries) => {
      const inputEntryIndex = inputEntries.findIndex(
        (e) => e.id === inputEntryId
      );
      const inputEntry = inputEntries[inputEntryIndex];

      const updatedInputEntry = {
        ...inputEntry,
        ...(typeof properties === "function"
          ? properties(inputEntry)
          : properties),
      };

      const updatedInputEntryWithSortedModifiers = {
        ...updatedInputEntry,
        modifiers: sort(
          (m1, m2) => ascending(m1.start, m2.start),
          updatedInputEntry.modifiers
        ),
      };
      return [
        ...inputEntries.slice(0, inputEntryIndex),
        updatedInputEntryWithSortedModifiers,
        ...inputEntries.slice(inputEntryIndex + 1),
      ];
    });

  const removeInputEntry = (scenarioId, id) =>
    setInputEntries(scenarioId, (rs) => rs.filter((r) => id !== r.id));

  const addModifier = (scenarioId, id, properties) =>
    updateInputEntry(scenarioId, id, ({ modifiers, ...inputEntry }) => {
      const previous = modifiers.slice(-1)[0] ?? inputEntry;
      return {
        modifiers: [
          ...modifiers,
          createInputEntryModifier({
            growthType: previous.growthType,
            growth: previous.growth,
            // start: addMonths(previous.start ?? projectionStartDate, 1),
            ...properties,
          }),
        ],
      };
    });

  const updateModifier = (
    scenarioId,
    inputEntryId,
    modifierIndex,
    properties
  ) => {
    // console.log(scenarioId, inputEntryId, modifierIndex, properties);
    return updateInputEntry(scenarioId, inputEntryId, (inputEntry) => ({
      modifiers: inputEntry.modifiers.map((m, i) =>
        i === modifierIndex ? { ...m, ...properties } : m
      ),
    }));
  };

  const removeModifier = (scenarioId, entryId, index) =>
    updateInputEntry(scenarioId, entryId, ({ modifiers }) => ({
      modifiers: modifiers.filter((_, i) => index !== i),
    }));

  const getState = React.useCallback(
    (selector) => selector(state, { companyId }),
    [state, companyId]
  );

  return [
    getState,
    {
      fetch: fetchById,
      fetchAll,
      create,
      replace,
      remove,
      localUpdate,
      addInputEntry,
      updateInputEntry,
      removeInputEntry,
      addModifier,
      updateModifier,
      removeModifier,
    },
  ];
};

export const selectById = (id) => (state) => state.entriesById[id];
export const selectPendingById = (id) => (state) => state.pendingEntryById[id];
export const selectAll = (state) => Object.values(state.entriesById);

export default useScenarios;
