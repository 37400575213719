import React from "react";
import Text from "./Text";

const GraphError = ({
  height,
  message = "Oh no! Looks like something went wrong. We apologize for the inconvenience. An engineer has been notified about the problem.",
  retry,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: height,
      background: "rgb(255 0 0 / 6%)",
      color: "rgb(125 0 0)",
      borderRadius: "0.5rem",
      padding: "2rem",
    }}
  >
    <Text
      weight="500"
      align="center"
      lineHeight={1.3}
      style={{ width: "44rem", margin: "0 auto" }}
    >
      {message}
    </Text>

    {typeof retry === "function" && (
      <Text
        weight="700"
        color="currentColor"
        component="button"
        onClick={retry}
        style={{
          marginTop: "1rem",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Try again
      </Text>
    )}
  </div>
);

export default GraphError;
