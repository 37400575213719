import formatDate from "date-fns/format";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  GREY_BACKGROUND,
  PRIMARY_COLOR,
  PRIMARY_COLOR_LIGHT_TINT,
} from "../../constants/colors";
import { sort } from "../../utils/array";
import { ascending } from "../../utils/comparators";
import { humanReadable, title } from "../../utils/string";
import { isOutgoing as isOutgoingCategory } from "../../utils/categories";
import useStore from "../../hooks/stores";
import useTransactions from "../../hooks/transactions-data";
import Box from "../Box";
import G from "../Grid";
import T from "../Text";
import Pie from "../Pie";
import Spinner from "../Spinner";
import VendorIcon from "../VendorIcon";
import FormattedNumber from "../FormattedNumber";
import FormattedCurrency from "../FormattedCurrency";
import FormattedDate from "../FormattedDate";
import Hover from "../Hover";
import FilterableListbox from "../FilterableListbox";
import Tooltip, { positionTopCenter, positionBottomRight } from "../Tooltip";
import Icon from "../Icon";

const CategorizationSection = ({
  selectedPeriodRange,
  periodSelector,
  onTransactionCategorized,
}) => {
  const { defaultCurrency: currency } = useStore("settings");
  const categories = useStore("categories");

  const selectedPeriodTransactionsQuery = React.useMemo(() => {
    return {
      range: selectedPeriodRange,
      size: 1000,
      maxAmount: 0,
      categories: [
        "unknown",
        ...categories.filter(isOutgoingCategory).map((c) => c.id),
      ],
    };
  }, [categories, selectedPeriodRange]);

  const selectedPeriodUncategorizedTransactionsQuery = React.useMemo(() => {
    return {
      range: selectedPeriodRange,
      categories: ["unknown"],
      sorting: "amount",
      size: 1000,
      maxAmount: 0,
    };
  }, [selectedPeriodRange]);

  const [
    { meta: periodMeta },
    { update: updateTransaction, fetch: fetchPeriodTransactions },
  ] = useTransactions(selectedPeriodTransactionsQuery);

  const [
    { data: periodUncategorizedTransactions, meta: periodUncategorizedMeta },
    { fetch: fetchPeriodUncategorizedTransactions },
  ] = useTransactions(selectedPeriodUncategorizedTransactionsQuery);

  const periodTransactionCount = periodMeta?.count;
  const periodAbsoluteTotal = periodMeta?.metadata?.sum_abs;

  const periodUncategorizedTransactionCount = periodUncategorizedMeta?.count;
  const periodUncategorizedAboluteTotal =
    periodUncategorizedMeta?.metadata?.sum_abs ?? 0;

  const uncategorizedFraction = [
    periodUncategorizedAboluteTotal,
    periodAbsoluteTotal,
  ].some((n) => n == null)
    ? null
    : periodAbsoluteTotal === 0
    ? 0
    : periodUncategorizedAboluteTotal / periodAbsoluteTotal;

  const hasCategorizedPeriod =
    periodTransactionCount > 0 && periodUncategorizedTransactionCount === 0;

  const periodUncategorizedTransactionsLink = [
    "/dashboard/transactions",
    new URLSearchParams([
      ["categories", "unknown"],
      ["from", formatDate(selectedPeriodRange[0], "yyyy-MM-dd")],
      ["to", formatDate(selectedPeriodRange[1], "yyyy-MM-dd")],
    ]).toString(),
  ].join("?");

  return (
    <div
      style={{
        padding: "4rem 3rem",
        background: GREY_BACKGROUND,
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
        borderRadius: "0.3rem",
        overflow: "hidden",
        lineHeight: 1.3,
      }}
    >
      <G
        column
        template="auto 1fr auto"
        gap="2rem"
        style={{ width: "100%", minWidth: 0 }}
      >
        <T align="center">Categorization {periodSelector}</T>

        {typeof uncategorizedFraction !== "number" ? (
          <div style={{ margin: "auto", padding: "2rem 0" }}>
            <Spinner size="3rem" color="rgb(0 0 0 / 25%)" />
          </div>
        ) : (
          <G column gap="2rem" style={{ alignSelf: "flex-start", minWidth: 0 }}>
            {hasCategorizedPeriod ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "6rem 0",
                }}
              >
                <Icon
                  name="checkmark"
                  size="4rem"
                  style={{ margin: "0 0 2rem" }}
                />
                <T align="center" size="1.6rem" weight="500">
                  All done!
                </T>
              </div>
            ) : (
              <>
                <Hover>
                  {([isHovered, hoverEventHandlers]) => (
                    <CategorizationTooltip
                      totalAmount={periodAbsoluteTotal ?? 0}
                      transactionCount={periodTransactionCount}
                      uncategorizedAmount={periodUncategorizedAboluteTotal}
                      uncategorizedTransactionCount={
                        periodUncategorizedTransactionCount
                      }
                      currency={currency}
                    >
                      <G
                        template="minmax(0,1fr) auto"
                        align="center"
                        style={{ justifySelf: "center", minWidth: 0 }}
                      >
                        <RouterLink
                          to={periodUncategorizedTransactionsLink}
                          {...hoverEventHandlers}
                          style={{
                            lineHeight: 1.5,
                            display: "block",
                            paddingRight: "2rem",
                          }}
                        >
                          {uncategorizedFraction != null && (
                            <T variant="number-large" size="2.6rem">
                              <FormattedNumber
                                percent
                                value={1 - uncategorizedFraction}
                                maximumFractionDigits={1}
                              />
                            </T>
                          )}
                        </RouterLink>
                        <RouterLink
                          to={periodUncategorizedTransactionsLink}
                          {...hoverEventHandlers}
                        >
                          <Pie
                            size={50}
                            weight={7}
                            data={[
                              1 - uncategorizedFraction,
                              uncategorizedFraction,
                            ]}
                            color={(_, i) => {
                              if (i !== 0) return "rgb(0 0 0 / 10%)";
                              return isHovered
                                ? PRIMARY_COLOR_LIGHT_TINT
                                : PRIMARY_COLOR;
                            }}
                          />
                        </RouterLink>
                      </G>
                    </CategorizationTooltip>
                  )}
                </Hover>

                <div>
                  <T block color="rgb(0 0 0 / 54%)" margin="0 0 1rem">
                    Largest items
                  </T>
                  <G
                    template="auto"
                    gap="1rem"
                    style={{ lineHeight: 1.3, minWidth: 0 }}
                  >
                    {periodUncategorizedTransactions?.slice(0, 3).map((t) => (
                      <TransactionCard
                        key={t.uuid}
                        transaction={t}
                        onSelectCategory={(id) =>
                          updateTransaction(t.uuid, {
                            category: id,
                          }).then(() => {
                            fetchPeriodTransactions();
                            fetchPeriodUncategorizedTransactions();
                            onTransactionCategorized();
                          })
                        }
                      />
                    ))}
                  </G>
                </div>
              </>
            )}
          </G>
        )}

        <T
          block
          color="rgb(0 0 0 / 54%)"
          align="center"
          style={{ paddingTop: "1rem" }}
        >
          <RouterLink to="/dashboard/transactions?categories=unknown">
            All uncategorized items &rarr;
          </RouterLink>
        </T>
      </G>
    </div>
  );
};

const TransactionCard = ({ transaction: t, onSelectCategory }) => {
  const { defaultCurrency } = useStore("settings");
  const vendors = useStore("vendors");
  const categories = useStore("categories");

  const hasVendor = t.merchant != null && t.merchant !== "";
  const knownVendor = vendors.find((v) => v.id === t.merchant);

  const humanReadableVendor =
    knownVendor?.description ?? humanReadable(t.merchant ?? "");
  const humanReadableTransactionDescription = title(t.description);

  const showTransactionDescription =
    !hasVendor ||
    humanReadableVendor?.toLowerCase() !==
      humanReadableTransactionDescription?.toLowerCase();

  return (
    <Box background="white" shadowOpacity="5%" padding="1rem">
      <G align="center" template="auto minmax(0,1fr)" gap="1rem">
        <VendorIcon
          size="3rem"
          vendor={{
            id: t.merchant,
            description: t.merchant || t.description,
          }}
        />
        <div>
          <G template="minmax(0,1fr) auto" gap="2rem" align="flex-start">
            <div
              style={{
                display: "inline-flex",
                flexWrap: "wrap",
                lineHeight: 1.3,
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              {hasVendor && (
                <T
                  component={RouterLink}
                  to={{
                    pathname: "/dashboard/transactions",
                    search: `vendors=${t.merchant}`,
                  }}
                  weight="700"
                  truncate
                  style={{
                    marginRight: "0.8rem",
                    ":hover": { color: "rgb(0 0 0 / 54%)" },
                  }}
                >
                  {humanReadableVendor}
                </T>
              )}
              {showTransactionDescription && (
                <T
                  weight={hasVendor ? "500" : "700"}
                  color={hasVendor ? "rgba(0,0,0,0.54)" : ""}
                  truncate
                >
                  {humanReadableTransactionDescription}
                </T>
              )}
            </div>
            <T
              color="rgb(0 0 0 / 54%)"
              size="1.2rem"
              truncate
              style={{ paddingTop: "0.2rem" }}
            >
              <FormattedDate
                value={new Date(t.date * 1000)}
                day="numeric"
                month="short"
              />
            </T>
          </G>
          <G
            template="minmax(0,1fr) auto"
            gap="2rem"
            align="center"
            style={{ marginTop: "0.2rem" }}
          >
            <T truncate>
              <FormattedCurrency
                value={Math.abs(t.amount)}
                currency={defaultCurrency}
              />
            </T>
            <FilterableListbox
              dark
              filterable
              filterInputPlaceholder="Categorize as..."
              value={null}
              options={[
                ...sort(
                  (o1, o2) => ascending(o1.label, o2.label),
                  categories.filter(isOutgoingCategory).map((c) => ({
                    value: c.id,
                    label: c.description,
                  }))
                ),
              ]}
              onSelect={onSelectCategory}
              popverPosition={positionBottomRight}
              renderButton={({ props }) => (
                <div>
                  <T
                    variant="link"
                    size="1.2rem"
                    component="button"
                    style={{ cursor: "pointer" }}
                    {...props}
                  >
                    Categorize
                  </T>
                </div>
              )}
            />
          </G>
        </div>
      </G>
    </Box>
  );
};

const CategorizationTooltip = ({
  totalAmount,
  transactionCount,
  uncategorizedAmount,
  uncategorizedTransactionCount,
  currency,
  children,
}) => {
  return (
    <Tooltip
      position={positionTopCenter}
      label={
        uncategorizedAmount === 0 ? (
          <T size="1.6rem">{"\u{1F60D}"}</T>
        ) : (
          <>
            <T block size="1.4rem" margin="0 0 0.5rem">
              <FormattedCurrency
                currency={currency}
                value={totalAmount - uncategorizedAmount}
                maximumFractionDigits={0}
              />{" "}
              <T block={false} size="1.2rem" color="rgb(255 255 255 / 60%)">
                categorized of total{" "}
                <FormattedNumber
                  value={totalAmount}
                  maximumFractionDigits={0}
                />
              </T>
            </T>
            <T size="1.2rem" color="rgb(255 255 255 / 60%)">
              ({transactionCount - uncategorizedTransactionCount} of{" "}
              {transactionCount} transactions categorized)
            </T>
          </>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default CategorizationSection;
