import { css } from "emotion";
import React from "react";
import Button from "./Button";
import HiddenOverlay from "./HiddenOverlay";

const Select = ({
  value,
  options,
  onChange,
  renderTrigger,
  label,
  placeholder,
  variant,
  size,
  style: containerStyle,
  ...selectProps
}) => {
  const selectedOption = options.find((o) => o.value === value);
  const visibleLabel = label || selectedOption?.label || placeholder;

  return (
    <HiddenOverlay
      renderOverlay={({ isHovered }) =>
        typeof renderTrigger === "function" ? (
          renderTrigger(selectedOption, isHovered)
        ) : (
          <ButtonWithDownArrow
            variant={variant}
            size={size}
            disabled={selectProps.disabled}
            isHovered={isHovered}
          >
            {visibleLabel}
          </ButtonWithDownArrow>
        )
      }
      style={containerStyle}
    >
      {({ style, ...props }) => (
        <select
          value={value == null ? "" : value}
          style={{
            ...style,
            WebkitAppearance: "none",
            fontSize: "inherit",
            outline: "none",
            border: 0,
            color: "inherit",
            cursor: "pointer",
            ":disabled": { cursor: "initial" },
          }}
          onChange={onChange}
          {...selectProps}
          {...props}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
    </HiddenOverlay>
  );
};

const ButtonWithDownArrow = ({ children, ...props }) => (
  <Button
    component="div"
    type={undefined}
    style={{
      display: "inline-grid",
      gridTemplateColumns: "auto auto",
      gap: "0.6rem",
      alignItems: "center",
      whiteSpace: "nowrap",
    }}
    {...props}
  >
    <span>{children}</span>
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      className={css({ width: "1rem", position: "relative", top: "0.1rem" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0.375L6 4.875L10.5 0.375L12 1.875L6 7.875L0 1.875L1.5 0.375Z"
        fill="currentColor"
      />
    </svg>
  </Button>
);

export default Select;
