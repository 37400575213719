import { CompaniesEndpoint } from "../utils/constants";
import { handleErrorResponse, apiInstance } from "./common";

export const companyService = {
  getCompanies,
  getCompanyMembers,
  inviteCompanyMember,
  confirmInvitation,
  getCompanyOldBalance,  // deprecated! delete when new dashboard is live
  getCompanyBalance,
  getCompanyRunway,
  getCompanyGrowth,
  getCompanyBurnRate
};

export async function getCompanies() {
  return apiInstance.get(CompaniesEndpoint)
    .then(response => {
      let companies = response.data
      return companies;
    }).catch(handleErrorResponse);
}

export async function getCompany(companyUuid) {
  return apiInstance.get(CompaniesEndpoint + "/" + companyUuid)
    .then(response => {
      return response
    }).catch(handleErrorResponse);
}

export async function getCompanyMembers() {
  let params = {
    expand: "user",
  }

  return apiInstance.get(CompaniesEndpoint + "/members", { params: params })
    .then(response => {
      let members = response.data
      return members;
    }).catch(handleErrorResponse);
}


export async function inviteCompanyMember(email) {
  let data = {
    "email": email
  }

  return apiInstance.post(CompaniesEndpoint + "/members", data)
    .then(response => {
      return response.data
    }).catch(handleErrorResponse);
}

export async function confirmInvitation(token) {
  let data = {
    "token": token
  }

  return apiInstance.post(CompaniesEndpoint + "/members/confirm", data)
    .then(response => {
      return response.data
    }).catch(handleErrorResponse);
}

export async function getCompanyOldBalance() {
  return apiInstance.get(CompaniesEndpoint + "/old_balance")
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}

export async function getCompanyBalance(queryParams) {
  return apiInstance.get(CompaniesEndpoint + "/balance", { params: queryParams })
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}

export async function getCompanyPendingBalance(queryParams) {
  return apiInstance.get(CompaniesEndpoint + "/pending_balance", { params: queryParams })
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}

export async function getCompanyRunway(queryParams) {
  return apiInstance.get(CompaniesEndpoint + "/runway", { params: queryParams })
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}

export async function getCompanyGrowth(queryParams) {
  return apiInstance.get(CompaniesEndpoint + "/growth", { params: queryParams })
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}

export async function getCompanyBurnRate(queryParams) {
  return apiInstance.get(CompaniesEndpoint + "/burn_rate", { params: queryParams })
    .then(response => {
      return response.data;
    }).catch(handleErrorResponse);
}
