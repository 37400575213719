import formatDate from "date-fns/format";
import parseDate from "date-fns/parse";
import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { pluralise } from "../../utils/string";
import { update as updateQueryString } from "../../utils/query-string";
import usePrevious from "../../hooks/previous";
import {
  selectByQuery as selectTransactionsByQuery,
  selectMetadataByQuery as selectTransactionsQueryMetadata,
} from "../../hooks/transactions";
import Text from "../Text";
import Divider from "../Divider";
import Container from "../Container";
import Button from "../Button";
import Select from "../Select";
import DatePicker from "../DatePicker";
import Icon from "../Icon";
import TransactionList from "../TransactionList";

const useDebouncedValue = (val) => {
  const [state, setState] = React.useState(val);

  React.useEffect(() => {
    const handle = setTimeout(() => setState(val), 1000);
    return () => clearTimeout(handle);
  }, [val]);

  return [state, () => setState(val)];
};

const Transactions = ({
  vendors,
  categories,
  getTransactionsState,
  fetchTransactions,
  updateTransaction,
  location,
  history,
}) => {
  const searchInputRef = React.useRef();
  const [
    { property: sortProperty, order: sortOrder },
    setSort,
  ] = React.useState({ property: "date", order: "desc" });

  const {
    q: searchQuery = "",
    page = 1,
    size = 50,
    minAmount,
    maxAmount,
    from: fromDateString,
    to: toDateString,
    accounts: accountFilterString,
    vendors: vendorFilterString,
    categories: categoryFilterString,
  } = Object.fromEntries(
    Array.from(new URLSearchParams(location.search).entries()).map(
      ([key, value]) => {
        switch (key) {
          case "page":
          case "size":
            return [key, parseInt(value)];
          case "min-amount":
            return ["minAmount", parseInt(value)];
          case "max-amount":
            return ["maxAmount", parseInt(value)];
          case "accounts":
            return ["accounts", value || undefined];
          default:
            return [key, value];
        }
      }
    )
  );

  const [fromDate, toDate] = [fromDateString, toDateString].map((s) =>
    s == null ? null : parseDate(s, "yyyy-MM-dd", new Date())
  );

  const [debouncedSearchQuery] = useDebouncedValue(searchQuery);

  const query = React.useMemo(() => {
    const [fromDate, toDate] = [fromDateString, toDateString].map((s) =>
      s == null ? null : parseDate(s, "yyyy-MM-dd", new Date())
    );
    return {
      page: page - 1,
      size,
      minAmount,
      maxAmount,
      search: debouncedSearchQuery.trim(),
      sorting:
        sortProperty == null
          ? undefined
          : `${sortOrder === "desc" ? "-" : ""}${sortProperty}`,
      range: [fromDate, toDate],
      accounts: accountFilterString?.split(","),
      vendors: vendorFilterString?.split(","),
      categories: categoryFilterString?.split(","),
    };
  }, [
    page,
    size,
    minAmount,
    maxAmount,
    debouncedSearchQuery,
    sortProperty,
    sortOrder,
    fromDateString,
    toDateString,
    accountFilterString,
    vendorFilterString,
    categoryFilterString,
  ]);

  const transactions =
    getTransactionsState(selectTransactionsByQuery(query)) ?? [];

  const { count, isFetching, didSuccessfullyFetch } =
    getTransactionsState(selectTransactionsQueryMetadata(query)) ?? {};

  React.useEffect(() => {
    fetchTransactions(query);
  }, [fetchTransactions, query]);

  const lastPage = Math.ceil(count / size);

  const previousDebouncedSearch = usePrevious(debouncedSearchQuery);
  React.useEffect(() => {
    if (previousDebouncedSearch == null) return;
    history.replace({
      search: updateQueryString(location.search, { page: undefined }),
    });
  }, [debouncedSearchQuery]); // eslint-disable-line

  React.useEffect(() => {
    const handleKeydown = (e) => {
      if (e.metaKey && e.key === "k") {
        searchInputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);

  const scrollContainerRef = React.useRef();
  React.useEffect(() => {
    scrollContainerRef.current.scroll(0, 0);
  }, [page]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    fetchTransactions({ ...query, search: searchQuery.trim() });
  };

  const clearFilters = () => {
    history.replace({
      search: updateQueryString(location.search, {
        q: undefined,
        from: undefined,
        to: undefined,
      }),
    });
  };

  return (
    <>
      <div
        className={css({
          borderBottom: "0.1rem solid",
          borderColor: "rgb(239, 241, 244)",
        })}
      >
        <form
          onSubmit={handleSearchFormSubmit}
          className={css({
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            height: "6rem",
            gap: "2rem",
            padding: "1rem",
            paddingLeft: "2rem",
          })}
        >
          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "3rem 1fr",
              alignItems: "center",
              gap: "1.4rem",
            })}
          >
            <Icon name="search" size="2rem" style={{ margin: "auto" }} />
            <input
              type="search"
              placeholder="Search your heart out..."
              value={searchQuery}
              onChange={({ target: { value: searchQuery } }) => {
                const isBlank = searchQuery === "";
                const isWordBreak = searchQuery.slice(-1)[0] === " ";
                history.replace({
                  search: updateQueryString(location.search, {
                    q: searchQuery,
                  }),
                });
                if (isBlank || isWordBreak)
                  fetchTransactions({ ...query, search: searchQuery.trim() });
              }}
              className={css({
                padding: 0,
                border: 0,
                fontWeight: "500",
                WebkitAppearance: "none",
                "::placeholder": {
                  color: "rgba(0,0,0,0.54)",
                  transition: "0.1s color",
                },
                ":not(:focus):hover::placeholder": { color: "currentColor" },
                "::-webkit-search-cancel-button": {
                  WebkitAppearance: "none", // TODO
                },
              })}
              ref={searchInputRef}
            />
          </div>

          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "repeat(1, minmax(0,auto))",
              gap: "1rem",
            })}
          >
            <DatePicker
              range={[fromDate, toDate]}
              onChange={([from, to]) =>
                history.push({
                  search: updateQueryString(location.search, {
                    page: undefined,
                    from: formatDate(from, "yyyy-MM-dd"),
                    to: formatDate(to, "yyyy-MM-dd"),
                  }),
                })
              }
              label={
                <>
                  <Icon
                    name="calendar"
                    size="1.4rem"
                    style={{ marginRight: "1rem" }}
                  />
                  <Text truncate style={{ flex: 1 }}>
                    {fromDate || toDate
                      ? [fromDate, toDate]
                          .map((d) => (d ? formatDate(d, "yyyy-MM-dd") : "*"))
                          .join(" – ")
                      : "Select interval"}
                  </Text>
                </>
              }
            />
          </div>
        </form>
      </div>

      <div
        ref={scrollContainerRef}
        className={css({ flex: "1 1", overflow: "auto" })}
      >
        {!isFetching && didSuccessfullyFetch && transactions.length === 0 ? (
          <NoResultsPlaceholder clearFilters={clearFilters} />
        ) : (
          <>
            <div className={css({ padding: "0 1rem", overflow: "auto" })}>
              <TransactionList
                transactions={transactions}
                size={size}
                header
                sort={({ property, order }) => setSort({ property, order })}
                sortOrder={sortOrder}
                sortProperty={sortProperty}
                vendors={vendors}
                categories={categories}
                updateTransaction={updateTransaction}
                location={location}
                history={history}
              />
            </div>

            <div
              className={css({
                position: "sticky",
                bottom: 0,
                zIndex: 2,
                background: "white",
                borderTop: "0.1rem solid",
                borderColor: "rgb(239, 241, 244)",
              })}
            >
              <Container size="wide">
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "1rem",
                  })}
                >
                  <div
                    className={css({
                      minWidth: 0,
                      overflow: "hidden",
                      display: "grid",
                      gridAutoFlow: "column",
                      gridAutoRows: "auto",
                      alignItems: "center",
                      gap: "1rem",
                    })}
                  >
                    <Select
                      value={size}
                      options={[10, 15, 30, 50].map((count) => ({
                        value: count,
                        label: `${count} ${pluralise("item", count)}`,
                      }))}
                      onChange={(e) => {
                        const currentOffset = (page - 1) * size;
                        const newSize = parseInt(e.target.value);
                        const newPage = Math.floor(currentOffset / newSize) + 1;
                        history.push({
                          search: updateQueryString(location.search, {
                            size: newSize === 50 ? undefined : newSize,
                            page: newPage === 1 ? undefined : newPage,
                          }),
                        });
                      }}
                      size="small"
                      variant="transparent"
                    />
                    <Text truncate lineHeight={1.2}>
                      {count != null && (
                        <>
                          Showing items {(page - 1) * size + 1}-
                          {Math.min(page * size, count)} of {count}
                        </>
                      )}
                    </Text>
                  </div>

                  {lastPage !== 1 && (
                    <div
                      className={css({
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoRows: "auto",
                        gap: "1rem",
                        alignItems: "center",
                      })}
                    >
                      <Button
                        size="small"
                        variant="transparent"
                        component={page <= 1 ? "button" : RouterLink}
                        to={{
                          search: updateQueryString(location.search, {
                            page: page - 1,
                          }),
                        }}
                        disabled={page <= 1}
                        style={{
                          display: "inline-grid",
                          gridAutoFlow: "column",
                          gridAutoRows: "auto",
                          gap: "0.6rem",
                          alignItems: "center",
                        }}
                      >
                        <CaretLeft
                          style={{
                            height: "1rem",
                            position: "relative",
                            top: "0.1rem",
                            right: "0.1rem",
                          }}
                        />
                        Prev
                      </Button>

                      <div
                        className={css({
                          display: "inline-grid",
                          gridAutoFlow: "column",
                          gridAutoRows: "auto",
                          "& > *": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "2.4rem",
                            height: "2.4rem",
                          },
                          a: {
                            color: "currentColor",
                            ":hover": { textDecoration: "underline" },
                          },
                        })}
                      >
                        {page >= 3 && (
                          <RouterLink
                            to={{
                              search: updateQueryString(location.search, {
                                page: undefined,
                              }),
                            }}
                          >
                            1
                          </RouterLink>
                        )}
                        {page >= 4 && <span>...</span>}
                        {page >= 2 && (
                          <RouterLink
                            to={{
                              search: updateQueryString(location.search, {
                                page: page - 1,
                              }),
                            }}
                          >
                            {page - 1}
                          </RouterLink>
                        )}
                        {lastPage >= 2 && <Text weight="700">{page}</Text>}
                        {lastPage - page >= 1 && (
                          <RouterLink
                            to={{
                              search: updateQueryString(location.search, {
                                page: page + 1,
                              }),
                            }}
                          >
                            {page + 1}
                          </RouterLink>
                        )}
                        {lastPage - page >= 3 && <span>...</span>}
                        {lastPage - page >= 2 && (
                          <RouterLink
                            to={{
                              search: updateQueryString(location.search, {
                                page: lastPage,
                              }),
                            }}
                          >
                            {lastPage}
                          </RouterLink>
                        )}
                      </div>

                      <Button
                        size="small"
                        variant="transparent"
                        onClick={() =>
                          history.push({
                            search: updateQueryString(location.search, {
                              page: page + 1,
                            }),
                          })
                        }
                        disabled={page * size > count}
                        style={{
                          display: "inline-grid",
                          gridTemplateColumns: "auto auto",
                          gap: "0.6rem",
                          alignItems: "center",
                        }}
                      >
                        Next
                        <CaretRight
                          style={{
                            height: "1rem",
                            position: "relative",
                            top: "0.1rem",
                            left: "0.1rem",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const NoResultsPlaceholder = ({ clearFilters }) => (
  <div className={css({ padding: "8rem", textAlign: "center" })}>
    <Text block align="center" size="1.6rem" weight="500">
      Found no transactions for the given query
    </Text>
    <Divider size="2rem" />
    <Button size="small" onClick={clearFilters}>
      Clear filters
    </Button>
  </div>
);

const CaretLeft = (props) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    aria-hidden="true"
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 1.625L3.25 6.125L7.75 10.625L6.25 12.125L0.250001 6.125L6.25 0.125L7.75 1.625Z"
      fill="currentColor"
    />
  </svg>
);

const CaretRight = (props) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    aria-hidden="true"
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 10.625L4.75 6.125L0.25 1.625L1.75 0.125005L7.75 6.125L1.75 12.125L0.25 10.625Z"
      fill="currentColor"
    />
  </svg>
);

export default Transactions;
