import React from "react";
import FormattedCurrency from "./FormattedCurrency";

const FormattedCurrencyWithSmallDecimals = ({ value, ...props }) => (
  <FormattedCurrency {...props}>
    {(formatter) =>
      formatter.formatToParts(value).map((part, i) =>
        ["integer", "group"].includes(part.type) ? (
          part.value
        ) : (
          <span key={i} style={{ fontSize: "0.7em" }}>
            {part.value}
          </span>
        )
      )
    }
  </FormattedCurrency>
);

export default FormattedCurrencyWithSmallDecimals;
