import memoize from "mem";
import addMonths from "date-fns/addMonths";
import subtractMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import { identity } from "./function";
import { sum, unfold } from "./array";
import { isNumber } from "./number";

const flipSign = (amount) => amount * -1;
const flipDatumAmounts = (datum) => ({
  ...datum,
  amount: flipSign(datum.amount),
  amountRange: datum.amountRange.map(flipSign),
});

const isValidGrowth = (growth) => Array.isArray(growth) || isNumber(growth);

export const projectInputEntryCashFlow = memoize((inputEntry) => {
  // console.log("project input", inputEntry.ik, inputEntry);

  // Some invariants to stay sane in this dynamic world
  if (isNaN(inputEntry.amount)) throw new Error();
  if (inputEntry.growth != null && !isValidGrowth(inputEntry.growth))
    throw new Error();
  if (!["one-time", "reccuring"].includes(inputEntry.type)) throw new Error();
  if (!["expense", "income"].includes(inputEntry.flow)) throw new Error();
  if (!["linear", "exponential", null].includes(inputEntry.growthType))
    throw new Error();

  const isExpense = inputEntry.flow === "expense";

  switch (inputEntry.type) {
    case "one-time": {
      const amount = isExpense
        ? flipSign(inputEntry.amount)
        : inputEntry.amount;
      return [
        {
          date: inputEntry.start,
          amount,
          amountRange: [amount, amount],
          categories: inputEntry.categories,
        },
      ];
    }
    case "reccuring": {
      const data = projectReccuringInputEntryCashFlow(inputEntry).map((d) => ({
        ...d,
        categories: inputEntry.categories,
      }));
      if (!isExpense) return data;
      return data.map(flipDatumAmounts);
    }
    default:
      throw new Error();
  }
});

const projectReccuringInputEntryCashFlow = memoize((row /*frequency */) => {
  // Tweak these three to add support non-month frequencies
  const floorDate = (date) => startOfMonth(date);
  const getNextDate = (date) => addMonths(date, 1);
  const getPreviousDate = (date) => subtractMonths(date, 1);

  // Don't allow anything to go below zero for now
  const trim = (amount) => Math.max(0, amount);

  // Default to start of month when missing start date for now
  const startDate = row.start ?? floorDate(new Date());
  const startAmount = row.amount ?? 0;

  // Derive "periods" with individual start and end dates from the entry
  // start-end interval + modifiers
  const periods = [
    { ...row, start: startDate, amount: startAmount },
    ...(row.modifiers ?? []),
  ].map((period, index, all) => {
    const isLast = index === all.length - 1;
    const nextPeriod = all[index + 1];
    return {
      ...period,
      end: isLast ? row.end : getPreviousDate(nextPeriod.start),
    };
  });

  // Use the mean as the growth value if a range is set
  const flattenGrowth = (growth) =>
    Array.isArray(growth) ? sum(identity, growth) / 2 : growth;

  // Calculate deviation from the growth range if set
  const calculateDeviation = (growth) =>
    Array.isArray(growth) ? Math.abs(growth[1] - growth[0]) / 2 : 0;

  // const getMostRecentSetAmount = (periodIndex) =>
  //   periods
  //     .slice(0, periodIndex)
  //     .reverse()
  //     .find((p) => p.amount != null)?.amount;

  const calculateNextAmount = (previousAmount, { growth, growthType }) => {
    if (growthType === null) return previousAmount;
    if (growthType === "linear") return trim(previousAmount + growth);
    return trim(previousAmount * (1 + growth));
  };

  return periods.reduce(
    (
      data,
      {
        start: periodStartDate,
        end: periodEndDate,
        amount: periodSetStartAmount,
        growth: periodGrowth,
        growthType: periodGrowthType,
      },
      periodIndex
    ) => {
      const calculatePeriodDatumAmount = (prevAmount, growth) =>
        calculateNextAmount(prevAmount, {
          growth,
          growthType: periodGrowthType,
        });

      const flattenedPeriodGrowth = flattenGrowth(periodGrowth);
      const periodDeviation = calculateDeviation(periodGrowth);

      const previousPeriod = periods[periodIndex - 1];
      const previousPeriodEndDatum = data.slice(-1)[0] ?? null;
      const periodStartAmount =
        periodSetStartAmount ??
        calculateNextAmount(previousPeriodEndDatum.amount, {
          growthType: previousPeriod.growthType,
          growth: flattenGrowth(previousPeriod.growth),
        });

      const periodStartDatum = {
        date: periodStartDate,
        amount: periodStartAmount,
        amountRange:
          periodSetStartAmount != null
            ? [periodSetStartAmount, periodSetStartAmount]
            : // If we don't have a set start amount for this period, we need
              // to calculate one from the incoming data
              [
                {
                  amount: previousPeriodEndDatum.amountRange[0],
                  growth:
                    flattenGrowth(previousPeriod.growth) -
                    calculateDeviation(previousPeriod.growth),
                },
                {
                  amount: previousPeriodEndDatum.amountRange[1],
                  growth:
                    flattenGrowth(previousPeriod.growth) +
                    calculateDeviation(previousPeriod.growth),
                },
              ].map(({ amount, growth }) =>
                calculateNextAmount(amount, {
                  growthType: previousPeriod.growthType,
                  growth,
                })
              ),
      };

      const periodData = unfold(
        (
          { date: prevDate, amount: prevAmount, amountRange: prevAmountRange },
          list
        ) => {
          if (prevDate == null || prevAmount == null) throw new Error();

          // TODO: Hard stop at 5 years for now
          if (list.length >= 12 * 5) return null;

          const nextDate = getNextDate(prevDate);

          if (periodEndDate != null && periodEndDate < nextDate) return null;

          return {
            date: nextDate,
            amount: calculatePeriodDatumAmount(
              prevAmount,
              flattenedPeriodGrowth
            ),
            amountRange: [
              // High
              calculatePeriodDatumAmount(
                prevAmountRange[0],
                flattenedPeriodGrowth - periodDeviation
              ),
              // Low
              calculatePeriodDatumAmount(
                prevAmountRange[1],
                flattenedPeriodGrowth + periodDeviation
              ),
            ],
          };
        },
        periodStartDatum
      );

      return [...data, ...periodData];
    },
    []
  );
});
