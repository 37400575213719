import React, { useEffect } from "react";

import { Route } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userActions } from "./actions/user.actions";
import Landing from "./Landing";
import ScrollToTop from "./components/ScrollToTop";
import Thanks from "./components/Thanks";
import LoginComponent from "./components/auth/Login";
// import RegisterComponent from "./components/auth/Register";
import ResetPasswordComponent from "./components/auth/ResetPassword";
import LogoutComponent from "./components/auth/Logout";
import RegisterOnboardingComponent from "./components/auth/RegisterOnboarding";
import RegisterProfileComponent from "./components/auth/RegisterProfile";
import ReactGA from "react-ga";
import RunwayMatrixLanding from "./components/RunwayMatrixLanding";
import LoggedInRoot from "./components/new_dashboard/Index";

const Routes = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authentication.loggedIn);

  useEffect(() => {
    dispatch(userActions.fetchUser());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <ScrollToTop>
      <Route
        exact
        path="/"
        render={(props) => (
          <Landing {...props} isAuthenticatedUser={isLoggedIn} />
        )}
      />
      <Route exact path="/runway-matrix" component={RunwayMatrixLanding} />

      <Route exact path="/success" component={Thanks} />

      <Route exact path="/login" component={LoginComponent} />
      <Route exact path="/logout" component={LogoutComponent} />

      <Route exact path="/reset" component={ResetPasswordComponent} />

      <Route
        exact
        path="/changelog"
        component={() => {
          window.location.href =
            "https://www.notion.so/tryobvious/Obvious-changelog-5454bb41f16f49678faa9a7e0feecbcb";
          return null;
        }}
      />

      {/* <Route exact path="/register" component={RegisterComponent} /> */}
      <Route
        exact
        path="/register"
        component={() => {
          window.location.href = "https://tryobvious.typeform.com/to/UqZcnYfn";
          return null;
        }}
      />

      <Route exact path="/welcome" component={RegisterOnboardingComponent} />

      <Route
        exact
        path="/welcome/profile"
        component={RegisterProfileComponent}
      />

      <Route path="/dashboard" component={LoggedInRoot} />
    </ScrollToTop>
  );
};

export default Routes;
