import React from "react";
import Text from "./Text";

const InlineTag = ({ dark, children }) => (
  <Text
    weight="700"
    style={{
      background: dark ? "rgb(255 255 255 / 10%)" : "rgb(0 0 0 / 10%)",
      borderRadius: "0.3rem",
      padding: "0 0.4rem 0.1rem",
    }}
  >
    {children}
  </Text>
);

export default InlineTag;
