
const initialState = {
  term: "",
  fromDate: null,
  toDate: null,
  sorting: "-date"
}

export function searchFilters(state = initialState, action) {
  switch (action.type) {
    case "SET_SEARCH_FILTERS":
      return Object.assign({}, state, {
        term: action.searchTerm,
        fromDate: action.fromDate,
        toDate: action.toDate
      })
    case "RESET_SEARCH_FILTERS":
      return initialState
    case "SET_SORTING":
      return Object.assign({}, state, {
        sorting: action.sorting
      })
    default:
      return state
  }
}