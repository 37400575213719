import { combineReducers } from "redux";

const disabledAccountIds = (state = [], action) => {
  switch (action.type) {
    case "toggle-enable-bank-account":
      return state.includes(action.id)
        ? state.filter((id) => id !== action.id)
        : [...state, action.id];
    default:
      return state;
  }
};

export default combineReducers({ disabledAccountIds });
