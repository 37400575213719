import { css } from "emotion";
import React from "react";
import { GREEN_TEXT } from "../constants/colors";
import { title, humanReadable } from "../utils/string";
import { update as updateQueryString } from "../utils/query-string";
import Text from "./Text";
import Icon from "./Icon";
import FormattedCurrency from "./FormattedCurrency";
import FormattedDate from "./FormattedDate";
import VendorIcon from "./VendorIcon";
import CategoryTag from "./CategoryTag";
import ActionableCategoryTag from "./ActionableCategoryTag";
import FilterableListbox from "./FilterableListbox";

const VendorMenu = ({ vendor, history, location, ...props }) => {
  const actionsByName = {
    filter: {
      label: (
        <div style={{ whiteSpace: "nowrap" }}>
          Show all <Text bold>{vendor.description}</Text> transactions
        </div>
      ),
      stringLabel: `Show all ${vendor.description} transactions`,
      dispatch() {
        history.push({
          pathname: "/dashboard/transactions",
          search: updateQueryString(location.search, {
            vendors: vendor.id,
          }),
        });
      },
    },
    "show-analysis": {
      label: "Show vendor analysis",
      dispatch() {
        history.push(`/dashboard/vendors/${vendor.id}`);
      },
    },
  };

  const defaultOptions = ["filter", "show-analysis"]
    .filter(Boolean)
    .map((name) => {
      const action = actionsByName[name];
      return {
        value: name,
        label: action.label,
        stringLabel: action.stringLabel,
        stayOpen: action.stayOpen,
      };
    });
  return (
    <FilterableListbox
      dark
      filterable={false}
      onSelect={(actionName) => actionsByName[actionName].dispatch()}
      options={defaultOptions}
      {...props}
    />
  );
};

const defaultColumnConfig = [
  {
    render: (t, { location, history, vendors }) => {
      const hasVendor = t.merchant != null && t.merchant !== "";
      const knownVendor = vendors.find((v) => v.id === t.merchant);

      const props = {
        size: "3rem",
        vendor: knownVendor ?? {
          id: t.merchant,
          description: t.merchant || t.description,
        },
      };

      if (hasVendor)
        return (
          <VendorMenu
            vendor={props.vendor}
            history={history}
            location={location}
            renderButton={({ props: buttonProps }) => (
              <VendorIcon
                {...buttonProps}
                {...props}
                component="button"
                style={{ cursor: "pointer" }}
              />
            )}
          />
        );

      if (t.status === "reserved")
        return (
          <VendorIcon {...props}>
            <Icon name="reserved" size="1.6rem" style={{ margin: "auto" }} />
          </VendorIcon>
        );

      return <VendorIcon {...props} />;
    },
    width: "3rem",
  },
  {
    label: "Item description",
    render: (t, { vendors, location, history }) => {
      const hasVendor = t.merchant != null && t.merchant !== "";
      const knownVendor = vendors.find((v) => v.id === t.merchant);

      const humanReadableVendor =
        knownVendor?.description ?? humanReadable(t.merchant ?? "");
      const humanReadableTransactionDescription = title(t.description);

      const showTransactionDescription =
        !hasVendor ||
        humanReadableVendor?.toLowerCase() !==
          humanReadableTransactionDescription?.toLowerCase();

      return (
        <div
          className={css({
            display: "inline-flex",
            flexWrap: "wrap",
            lineHeight: 1.3,
            maxWidth: "100%",
            overflow: "hidden",
          })}
        >
          {hasVendor && (
            <VendorMenu
              vendor={{ id: t.merchant, description: humanReadableVendor }}
              history={history}
              location={location}
              renderButton={({ props: buttonProps }) => (
                <Text
                  component="button"
                  weight="700"
                  truncate
                  {...buttonProps}
                  style={{
                    cursor: "pointer",
                    marginRight: "0.8rem",
                    ":hover": { opacity: 0.7 },
                  }}
                >
                  {humanReadableVendor}
                </Text>
              )}
            />
          )}
          {showTransactionDescription && (
            <Text
              weight={hasVendor ? "500" : "700"}
              color={hasVendor ? "rgba(0,0,0,0.54)" : ""}
              truncate
            >
              {humanReadableTransactionDescription}
            </Text>
          )}
        </div>
      );
    },
    sort: "description",
    defaultOrder: "asc",
    width: "minmax(12rem,2fr)",
  },
  {
    label: "Date",
    render: (t) =>
      t.status === "reserved" ? (
        <div className={css({ opacity: 0.5 })}>
          <FormattedDate
            value={new Date(t.date * 1000)}
            year="numeric"
            month="short"
            day="numeric"
          />
        </div>
      ) : (
        <FormattedDate
          value={new Date(t.date * 1000)}
          year="numeric"
          month="short"
          day="numeric"
        />
      ),
    sort: "date",
    defaultOrder: "desc",
    width: "minmax(10rem,1fr)",
  },
  {
    label: "Bank account",
    render: (t) => (
      <Text
        block
        truncate
        lineHeight={1.3}
        style={{ opacity: t.status === "reserved" ? 0.5 : 1 }}
      >
        {t.bankAccount}
      </Text>
    ),
    width: "minmax(10rem,1fr)",
  },
  {
    label: "Category",
    sort: "category",
    defaultOrder: "asc",
    render: (t, { categories, updateTransaction, history, location }) => {
      if (t.status === "reserved")
        return (
          <CategoryTag
            label="pending"
            color="#fff"
            style={{ color: "#EB9515", border: "1px solid #EB9515" }}
          />
        );

      const category = categories.find((c) => c.id === t.category);

      return (
        <ActionableCategoryTag
          category={category}
          categories={categories}
          updateCategory={(categoryId) =>
            updateTransaction(t.uuid, { category: categoryId })
          }
          history={history}
          location={location}
        />
      );
    },
    width: "minmax(10rem,1.4fr)",
  },
  {
    label: "Amount",
    render: (t) =>
      t.status === "booked" ? (
        <Text
          weight="700"
          truncate
          color={t.amount > 0 ? GREEN_TEXT : undefined}
        >
          {t.amount > 0 && "+"}
          <FormattedCurrency value={Math.abs(t.amount)} currency={t.currency} />
        </Text>
      ) : (
        <Text weight="700" truncate color="rgb(0 0 0 / 54%)">
          ±
          <FormattedCurrency value={Math.abs(t.amount)} currency={t.currency} />
        </Text>
      ),
    sort: "amount",
    defaultOrder: "desc",
    align: "right",
    width: "minmax(12rem,1fr)",
  },
];

const TransactionList = ({
  transactions,
  size = 0,
  columns = defaultColumnConfig,
  header = false,
  sort,
  sortProperty,
  sortOrder,
  vendors,
  categories,
  updateTransaction,
  location,
  history,
}) => {
  return (
    <>
      {header && (
        <Header
          columns={columns}
          sort={sort}
          sortProperty={sortProperty}
          sortOrder={sortOrder}
        />
      )}

      <ul>
        {transactions.length === 0
          ? Array.from({ length: size }).map((_, i) => (
              <li
                key={i}
                className={css({
                  padding: "0.3rem 1rem",
                  ":not(:first-of-type)": { marginTop: "0.4rem" },
                })}
              >
                <PlaceholderListItem columns={columns} />
              </li>
            ))
          : transactions.map((t) => (
              <li key={t.id}>
                <div
                  className={css({
                    display: "block",
                    padding: "0.5rem 1rem",
                    color: "currentColor",
                    cursor: "auto",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.025) 30%, rgba(0,0,0,0.025) 70%, rgba(0,0,0,0) 100%)",
                    },
                  })}
                >
                  <div
                    className={css({
                      display: "grid",
                      gap: "1.4rem",
                      alignItems: "center",
                    })}
                    style={{
                      gridTemplateColumns: columns
                        .map((c) => c.width)
                        .join(" "),
                    }}
                  >
                    {columns.map(({ render, align }, i) => (
                      <div
                        key={i}
                        className={css({
                          textAlign: align || "left",
                          ":hover": { position: "relative", zIndex: 2 },
                        })}
                      >
                        {render(t, {
                          vendors,
                          categories,
                          updateTransaction,
                          location,
                          history,
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </li>
            ))}
      </ul>
    </>
  );
};

const Header = ({ columns, sortProperty, sortOrder, sort }) => (
  <div
    className={css({
      display: "grid",
      gap: "1.4rem",
      alignItems: "flex-end",
      padding: "1.2rem 1rem",
    })}
    style={{ gridTemplateColumns: columns.map((c) => c.width).join(" ") }}
  >
    {columns.map(
      ({ label, sort: columnSortProperty, defaultOrder, align }, i) => (
        <div
          key={label || i}
          className={css({
            justifySelf: align === "right" ? "flex-end" : undefined,
          })}
        >
          {columnSortProperty ? (
            <button
              key={label}
              onClick={() =>
                sort({
                  property: columnSortProperty,
                  order:
                    sortProperty !== columnSortProperty
                      ? defaultOrder
                      : sortOrder === "asc"
                      ? "desc"
                      : "asc",
                })
              }
              className={css({
                display: "inline-flex",
                alignItems: "center",
                // Put the sorting arrow to the left for right aligned columns
                flexDirection: align === "right" ? "row-reverse" : "row",
                cursor: "pointer",
                textAlign: "left",
              })}
            >
              <Text variant="label" lineHeight={1.5}>
                {label}
              </Text>
              {columnSortProperty && (
                <ArrowDown
                  style={{
                    width: "1.4rem",
                    marginLeft: "0.3rem",
                    transform: `rotate(${sortOrder === "desc" ? 0 : "180deg"})`,
                    opacity: sortProperty === columnSortProperty ? 1 : 0,
                  }}
                />
              )}
            </button>
          ) : label ? (
            <Text variant="label" lineHeight={1.5}>
              {label}
            </Text>
          ) : null}
        </div>
      )
    )}
  </div>
);
const PlaceholderListItem = ({ columns }) => {
  const columnSizes = React.useMemo(
    () => Array.from({ length: columns.length - 1 }).map(Math.random),
    [columns.length]
  );

  return (
    <div
      className={css({ display: "grid", gap: "1.4rem", alignItems: "center" })}
      style={{ gridTemplateColumns: columns.map((c) => c.width).join(" ") }}
    >
      <div
        className={css({
          background: "#eee",
          height: "3rem",
          width: "3rem",
          borderRadius: "50%",
        })}
      />
      {[
        `calc(7rem + ${columnSizes[0]} * 7rem)`,
        columnSizes[1] > 0.8 ? "7rem" : "6rem",
        `calc(8rem + ${columnSizes[2]} * 1rem)`,
        `calc(8rem + ${columnSizes[3]} * 1rem)`,
      ].map((width, i) => (
        <div
          key={i}
          className={css({
            background: "#eee",
            height: "2.2rem",
            borderRadius: "0.5rem",
            maxWidth: "100%",
          })}
          style={{ width }}
        />
      ))}
      <div className={css({ display: "flex", justifyContent: "flex-end" })}>
        <div
          className={css({
            background: "#eee",
            height: "2.2rem",
            borderRadius: "0.5rem",
            width: `calc(7rem + ${columnSizes[3]} * 4rem)`,
            maxWidth: "100%",
          })}
        />
      </div>
    </div>
  );
};

const ArrowDown = (props) => (
  <svg viewBox="0 0 24 24" aria-hidden="true" role="presentation" {...props}>
    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
  </svg>
);

export default TransactionList;
