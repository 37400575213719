import { css } from "emotion";
import React from "react";
import { PRIMARY_COLOR, GREEN_TEXT } from "../constants/colors";
import useStore from "../hooks/stores";
import usePlaid from "../hooks/plaid";
import Box from "./Box";
import Text from "./Text";
import Divider from "./Divider";
import Icon from "./Icon";
import Button from "./Button";
import Modal from "./Modal";
import BankSelectorModal from "./BankSelectorModal";

const Onboarding = ({ user }) => {
  const [_, { fetchAiiaConnectionUrl }] = useStore("bank-accounts"); // eslint-disable-line no-unused-vars

  const [bankSelectorVisible, setBankSelectorVisible] = React.useState(false);
  const closeBankSelector = React.useCallback(
    () => setBankSelectorVisible(false),
    []
  );

  const navigateToAiiaExternalConnectionFlow = () =>
    fetchAiiaConnectionUrl().then(({ url }) => (window.location.href = url));

  const {
    openConnectionUI: openPlaidConnectionUI,
    // error, // TODO: React to error
  } = usePlaid({
    onSuccess: bankSelectorVisible ? closeBankSelector : undefined,
  });
  return (
    <>
      <Text block variant="headline-large" margin="0 0 2rem">
        Welcome {user.first_name}, let's get you set up!
      </Text>

      <Text variant="paragraph" size="1.6rem">
        You're a few clicks away from daily insights, and effortless control of
        your finances.
      </Text>

      <Divider size="3rem" />

      <Box border={PRIMARY_COLOR}>
        <Box>
          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
              gridGap: "5rem",
              alignItems: "stretch",
            })}
          >
            {[
              { label: "Sign up", fraction: 1 },
              { label: "Connect your bank(s)", fraction: 0.15 },
              { label: "Done", fraction: 0 },
            ].map(({ label, fraction }, i) => (
              <div
                key={i}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                })}
              >
                <Text
                  block
                  variant="label-light"
                  margin="0 0 1rem"
                  lineHeight={1.2}
                  style={{ display: "flex" }}
                >
                  <div>{i + 1}.&nbsp;</div>
                  <div>
                    {label}
                    {fraction === 1 && (
                      <div
                        className={css({
                          display: "inline-block",
                          marginLeft: "0.5rem",
                        })}
                      >
                        <Icon
                          name="checkmark"
                          size="1.2rem"
                          color={GREEN_TEXT}
                        />
                      </div>
                    )}
                  </div>
                </Text>
                <ProgressBar
                  background={
                    fraction === 1
                      ? "rgba(31, 190, 123, 0.2)"
                      : "rgba(54, 94, 235, 0.2)"
                  }
                  color={
                    fraction === 1 ? "rgba(31, 190, 123, 1)" : PRIMARY_COLOR
                  }
                  fraction={fraction}
                />
              </div>
            ))}
          </div>
        </Box>

        <Divider size="4.5rem" />

        <Text
          variant="headline"
          component="h1"
          size="1.8rem"
          margin="0 0 1.4rem"
        >
          Connect your primary bank
        </Text>
        <Text variant="paragraph" component="p" size="1.8rem" margin="0 0 3rem">
          It’s often the bank you use to pay salaries, issue credit cards, or
          receive funding.
        </Text>

        <div
          className={css({
            display: "grid",
            gridTemplateColumns: "auto auto",
            gridGap: "2rem",
            justifyContent: "flex-start",
          })}
        >
          <Button
            variant="primary"
            size="large"
            onClick={() => setBankSelectorVisible(true)}
          >
            Connect bank accounts
          </Button>
        </div>

        <Modal
          isOpen={bankSelectorVisible}
          onDismiss={closeBankSelector}
          label="Select Region"
        >
          {bankSelectorVisible && (
            <BankSelectorModal
              dismiss={closeBankSelector}
              navigateToAiiaExternalConnectionFlow={
                navigateToAiiaExternalConnectionFlow
              }
              openPlaidConnectionUI={openPlaidConnectionUI}
            />
          )}
        </Modal>
      </Box>
    </>
  );
};

const ProgressBar = ({
  color,
  background,
  fraction,
  width = "100%",
  height = "0.5rem",
}) => (
  <div className={css({ width, background })}>
    <div
      className={css({
        transformOrigin: "0 50%",
        transform: `scaleX(${fraction})`,
        width: "100%",
        height,
        background: color,
        transition: "0.1s transform",
      })}
    />
  </div>
);

export default Onboarding;
