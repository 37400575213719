import React from "react";
import { sort } from "../utils/array";
import { ascending } from "../utils/comparators";
import { update as updateQueryString } from "../utils/query-string";
import { isBrowsable as isBrowsableCategory } from "../utils/categories";
import CategoryTag from "./CategoryTag";
import FilterableListbox from "./FilterableListbox";

const ActionableCategoryTag = ({
  category,
  categories,
  updateCategory,
  history,
  location,
}) => {
  const [isCategorizing, setIsCategorizing] = React.useState(false);
  const mode = isCategorizing ? "categorize" : "default";

  const actionsByName = {
    filter: {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <div style={{ marginRight: "1rem" }}>Show all</div>
          <CategoryTag
            label={category?.description ?? "Unknown"}
            color="rgb(255 255 255 / 10%)"
            style={{ color: "white" }}
          />
        </div>
      ),
      stringLabel: `Show all ${categories?.description}`,
      dispatch() {
        history.push({
          pathname: "/dashboard/transactions",
          search: updateQueryString(location.search, {
            categories: category?.id ?? "unknown",
          }),
        });
      },
    },
    categorize: {
      label: "Change category...",
      stayOpen: true,
      dispatch() {
        setIsCategorizing(true);
      },
    },
    "show-analysis": {
      label: "Show category analysis",
      dispatch() {
        history.push(`/dashboard/categories/${category.id}`);
      },
    },
  };

  const defaultOptions = [
    "filter",
    isBrowsableCategory(category) ? "show-analysis" : null,
    "categorize",
  ]
    .filter(Boolean)
    .map((name) => {
      const action = actionsByName[name];
      return {
        value: name,
        label: action.label,
        stringLabel: action.stringLabel,
        stayOpen: action.stayOpen,
      };
    });

  return (
    <FilterableListbox
      dark
      filterable={mode === "categorize"}
      filterInputPlaceholder="Categorize as..."
      value={mode === "categorize" ? category?.id : null}
      onSelect={
        mode === "categorize"
          ? (categoryId) => updateCategory(categoryId)
          : (actionName) => actionsByName[actionName].dispatch()
      }
      options={
        mode === "categorize"
          ? [
              { value: "unknown", label: "Unknown" },
              ...sort(
                (o1, o2) => ascending(o1.label, o2.label),
                categories.map((c) => ({
                  value: c.id,
                  label: c.description,
                }))
              ),
            ]
          : defaultOptions
      }
      renderButton={({ props }) => (
        <CategoryTag
          component="button"
          type="button"
          {...props}
          label={category?.description ?? "Unknown"}
          color="#eee"
          style={{ cursor: "pointer" }}
        />
      )}
      onClose={() => setIsCategorizing(false)}
    />
  );
};

export default ActionableCategoryTag;
