import { userActions } from "../actions/user.actions";
import Axios from "axios";
import { ApiUrl } from "../utils/constants";
import queryString from "query-string";
import store from "../store";

export function handleErrorResponse(error) {
  let response = error.response;
  if (response && response.status === 401) {
    // auto logout if 401 response returned from api
    userActions.logout();
  }

  return Promise.reject(error);
}

export const apiInstance = Axios.create({
  baseURL: ApiUrl,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
  paramsSerializer: function (params) {
    return queryString.stringify(params);
  },
  /* other custom settings */
});

apiInstance.interceptors.request.use(
  function (config) {
    let state = store.getState();
    if (state.authentication) {
      const companyUuid = state.companies.currentUuid;
      if (companyUuid) config.headers["Company-Id"] = `${companyUuid}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
