import React from "react";
import useStore from "../hooks/stores";
import { createDefaultScenarioProperties } from "./scenarios/Index";

const ScenarioListing = ({ history, categories }) => {
  const [_, { fetchAll, create }] = useStore("scenarios"); // eslint-disable-line

  React.useEffect(() => {
    fetchAll().then((response) => {
      const scenarios = response.data;

      if (scenarios.length > 0) {
        history.push(`/dashboard/runway/${scenarios[0].uuid}`);
        return;
      }

      create(createDefaultScenarioProperties({ categories })).then((response) =>
        history.push(`/dashboard/runway/${response.uuid}`)
      );
    });
  }, [fetchAll, create, history, categories]);

  return null;
};

export default ScenarioListing;
