export const getIconSourceFromVendorId = (vendorId) => {
  if (!vendorId) return null;
  return getIconSource("merchants", vendorId);
};

export const getIconSourceFromBankId = (bankId) => {
  if (!bankId) return null;
  return getIconSource("banks", "bank-" + bankId);
};

const getIconSource = (kind, id) => {
  let path;
  try {
    path = require(`../assets/logos/${kind}/${id}.svg`);
  } catch (e) {
    try {
      path = require(`../assets/logos/${kind}/${id}.png`);
    } catch (e) {}
  }
  return path;
};
