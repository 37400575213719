import startOfMonth from "date-fns/startOfMonth";
import subtractWeeks from "date-fns/subWeeks";
import addWeeks from "date-fns/addWeeks";
import endOfMonth from "date-fns/endOfMonth";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PRIMARY_COLOR } from "../constants/colors";
import { humanReadable } from "../utils/string";
import { sort, unfold, fit } from "../utils/array";
import { descending } from "../utils/comparators";
import useStore from "../hooks/stores";
import OverlayCard from "./OverlayCard";
import Select from "./Select";
import FormattedDate from "./FormattedDate";
import FormattedCurrency from "./FormattedCurrency";
import FormattedNumber from "./FormattedNumber";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import Text from "./Text";
import Divider from "./Divider";
import VendorIcon from "./VendorIcon";
import Tooltip from "./Tooltip";
import Icon from "./Icon";
import Section from "./PageSubSection";
import TransactionList from "./TransactionList";
import PageContainer from "./PageContainer";
import { ascending } from "d3-array";

const AnalysisPage = ({
  title,
  selectedOption,
  options,
  onSelectOption,
  isIncomeQuery,
  history,
  location,
  range: [from, to],
  queriedAmounts,
  queriedVendorAmounts,
  totalAmounts,
  transactions,
  totalTransactionCount,
  createTransactionListLink,
  noDataPlaceholder,
}) => {
  const { defaultCurrency: currency } = useStore("settings");
  const categories = useStore("categories");
  const vendors = useStore("vendors");

  const sum = (data, getValue = (d) => d.value) => {
    if (data == null) return null;
    return data.reduce((sum, d) => sum + getValue(d), 0);
  };

  const avarage = (data) => {
    if (data == null || data.length === 0) return null;
    return sum(data) / data.length;
  };

  // TODO most recent full months
  const queriedRollingAvarage = avarage(queriedAmounts?.slice(-3));
  const totalRollingAvarage = avarage(totalAmounts?.slice(-3));

  const cumulativeQueriedAmounts = queriedAmounts?.reduce((data, d) => {
    const cumAmount = data.slice(-1)[0]?.value ?? 0;
    return [...data, { date: d.date, value: cumAmount + d.value }];
  }, []);

  const queriedPeriodTotal = sum(queriedAmounts);

  const sortedVendors =
    queriedVendorAmounts == null
      ? null
      : sort(
          (v1, v2) => descending(v1.value, v2.value),
          queriedVendorAmounts.map((v) => ({
            ...v,
            fraction: v.value / sum(queriedVendorAmounts),
            description: vendors.find(({ id }) => v.id === id)?.description,
          }))
        );

  return (
    <PageContainer
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem 2rem",
          }}
        >
          <Select
            renderTrigger={(_, isHovered) => (
              <Text
                variant="headline-small"
                component="h2"
                color={isHovered ? "rgb(0 0 0 / 54%)" : "black"}
                style={{
                  display: "inline-flex",
                  transition: "0.15s color",
                  alignItems: "center",
                  padding: "0.2rem 0",
                  lineHeight: 1.3,
                }}
              >
                <span style={{ marginRight: "0.6rem" }}>
                  {title ?? selectedOption.label}
                </span>
                <Icon
                  name="caret-up"
                  style={{ transform: "scaleY(-1) translateY(-0.1rem)" }}
                />
              </Text>
            )}
            value={selectedOption.value}
            options={sort((c1, c2) => ascending(c1.label, c2.label), options)}
            onChange={(e) => onSelectOption(e.target.value)}
          />
        </div>
      }
    >
      {transactions == null || queriedAmounts == null ? (
        <div /> // Loading state
      ) : transactions.length === 0 || queriedAmounts.length === 0 ? (
        // TODO
        noDataPlaceholder
      ) : (
        <>
          <Section title="Overview">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(0,1fr))",
                alignItems: "flex-start",
                gridGap: "4rem",
                paddingTop: "2rem",
              }}
            >
              {[
                {
                  label: `Avg monthly ${isIncomeQuery ? "in" : "spend"}`,
                  content:
                    queriedRollingAvarage == null ? null : (
                      <Text block variant="number-large">
                        <FormattedCurrency
                          value={queriedRollingAvarage}
                          currency={currency}
                        />
                      </Text>
                    ),
                  tooltip:
                    queriedAmounts == null ? null : (
                      <Text
                        block
                        color="rgb(255 255 255 / 60%)"
                        weight="500"
                        lineHeight={1.3}
                      >
                        <Text block color="white">
                          {selectedOption.label} monthly{" "}
                          {isIncomeQuery ? "money in" : "spend"}
                        </Text>
                        3 months rolling avarage
                      </Text>
                    ),
                },
                {
                  label: `Avg ratio of total ${isIncomeQuery ? "in" : "spend"}`,
                  content: (() => {
                    if (queriedAmounts == null || totalAmounts == null)
                      return null;

                    if (totalRollingAvarage === 0) return "-";

                    const avarageRatio =
                      queriedRollingAvarage / totalRollingAvarage;

                    return (
                      <Text block variant="number-large">
                        <FormattedNumber
                          percent
                          value={avarageRatio}
                          minimumFractionDigits={1}
                          maximumFractionDigits={2}
                        />
                      </Text>
                    );
                  })(),
                  tooltip:
                    queriedAmounts == null ? null : (
                      <Text
                        block
                        color="rgb(255 255 255 / 60%)"
                        weight="500"
                        lineHeight={1.3}
                      >
                        <Text block color="white">
                          {selectedOption.label} ratio of{" "}
                          {isIncomeQuery ? "total money in" : "total spend"}
                        </Text>
                        3 months rolling avarage
                      </Text>
                    ),
                },
                // Hiding this section if we don't have  any identified
                // "important" (fraction > 0.05) vendors
                sortedVendors != null &&
                sortedVendors.filter(
                  (c) => c.id !== "unknown" && c.fraction >= 0.05
                ).length === 0
                  ? null
                  : {
                      label:
                        queriedVendorAmounts == null
                          ? null
                          : "Important vendors",
                      content: (() => {
                        if (queriedVendorAmounts == null) return null;

                        const [
                          importantVendors,
                          restVendors,
                        ] = sortedVendors.reduce(
                          ([importantVendors, restVendors], v) => {
                            if (v.fraction >= 0.05 && v.id !== "unknown")
                              return [[...importantVendors, v], restVendors];
                            return [importantVendors, [...restVendors, v]];
                          },
                          [[], []]
                        );

                        return (
                          <Tooltip
                            label={
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, max-content)",
                                  gridGap: "0.8rem 1rem",
                                }}
                              >
                                {importantVendors.map((v) => (
                                  <React.Fragment key={v.id}>
                                    <div>
                                      {v.description ?? humanReadable(v.id)}
                                    </div>
                                    <Text color="rgb(255 255 255 / 60%)">
                                      <FormattedNumber
                                        percent
                                        value={
                                          v.value / sum(queriedVendorAmounts)
                                        }
                                        maximumFractionDigits={2}
                                      />
                                    </Text>
                                    <Text color="rgb(255 255 255 / 60%)">
                                      <FormattedCurrency
                                        value={v.value}
                                        currency={currency}
                                      />
                                    </Text>
                                  </React.Fragment>
                                ))}
                                {restVendors.length !== 0 && (
                                  <>
                                    <Text color="rgb(255 255 255 / 60%)">
                                      Rest
                                    </Text>
                                    <Text color="rgb(255 255 255 / 60%)">
                                      <FormattedNumber
                                        percent
                                        value={1 - sum(importantVendors)}
                                      />
                                    </Text>
                                    <Text color="rgb(255 255 255 / 60%)">
                                      <FormattedCurrency
                                        value={sum(restVendors)}
                                        currency={currency}
                                      />
                                    </Text>
                                  </>
                                )}
                              </div>
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                margin: "-0.5rem 0 0 -0.5rem",
                              }}
                            >
                              {importantVendors
                                .filter((v) => v.id !== "unknown")
                                .map((v) => (
                                  <RouterLink
                                    key={v.id}
                                    to={`/dashboard/vendors/${v.id}`}
                                    style={{ margin: "0.5rem 0 0 0.5rem" }}
                                  >
                                    <VendorIcon
                                      vendor={v}
                                      size="3rem"
                                      noBorder
                                    />
                                  </RouterLink>
                                ))}
                            </div>
                          </Tooltip>
                        );
                      })(),
                    },
                {
                  label: `Last month ${isIncomeQuery ? "in" : "spend"}`,
                  content: (() => {
                    if (queriedAmounts == null || queriedAmounts.length === 0)
                      return null;
                    const value = queriedAmounts.slice(-1)[0]?.value;
                    return (
                      <Text block variant="number-large">
                        <FormattedCurrency value={value} currency={currency} />
                      </Text>
                    );
                  })(),
                  tooltip:
                    queriedAmounts == null ? null : (
                      <Text
                        block
                        color="rgb(255 255 255 / 60%)"
                        weight="500"
                        lineHeight={1.3}
                      >
                        {isIncomeQuery ? "Money in" : "Spend"} between{" "}
                        <Text color="white">
                          <FormattedDate
                            value={startOfMonth(to)}
                            month="short"
                            day="numeric"
                          />
                        </Text>{" "}
                        and{" "}
                        <Text color="white">
                          <FormattedDate
                            value={endOfMonth(to)}
                            month="short"
                            day="numeric"
                          />
                        </Text>
                      </Text>
                    ),
                },
                {
                  label: `12 months ${isIncomeQuery ? "in" : "spend"}`,
                  content:
                    queriedAmounts == null ? null : (
                      <Text block variant="number-large">
                        <FormattedCurrency
                          value={queriedPeriodTotal}
                          currency={currency}
                        />
                      </Text>
                    ),
                  tooltip:
                    queriedAmounts == null ? null : (
                      <Text
                        block
                        color="rgb(255 255 255 / 60%)"
                        weight="500"
                        lineHeight={1.3}
                      >
                        {isIncomeQuery ? "Money in" : "Spend"} between{" "}
                        <Text color="white">
                          <FormattedDate
                            value={startOfMonth(from)}
                            month="short"
                            day="numeric"
                            year="numeric"
                          />
                        </Text>{" "}
                        and{" "}
                        <Text color="white">
                          <FormattedDate
                            value={endOfMonth(to)}
                            month="short"
                            day="numeric"
                            year="numeric"
                          />
                        </Text>
                      </Text>
                    ),
                },
                {
                  label: "No. of transactions",
                  content:
                    totalTransactionCount == null ? null : (
                      <Text block variant="number-large">
                        {totalTransactionCount}
                      </Text>
                    ),
                  tooltip:
                    totalTransactionCount == null ? null : (
                      <Text color="rgb(255 255 255 / 60%)" weight="500">
                        All available transactions categorized as{" "}
                        {selectedOption.label}
                      </Text>
                    ),
                },
              ]
                .filter(Boolean)
                .map((props) => (
                  <MetricBlock key={props.label} {...props} />
                ))}
            </div>

            <Divider size="6rem" />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gridGap: "4rem",
              }}
            >
              {queriedAmounts != null && totalAmounts != null && (
                <>
                  <div>
                    <ChartBlock
                      title={
                        isIncomeQuery ? "Monthly net cash" : "Monthly spend"
                      }
                    >
                      <BarChart
                        data={[
                          {
                            data: queriedAmounts.map((d) => [d.date, d.value]),
                            color: PRIMARY_COLOR,
                          },
                        ]}
                        domain={([
                          // Destructured x and y extent
                          [firstDate, lastDate],
                          [minAmount, maxAmount],
                        ]) => [
                          [subtractWeeks(firstDate, 2), addWeeks(lastDate, 2)],
                          // Make sure we start at 0 when the min value is positive
                          [Math.min(minAmount, 0), maxAmount],
                        ]}
                        xTicks={queriedAmounts
                          .map((d) => d.date)
                          .filter((_, i) => i % 2 !== 0)}
                        renderXTick={(d) => (
                          <FormattedDate value={d} month="short" />
                        )}
                        createDatumLink={([date]) =>
                          createTransactionListLink({
                            from: startOfMonth(date),
                            to: endOfMonth(date),
                          })
                        }
                        renderHoverPopoverContent={(hoveredDate) => {
                          const categoryAmount = queriedAmounts.find(
                            ({ date }) =>
                              hoveredDate.getTime() === date.getTime()
                          ).value;

                          return (
                            <OverlayCard>
                              <Text
                                block
                                size="1.1rem"
                                color="rgb(255 255 255 / 60%)"
                                margin="0 0 0.7rem"
                              >
                                <FormattedDate
                                  value={hoveredDate}
                                  month="long"
                                  year="numeric"
                                />
                              </Text>
                              <Text block weight="700" size="1.4rem">
                                <FormattedCurrency
                                  value={categoryAmount}
                                  currency={currency}
                                />
                              </Text>
                            </OverlayCard>
                          );
                        }}
                      />
                    </ChartBlock>
                  </div>
                  <div>
                    <ChartBlock
                      title={
                        isIncomeQuery
                          ? "Monthly comparison with total money in"
                          : "Monthly comparison with total spend"
                      }
                    >
                      <BarChart
                        data={[
                          {
                            data: totalAmounts.map((d) => [d.date, d.value]),
                            color: "#ddd",
                          },
                          {
                            data: queriedAmounts.map((d) => [d.date, d.value]),
                            color: PRIMARY_COLOR,
                          },
                        ]}
                        domain={([
                          // Destructured x and y extent
                          [firstDate, lastDate],
                          [minAmount, maxAmount],
                        ]) => [
                          [subtractWeeks(firstDate, 2), addWeeks(lastDate, 2)],
                          // Make sure we start at 0 when the min value is positive
                          [Math.min(minAmount, 0), maxAmount],
                        ]}
                        xTicks={queriedAmounts
                          .map((d) => d.date)
                          .filter((_, i) => i % 2 !== 0)}
                        renderXTick={(d) => (
                          <FormattedDate value={d} month="short" />
                        )}
                        createDatumLink={([date]) =>
                          createTransactionListLink({
                            from: startOfMonth(date),
                            to: endOfMonth(date),
                          })
                        }
                        renderHoverPopoverContent={(hoveredDate) => {
                          const [categoryAmount, totalAmount] = [
                            queriedAmounts,
                            totalAmounts,
                          ].map(
                            (data) =>
                              data.find(
                                ({ date }) =>
                                  hoveredDate.getTime() === date.getTime()
                              )?.value ?? 0
                          );

                          const categoryFraction =
                            totalAmount === 0
                              ? null
                              : categoryAmount / totalAmount;

                          return (
                            <OverlayCard>
                              <Text
                                block
                                size="1.1rem"
                                color="rgb(255 255 255 / 60%)"
                                margin="0 0 0.7rem"
                              >
                                <FormattedDate
                                  value={hoveredDate}
                                  month="long"
                                  year="numeric"
                                />
                              </Text>
                              <Text block weight="700" size="1.4rem">
                                <FormattedCurrency
                                  value={categoryAmount}
                                  currency={currency}
                                />
                              </Text>
                              <Text
                                block
                                margin="0.5rem 0 0"
                                size="1.1rem"
                                color="rgb(255 255 255 / 60%)"
                              >
                                {categoryFraction != null ? (
                                  <>
                                    <FormattedNumber
                                      percent
                                      value={categoryFraction}
                                      minimumFractionDigits={1}
                                      maximumFractionDigits={2}
                                    />{" "}
                                    of total{" "}
                                    <FormattedCurrency
                                      value={totalAmount}
                                      currency={currency}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FormattedCurrency
                                      value={totalAmount}
                                      currency={currency}
                                    />{" "}
                                    total
                                  </>
                                )}
                              </Text>
                            </OverlayCard>
                          );
                        }}
                      />
                    </ChartBlock>
                  </div>

                  <div style={{ gridColumn: "span 1" }}>
                    <ChartBlock
                      title={
                        isIncomeQuery
                          ? `Ratio of total cash in`
                          : `Ratio of total spend`
                      }
                    >
                      {queriedAmounts.length === 0 ? null : (
                        <LineChart
                          data={[
                            {
                              color: PRIMARY_COLOR,
                              data: queriedAmounts.map((d) => {
                                const total = totalAmounts.find(
                                  ({ date }) =>
                                    date.getTime() === d.date.getTime()
                                ).value;
                                if (total === 0) return [d.date, 0];
                                return [d.date, d.value / total];
                              }),
                            },
                          ]}
                          domain={([x, y]) => [
                            x,
                            [Math.min(0, y[0]), Math.max(0.05, y[1])],
                          ]}
                          xTicks={queriedAmounts
                            .map((d) => d.date)
                            .filter((_, i) => i % 2 !== 0)}
                          yTicks={([yMin, yMax]) => {
                            // Doing the calculation in whole numbers to that
                            // we can use the modulo operator
                            const [yMinPercent, yMaxPercent] = [yMin, yMax].map(
                              (n) => n * 100
                            );

                            const step = yMaxPercent < 10 ? 2 : 5;
                            const start = yMinPercent - (yMinPercent % step);

                            const stepTicks = unfold((prev) => {
                              const next = prev + step;
                              return next > yMaxPercent ? null : next;
                            }, start);

                            const fittedStepTick = fit(
                              (ticks) => ticks.filter((_, i) => i % 2 === 0),
                              4,
                              stepTicks
                            );

                            return fittedStepTick.map((n) => n / 100);
                          }}
                          renderXTick={(d) => (
                            <FormattedDate value={d} month="short" />
                          )}
                          renderYTick={(n) => (
                            <FormattedNumber percent value={n} />
                          )}
                          renderHoverPopoverContent={(hoveredDate) => {
                            const [categoryAmount, totalAmount] = [
                              queriedAmounts,
                              totalAmounts,
                            ].map(
                              (data) =>
                                data.find(
                                  ({ date }) =>
                                    hoveredDate.getTime() === date.getTime()
                                )?.value
                            );

                            const categoryFraction =
                              categoryAmount / Math.abs(totalAmount);

                            return (
                              <OverlayCard>
                                <Text
                                  block
                                  size="1.1rem"
                                  color="rgb(255 255 255 / 60%)"
                                  margin="0 0 0.7rem"
                                >
                                  <FormattedDate
                                    value={hoveredDate}
                                    month="long"
                                    year="numeric"
                                  />
                                </Text>
                                <Text block weight="700" size="1.4rem">
                                  <FormattedNumber
                                    percent
                                    value={categoryFraction}
                                    minimumFractionDigits={1}
                                    maximumFractionDigits={2}
                                  />{" "}
                                  of total
                                </Text>
                                <Divider size="1rem" />
                                <Text
                                  block
                                  margin="0.5rem 0 0"
                                  size="1.1rem"
                                  color="rgb(255 255 255 / 60%)"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      "repeat(2, max-content)",
                                    gridGap: "0.5rem 2rem",
                                  }}
                                >
                                  <div>{selectedOption.label}</div>
                                  <FormattedCurrency
                                    value={categoryAmount}
                                    currency={currency}
                                  />
                                  <div>Total</div>
                                  <FormattedCurrency
                                    value={Math.abs(totalAmount)}
                                    currency={currency}
                                  />
                                </Text>
                                {categoryAmount !== 0 && (
                                  <>
                                    <Divider size="1rem" />
                                    <Text
                                      block
                                      margin="0.5rem 0 0"
                                      size="1.1rem"
                                      color="rgb(255 255 255 / 60%)"
                                    >
                                      <FormattedNumber
                                        value={categoryAmount}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                      />{" "}
                                      /{" "}
                                      <FormattedNumber
                                        value={Math.abs(totalAmount)}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                      />{" "}
                                      ={" "}
                                      <FormattedNumber
                                        value={categoryFraction}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                      />
                                    </Text>
                                  </>
                                )}
                              </OverlayCard>
                            );
                          }}
                        />
                      )}
                    </ChartBlock>
                  </div>

                  <div style={{ gridColumn: "span 1" }}>
                    <ChartBlock
                      title={
                        isIncomeQuery ? `Net cumulative` : `Cumulative spend`
                      }
                    >
                      <LineChart
                        data={[
                          {
                            data: cumulativeQueriedAmounts.map((datum) => [
                              datum.date,
                              datum.value,
                            ]),
                            color: PRIMARY_COLOR,
                            area: "rgb(54 94 235 / 10%)",
                          },
                        ]}
                        domain={([x, y]) => [x, [Math.min(0, y[0]), y[1]]]}
                        xTicks={cumulativeQueriedAmounts
                          .map((d) => d.date)
                          .filter((_, i) => i % 2 !== 0)}
                        renderXTick={(d) => (
                          <FormattedDate value={d} month="short" />
                        )}
                        renderHoverPopoverContent={(hoveredDate) => {
                          const cumulativeCategoryAmount = cumulativeQueriedAmounts.find(
                            ({ date }) =>
                              hoveredDate.getTime() === date.getTime()
                          )?.value;

                          return (
                            <OverlayCard>
                              <Text
                                block
                                size="1.1rem"
                                color="rgb(255 255 255 / 60%)"
                                margin="0 0 0.7rem"
                              >
                                <FormattedDate
                                  value={from}
                                  month="long"
                                  year="numeric"
                                />{" "}
                                to{" "}
                                <FormattedDate
                                  value={hoveredDate}
                                  month="long"
                                  year="numeric"
                                />
                              </Text>
                              <Text block weight="700" size="1.4rem">
                                <FormattedCurrency
                                  value={cumulativeCategoryAmount}
                                  currency={currency}
                                />
                              </Text>
                            </OverlayCard>
                          );
                        }}
                      />
                    </ChartBlock>
                  </div>
                </>
              )}
            </div>
          </Section>

          <Divider size="4rem" />

          <Section title="Recent transactions" padding={0}>
            <div style={{ padding: "1.5rem 1rem 0" }}>
              <TransactionList
                transactions={transactions}
                vendors={vendors}
                categories={categories}
                history={history}
                location={location}
              />
            </div>
            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <RouterLink
                  to={createTransactionListLink()}
                  style={{
                    color: PRIMARY_COLOR,
                    fontWeight: "700",
                    textDecoration: "none",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>
                    Complete transaction history
                  </span>{" "}
                  &rarr;
                </RouterLink>
              </div>
            </div>
          </Section>
        </>
      )}
    </PageContainer>
  );
};

const MetricBlock = ({ label, content, tooltip }) => {
  const children = (
    <>
      <Text block variant="label-light" margin="0 0 1rem">
        {label}
      </Text>
      <div
        style={{
          transition: "0.1s opacity",
          opacity: content == null ? 0 : 1,
        }}
      >
        {content}
      </div>
    </>
  );
  return tooltip ? (
    <Tooltip key={`${label}-tooltip`} label={tooltip}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <div key={label}>{children}</div>
  );
};

const ChartBlock = ({ title, children }) => (
  <>
    <Text
      block
      margin="0 0 1rem"
      weight="500"
      size="1.2rem"
      color="rgb(0 0 0 / 54%)"
    >
      {title}
    </Text>
    <div style={{ padding: "0 3rem 1rem 0" }}>{children}</div>
  </>
);

export default AnalysisPage;
