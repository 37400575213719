import parseDate from "date-fns/parse";
import formatDate from "date-fns/format";
import startOfYear from "date-fns/startOfYear";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import startOfDay from "date-fns/startOfDay";
import endOfYear from "date-fns/endOfYear";
import endOfQuarter from "date-fns/endOfQuarter";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import endOfDay from "date-fns/endOfDay";
import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "@reach/tooltip";
import { GREEN_TEXT, RED_TEXT } from "../../constants/colors";
import { update as updateQueryString } from "../../utils/query-string";
import { groupBy, sort } from "../../utils/array";
import { ascending } from "../../utils/comparators";
import {
  selectDataByQuery as selectMetricDataByQuery,
  selectMetadataByQuery as selectMetricMetadataByQuery,
} from "../../hooks/metrics";
import Text from "../Text";
import Divider from "../Divider";
import Icon from "../Icon";
import FormattedCurrency from "../FormattedCurrency";
import FormattedDate from "../FormattedDate";
import Select from "../Select";
import Button from "../Button";
import DatePicker from "../DatePicker";

const iconByMetric = {
  money_net: "arrows-left-right",
  money_in: "arrow-up-right",
  money_out: "arrow-down-right",
};

const frequencies = [
  {
    label: "Daily",
    key: "daily",
    deriveRange: (date) => [startOfDay(date), endOfDay(date)],
    renderRange: ([start]) => (
      <FormattedDate value={start} month="short" day="numeric" year="numeric" />
    ),
    renderGroupTitle: (datapoints) => (
      <FormattedDate value={datapoints[0].date} year="numeric" />
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Weekly",
    key: "weekly",
    deriveRange: (date) => [
      startOfWeek(date, { weekStartsOn: 1 }),
      endOfWeek(date, { weekStartsOn: 1 }),
    ],
    renderRange: ([start, end]) => (
      <>
        <FormattedDate value={start} month="short" day="numeric" /> &ndash;{" "}
        <FormattedDate value={end} month="short" day="numeric" year="numeric" />
      </>
    ),
    renderGroupTitle: (datapoints) => (
      <FormattedDate value={datapoints[0].date} year="numeric" />
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Monthly",
    key: "monthly",
    deriveRange: (date) => [startOfMonth(date), endOfMonth(date)],
    renderRange: ([start, end]) => (
      <>
        <FormattedDate value={start} month="short" day="numeric" /> &ndash;{" "}
        <FormattedDate value={end} month="short" day="numeric" year="numeric" />
      </>
    ),
    renderGroupTitle: (datapoints) => (
      <FormattedDate value={datapoints[0].date} year="numeric" />
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Quarterly",
    key: "quarterly",
    deriveRange: (date) => [startOfQuarter(date), endOfQuarter(date)],
    renderRange: ([start, end]) => (
      <>
        <FormattedDate value={start} month="short" day="numeric" /> &ndash;{" "}
        <FormattedDate value={end} month="short" day="numeric" year="numeric" />
      </>
    ),
    renderGroupTitle: (datapoints) => (
      <FormattedDate value={datapoints[0].date} year="numeric" />
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Yearly",
    key: "yearly",
    deriveRange: (date) => [startOfYear(date), endOfYear(date)],
    renderRange: ([start, end]) => (
      <>
        <FormattedDate value={start} month="short" day="numeric" /> &ndash;{" "}
        <FormattedDate value={end} month="short" day="numeric" year="numeric" />
      </>
    ),
    group: (datapoints) => ({ 0: datapoints }),
  },
];

const CashFlow = ({ getMetricState, fetchMetricData, history, location }) => {
  const currency = useSelector((state) => state.settings.table.currency);

  const {
    metric = "money_net",
    frequency = "daily",
    fromDate,
    toDate,
  } = Object.fromEntries(
    Array.from(new URLSearchParams(location.search).entries()).map(
      ([key, value]) => {
        switch (key) {
          case "frequency":
            return ["frequency", value];
          case "metric":
            return ["metric", value];
          case "from":
            return ["fromDate", parseDate(value, "yyyy-MM-dd", new Date())];
          case "to":
            return ["toDate", parseDate(value, "yyyy-MM-dd", new Date())];
          default:
            return [key, value];
        }
      }
    )
  );

  const dataPoints =
    getMetricState(
      selectMetricDataByQuery({
        metric,
        frequency,
        from: fromDate,
        to: toDate,
      })
    ) ?? [];

  const metadata = getMetricState(
    selectMetricMetadataByQuery({
      metric,
      frequency,
      from: fromDate,
      to: toDate,
    })
  );

  React.useEffect(() => {
    fetchMetricData({ metric, frequency, from: fromDate, to: toDate });
  }, [metric, frequency, fromDate?.getTime(), toDate?.getTime()]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    deriveRange,
    renderRange,
    group,
    renderGroupTitle,
  } = frequencies.find((f) => f.key === frequency);

  const groupedDataPoints = group(dataPoints);

  const max = Math.max(...dataPoints.map((dp) => dp.value));
  const min = Math.min(0, ...dataPoints.map((dp) => dp.value));

  const netAmount = dataPoints.reduce((sum, dp) => sum + dp.value, 0);

  return (
    <>
      <div
        className={css({
          borderBottom: "0.1rem solid",
          borderColor: "rgb(239, 241, 244)",
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          height: "6rem",
          gap: "2rem",
          padding: "1rem",
          paddingLeft: "2rem",
        })}
      >
        <Text variant="headline" component="h2" size="1.6rem">
          Heat map
        </Text>
        <div
          className={css({
            display: "grid",
            alignItems: "center",
            gridAutoColumns: "auto",
            gridAutoFlow: "column",
            gridGap: "1rem",
          })}
        >
          <Select
            value={metric}
            options={[
              { value: "money_net", label: "Net cash flow" },
              { value: "money_in", label: "Money in" },
              { value: "money_out", label: "Money out" },
            ]}
            onChange={(e) =>
              history.push({
                search: updateQueryString(location.search, {
                  metric: e.target.value,
                }),
              })
            }
            renderTrigger={(selectedOption, isHovered) => (
              <Button
                component="div"
                variant="transparent"
                size="small"
                isHovered={isHovered}
              >
                <Icon
                  name={iconByMetric[metric]}
                  size="1.4rem"
                  style={{ marginRight: "0.7rem" }}
                />
                {selectedOption?.label}
              </Button>
            )}
          />
          <DatePicker
            range={[fromDate, toDate]}
            onChange={([from, to]) =>
              history.push({
                search: updateQueryString(location.search, {
                  from: formatDate(from, "yyyy-MM-dd"),
                  to: formatDate(to, "yyyy-MM-dd"),
                }),
              })
            }
            label={
              <>
                <Icon
                  name="calendar"
                  size="1.4rem"
                  style={{ marginRight: "1rem" }}
                />
                <Text truncate style={{ flex: 1 }}>
                  {fromDate || toDate
                    ? [fromDate, toDate]
                        .map((d) => (d ? formatDate(d, "yyyy-MM-dd") : "*"))
                        .join(" – ")
                    : "Select interval"}
                </Text>
              </>
            }
          />
          <Select
            value={frequency}
            options={frequencies.map(({ label, key }) => ({
              label,
              value: key,
            }))}
            onChange={(e) =>
              history.push({
                search: updateQueryString(location.search, {
                  frequency: e.target.value,
                }),
              })
            }
            renderTrigger={(selectedOption, isHovered) => (
              <Button
                component="div"
                variant="transparent"
                size="small"
                isHovered={isHovered}
              >
                <Icon
                  name="ratio"
                  size="1.4rem"
                  style={{ marginRight: "0.7rem" }}
                />
                {selectedOption.label}
              </Button>
            )}
          />
        </div>
      </div>
      <div className={css({ flex: "1 1", overflow: "auto" })}>
        <div className={css({ padding: "4rem 2rem 0" })}>
          <div
            className={css({
              display: "grid",
              gridTemplateColumns: "minmax(30%, auto) minmax(auto, 1fr)",
              alignItems: "flex-end",
              gridGap: "4rem",
            })}
          >
            <div>
              {dataPoints.length !== 0 && (
                <>
                  <Text block variant="label-light">
                    Total amount
                  </Text>
                  <Text block size="3rem" weight="800" margin="1rem 0 0">
                    <FormattedCurrency value={netAmount} currency={currency} />
                  </Text>
                </>
              )}
            </div>
            <div>
              {metadata != null && (
                <>
                  <Text block variant="label-light">
                    Total transactions
                  </Text>
                  <Text block size="3rem" weight="800" margin="1rem 0 0">
                    {metadata.count}
                  </Text>
                </>
              )}
            </div>
          </div>

          <Divider size="4rem" />

          <div>
            {sort(
              (a, b) => ascending(parseInt(a[0]), parseInt(b[0])),
              Object.entries(groupedDataPoints)
            ).map(([groupKey, groupDataPoints]) => (
              <React.Fragment key={groupKey}>
                {renderGroupTitle && (
                  <Text block size="1.6rem" weight="500" margin="0 0 1rem">
                    {renderGroupTitle(groupDataPoints)}
                  </Text>
                )}
                <div
                  className={css({
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "-0.7rem",
                    marginRight: "-0.7rem",
                    marginBottom: "3.4rem",
                  })}
                >
                  {groupDataPoints.map(({ value, date }, i) => {
                    const opacity =
                      value === 0
                        ? 0.25
                        : Math.max(
                            0.5,
                            0.5 +
                              Math.abs(
                                value / ((value < 0 ? Math.abs(min) : max) / 1)
                              ) *
                                0.5
                          );

                    const range = deriveRange(new Date(date));

                    return (
                      <Tooltip
                        label={
                          <>
                            <Text block color="rgba(0,0,0,0.54)" weight="500">
                              {renderRange(range)}
                            </Text>
                            <Text block weight="700" size="1.4rem">
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {value === 0 ? "" : value > 0 ? "+" : "-"}
                                <FormattedCurrency
                                  value={Math.abs(value)}
                                  currency={currency}
                                />
                                {value !== 0 && (
                                  <div
                                    style={{
                                      marginLeft: "0.5rem",
                                      color: value < 0 ? RED_TEXT : GREEN_TEXT,
                                    }}
                                  >
                                    <Icon
                                      name={
                                        value > 0
                                          ? "arrow-up-right"
                                          : "arrow-down-right"
                                      }
                                      size="1rem"
                                    />
                                  </div>
                                )}
                              </div>
                            </Text>
                          </>
                        }
                        key={`${date}-${i}`}
                        style={{
                          zIndex: 1,
                          pointerEvents: "none",
                          position: "absolute",
                          padding: "0.8rem 1rem",
                          boxShadow: "2px 2px 10px hsla(0, 0%, 0%, 0.1)",
                          // whiteSpace: "nowrap",
                          fontSize: "1.2rem",
                          lineHeight: 1.3,
                          // background: "#f0f0f0",
                          background: "white",
                          borderRadius: "0.5rem",
                          // color: "#444",
                          // border: "solid 1px #ccc",
                        }}
                      >
                        <RouterLink
                          to={{
                            pathname: "/dashboard/transactions",
                            search: `from=${formatDate(
                              range[0].getTime(),
                              "yyyy-MM-dd"
                            )}&to=${formatDate(
                              range[1].getTime(),
                              "yyyy-MM-dd"
                            )}`,
                          }}
                          className={css({
                            width: "1.5rem",
                            height: "1.5rem",
                            marginTop: "0.4rem",
                            marginRight: "0.4rem",
                            ":hover": {
                              boxShadow: "0 0 0 0.3rem #365eeb",
                            },
                          })}
                          style={{
                            background:
                              value === 0
                                ? "rgb(0 0 0 / 5%)"
                                : value < 0
                                ? `rgba(241, 30, 79, ${opacity})`
                                : `rgba(0, 187, 127, ${opacity})`,
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CashFlow;
