import { combineReducers } from "redux";
import { authentication } from "./authentication";
import { data as transactions } from "./transactions";
import tableSettings from "./tableSettings";
import accountSettings from "./account-settings";
import { searchFilters } from "./searchFilters";
import { data as companies } from "./companies";

const rootReducer = combineReducers({
  authentication,
  transactions,
  settings: combineReducers({
    table: tableSettings,
    accounts: accountSettings,
  }),
  search: searchFilters,
  companies,
});

export default rootReducer;
