import React, { Component } from 'react';
import './App.scss';

import Routes from './routes';


class App extends Component {
  constructor() {
    super();
    this.state = {

    };
  }


  render() {
    return (
      <Routes />
    );
  }
}

export default App;