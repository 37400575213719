import { persistedReducer } from './configureStore'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  const loggerMiddleware = createLogger({
    duration: true,
    timestamp: true,
    diff: false,
    level: "debug"
  });
  middlewares.push(loggerMiddleware);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middlewares),
  )
)

export default store