import { css } from "emotion";
import formatDate from "date-fns/format";
import React from "react";
import { partition, sort, sum } from "../../utils/array";
import { descending } from "../../utils/comparators";
import FormattedDate from "../FormattedDate";
import RawFormattedCurrency from "../FormattedCurrency";
import RawFormattedNumber from "../FormattedNumber";
import OverlayCard from "../OverlayCard";
import Text from "../Text";
import Grid from "../Grid";
import { RED, GREEN, lightBlue } from "./Index";

const MainChartDatumHoverPopover = ({
  date,
  balanceProjection,
  dataByMonth,
  inputEntries,
  currency,
}) => {
  const balanceDatumIndex = balanceProjection.findIndex(
    (d) => d.date.getTime() === date.getTime()
  );
  const balanceDatum = balanceProjection[balanceDatumIndex];
  // const prevBalanceDatum = balanceProjection[balanceDatumIndex - 1];

  if (balanceDatum == null) return null;

  const monthData = (dataByMonth[formatDate(date, "yyyy-MM")] ?? []).map(
    ({ amount, amountRange, inputEntryId }) => {
      const inputEntry = inputEntries.find((e) => e.id === inputEntryId);
      return { ...inputEntry, amount, amountRange };
    }
  );

  const [incomeData, expenseData] = partition(
    (d) => d.flow !== "expense",
    sort(
      (d1, d2) => descending(Math.abs(d1.amount), Math.abs(d2.amount)),
      monthData.filter((d) => d.amount !== 0)
    )
  );

  const [incomeTotal, expensesTotal] = [incomeData, expenseData].map((data) =>
    sum((d) => (d.flow === "expense" ? d.amount * -1 : d.amount), data)
  );
  const [incomeTotalRange, expensesTotalRange] = [incomeData, expenseData].map(
    (data) =>
      [0, 1].map((index) =>
        sum((d) => {
          const amount = d.amountRange[index];
          return d.flow === "expense" ? amount * -1 : amount;
        }, data)
      )
  );

  const netCashChange = sum((d) => d.amount, monthData);
  const netCashChangeRange = [0, 1].map((index) =>
    sum((d) => d.amountRange[index], monthData)
  );

  return (
    <OverlayCard padding={0}>
      <div style={{ padding: "1rem 2rem" }}>
        <Text
          block
          color="rgb(255 255 255 / 60%)"
          size="1.2rem"
          margin="0 0 0.6rem"
        >
          <FormattedDate value={date} month="long" year="numeric" /> forecast
        </Text>
        <Text block size="1.6rem" weight="500" color={lightBlue()}>
          <FormattedCurrencyWithFloatingSign
            value={balanceDatum.balance}
            currency={currency}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            signDisplay="auto"
            notation="standard"
          />{" "}
          <Text size="1.2rem">Start balance</Text>
        </Text>
      </div>

      <Grid
        template="repeat(3,max-content)"
        gap="0.5rem 2rem"
        align="flex-end"
        style={{
          background: "rgb(255 255 255 / 5%)",
          padding: "1rem 2rem 2rem",
          fontWeight: "500",
        }}
      >
        {[
          {
            label: "Cash in",
            flow: "income",
            data: incomeData,
            total: incomeTotal,
            totalRange: incomeTotalRange,
          },
          {
            label: "Cash out",
            flow: "expenses",
            data: expenseData,
            total: expensesTotal,
            totalRange: expensesTotalRange,
          },
        ].map(({ flow, label, data, total, totalRange }, i) => (
          <React.Fragment key={label}>
            <div
              style={{
                gridColumn: "span 3",
                marginTop: i === 0 ? 0 : "1.2rem",
                marginBottom: "0.3rem",
              }}
            >
              <Text
                variant="label"
                size="0.9rem"
                // color="rgb(255 255 255 / 60%)"
                color={flow === "expenses" ? RED : GREEN}
              >
                {label}
              </Text>
            </div>

            {data.map(({ id, name, amount, amountRange }) => (
              <React.Fragment key={id}>
                <Text>{name}</Text>
                <Text>
                  <FormattedCurrency
                    value={Math.abs(amount)}
                    currency={currency}
                  />
                </Text>
                <Text size="1rem" color="rgb(255 255 255 / 60%)">
                  {amountRange.some((a) => a !== amount) && (
                    <>
                      <FormattedNumber
                        value={Math.abs(amountRange[0])}
                        notation="compact"
                      />
                      {" – "}
                      <FormattedNumber
                        value={Math.abs(amountRange[1])}
                        notation="compact"
                      />
                    </>
                  )}
                </Text>
              </React.Fragment>
            ))}

            <div
              style={{
                gridColumn: "span 3",
                borderTop: "0.1rem solid rgb(255 255 255 / 10%)",
              }}
            />
            <Text color="rgb(255 255 255 / 60%)">Total</Text>
            <Text>
              <FormattedCurrency value={total} currency={currency} />
            </Text>
            <Text size="1rem" color="rgb(255 255 255 / 60%)">
              {totalRange.some((a) => a !== total) && (
                <>
                  <FormattedNumber
                    value={Math.abs(totalRange[0])}
                    notation="compact"
                  />
                  {" – "}
                  <FormattedNumber
                    value={Math.abs(totalRange[1])}
                    notation="compact"
                  />
                </>
              )}
            </Text>
          </React.Fragment>
        ))}

        <div
          style={{
            gridColumn: "span 3",
            marginTop: "1.2rem",
            marginBottom: "0.3rem",
          }}
        >
          <Text variant="label" size="0.9rem" color={lightBlue()}>
            Balance
          </Text>
        </div>
        <Text>Start balance</Text>
        <Text>
          <FormattedCurrencyWithFloatingSign
            value={balanceDatum.balance}
            currency={currency}
            signDisplay="auto"
          />
        </Text>
        <Text size="1rem" color="rgb(255 255 255 / 60%)">
          {balanceDatum.balanceRange.some(
            (a) => a !== balanceDatum.balance
          ) && (
            <>
              <FormattedNumberWithFloatingSign
                value={balanceDatum.balanceRange[0]}
                notation="compact"
              />
              {" – "}
              <FormattedNumber
                value={balanceDatum.balanceRange[1]}
                notation="compact"
              />
            </>
          )}
        </Text>

        <Text>Net cash change</Text>
        <Text>
          <FormattedCurrencyWithFloatingSign
            value={netCashChange}
            currency={currency}
          />
        </Text>
        <Text size="1rem" color="rgb(255 255 255 / 60%)">
          {netCashChangeRange.some((a) => a !== netCashChange) && (
            <>
              <FormattedNumberWithFloatingSign
                value={netCashChangeRange[0]}
                notation="compact"
              />
              {" – "}
              <FormattedNumber
                value={netCashChangeRange[1]}
                notation="compact"
                signDisplay="exceptZero"
              />
            </>
          )}
        </Text>

        <div
          style={{
            gridColumn: "span 3",
            borderTop: "0.1rem solid rgb(255 255 255 / 10%)",
          }}
        />
        <Text color={lightBlue()}>End balance</Text>
        <Text color={lightBlue()}>
          <FormattedCurrencyWithFloatingSign
            value={balanceDatum.balance + netCashChange}
            currency={currency}
            signDisplay="auto"
          />
        </Text>
        <Text size="1rem" color="rgb(255 255 255 / 60%)">
          {balanceDatum.balanceRange.some(
            (a) => a !== balanceDatum.balance
          ) && (
            <>
              <FormattedNumberWithFloatingSign
                value={balanceDatum.balanceRange[0] + netCashChangeRange[0]}
                notation="compact"
              />
              {" – "}
              <FormattedNumber
                value={balanceDatum.balanceRange[1] + netCashChangeRange[1]}
                notation="compact"
              />
            </>
          )}
        </Text>
      </Grid>
    </OverlayCard>
  );
};

const FormattedCurrencyWithFloatingSign = ({
  signDisplay,
  value,
  ...props
}) => (
  <FloatingNumberSignBefore value={value} signDisplay={signDisplay}>
    <FormattedCurrency value={value} signDisplay="never" {...props} />
  </FloatingNumberSignBefore>
);

const FormattedNumberWithFloatingSign = ({ signDisplay, value, ...props }) => (
  <FloatingNumberSignBefore value={value} signDisplay={signDisplay}>
    <FormattedNumber value={value} signDisplay="never" {...props} />
  </FloatingNumberSignBefore>
);

const FloatingNumberSignBefore = ({
  children,
  value,
  signDisplay = "auto",
}) => {
  const getSign = (number) => {
    switch (signDisplay) {
      case "never":
        return undefined;
      case "exceptZero": {
        if (number === 0) return undefined;
        return number > 0 ? "+" : "-";
      }
      case "auto":
        return number === 0 || number > 0 ? undefined : "-";
      default:
        throw new Error();
    }
  };

  return (
    <span
      className={css({
        position: "relative",
        ":before": {
          position: "absolute",
          right: "100%",
          content: `'${getSign(value) ?? ""}'`,
        },
      })}
    >
      {children}
    </span>
  );
};

const FormattedCurrency = (props) => (
  <RawFormattedCurrency
    notation={props.value >= 1000000 ? "compact" : undefined}
    {...props}
  />
);

const FormattedNumber = (props) => (
  <RawFormattedNumber
    notation={props.value >= 1000000 ? "compact" : undefined}
    {...props}
  />
);

export default MainChartDatumHoverPopover;
