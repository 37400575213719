import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PRIMARY_COLOR } from "../../constants/colors";
import {
  getCompanyBalance,
  getCompanyPendingBalance,
} from "../../api/companies";
import FormattedCurrency from "../FormattedCurrency";
import Box from "../Box";
import Divider from "../Divider";
import Text from "../Text";
import { getIconSourceFromBankId } from "../../utils/logos";

const useBalance = ({ companyId, accountIds }) => {
  const [{ balance, currency }, set] = React.useState({
    balance: null,
    currency: null,
  });

  const accountIdsString = accountIds.join(",");

  React.useEffect(() => {
    getCompanyBalance({ bank_accounts: accountIdsString }).then((response) =>
      set({
        balance: response["balance"],
        currency: response["default_currency"],
      })
    );
  }, [companyId, accountIdsString]);

  return { balance, currency };
};

const usePendingBalance = ({ companyId, accountIds }) => {
  const [{ pendingBalance, pendingCurrency }, set] = React.useState({
    pendingBalance: null,
    pendingCurrency: null,
  });

  const accountIdsString = accountIds.join(",");

  React.useEffect(() => {
    getCompanyPendingBalance({ bank_accounts: accountIdsString }).then(
      (response) => {
        set({
          pendingBalance: response["balance"],
          pendingCurrency: response["default_currency"],
        });
      }
    );
  }, [companyId, accountIdsString]);

  return { pendingBalance, pendingCurrency };
};

const Balance = ({ companyId, enabledBankAccounts }) => {
  const { balance, currency: balanceCurrency } = useBalance({
    companyId,
    accountIds: enabledBankAccounts.map((a) => a.id),
  });

  const {
    pendingBalance,
    pendingCurrency: pendingBalanceCurrency,
  } = usePendingBalance({
    companyId,
    accountIds: enabledBankAccounts.map((a) => a.id),
  });

  if (enabledBankAccounts.length === 0) return null;

  return (
    <div
      className={css({
        flex: "1 1",
        overflow: "auto",
        width: "90rem",
        maxWidth: "100%",
        margin: "0 auto",
        padding: "6rem 1rem",
      })}
    >
      <Box>
        <Text variant="headline" component="h2" id="Insights">
          Total balance
        </Text>

        <Divider size="5rem" />

        <Text variant="label-light" component="h3" margin="0 0 1rem">
          Total account balance
        </Text>

        {balance != null && (
          <Text block size="3.8rem" weight="800">
            <FormattedCurrency value={balance} currency={balanceCurrency} />
          </Text>
        )}

        <Divider size="5rem" />

        <Text variant="label-light" component="h3" margin="0 0 1rem">
          Pending amount
        </Text>

        {pendingBalance != null && (
          <Text block size="2.8rem" weight="800">
            <FormattedCurrency
              value={pendingBalance}
              currency={pendingBalanceCurrency}
            />
          </Text>
        )}
      </Box>

      <Divider size="6rem" />

      <Box>
        <Text variant="headline" component="h2" id="Insights">
          Bank accounts
        </Text>

        <Divider size="5rem" />

        <Text variant="label-light" component="h3" margin="0 0 1rem">
          Your connected accounts
        </Text>

        <ul>
          {enabledBankAccounts.map((a) => {
            let iconSrc = getIconSourceFromBankId(a.bank_slug);

            return (
              <li
                key={a.uuid}
                className={css({
                  ":not(:last-of-type)": { marginBottom: "2rem" },
                })}
              >
                <Box padding={0} key={a.id} style={{ padding: "0 2rem" }}>
                  <div
                    className={css({
                      display: "grid",
                      gridTemplateColumns:
                        "3rem minmax(22rem,2fr) minmax(12rem,1fr) minmax(12rem,1fr) minmax(14rem,1fr)",
                      alignItems: "center",
                      gridGap: "1.4rem",
                      lineHeight: 1.3,
                      padding: "2rem 0",
                      overflow: "auto",
                      ":not(:first-of-type)": {
                        marginTop: "2rem",
                      },
                    })}
                  >
                    {iconSrc != null ? (
                      <img
                        alt={a.bank_name}
                        src={iconSrc}
                        className={css({ width: "3rem", height: "3rem" })}
                      />
                    ) : (
                      <div
                        className={css({
                          width: "3rem",
                          height: "3rem",
                          background: "rgba(54,94,235,0.075)",
                          color: "rgba(54,94,235)",
                          fontWeight: "700",
                          borderRadius: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        })}
                      >
                        {a.bank_name[0]}
                      </div>
                    )}
                    <RouterLink
                      to={{
                        pathname: "/dashboard/transactions",
                        search: `accounts=${a.id}`,
                      }}
                      className={css({
                        fontWeight: "700",
                        ":hover": { color: "rgb(0 0 0 / 70%)" },
                      })}
                    >
                      {a.bank_name} | {a.name}
                    </RouterLink>
                    <div>{a.number}</div>
                    <div />
                    <Text weight="700" align="right">
                      <FormattedCurrency
                        value={a.booked_balance.amount}
                        currency={a.booked_balance.currency}
                      />
                    </Text>
                  </div>
                </Box>
              </li>
            );
          })}
        </ul>

        <Divider size="4rem" />

        <div className={css({ textAlign: "right" })}>
          <RouterLink
            to="/dashboard/settings"
            className={css({
              color: PRIMARY_COLOR,
              fontWeight: "500",
              textDecoration: "underline",
            })}
          >
            + Add bank account
          </RouterLink>
        </div>
      </Box>
    </div>
  );
};

export default Balance;
