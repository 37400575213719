import React from "react";
import { css } from "emotion";
import {
  PRIMARY_COLOR,
  RED_TEXT,
  GREEN_TEXT,
  YELLOW_TEXT,
} from "../../constants/colors";
import { SUPPORT_EMAIL } from "../../constants/obvious";
import useStore from "../../hooks/stores";
import { selectAll as selectAllBankAccounts } from "../../hooks/bank-accounts";
import usePlaid from "../../hooks/plaid";
import Box from "../Box";
import Text from "../Text";
import Divider from "../Divider";
import Button from "../Button";
import DropdownMenu from "../DropdownMenu";
import LabelledCheckbox from "../LabelledCheckbox";
import FormattedRelativeDate from "../FormattedRelativeDate";
import { getSeverityFromBank } from "../../utils/banks";
import { getIconSourceFromBankId } from "../../utils/logos";
import Modal from "../Modal";
import BankSelectorModal from "../BankSelectorModal";

const colorByAccountSyncSeverity = {
  0: GREEN_TEXT,
  1: YELLOW_TEXT,
  2: RED_TEXT,
};

const Settings = ({ toggleEnableBankAccount }) => {
  const [getBankAccountsState, { fetchAiiaConnectionUrl }] = useStore(
    "bank-accounts"
  );
  const bankAccounts = getBankAccountsState(selectAllBankAccounts);

  const [bankSelectorVisible, setBankSelectorVisible] = React.useState(false);
  const closeBankSelector = React.useCallback(
    () => setBankSelectorVisible(false),
    []
  );

  const {
    openConnectionUI: openPlaidConnectionUI,
    // error, // TODO: React to error
  } = usePlaid({
    onSuccess: bankSelectorVisible ? closeBankSelector : undefined,
  });

  const navigateToAiiaExternalConnectionFlow = () =>
    fetchAiiaConnectionUrl().then(({ url }) => (window.location.href = url));

  return (
    <div
      className={css({
        flex: "1 1",
        overflow: "auto",
        padding: "6rem 2rem 12rem",
        margin: "0 auto",
        maxWidth: "100%",
        width: "90rem",
      })}
    >
      <Text variant="headline-large" component="h1">
        Bank accounts
      </Text>

      <Divider size="2rem" />

      <Box shadowed padding={0}>
        <div className={css({ padding: "5rem 3rem" })}>
          {/* <Box */}
          {/*   padding="2rem" */}
          {/*   shadowed={false} */}
          {/*   background="rgba(245, 89, 0, 0.05)" */}
          {/* > */}
          {/*   <Text component="h3" size="1.6rem" margin="0 0 2.4rem"> */}
          {/*     Last synched 4 hours ago */}
          {/*   </Text> */}
          {/*   <Text component="h4" color="#F55900" margin="0 0 0.7rem"> */}
          {/*     Issues detected */}
          {/*   </Text> */}

          {/*   <ul */}
          {/*     className={css({ */}
          {/*       lineHeight: 1.8, */}
          {/*       listStyle: "disc", */}
          {/*       paddingLeft: "2.2rem", */}
          {/*       fontWeight: "500", */}
          {/*     })} */}
          {/*   > */}
          {/*     <li> */}
          {/*       <em>Shopping account (Klarna)</em> did not sync properly. Next */}
          {/*       try in 2 hours. */}
          {/*     </li> */}
          {/*     <li> */}
          {/*       <em>Checkings account 1 (Revolut)</em> failed to sync. Manual */}
          {/*       reconnect may be required. */}
          {/*     </li> */}
          {/*   </ul> */}
          {/* </Box> */}

          {/* <Divider size="6rem" /> */}

          <Text component="h2" size="1.8rem" margin="0 0 3rem">
            Connected account
          </Text>

          <div style={{ overflow: "auto" }}>
            <ul style={{ minWidth: "56rem" }}>
              {bankAccounts.map((account) => (
                <li
                  key={account.id}
                  className={css({
                    ":not(:last-of-type)": { marginBottom: "2rem" },
                  })}
                >
                  <AccountListItem
                    account={account}
                    onTriggerAiiaRecconect={
                      navigateToAiiaExternalConnectionFlow
                    }
                    // onTriggerRemove={navigateToBankIntegrationProvider}
                    onToggleEnabled={() => toggleEnableBankAccount(account.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className={css({
            padding: "5rem 3rem",
            borderTop: "0.1rem solid #e7e7e7",
          })}
        >
          <Text component="h2" size="1.8rem" margin="0 0 2rem">
            Add bank account
          </Text>

          <Text variant="paragraph" component="p" color="rgba(0,0,0,0.54)">
            We currently support Swedish banks. Email{" "}
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
              className={css({
                color: "currentColor",
                textDecoration: "underline",
              })}
            >
              {SUPPORT_EMAIL}
            </a>{" "}
            and let us know what you need.
          </Text>

          <Divider size="4.5rem" />

          <Button
            variant="primary"
            size="large"
            onClick={() => setBankSelectorVisible(true)}
          >
            Add bank account
          </Button>
        </div>
      </Box>

      <Modal
        isOpen={bankSelectorVisible}
        onDismiss={closeBankSelector}
        label="Pick Region"
      >
        {bankSelectorVisible && (
          <BankSelectorModal
            dismiss={closeBankSelector}
            navigateToAiiaExternalConnectionFlow={
              navigateToAiiaExternalConnectionFlow
            }
            openPlaidConnectionUI={openPlaidConnectionUI}
          />
        )}
      </Modal>
    </div>
  );
};

const AccountListItem = ({
  onToggleEnabled,
  onTriggerAiiaRecconect,
  // onTriggerRemove,
  account,
}) => {
  let iconSrc = getIconSourceFromBankId(account.bank_slug);
  const accountSyncSeverity = getSeverityFromBank(account);

  return (
    <div
      className={css({
        display: "grid",
        gridTemplateColumns: "4rem minmax(0,3fr) repeat(3, minmax(0,2fr)) auto",
        alignItems: "center",
        gridGap: "2rem",
        fontSize: "1.6rem",
        fontWeight: "500",
        whiteSpace: "nowrap",
        lineHeight: 1.5,
        minWidth: "50rem",
      })}
    >
      {iconSrc != null ? (
        <img
          alt={account.bank_name || ""}
          src={iconSrc}
          className={css({ height: "40px" })}
        />
      ) : (
        <div
          className={css({
            width: "4rem",
            height: "4rem",
            background: "rgba(54,94,235,0.075)",
            color: PRIMARY_COLOR,
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0.4rem",
          })}
        >
          {account.bank_name[0]}
        </div>
      )}
      <Text truncate>{account.name}</Text>
      <Text truncate color="rgb(0 0 0 / 54%)">
        {account.number}
      </Text>
      <div className={css({ display: "flex", alignItems: "center" })}>
        <div
          className={css({
            width: "1rem",
            height: "1rem",
            borderRadius: "50%",
            background: colorByAccountSyncSeverity[accountSyncSeverity],
            marginRight: "1rem",
          })}
        />
        <Text truncate style={{ minWidth: 0, flex: 1 }}>
          {account.sync_status === "done" ? (
            <FormattedRelativeDate value={new Date(account.sync_date * 1000)} />
          ) : account.sync_status === "failed" ? (
            "Error" // TODO
          ) : account.sync_status === "pending" ? (
            "Fetching data..."
          ) : (
            "" // TODO: 'created' status
          )}
        </Text>
      </div>

      <LabelledCheckbox
        id={`enable-account-${account.id}`}
        label="Enabled"
        isChecked={account.isEnabled}
        onChange={onToggleEnabled}
      />

      {account.provider === "aiia" ? (
        <DropdownMenu
          renderButton={({ component: MenuButton }) => (
            <MenuButton
              className={css({
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "repeat(3, auto)",
                gap: "0.2rem",
                padding: "0.6rem 0",
                color: "black",
                cursor: "pointer",
                ":hover": { color: "#888" },
              })}
            >
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className={css({
                    width: "0.4rem",
                    height: "0.4rem",
                    borderRadius: "50%",
                    background: "currentColor",
                  })}
                />
              ))}
            </MenuButton>
          )}
          items={[
            {
              type: "button",
              label: "Reconnect",
              onSelect: onTriggerAiiaRecconect,
            },
            // {
            //   label: "Remove",
            //   onSelect: onTriggerRemove,
            //   style: { color: RED_TEXT },
            // },
          ]}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default Settings;
