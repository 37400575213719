import { styled, withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';

export const MyButton = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});


export const BlackBetaButton = withStyles({
  root: {
    background: "#323245",
    borderRadius: 3,
    height: 46,

    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#fffefc",
    textAlign: "center",
    textTransform: "none",

    '&:hover': {
      background: "#323245",
      opacity: "0.7",
    },

    // '&:disabled': {
    //   color: "red"
    // }
  },
  label: {
    padding: "0px 15px"
  },
})(Button);

export const WhiteButton = withStyles({
  root: {
    background: "#FFFFFF",
    borderRadius: 3,
    boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.1)",
    height: 46,

    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#323245",
    textAlign: "center",
    textTransform: "none",

    '&:hover': {
      background: "#FFFFFF",
      // background: "#323245",
      opacity: "0.7",
    },

    // '&:disabled': {
    //   color: "red"
    // }
  },
})(BlackBetaButton);


export const YellowButton = withStyles({
  root: {
    background: "#F2E09F",
    // borderRadius: 3,
    // boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.1)",
    // height: 46,

    // fontFamily: "Muli",
    // fontStyle: "normal",
    // fontWeight: "bold",
    // fontSize: "16px",
    // lineHeight: "20px",

    color: "#323245",
    // textAlign: "center",
    // textTransform: "none",

    '&:hover': {
      background: "#F2E09F",
      opacity: "0.7",
    },
    
    // '&:disabled': {
    //   color: "red"
    // }
  },
})(BlackBetaButton);


export const GreyButton = withStyles({
  root: {
    background: "#F5F6FA",
    borderRadius: 3,
    border: "1px solid #323245",
    boxShadow: "0px 4px 12px rgba(50, 50, 69, 0.1)",
    height: 46,

    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#323245",
    textAlign: "center",
    textTransform: "none",

    '&:hover': {
      background: "#FFFFFF",
      // background: "#323245",
      opacity: "0.7",
    },

    // '&:disabled': {
    //   color: "red"
    // }
  },
})(BlackBetaButton);


export const TableButton = withStyles({
  root: {
    background: "#FFFFFF",
    borderRadius: 3,
    border: "1px solid rgba(50, 50, 69, 0.2)",
    height: 36,

    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",

    color: "rgba(50, 50, 69, 0.8)",
    textAlign: "center",
    textTransform: "none",

    '&:hover': {
      background: "#FFFFFF",
      opacity: "0.7",
    },

    // '&:disabled': {
    //   color: "red"
    // }
  },
  label: {
    padding: "0px 10px"
  },
})(Button);