import React, { useEffect } from 'react';
import { FullScreenPage, FullScreenGrid } from '../../common/skeleton';
import { userActions } from '../../actions/user.actions';
import { useDispatch } from 'react-redux';


const LogoutComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.logout())
    return () => { }
  })


  return (
    <FullScreenPage>
      <FullScreenGrid>

      </FullScreenGrid>
    </FullScreenPage>
  )
}

export default LogoutComponent
