import { useReducer, useCallback } from "react";
import { indexBy } from "../utils/array";
import { combine as combineReducers } from "../utils/reducers";
import useRequest from "../hooks/request";

const initialState = {
  accountsById: {},
  syncStatus: {
    isFetching: false,
    // We need this to know when to show the onboarding flow: if this is truthy and we don't have any accounts
    didSuccessfulFetch: false,
    isSyncingNewConnection: false,
  },
};

const accountsById = (state, action) => {
  switch (action.type) {
    case "fetch-success":
      return indexBy((a) => a.uuid, action.accounts);
    default:
      return state;
  }
};

const syncStatus = (state, action) => {
  switch (action.type) {
    case "fetch-dispatch":
      return { ...state, isFetching: true };
    case "fetch-success": {
      const isFullySynced = action.accounts.every(isSynced);
      return {
        ...state,
        isFetching: false,
        didSuccessfulFetch: true,
        // Keep truthy as long as we're not yet fully synched
        isSyncingNewConnection: state.isSyncingNewConnection && !isFullySynced,
      };
    }
    case "fetch-error":
      return { ...state, isFetching: false };
    case "initialize-connection-dispatch":
      return { ...state, isSyncingNewConnection: true };
    case "initialize-connection-error":
      return { ...state, isSyncingNewConnection: false };
    default:
      return state;
  }
};

const useBankAccounts = ({
  companyId,
  getSettingsState,
  getCompaniesState,
}) => {
  const request = useRequest();

  const [state, dispatch] = useReducer(
    // TODO: Slice by company
    combineReducers({ accountsById, syncStatus }),
    initialState
  );

  const fetchAll = useCallback(() => {
    dispatch({ type: "fetch-dispatch" });

    return request("/bank_accounts", {
      searchParams: { size: 100 },
      headers: { "Company-Id": companyId },
    }).then(
      (res) => {
        dispatch({ type: "fetch-success", accounts: res.data });
        return res;
      },
      (error) => {
        dispatch({ type: "fetch-error", error });
        return Promise.reject(error);
      }
    );
  }, [request, companyId]);

  const fetchAllUntilSynced = useCallback(
    ({ timeout = 5000, maxRetryCount = 100 } = {}) => {
      let cancelled = false;
      let retryCount = 0;

      const cancel = () => {
        cancelled = true;
      };

      const checkSyncStatusRecursively = () =>
        fetchAll().then((response) => {
          if (cancelled) return;

          const accounts = response.data;
          const isAllSynced = accounts.every(isSynced);

          if (isAllSynced) return response;

          // TODO: handle failed sync statuses?

          if (retryCount >= maxRetryCount) return Promise.reject(new Error());

          return new Promise((resolve, reject) => {
            window.setTimeout(() => {
              if (cancelled) return;

              retryCount += 1;
              checkSyncStatusRecursively().then(resolve, reject);
            }, timeout);
          });
        });

      const promise = checkSyncStatusRecursively();
      promise.cancel = cancel;

      return promise;
    },
    [fetchAll]
  );

  const fetchGatewayData = useCallback(
    ({ gateway }) =>
      request("/connect", {
        headers: { "Company-Id": companyId },
        searchParams: { gateway },
      }),
    [request, companyId]
  );

  const fetchAiiaConnectionUrl = useCallback(
    () => fetchGatewayData({ gateway: "viia" }),
    [fetchGatewayData]
  );

  const fetchPlaidLinkToken = useCallback(
    () =>
      fetchGatewayData({ gateway: "plaid" }).then(({ url }) => ({
        linkToken: url,
      })),
    [fetchGatewayData]
  );

  const initializeConnection = useCallback(
    (body) => {
      dispatch({ type: "initialize-connection-dispatch" });
      return request("/connect", {
        method: "POST",
        headers: {
          "Company-Id": companyId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then(
        (response) => {
          dispatch({ type: "initialize-connection-success" });
          return response;
        },
        (error) => {
          dispatch({ type: "initialize-connection-error" });
          return Promise.reject(error);
        }
      );
    },
    [request, companyId]
  );

  const initializeAiiaConnection = useCallback(
    ({ code, state, consentId: consent }) =>
      initializeConnection({ gateway: "viia", code, state, consent }),
    [initializeConnection]
  );

  const initializePlaidConnection = useCallback(
    ({ publicToken: code, metadata }) =>
      initializeConnection({ gateway: "plaid", code, metadata }),
    [initializeConnection]
  );

  const getState = useCallback(
    (selector) =>
      selector(state, {
        companyId,
        settings: getSettingsState(),
        companies: getCompaniesState(),
      }),
    [state, companyId, getSettingsState, getCompaniesState]
  );

  return [
    getState,
    {
      fetchAll,
      fetchAllUntilSynced,
      fetchAiiaConnectionUrl,
      fetchPlaidLinkToken,
      initializeAiiaConnection,
      initializePlaidConnection,
    },
  ];
};

const isSynced = (account) => account.sync_status === "done";

export const selectAll = (state, { settings, companies }) =>
  Object.values(state.accountsById).map((account) => ({
    ...account,
    company: companies.data.find((c) => c.id === account.company),
    isEnabled: !settings.accounts.disabledAccountIds.includes(account.id),
  }));

export const selectAllEnabled = (...args) =>
  selectAll(...args).filter((a) => a.isEnabled);

export const selectAllSyncedStatus = (...args) =>
  selectAll(...args).every(isSynced);

export const selectAllEnabledSyncedStatus = (...args) =>
  selectAllEnabled(...args).every(isSynced);

export const selectSyncStatus = (state) => state.syncStatus;

export default useBankAccounts;
