import { companyConstants } from "../constants/company.constants";

let cid = sessionStorage.getItem("cid");

const initialState = {
  data: [],
  currentUuid: cid,
  isLoading: false,
};

export function data(state = initialState, action) {
  switch (action.type) {
    case companyConstants.FETCH_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case companyConstants.FETCH_COMPANIES_SUCCESS:
      let currentUuid = state.currentUuid;
      let companies = action.response;

      if (!currentUuid) {
        if (companies.length > 0) {
          state.currentUuid = companies[0].uuid;
          sessionStorage.setItem("cid", state.currentUuid);
        }
      } else {
        let hasAccessCurrentCompany = false;
        companies.forEach((company) => {
          if (company.uuid === currentUuid) {
            hasAccessCurrentCompany = true;
          }
        });

        if (!hasAccessCurrentCompany && companies.length > 0) {
          state.currentUuid = companies[0].uuid;
          sessionStorage.setItem("cid", state.currentUuid);
        }
      }

      return Object.assign({}, state, {
        isLoading: false,
        data: action.response,
      });
    case companyConstants.FETCH_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case companyConstants.SET_CURRENT_COMPANY:
      sessionStorage.setItem("cid", action.company);
      return Object.assign({}, state, {
        currentUuid: action.company,
      });
    default:
      return state;
  }
}
