import { css } from "emotion";
import React from "react";

const Circle = ({ children, size, background, color, style }) => (
  <div
    className={css({
      width: size,
      height: size,
      background,
      color,
      fontWeight: "700",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    })}
    style={style}
  >
    {children}
  </div>
);

export default Circle;
