import { useMemo, useCallback } from "react";
import {
  ONE_MINUTE_IN_MILLIS,
  ONE_HOUR_IN_MILLIS,
  ONE_DAY_IN_MILLIS,
} from "../constants/time";

const defaultFormatOptions = { numeric: "auto" };

const millisByUnit = {
  minute: ONE_MINUTE_IN_MILLIS,
  hour: ONE_HOUR_IN_MILLIS,
  day: ONE_DAY_IN_MILLIS,
};

export const useFormatter = ({ locale, ...numberFormatOptions }) => {
  const formatter = useMemo(
    () =>
      new Intl.RelativeTimeFormat(locale ?? "en", {
        ...defaultFormatOptions,
        ...numberFormatOptions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, ...Object.values(numberFormatOptions)]
  );

  const format = useCallback(
    (date) => {
      const deltaInMillis = date - new Date();
      const unit =
        ["minute", "hour", "day"].find(
          (unit) => Math.abs(deltaInMillis) < millisByUnit[unit]
        ) ?? "day";

      return formatter.format(
        Math.ceil(deltaInMillis / millisByUnit[unit]),
        unit
      );
    },
    [formatter]
  );

  return format;
};

const FormattedRelativeDate = ({ value, children, ...formatOptions }) => {
  const format = useFormatter(formatOptions);

  if (typeof children === "function") return children(format);

  if (value == null) throw new Error("Missing `value` prop");

  return format(value);
};

export default FormattedRelativeDate;
