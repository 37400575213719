import React from "react";

const BrandSymbol = ({ color = "currentColor" }) => (
  <svg
    width="44"
    height="53"
    viewBox="0 0 44 53"
    fill="none"
    style={{ display: "block", width: "100%", height: "auto" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 34C29.732 34 36 27.732 36 20C36 12.268 29.732 6 22 6C14.268 6 8 12.268 8 20C8 27.732 14.268 34 22 34ZM22 40C33.0457 40 42 31.0457 42 20C42 8.9543 33.0457 0 22 0C10.9543 0 2 8.9543 2 20C2 31.0457 10.9543 40 22 40Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 22C21.3137 22 24 19.3137 24 16C24 12.6863 21.3137 10 18 10C14.6863 10 12 12.6863 12 16C12 19.3137 14.6863 22 18 22ZM18 28C24.6274 28 30 22.6274 30 16C30 9.37258 24.6274 4 18 4C11.3726 4 6 9.37258 6 16C6 22.6274 11.3726 28 18 28Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5781 41.3592C28.9802 47.6042 15.0206 47.6042 3.42272 41.3592L0.578125 46.642C13.9519 53.8433 30.049 53.8433 43.4227 46.642L40.5781 41.3592Z"
      fill={color}
    />
  </svg>
);

export default BrandSymbol;
