import { css } from "emotion";
import React from "react";
import { getIconSourceFromVendorId } from "../utils/logos";
import Circle from "./Circle";

const VendorIcon = React.forwardRef(
  (
    {
      vendor,
      size = "3rem",
      component: Component = "div",
      noBorder,
      style,
      children,
      ...props
    },
    ref
  ) => {
    const iconSrc = getIconSourceFromVendorId(vendor.id);

    return (
      <Component style={{ display: "block", ...style }} {...props} ref={ref}>
        <div
          className={css({
            background: "white",
            border: noBorder ? undefined : "0.2rem solid white",
            borderRadius: "50%",
          })}
        >
          {iconSrc != null ? (
            <img
              alt={vendor.description}
              src={iconSrc}
              style={{ display: "block", width: size, height: size }}
            />
          ) : (
            <Circle
              size={size}
              background="rgba(54,94,235,0.075)"
              color="rgba(54,94,235)"
            >
              {children ?? (
                <div style={{ textTransform: "uppercase" }}>
                  {vendor.description?.[0] ?? vendor.id[0]}
                </div>
              )}
            </Circle>
          )}
        </div>
      </Component>
    );
  }
);

export default VendorIcon;
