import React from 'react';

import logo from '../assets/logos/obv-logo-large-text.svg'
import { LandingContainer, landingTheme } from './skeleton';
import { Box, Grid, useTheme, makeStyles, styled, Link, Hidden, createMuiTheme, ThemeProvider } from '@material-ui/core';

const HeaderLink = styled(Link)({
  fontFamily: "Muli",
  fontSize: "18px",
  color: "#323245",
  opacity: "0.9",
  fontWeight: "bold",
  cursor: "pointer",

  '&:hover': {
    opacity: "0.7",
    textDecoration: "none",
    "& *": {
      opacity: "0.9"
    }
  },

});

export function Header() {
  const theme = useTheme();

  const getPageMuiTheme = () => createMuiTheme({
    ...landingTheme,
    overrides: {
      MuiChip: {
        label: {
          paddingLeft: 0,
          paddingRight: 0
        },
        clickable: {
          "&:hover": {
            opacity: 0.7,
            backgroundColor: "#6280EA"
          }
        }
      },
    }
  })

  const useStyles = makeStyles({
    headerContainer: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      [theme.breakpoints.down('xs')]: {
        flexDirection: "column",
        textAlign: "center"
      },
    },
    signinLink: {
      [theme.breakpoints.down('xs')]: {
        whiteSpace: "nowrap",
      }
    },
    covidChip: {
      backgroundColor: "#6280EA",
      borderRadius: "3px",
      fontFamily: "Muli",
      fontWeight: "800",
      fontSize: "11px",
      letterSpacing: "0.05em",
      color: "#FFFEFC",
      textTransform: "uppercase",
      height: "20px",
      width: "68px",
    },
    runwayLink: {
      fontFamily: "Muli",
      fontSize: "18px",
      color: "#323245",
      opacity: "0.9",
      fontWeight: "bold",
      // '&:hover': {
      //   opacity: "0.7",
      //   textDecoration: "none",
      // },
    }
  })

  const classes = useStyles();

  let user = JSON.parse(localStorage.getItem('user'));
  let hasUser = user ? true : false
  let headerText = "Sign in"
  let headerAnchor = "/login"

  if (hasUser) {
    headerText = "Dashboard \u2192"
    headerAnchor = "/dashboard"
  }

  return (
    <LandingContainer>
      <ThemeProvider
        theme={theme => getPageMuiTheme()}
      >
        <Box paddingTop="40px">
          <Grid container spacing={2} className={classes.headerContainer}>
            <Grid item>
              <a href="/">
                <img draggable="false" src={logo} alt="Logo" />
              </a>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={5}>
                <Hidden only={['xs']}>
                  <Grid item>
                    <HeaderLink href="mailto:hello@tryobvious.com">
                      Contact Us
                  </HeaderLink>
                  </Grid>
                </Hidden>
                <Grid item>
                  <HeaderLink href={headerAnchor} className={classes.signinLink}>
                    {headerText}
                  </HeaderLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </LandingContainer>
  )
}
