import React from "react";

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    if (delay === null) return;

    const intervalHandle = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [delay]);
};

export default useInterval;
