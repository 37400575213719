import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, makeStyles, TextField, InputAdornment, useTheme } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { BlackBetaButton, TableButton } from '../buttons';
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions } from '../../actions/transaction.actions';
import SearchIcon from '@material-ui/icons/Search';
import { setPage } from '../../actions/tableSettings.actions';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { setSearchFilters, resetSearchFilters } from '../../actions/searchFilters.actions';
import { isSearch } from '../../utils/search';

import "moment/locale/en-gb";

export const BaseForm = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    emailInput: {
      fontFamily: "Muli",
      fontSize: "16px",
      maxWidth: "400px",
      width: "100%",
      borderRadius: "3px",
      outline: "none",
      border: "0px",
      height: "100%",
      boxSizing: "border-box",
      padding: "10px 10px",
      background: "#FFFFFF",
      '&::placeholder': {
        opacity: 0.5,
      },
    },
    emailButton: {
      width: "100%",
      maxWidth: "400px",
    },
    hintBox: {
      width: "100%",
      textAlign: "center"
    },
    errorBox: {
      color: "red"
    },
    formBox: {
      [theme.breakpoints.down('xs')]: {
        padding: "0px 10px"
      },
    },
    formGridContainer: {
      [theme.breakpoints.up('xs')]: {
        textAlign: "center"
      }
    }
  });

  const classes = useStyles();

  return (
    <Box className={classes.formBox}>
      <Typography variant="h3" align="center">{props.title}</Typography>
      {props.subtitle &&
        <Box paddingTop="15px">
          <Typography variant="subtitle1" align="center">{props.subtitle}</Typography>
        </Box>
      }
      <Box paddingTop="40px">
        <Formik
          initialValues={{ email: props.queryEmail ? props.queryEmail : '', password: '' }}
          validate={values => {
            const errors = {};
            if (props.password && props.newPassword && values.password.length < 8) {
              errors.password = 'Invalid'
            }
            return errors;
          }}
          onSubmit={props.handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Grid container spacing={2} direction="column" className={classes.formGridContainer}>
                {props.email &&
                  <Grid item>
                    <Field type="email" name="email" autoComplete="email" placeholder="Email" required className={classes.emailInput} />
                  </Grid>
                }
                {props.password &&
                  <Grid item>
                    <Field type="password" name="password" autoComplete={props.newPassword ? "new-password" : "current-password"} placeholder="Password" required className={classes.emailInput} />
                  </Grid>
                }
                {props.password && props.newPassword && !props.resetPassword &&
                  <Box paddingBottom="30px" paddingLeft="8px" textAlign="center">
                    <Typography variant="subtitle2" className={errors.password && touched.password && errors.password ? classes.errorBox : ""}>
                      {errors.password && touched.password && errors.password ? "Invalid password, must be at least 8 characters" : "Password must be at least 8 characters"}
                    </Typography>
                  </Box>
                }

                {props.password && props.newPassword && props.resetPassword &&
                  <Box paddingBottom="30px" paddingLeft="8px" textAlign="center">
                    <Typography variant="subtitle2" className={errors.password && touched.password && errors.password ? classes.errorBox : ""}>
                      {errors.password && touched.password && errors.password ? "Invalid password, must be at least 8 characters" : "Password must be at least 8 characters"}
                    </Typography>
                  </Box>
                }

                {props.isLoginError &&
                  <Box paddingBottom="30px" paddingLeft="8px" textAlign="center">
                    <Typography variant="subtitle2" className={classes.errorBox}>
                      Invalid email or password. Please try again.
                    </Typography>
                  </Box>
                }

                {props.buttonText &&
                  <Grid item>
                    <BlackBetaButton type="submit" disabled={props.isLoading} className={classes.emailButton}>{props.buttonText}</BlackBetaButton>
                  </Grid>
                }
                {props.hint &&
                  <Grid item>
                    <Box className={classes.hintBox}>
                      {props.hint}
                    </Box>
                  </Grid>
                }
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )

}


export const SearchForm = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    searchInputMaterial: {
      background: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "10px",

      fontFamily: "Muli",
      fontSize: "16px",
      fontWeight: "600",

      width: "100%",
      // maxWidth: "600px",
      height: "50px",

      '&::placeholder': {
        fontWeight: "600"
      },

    },
    searchBarBox: {
      padding: "0 60px 40px 60px",
    },
    searchBarContainer: {
      // justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        // justifyContent: "center",
        // paddingBottom: "20px"
      },
    },
    datePicker: {
      width: "130px",
      height: "50px",
      fontWeight: "600",
    },
    datePickersContainer: {
    },
    resetFiltersButton: {
      // alignSelf: "flex-end",
      textTransform: "none",
      color: "rgba(98, 128, 234)",
      fontWeight: "600",
      height: "53px"
    },
    searchFieldItem: {
      [theme.breakpoints.down('1020')]: {
        flexGrow: 1
      }
    },
    searchFiltersContainer: {
      justifyContent: "flex-end",
      [theme.breakpoints.down('1020')]: {
        justifyContent: "flex-start"
      }
    },
    searchFiltersItem: {
      flexGrow: 1,

    },
  });

  const classes = useStyles();

  const [timer, setTimer] = useState(null)
  const dispatch = useDispatch()
  const currentPage = useSelector(state => state.settings.page);
  const currentRowsPerPage = useSelector(state => state.settings.rowsPerPage);
  const transactions = useSelector(state => state.transactions.data);
  const banks = useSelector(state => state.banks.data);
  const banksSynced = useSelector(state => state.banks.isSynced);
  const searchOptions = useSelector(state => state.search)

  let storedTerm = useSelector(state => state.search.term);
  const [searchValue, setSearchValue] = useState(storedTerm);

  let storedFromDate = useSelector(state => state.search.fromDate);
  let storedToDate = useSelector(state => state.search.toDate);

  const [selectedFromDate, setSelectedFromDate] = useState(storedFromDate ? storedFromDate : null);
  const [selectedToDate, setSelectedToDate] = useState(storedToDate ? storedToDate : null);
  const sorting = useSelector(state => state.search.sorting);
  const companyUuid = useSelector(state => state.companies.currentUuid);

  let searchDisabled = banks.length === 0 || ((!transactions || transactions.length === 0) && !isSearch(searchOptions))

  async function handleSubmit(values) {

  }

  async function handleFromDateChange(date) {
    setSelectedFromDate(date)
    let currentDate = moment(date)
    if (currentDate.isValid()) {
      dispatch(setPage(0))
      dispatch(setSearchFilters(storedTerm, date, storedToDate))
    }
  }

  async function handleToDateChange(date) {
    setSelectedToDate(date)
    let currentDate = moment(date)
    if (currentDate.isValid()) {
      dispatch(setPage(0))
      dispatch(setSearchFilters(storedTerm, storedFromDate, date))
    }
  }

  async function handleChange(event) {
    if (timer) {
      clearTimeout(timer)
    }
    event.preventDefault()
    let searchTerm = event.target.value
    setSearchValue(searchTerm)

    setTimer(setTimeout(() => {
      dispatch(setPage(0))
      dispatch(setSearchFilters(searchTerm, storedFromDate, storedToDate))
    }, 250))
  }

  async function handleResetFiltersClick(event) {
    event.preventDefault()
    dispatch(setPage(0))
    setSearchValue("")
    setSelectedFromDate(null)
    setSelectedToDate(null)
    dispatch(resetSearchFilters())
  }

  useEffect(() => {
    if (banksSynced) {
      setTimer(setTimeout(() => {
        dispatch(transactionActions.searchTransactions())
      }, 200))
    }

    return () => { }
  }, [storedTerm, storedFromDate, storedToDate, currentRowsPerPage, dispatch, banksSynced, sorting, currentPage, companyUuid])


  return (
    <Box className={classes.searchBarBox}>
      <Formik
        initialValues={{ search: searchValue }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Grid container spacing={3} className={classes.searchBarContainer}>
              <Grid item lg={5} className={classes.searchFieldItem}>
                <TextField
                  type="text"
                  name="search"
                  placeholder="Filter by description"
                  onChange={handleChange}
                  className={classes.searchInputMaterial}
                  value={searchValue ? searchValue : ""}
                  disabled={searchDisabled}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item className={classes.searchFiltersItem}>
                <Grid container spacing={3} className={classes.searchFiltersContainer}>
                  <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        autoOk
                        disableToolbar
                        autoComplete="off"
                        inputVariant="outlined"
                        variant="inline"
                        label="From date"
                        disabled={searchDisabled}
                        value={selectedFromDate}
                        onChange={date => handleFromDateChange(date)}
                        format="DD/MM/YYYY"
                        maxDate={selectedToDate ? selectedToDate : moment()}
                        className={classes.datePicker}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <DateRangeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        autoOk
                        disableToolbar
                        autoComplete="off"
                        inputVariant="outlined"
                        variant="inline"
                        label="To date"
                        disabled={searchDisabled}
                        value={selectedToDate}
                        onChange={date => handleToDateChange(date)}
                        format="DD/MM/YYYY"
                        maxDate={moment()}
                        className={classes.datePicker}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <DateRangeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>

                  </Grid>
                  <Grid item>
                    <TableButton
                      onClick={handleResetFiltersClick}
                      className={classes.resetFiltersButton}
                      disabled={searchDisabled}
                    >
                      Clear filters
                  </TableButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )

}