import { useRect } from "@reach/rect";
import { scaleLinear, scaleTime } from "d3-scale";
import { extent } from "d3-array";
import { css } from "emotion";
import React from "react";
import { identity } from "../utils/function";

export const useChart = ({ data, domain = identity, xTicks, yTicks }) => {
  const containerRef = React.useRef();
  const containerRect = useRect(containerRef, true);

  const xExtent = extent(
    data.flatMap((d) => d.data),
    (d) => d[0]
  );
  const yExtent = extent(
    data.flatMap((d) => d.data),
    (d) => d[1]
  );

  const [xDomain, yDomain] =
    typeof domain !== "function" ? domain : domain([xExtent, yExtent]);

  const scaleX = scaleTime()
    .domain(xDomain)
    .range([0, containerRect?.width || 0]);

  const scaleY = scaleLinear()
    .domain(yDomain)
    .range([containerRect?.height || 0, 0]);

  const yDomainSize = Math.abs(yExtent[1] - yExtent[0]);

  return {
    containerRef,
    containerRect,
    data,
    domain: [xDomain, yDomain],
    extent: [xExtent, yExtent],
    scaleX,
    scaleY,
    xTicks:
      typeof xTicks === "function"
        ? xTicks(xDomain, containerRect?.width)
        : xTicks ?? scaleX.ticks(Math.min(data[0].data.length, 12)),
    yTicks:
      typeof yTicks === "function"
        ? yTicks(yDomain)
        : yTicks ?? scaleY.ticks(Math.min(yDomainSize, 3)),
  };
};

export const Chart = ({
  children,
  width = "100%",
  height = "10rem",
  ...props
}) => {
  const config = useChart(props);
  return (
    <div
      ref={config.containerRef}
      style={{ width, maxWidth: "100%", position: "relative" }}
    >
      <svg
        width="100%"
        height={height}
        style={{ display: "block", overflow: "visible" }}
      >
        {children(config)}
      </svg>
    </div>
  );
};

export const VerticalLine = ({
  color = "black",
  weight = "0.1rem",
  style = "solid",
  x,
  y,
  length,
  zIndex,
}) => (
  <div
    className={css({ position: "absolute", width: 0 })}
    style={{
      left: x,
      top: y,
      height: length,
      borderColor: color,
      borderStyle: style,
      borderLeftWidth: weight,
      zIndex,
      transform: "translateX(-50%)",
    }}
  />
);

export const HorizontalLine = ({
  color = "black",
  weight = "0.1rem",
  style = "solid",
  x,
  y,
  length,
  zIndex,
}) => (
  <div
    className={css({ position: "absolute", height: 0 })}
    style={{
      left: x,
      top: y,
      width: length,
      borderColor: color,
      borderStyle: style,
      borderTopWidth: weight,
      zIndex,
      transform: "translateY(-50%)",
    }}
  />
);

export const Grid = ({
  color = "black",
  style = "solid",
  xTicks,
  yTicks,
  scaleX,
  scaleY,
}) => (
  <>
    {xTicks.map((x) => (
      <VerticalLine
        key={x}
        x={scaleX(x)}
        y={0}
        length="100%"
        zIndex={0}
        color={typeof color === "function" ? color({ x }) : color}
        style={style}
      />
    ))}
    {yTicks.map((y) => (
      <HorizontalLine
        key={y}
        x={0}
        y={scaleY(y)}
        length="100%"
        zIndex={0}
        color={typeof color === "function" ? color({ y }) : color}
        style={style}
      />
    ))}
  </>
);

export const XAxis = ({ labelColor, axisColor, ticks, renderTick, scale }) => (
  <>
    <HorizontalLine
      x="-0.25rem"
      y="100%"
      length="calc(100% + 0.5rem)"
      color={axisColor}
    />

    {ticks.map((x) => (
      <React.Fragment key={x}>
        <VerticalLine
          x={scale(x)}
          y="100%"
          length="0.25rem"
          color={axisColor}
        />
        <div
          className={css({
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            transform: "translateX(-50%)",
            fontSize: "1rem",
            whiteSpace: "nowrap",
            color: labelColor,
          })}
          style={{ left: scale(x) }}
        >
          {typeof renderTick === "function" ? renderTick(x) : x}
        </div>
      </React.Fragment>
    ))}
  </>
);

export const YAxis = ({
  labelColor,
  axisColor,
  ticks,
  scale,
  renderTick,
  orientation,
}) => (
  <>
    <VerticalLine
      x={orientation === "right" ? "100%" : 0}
      y="-0.25rem"
      length="calc(100% + 0.5rem)"
      color={axisColor}
    />

    {ticks.map((y) => (
      <React.Fragment key={`tick-${y}`}>
        <HorizontalLine
          x={orientation === "right" ? "100%" : "-0.25rem"}
          y={scale(y)}
          length="0.25rem"
          color={axisColor}
        />
        <div
          className={css({
            position: "absolute",
            left: orientation === "right" ? "calc(100% + 0.5rem)" : undefined,
            right: orientation === "right" ? undefined : "calc(100% + 0.5rem)",
            transform: "translateY(-50%)",
            fontSize: "1rem",
            whiteSpace: "nowrap",
            color: labelColor,
          })}
          style={{ top: scale(y) }}
        >
          {typeof renderTick === "function" ? renderTick(y) : y}
        </div>
      </React.Fragment>
    ))}
  </>
);
