import { useReducer, useCallback } from "react";
import {
  getTransactions,
  update as updateTransaction,
} from "../api/transactions";
import { indexBy } from "../utils/array";
import { combine as combineReducers } from "../utils/reducers";

const createQueryCacheKey = ({
  page,
  size,
  search,
  sorting,
  range: [fromDate, toDate] = [],
  minAmount,
  maxAmount,
  bankAccounts,
  vendors,
  categories,
  companyId,
}) =>
  [
    page,
    size,
    search,
    sorting,
    fromDate?.getTime(),
    toDate?.getTime(),
    minAmount,
    maxAmount,
    bankAccounts?.join(""),
    vendors?.join(""),
    categories?.join(""),
    companyId,
  ]
    .filter(Boolean)
    .join("");

const initialState = {
  transactionsById: {},
  metadataByQuery: {},
};

const transactionsById = (state, action) => {
  switch (action.type) {
    case "fetch-success":
      return {
        ...state,
        ...indexBy((t) => t.uuid, action.payload.data),
      };
    case "update-transaction": {
      const transaction = state[action.id];
      if (transaction == null) return state;
      return {
        ...state,
        [action.id]: { ...transaction, ...action.attributes },
      };
    }
    default:
      return state;
  }
};

const metadataByQuery = (state, action) => {
  switch (action.type) {
    case "fetch-dispatch": {
      const queryCacheKey = createQueryCacheKey(action.query);
      return {
        ...state,
        [queryCacheKey]: {
          ...state[queryCacheKey],
          isFetching: true,
        },
      };
    }
    case "fetch-success": {
      const queryCacheKey = createQueryCacheKey(action.query);
      const { data, ...rest } = action.payload;
      return {
        ...state,
        [queryCacheKey]: {
          ...state[queryCacheKey],
          ids: data.map((t) => t.uuid),
          ...rest,
          isFetching: false,
          didSuccessfullyFetch: true,
        },
      };
    }
    case "fetch-error": {
      const queryCacheKey = createQueryCacheKey(action.query);
      return {
        ...state,
        [queryCacheKey]: {
          ...state[queryCacheKey],
          isFetching: false,
        },
      };
    }
    default:
      return state;
  }
};

const useTransactions = ({ companyId, bankAccounts }) => {
  const [state, dispatch] = useReducer(
    combineReducers({ transactionsById, metadataByQuery }),
    initialState
  );

  const createQuery = useCallback(
    ({ bankAccounts: customBankAccountIds, ...rest }) => {
      return {
        // Default to all enabled accounts
        bankAccounts: customBankAccountIds ?? bankAccounts.map((a) => a.id),
        companyId,
        ...rest,
      };
    },
    [bankAccounts, companyId]
  );

  const fetchData = useCallback(
    (rawQuery) => {
      const query = createQuery(rawQuery);
      dispatch({ type: "fetch-dispatch", query });

      return getTransactions(query).then(
        (res) => {
          dispatch({ type: "fetch-success", query, payload: res });
          return res;
        },
        (error) => {
          dispatch({ type: "fetch-error", query, error });
          return Promise.reject(error);
        }
      );
    },
    [createQuery]
  );

  const update = useCallback(
    (id, attributes) => {
      dispatch({ type: "update-transaction", id, attributes });
      return updateTransaction(id, { ...attributes, companyId });
    },
    [companyId]
  );

  const getState = useCallback(
    (selector) => selector(state, { companyId, bankAccounts, createQuery }),
    [state, companyId, bankAccounts, createQuery]
  );

  return [getState, { fetchData, update }];
};

export const selectById = (id) => (state, { bankAccounts }) => {
  const transaction = state.transactionsById[id];
  const bankAccount = bankAccounts.find(
    (a) => a.id === transaction.bank_account
  );
  return {
    ...transaction,
    bankAccount: bankAccount?.name,
  };
};

export const selectMetadataByQuery = (query) => (state, { createQuery }) => {
  const queryCacheKey = createQueryCacheKey(createQuery(query));
  return state.metadataByQuery[queryCacheKey];
};

export const selectByQuery = (query) => (state, config) => {
  const metadata = selectMetadataByQuery(query)(state, config);
  if (metadata?.ids == null) return null;
  return metadata.ids.map((id) => selectById(id)(state, config));
};

export default useTransactions;
