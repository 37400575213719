import { css } from "emotion";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { update as updateQueryString } from "../utils/query-string";

const CategoryTag = React.forwardRef(
  ({ label, id, color, component: Component = "div", ...props }, ref) => {
    const location = useLocation();
    return (
      <Component
        ref={ref}
        title={label}
        className={css({
          display: "inline-block",
          background: color,
          fontSize: "1.1rem",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          fontWeight: "700",
          whiteSpace: "nowrap",
          padding: "0.4rem 0.8rem",
          borderRadius: "0.5rem",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ":hover": { maxWidth: "none" },
        })}
        {...props}
      >
        {/* TODO: when we have status filter we can handle Pending transactions */}
        {id ? (
          <RouterLink
            to={{
              pathname: "/dashboard/transactions",
              search: updateQueryString(location.search, {
                categories: id,
              }),
            }}
            className={css({ ":hover": { opacity: 0.7 } })}
          >
            {label}
          </RouterLink>
        ) : (
          label
        )}
      </Component>
    );
  }
);

export default CategoryTag;
