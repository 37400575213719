import formatDate from "date-fns/format";
import subtractMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PRIMARY_COLOR } from "../constants/colors";
import { sum } from "../utils/array";
import {
  isOutgoing as isOutgoingCategory,
  isIncoming as isIncomingCategory,
} from "../utils/categories";
import useMetricData from "../hooks/metric-data";
import useTransactions from "../hooks/transactions-data";
import useStore from "../hooks/stores";
import G from "./Grid";
import T from "./Text";
import Divider from "./Divider";
import FormattedDate from "./FormattedDate";
import VendorIcon from "./VendorIcon";
import AnalysisPage from "./AnalysisPage";

const frequency = "monthly";

const useVendorTransactions = (vendorId) => {
  const vendors = React.useMemo(() => [vendorId], [vendorId]);

  const [{ data, meta }] = useTransactions({ vendors });

  return { data, meta };
};

const useVendorsWithCashFlow = ({ from, to }) => {
  const vendors = useStore("vendors");

  const vendorGroupQuery = React.useMemo(
    () => ({
      from,
      to,
      metric: "grouped_transactions",
      groupBy: "merchant",
    }),
    [from, to]
  );

  const { vendorGroups } = useMetricData({
    vendorGroups: vendorGroupQuery,
  });

  const vendorIdsWithData = vendorGroups?.map((d) => d.id) ?? [];

  return vendors.filter((v) => vendorIdsWithData.includes(v.id));
};

const VendorPage = ({ history, location, vendor }) => {
  const categories = useStore("categories");

  const to = React.useMemo(() => endOfMonth(subtractMonths(new Date(), 1)), []);
  const from = React.useMemo(() => startOfMonth(subtractMonths(to, 12)), [to]);

  const vendorsWithCashFlow = useVendorsWithCashFlow({ from, to });

  const { data: transactions, meta: transactionsMeta } = useVendorTransactions(
    vendor.id
  );

  const vendorNetQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      metric: "money_net",
      vendors: [vendor.id],
    }),
    [from, to, vendor.id]
  );

  const { net: netRaw } = useMetricData({ net: vendorNetQuery });

  const isIncomeVendor = sum((d) => d.value, netRaw ?? []) > 0;

  const allVendorsNetQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      categories: categories
        .filter(isIncomeVendor ? isIncomingCategory : isOutgoingCategory)
        .map((c) => c.id),
      metric: "money_net",
    }),
    [from, to, isIncomeVendor, categories]
  );

  const uncategorizedQuery = React.useMemo(
    () => ({
      from,
      to,
      frequency,
      categories: ["unknown"],
      metric: isIncomeVendor ? "money_in" : "money_out",
    }),
    [from, to, isIncomeVendor]
  );

  const { uncategorized, allNet } = useMetricData({
    allNet: allVendorsNetQuery,
    uncategorized: uncategorizedQuery,
  });

  const net = React.useMemo(() => {
    if (isIncomeVendor) return netRaw;

    return netRaw?.map((d) => ({
      ...d,
      // Flip sign for outgoing
      value: d.value === 0 ? 0 : d.value * -1,
    }));
  }, [netRaw, isIncomeVendor]);

  const all = React.useMemo(() => {
    const uncategorizedByDate = (uncategorized ?? [])?.reduce(
      (acc, d) => ({ ...acc, [d.date]: d }),
      {}
    );

    return allNet
      ?.map((d) => {
        const uncategorizedAmount = uncategorizedByDate[d.date]?.value ?? 0;
        return { ...d, value: d.value + uncategorizedAmount };
      })
      .map((d) => {
        const amount =
          d.value === 0
            ? 0
            : isIncomeVendor
            ? d.value
            : // We don't want to have negative values for spend, could technically happen
              Math.max(d.value * -1, 0);
        return { ...d, value: amount };
      });
  }, [isIncomeVendor, allNet, uncategorized]);

  return (
    <AnalysisPage
      history={history}
      location={location}
      title={
        <G align="center" gap="0.7rem">
          <VendorIcon vendor={vendor} style={{ margin: "-100% 0" }} />
          <div>{vendor.description}</div>
        </G>
      }
      options={vendorsWithCashFlow.map((v) => ({
        value: v.id,
        label: v.description,
      }))}
      selectedOption={{ value: vendor.id, label: vendor.description }}
      onSelectOption={(vendorId) =>
        history.push(`/dashboard/vendors/${vendorId}`)
      }
      isIncomeQuery={isIncomeVendor}
      range={[from, to]}
      queriedAmounts={net}
      totalAmounts={all}
      // queriedVendorAmounts={categoryVendors}
      transactions={transactions}
      totalTransactionCount={transactionsMeta?.count}
      createTransactionListLink={({ from, to } = {}) =>
        [
          `/dashboard/transactions`,
          new URLSearchParams(
            [
              ["vendors", vendor.id],
              ["from", from == null ? null : formatDate(from, "yyyy-MM-dd")],
              ["to", to == null ? null : formatDate(to, "yyyy-MM-dd")],
            ].filter(([_, value]) => value != null)
          ).toString(),
        ].join("?")
      }
      noDataPlaceholder={
        <NoDataPlaceholder vendor={vendor} from={from} to={to} />
      }
    />
  );
};

const NoDataPlaceholder = ({ vendor, from, to }) => (
  <div
    style={{
      padding: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    }}
  >
    <div
      style={{
        paddingBottom: "20vh",
        textAlign: "center",
        fontSize: "1.6rem",
      }}
    >
      <T weight="500" size="1.6rem">
        Looks like you don't have any data for <T bold>{vendor.description}</T>{" "}
        between <FormattedDate value={from} month="short" year="numeric" /> and{" "}
        <FormattedDate value={to} month="short" year="numeric" />
      </T>
      <Divider />
      <RouterLink
        to={`/dashboard/transactions?vendors=${vendor.id}`}
        style={{
          color: PRIMARY_COLOR,
          fontWeight: "700",
          textDecoration: "none",
        }}
      >
        <span style={{ textDecoration: "underline" }}>See transactions</span>{" "}
        &rarr;
      </RouterLink>
    </div>
  </div>
);

export default VendorPage;
