import { css } from "emotion";
import React from "react";
import { PRIMARY_COLOR } from "../constants/colors";

const base = {
  fontWeight: "500",
  transition: "0.1s opacity",
};

const label = {
  fontSize: "1.1rem",
  fontWeight: "800",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
};
const paragraph = {
  lineHeight: 1.3,
  fontWeight: "500",
};
const paragraphSmall = {
  ...paragraph,
  fontSize: "1.3rem",
};

const LIGHT_ON_LIGHT = "rgb(0 0 0 / 54%)";

const stylesByVariant = {
  label,
  "label-light": {
    ...label,
    color: "rgba(0,0,0,0.54)",
  },
  paragraph,
  "paragraph-light": { ...paragraph, color: LIGHT_ON_LIGHT },
  "paragraph-small": paragraphSmall,
  "paragraph-small-light": { ...paragraphSmall, color: LIGHT_ON_LIGHT },
  "paragraph-tiny-light": {
    ...paragraph,
    fontSize: "1.2rem",
    color: LIGHT_ON_LIGHT,
  },
  "paragraph-large": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "500",
  },
  headline: {
    fontSize: "2.2rem",
    fontWeight: "700",
  },
  "headline-large": {
    fontSize: "2.8rem",
    fontWeight: "700",
  },
  "headline-small": {
    fontSize: "1.6rem",
    fontWeight: "700",
  },
  "number-large": {
    fontSize: "2.4rem",
    fontWeight: "800",
  },
  link: {
    textDecoration: "underline",
    color: PRIMARY_COLOR,
    fontWeight: "700",
  },
};

const defaultComponentByVariant = {
  paragraph: "p",
};

const Text = React.forwardRef(
  (
    {
      variant,
      color,
      size,
      weight,
      margin,
      align,
      lineHeight,
      block = false,
      italic = false,
      bold = false,
      truncate = false,
      underline = false,
      hide = false,
      component = "span",
      children,
      style: customStyle,
      inlineStyle,
      ...props
    },
    ref
  ) => {
    if (variant && !stylesByVariant[variant])
      throw new Error(`Unknown variant "${variant}"`);

    const style = { ...base, ...stylesByVariant[variant] };

    if (size) style.fontSize = size;
    if (weight) style.fontWeight = weight;
    if (color) style.color = color;
    if (lineHeight) style.lineHeight = lineHeight;
    if (margin) style.margin = margin;
    if (italic) style.fontStyle = "italic";
    if (bold) style.fontWeight = "700";
    if (align) style.textAlign = align;
    if (block) style.display = "block";
    if (truncate) {
      style.whiteSpace = "nowrap";
      style.overflow = "hidden";
      style.textOverflow = "ellipsis";
    }
    if (underline) style.textDecoration = "underline";
    if (hide) style.opacity = 0;

    return React.createElement(
      defaultComponentByVariant[variant] || component,
      {
        ...props,
        ref,
        className: css({ ...style, ...customStyle }),
        style: inlineStyle,
      },
      children
    );
  }
);

export default Text;
