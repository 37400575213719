import { css } from "emotion";
import React from "react";
import Popover from "@reach/popover";
import { line, area } from "d3-shape";
import {
  GRID as GRID_COLOR,
  AXIS as AXIS_COLOR,
} from "../constants/chart-colors";
import { useChart, Grid, XAxis, YAxis } from "./chart-utils";
import FormattedDate from "./FormattedDate";
import FormattedNumber from "./FormattedNumber";

const LineChart = ({
  data,
  domain: domainProp,
  width = "100%",
  height = "18rem",
  xTicks: xTicksProp,
  yTicks: yTicksProp,
  renderXTick,
  renderYTick,
  onClick,
  hoverPopoverPosition,
  renderHoverPopoverContent,
  gridColor = GRID_COLOR,
  axisColor = AXIS_COLOR,
  tickColor = "rgb(0 0 0 / 54%)",
}) => {
  const [hoveredX, setHoveredX] = React.useState(null);
  const hoveredXRef = React.useRef();

  const { containerRef, domain, scaleX, scaleY, xTicks, yTicks } = useChart({
    data,
    domain: domainProp,
    xTicks: xTicksProp,
    yTicks: yTicksProp,
  });

  const [[xDomainStart, xDomainEnd], [yDomainStart, yDomainEnd]] = domain;

  const defaultHoverPopoverPosition = React.useCallback(
    (hoveredRect, popoverRect) => {
      const hoveredRectCenter = hoveredRect.left + hoveredRect.width / 2;

      const top = `calc(${hoveredRect.top + window.scrollY}px + 2rem)`;
      const left =
        hoveredRectCenter + popoverRect.width + 40 > window.innerWidth
          ? `calc(${hoveredRectCenter - popoverRect.width}px - 2rem)`
          : `calc(${hoveredRectCenter}px + 2rem)`;

      return { top, left };
    },
    []
  );

  return (
    <div
      ref={containerRef}
      className={css({
        width,
        maxWidth: "100%",
        position: "relative",
      })}
    >
      <Grid
        xTicks={xTicks}
        yTicks={yTicks}
        scaleX={scaleX}
        scaleY={scaleY}
        color={({ y, x }) => (y === 0 || x != null ? "transparent" : gridColor)}
        style="solid" // eslint-disable-line react/style-prop-object
      />

      <YAxis
        ticks={yTicks}
        scale={scaleY}
        orientation="right"
        axisColor={axisColor}
        labelColor={tickColor}
        renderTick={(y) => {
          if (typeof renderYTick === "function") return renderYTick(y);

          return (
            <FormattedNumber
              value={y}
              style="decimal" // eslint-disable-line react/style-prop-object
              notation="compact"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          );
        }}
      />
      <XAxis
        ticks={xTicks}
        scale={scaleX}
        axisColor={axisColor}
        labelColor={tickColor}
        renderTick={(x) => {
          if (typeof renderXTick === "function") return renderXTick(x);
          return <FormattedDate value={x} day="numeric" month="short" />;
        }}
      />

      <svg
        width="100%"
        height={height}
        className={css({
          display: "block",
          position: "relative",
          zIndex: 1,
          overflow: "visible",
        })}
      >
        {data.map(({ data, area, color }, i) =>
          area ? (
            <Area
              key={`${color}-${i}`}
              data={data}
              scaleX={scaleX}
              scaleY={scaleY}
              fill={typeof area === "string" ? area : undefined}
            />
          ) : null
        )}

        {hoveredX != null && (
          <line
            x1={scaleX(hoveredX)}
            y1={scaleY(yDomainStart)}
            x2={scaleX(hoveredX)}
            y2={scaleY(yDomainEnd)}
            stroke="rgb(0 0 0 / 25%)"
            strokeDasharray="4 2"
          />
        )}

        {data.map(({ data, color }) => (
          <Line
            key={color}
            data={data}
            scaleX={scaleX}
            scaleY={scaleY}
            stroke={color}
            strokeWidth={2}
          />
        ))}

        {data.map(({ data, color }) =>
          data.map(([x, y]) => (
            <circle
              key={`${color}-${x.getTime()}`}
              cy={scaleY(y)}
              cx={scaleX(x)}
              r={3}
              fill={hoveredX?.getTime() === x.getTime() ? color : "transparent"}
            />
          ))
        )}

        {data[0].data.map(([x]) => {
          // This assumes that datapoints are evenly spaced
          const full = scaleX(xDomainEnd) - scaleX(xDomainStart);
          const width = full / data[0].data.length;
          return (
            <rect
              key={x.getTime()}
              x={scaleX(x) - width / 2}
              y={0}
              width={width}
              height="100%"
              onClick={
                typeof onClick === "function" ? () => onClick(x) : undefined
              }
              onMouseMove={(e) => {
                hoveredXRef.current = e.target;
                setHoveredX(x);
              }}
              onMouseLeave={() => {
                hoveredXRef.current = null;
                setHoveredX(null);
              }}
              fill="transparent"
            />
          );
        })}
      </svg>

      {hoveredX != null && typeof renderHoverPopoverContent === "function" && (
        <Popover
          targetRef={hoveredXRef}
          style={{ zIndex: 1, position: "absolute", pointerEvents: "none" }}
          position={hoverPopoverPosition ?? defaultHoverPopoverPosition}
        >
          {renderHoverPopoverContent(hoveredX)}
        </Popover>
      )}
    </div>
  );
};

export const Line = ({
  data,
  scaleX,
  scaleY,
  stroke = "black",
  fill = "none",
  strokeWidth = "0.1rem",
  strokeLinecap = "round",
  strokeLinejoin = "round",
  strokeDasharray = undefined,
  ...props
}) => {
  const createLine = line()
    .x((d) => scaleX(d[0]))
    .y((d) => scaleY(d[1]));
  return (
    <path
      d={createLine(data)}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
      strokeDasharray={strokeDasharray}
      {...props}
    />
  );
};

export const Area = ({ data, scaleX, scaleY, fill = "#eee", ...props }) => {
  const createArea = area()
    .x((d) => scaleX(d[0]))
    .y1((d) => scaleY(d[1]))
    .y0((d) => scaleY(d[2] ?? 0));
  return <path d={createArea(data)} fill={fill} {...props} />;
};

export default LineChart;
