import formatDate from "date-fns/format";
import startOfYear from "date-fns/startOfYear";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfMonth from "date-fns/startOfMonth";
import endOfYear from "date-fns/endOfYear";
import endOfQuarter from "date-fns/endOfQuarter";
import endOfMonth from "date-fns/endOfMonth";
import subtractMonths from "date-fns/subMonths";
import getQuarter from "date-fns/getQuarter";
import { css } from "emotion";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { PRIMARY_COLOR, GREEN_TEXT, RED_TEXT } from "../../constants/colors";
import { parse as parseDate } from "../../utils/date";
import { update as updateQueryString } from "../../utils/query-string";
import { groupBy, sort } from "../../utils/array";
import { ascending } from "../../utils/comparators";
import { selectDataByQuery as selectMetricDataByQuery } from "../../hooks/metrics";
import Box from "../Box";
import Text from "../Text";
import Divider from "../Divider";
import Icon from "../Icon";
import FormattedDate from "../FormattedDate";
import FormattedCurrency from "../FormattedCurrency";
import FormattedNumber from "../FormattedNumber";
import Select from "../Select";
import Button from "../Button";
import DatePicker from "../DatePicker";
import InlineTag from "../InlineTag";
import Tooltip from "../Tooltip";
import CashFlowGraph from "../CashFlowGraph";
import Section from "../PageSubSection";
import VendorIcon from "../VendorIcon";
import { descending } from "../../utils/comparators";

const colorByMetric = {
  close_balance: PRIMARY_COLOR,
  money_in: GREEN_TEXT,
  money_out: "#c4c4c4",
  money_net: "black",
};

const VARIANCE_THRESHOLD = 0.1;

const frequencies = [
  // {
  //   label: "Daily",
  //   key: "daily",
  //   deriveRange: (date) => [startOfDay(date), endOfDay(date)],
  //   renderRange: ([start]) => (
  //     <FormattedDate value={start} month="short" day="numeric" year="numeric" />
  //   ),
  //   group: (datapoints) =>
  //     groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  // },
  // {
  //   label: "Weekly",
  //   key: "weekly",
  //   deriveRange: (date) => [
  //     startOfWeek(date, { weekStartsOn: 1 }),
  //     endOfWeek(date, { weekStartsOn: 1 }),
  //   ],
  //   renderRange: ([start, end]) => (
  //     <>
  //       <FormattedDate value={start} month="short" day="numeric" /> &ndash;{" "}
  //       <FormattedDate value={end} month="short" day="numeric" year="numeric" />
  //     </>
  //   ),
  //   group: (datapoints) =>
  //     groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  // },
  {
    label: "Monthly",
    key: "monthly",
    deriveRange: (date) => [startOfMonth(date), endOfMonth(date)],
    renderPeriod: (date, { short = false } = {}) => (
      <FormattedDate
        value={date}
        month={short ? "short" : "long"}
        year="numeric"
      />
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Quarterly",
    key: "quarterly",
    deriveRange: (date) => [startOfQuarter(date), endOfQuarter(date)],
    renderPeriod: (date) => (
      <>
        Q{getQuarter(date)} <FormattedDate value={date} year="numeric" />
      </>
    ),
    group: (datapoints) =>
      groupBy((dp) => startOfYear(dp.date).getTime(), datapoints),
  },
  {
    label: "Yearly",
    key: "yearly",
    deriveRange: (date) => [startOfYear(date), endOfYear(date)],
    renderPeriod: (date) => <FormattedDate value={date} year="numeric" />,
    group: (datapoints) => ({ 0: datapoints }),
  },
];

const metrics = ["close_balance", "money_in", "money_out", "money_net"];

const CashFlow = ({
  categories,
  vendors,
  getMetricState,
  fetchMetricData,
  fetchVarianceBreakdown,
  history,
  location,
}) => {
  const currency = useSelector((state) => state.settings.table.currency);

  const defaultFromDate = React.useMemo(
    () => subtractMonths(new Date(), 12),
    []
  );

  const {
    frequency = "monthly",
    from: fromDateString = formatDate(defaultFromDate, "yyyy-MM-dd"),
    to: toDateString,
    primary: selectedPrimaryDateString,
    compared: selectedComparedDateString,
  } = Object.fromEntries(new URLSearchParams(location.search).entries());

  const metricQuery = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries({
          frequency,
          from: fromDateString,
          to: toDateString,
        })
          .filter(([_, value]) => value != null)
          .map(([key, value]) => {
            switch (key) {
              case "from":
              case "to":
                // Treat invalid dates string as 'null'
                try {
                  return [key, parseDate(value, "yyyy-MM-dd")];
                } catch (e) {
                  return [key, null];
                }
              default:
                return [key, value];
            }
          })
      ),
    [frequency, fromDateString, toDateString]
  );

  const [selectedPrimaryDate, selectedComparedDate] = React.useMemo(
    () =>
      [selectedPrimaryDateString, selectedComparedDateString].map((d) => {
        if (d == null) return null;
        try {
          return parseDate(d, "yyyy-MM-dd");
        } catch (e) {
          return null;
        }
      }),
    [selectedPrimaryDateString, selectedComparedDateString]
  );

  const { from: fromDate, to: toDate } = metricQuery;

  const setSelectedDates = ({
    primary = selectedPrimaryDate,
    compared = selectedComparedDate,
  }) =>
    history.replace({
      search: updateQueryString(location.search, {
        primary:
          primary == null ? undefined : formatDate(primary, "yyyy-MM-dd"),
        compared:
          compared == null ? undefined : formatDate(compared, "yyyy-MM-dd"),
      }),
    });

  const dataByMetric = metrics.reduce(
    (dataByMetric, metric) => ({
      ...dataByMetric,
      [metric]:
        getMetricState(selectMetricDataByQuery({ metric, ...metricQuery })) ??
        [],
    }),
    {}
  );

  React.useEffect(() => {
    for (let metric of metrics) fetchMetricData({ metric, ...metricQuery });
  }, [fetchMetricData, metricQuery]);

  const { deriveRange, renderPeriod } = frequencies.find(
    (f) => f.key === frequency
  );

  const deriveMostRecentRanges = (frequency) => {
    const { deriveRange } = frequencies.find(({ key }) => key === frequency);
    const primaryRange = deriveRange(toDate ?? new Date());
    const comparedRange = deriveRange(new Date(primaryRange[0].getTime() - 1));

    return [primaryRange, comparedRange];
  };

  React.useEffect(() => {
    if ([selectedPrimaryDate, selectedComparedDate].some((d) => d != null))
      return;

    const [primaryRange, comparedRange] = deriveMostRecentRanges(frequency);
    const [primaryDateString, comparedDateString] = [
      primaryRange,
      comparedRange,
    ].map((r) => formatDate(r[0], "yyyy-MM-dd"));

    history.replace({
      search: updateQueryString(location.search, {
        frequency,
        primary: primaryDateString,
        compared: comparedDateString,
      }),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={css({
          borderBottom: "0.1rem solid",
          borderColor: "rgb(239, 241, 244)",
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          height: "6rem",
          gap: "2rem",
          padding: "1rem",
          paddingLeft: "2rem",
        })}
      >
        <Text variant="headline" component="h2" size="1.6rem">
          Cash overview
        </Text>
        <div
          className={css({
            display: "grid",
            alignItems: "center",
            gridAutoColumns: "auto",
            gridAutoFlow: "column",
            gridGap: "1rem",
          })}
        >
          <DatePicker
            range={[fromDate, toDate]}
            onChange={([from, to]) =>
              history.push({
                search: updateQueryString(location.search, {
                  from: formatDate(from, "yyyy-MM-dd"),
                  to: formatDate(to, "yyyy-MM-dd"),
                  primary: undefined,
                  compared: undefined,
                }),
              })
            }
            label={
              <>
                <Icon
                  name="calendar"
                  size="1.4rem"
                  style={{ marginRight: "1rem" }}
                />
                <Text truncate style={{ flex: 1 }}>
                  {fromDate || toDate
                    ? [fromDate, toDate]
                        .map((d) => (d ? formatDate(d, "yyyy-MM-dd") : "*"))
                        .join(" – ")
                    : "Select interval"}
                </Text>
              </>
            }
          />
          <Select
            value={frequency}
            options={frequencies.map(({ label, key }) => ({
              label,
              value: key,
            }))}
            onChange={({ target: { value: frequency } }) => {
              // Auto select the most recent data points
              const [primaryRange, comparedRange] = deriveMostRecentRanges(
                frequency
              );
              const [primaryDateString, comparedDateString] = [
                primaryRange,
                comparedRange,
              ].map((r) => formatDate(r[0], "yyyy-MM-dd"));

              history.push({
                search: updateQueryString(location.search, {
                  frequency,
                  primary: primaryDateString,
                  compared: comparedDateString,
                }),
              });
            }}
            renderTrigger={(selectedOption, isHovered) => (
              <Button
                component="div"
                variant="transparent"
                size="small"
                isHovered={isHovered}
              >
                <Icon
                  name="ratio"
                  size="1.4rem"
                  style={{ marginRight: "0.7rem" }}
                />
                {selectedOption.label}
              </Button>
            )}
          />
        </div>
      </div>

      <div className={css({ flex: "1 1", overflow: "auto" })}>
        <div className={css({ padding: "2rem" })}>
          <CashFlowGraph
            getMetricState={getMetricState}
            frequency={frequency}
            fromDate={fromDate}
            toDate={toDate}
            renderDate={renderPeriod}
            currency={currency}
            selectedDataPoints={[selectedComparedDate, selectedPrimaryDate]}
            onClickDataPoint={(x) => {
              if (x.getTime() === selectedComparedDate?.getTime()) {
                setSelectedDates({ compared: null });
                return;
              }

              if (x.getTime() === selectedPrimaryDate?.getTime()) {
                setSelectedDates({
                  primary: selectedComparedDate,
                  compared: null,
                });
                return;
              }

              if (selectedComparedDate != null) {
                setSelectedDates({ primary: x, compared: null });
                return;
              }

              const [compared, primary] = sort(ascending, [
                selectedPrimaryDate,
                x,
              ]);
              setSelectedDates({ primary, compared });
            }}
            fetchMetricData={(metric) =>
              fetchMetricData({ metric, ...metricQuery })
            }
          />
        </div>

        <ComparisonTable
          primaryDate={selectedPrimaryDate}
          comparedDate={selectedComparedDate}
          setSelectedDates={setSelectedDates}
          dataByMetric={dataByMetric}
          currency={currency}
        />

        <Divider size="3rem" />

        <Section
          title="Breakdown analysis"
          subtitle="Detected variance by order of importance"
        >
          {selectedPrimaryDate == null || selectedComparedDate == null ? (
            <Box
              style={{
                padding: "1rem 2rem",
                minHeight: "6rem",
                display: "grid",
                gridTemplateColumns: "minmax(0,auto)",
                alignItems: "center",
                ":not(:last-of-type)": { marginBottom: "2rem" },
              }}
            >
              <div
                className={css({
                  display: "grid",
                  alignItems: "center",
                  gridAutoColumns: "auto",
                  gridAutoFlow: "column",
                  justifyContent: "center",
                  gridGap: "1rem",
                })}
              >
                <Text weight="500" size="1.4rem">
                  Select two points on the graph to analyze variance.
                </Text>
              </div>
            </Box>
          ) : (
            <Breakdown
              primaryRange={deriveRange(selectedPrimaryDate)}
              comparedRange={deriveRange(selectedComparedDate)}
              fetchData={fetchVarianceBreakdown}
              categories={categories}
              vendors={vendors}
              currency={currency}
              renderDate={renderPeriod}
            />
          )}
        </Section>

        <Divider size="10rem" />
      </div>
    </>
  );
};

const nameByMetric = {
  close_balance: "Bank balance",
  money_in: "Money in",
  money_out: "Money out",
  money_net: "Net cash flow",
};

const ComparisonTable = ({
  primaryDate,
  comparedDate,
  dataByMetric,
  setSelectedDates,
  currency,
}) => {
  const [selectedPrimaryDataByMetric, selectedComparedDataByMetric] = [
    primaryDate,
    comparedDate,
  ].map((date) =>
    metrics.reduce((valueByMetric, metric) => {
      const metricData = dataByMetric[metric];
      const selectedMetricDataPoint = metricData.find(
        (dp) => dp.date.getTime() === date?.getTime()
      );
      if (selectedMetricDataPoint == null) return valueByMetric;
      return {
        ...valueByMetric,
        [metric]: selectedMetricDataPoint.value,
      };
    }, {})
  );

  return (
    <div style={{ overflow: "auto" }}>
      <div style={{ minWidth: "92rem" }}>
        <div
          className={css({
            display: "grid",
            gridTemplateColumns: "repeat(5, minmax(0,1fr))",
            alignItems: "center",
            gridGap: "1rem",
            padding: "0 2rem",
            minHeight: "4rem",
            background: "#f3f3f3",
          })}
        >
          <Text weight="500" color="rgb(0 0 0 / 54%)">
            Time frame comparison
          </Text>
          {metrics.map((metric) => (
            <div
              key={metric}
              className={css({
                display: "grid",
                gridTemplateColumns: "auto minmax(0,1fr)",
                alignItems: "center",
                justifySelf: "flex-end",
                gridGap: "0.5rem",
              })}
            >
              <div
                className={css({
                  width: "0.8rem",
                  height: "0.8rem",
                  borderRadius: "50%",
                  background: colorByMetric[metric],
                })}
              />
              <Text block truncate weight="500" align="right">
                {nameByMetric[metric]}
              </Text>
            </div>
          ))}
        </div>
        {[
          {
            name: "compared",
            date: comparedDate,
            setDate: (date) => setSelectedDates({ compared: date }),
            valueByMetric: selectedComparedDataByMetric,
          },
          {
            name: "primary",
            date: primaryDate,
            setDate: (date) => setSelectedDates({ primary: date }),
            valueByMetric: selectedPrimaryDataByMetric,
          },
          {
            name: "difference",
            valueByMetric: metrics.reduce((valueByMetric, metric) => {
              if (
                [
                  selectedPrimaryDataByMetric,
                  selectedComparedDataByMetric,
                ].some((dataByMetric) => dataByMetric[metric] == null)
              )
                return valueByMetric;

              const primary = selectedPrimaryDataByMetric[metric];
              const compared = selectedComparedDataByMetric[metric];
              const difference = primary - compared;
              const percentage = difference / Math.abs(compared);

              return {
                ...valueByMetric,
                [metric]: { difference, percentage },
              };
            }, {}),
          },
        ]
          .filter(Boolean)
          .map(({ name, date, setDate, valueByMetric }, i) => (
            <div
              key={name}
              className={css({
                display: "grid",
                gridTemplateColumns: "repeat(5, minmax(0,1fr))",
                alignItems: "center",
                gridGap: "1rem",
                minHeight: "4.6rem",
                padding: "0 2rem",
                borderTop: i === 0 ? "none" : "0.1rem solid #eee",
              })}
            >
              <div>
                {setDate != null && (
                  <FormattedDate month="long" year="numeric">
                    {(formatDate) => (
                      <Select
                        renderTrigger={(_, isHovered) => (
                          <Button
                            size="small"
                            component="div"
                            isHovered={isHovered}
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width: "100%",
                              padding: "0.2rem 0.8rem",
                              minHeight: "3rem",
                            }}
                          >
                            <Text
                              truncate
                              lineHeight="1.5"
                              color={
                                date == null ? "rgb(0 0 0 / 54%)" : undefined
                              }
                            >
                              {date == null ? (
                                "Select..."
                              ) : (
                                <FormattedDate
                                  value={date}
                                  month="long"
                                  year="numeric"
                                />
                              )}
                            </Text>
                          </Button>
                        )}
                        value={date?.getTime() ?? ""}
                        options={[
                          { value: "", label: "-" },
                          ...dataByMetric["close_balance"].map(({ date }) => ({
                            value: date.getTime(),
                            label: formatDate(date),
                          })),
                        ]}
                        onChange={(e) =>
                          setDate(
                            e.target.value === ""
                              ? null
                              : new Date(parseInt(e.target.value))
                          )
                        }
                      />
                    )}
                  </FormattedDate>
                )}
              </div>
              {metrics.map((metric, i) => {
                if (name === "difference") {
                  const { difference, percentage } =
                    valueByMetric[metric] ?? {};
                  const isNegativeIndicator =
                    metric === "money_net" && difference < 0;
                  const isLarge = percentage >= 10;
                  const isSmall = percentage <= -10;
                  return (
                    <Text
                      key={i}
                      block
                      truncate
                      weight={name === "difference" ? "700" : "500"}
                      align="right"
                      color={isNegativeIndicator ? RED_TEXT : undefined}
                    >
                      {difference == null ? (
                        "-"
                      ) : (
                        <>
                          <Text
                            color="rgb(0 0 0 / 54%)"
                            weight="500"
                            style={{ marginRight: "1rem" }}
                          >
                            {isLarge ? ">" : isSmall ? "<" : ""}
                            <FormattedNumber
                              value={Math.max(
                                Math.min(percentage, 9.99),
                                -9.99
                              )}
                              style="percent" // eslint-disable-line
                              locale="en-US"
                            />
                          </Text>
                          <FormattedCurrency
                            value={difference}
                            currency={currency}
                            signDisplay="exceptZero"
                          />
                        </>
                      )}
                    </Text>
                  );
                }

                const value = valueByMetric[metric];

                return (
                  <Text key={i} block truncate weight="500" align="right">
                    {valueByMetric[metric] == null ? (
                      "-"
                    ) : (
                      <FormattedCurrency value={value} currency={currency} />
                    )}
                  </Text>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

const InsightIcon = ({ kind, changePercentage }) => {
  const itemByKind = {
    change: changePercentage < 0 ? "arrow-down-right" : "arrow-up-right",
    cut: "crossed-out-dollar-symbol",
    new: "add-tag",
  };

  return <Icon name={itemByKind[kind]} size="1.8rem" />;
};

const InsightDescription = ({ kind, categoryLink, changePercentage }) => {
  switch (kind) {
    case "income-change":
    case "expense-change":
      return (
        <>
          {changePercentage < 0 ? "Decrease" : "Increase"} in {categoryLink}
        </>
      );
    case "income-cut":
      return <>{categoryLink} income reduced to zero</>;
    case "expense-cut":
      return <>{categoryLink} costs reduced to zero</>;
    case "income-new":
      return <>New income in {categoryLink}</>;
    case "expense-new":
      return <>New costs in {categoryLink}</>;
    default:
      throw new Error(`Unknown kind "${kind}"`);
  }
};

const BreakdownItem = ({
  name,
  kind,
  changeAmount,
  changePercentage,
  selectedAmount,
  comparedAmount,
  category,
  categoryLink,
  vendorIcons,
  currency,
  primaryRange,
  comparedRange,
  renderDate,
}) => {
  return (
    <Box
      style={{
        padding: "1rem 2rem",
        minHeight: "6rem",
        display: "grid",
        gridTemplateColumns: "minmax(0,2fr) minmax(0,1.8fr) minmax(0,2fr)",
        gridGap: "2rem",
        alignItems: "center",
        ":not(:last-of-type)": { marginBottom: "2rem" },
      }}
    >
      <div
        className={css({
          display: "grid",
          alignItems: "center",
          gridAutoColumns: "auto",
          gridAutoFlow: "column",
          justifyContent: "flex-start",
          gridGap: "1rem",
        })}
      >
        <InsightIcon kind={kind} changePercentage={changePercentage} />
        <Text lineHeight={1.2}>
          <InsightDescription
            kind={[name, kind].join("-")}
            changePercentage={changePercentage}
            categoryLink={
              <Tooltip
                label={
                  <div>
                    Click to show{" "}
                    <InlineTag dark>{category?.description}</InlineTag>{" "}
                    transactions
                  </div>
                }
              >
                <Text
                  component={RouterLink}
                  to={categoryLink}
                  weight="700"
                  style={{
                    transition: "0.1s opacity",
                    ":hover": { opacity: 0.7 },
                  }}
                >
                  {category?.description}
                </Text>
              </Tooltip>
            }
          />
        </Text>
      </div>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gridGap: "1rem",
        }}
      >
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            alignItems: "center",
          }}
        >
          {vendorIcons}

          {vendorIcons.length > 4 && (
            <div
              style={{
                position: "relative",
                zIndex: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "3rem",
                height: "3rem",
                boxSizing: "content-box",
                borderRadius: "50%",
                border: "0.2rem solid white",
                background: "#eee",
                fontSize: "1.1rem",
                fontWeight: "700",
                color: "rgb(0 0 0 / 70%)",
              }}
            >
              +{vendorIcons.slice(4).length}
            </div>
          )}
        </div>

        {kind === "change" && (
          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridGap: "1rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                gridGap: "0.3rem",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              {[comparedAmount, selectedAmount]
                // Using the absolute values here since a negative expense is confusing
                .map(Math.abs)
                .map((amount, i) => (
                  <Text key={i} size="1rem" color="rgb(0 0 0 / 54%)">
                    <FormattedCurrency value={amount} currency={currency} />
                  </Text>
                ))}
            </div>
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                gridGap: "0.3rem",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              {[comparedRange[0], primaryRange[0]].map((date, i) => (
                <Text key={i} size="1rem">
                  {renderDate(date, { short: true })}
                </Text>
              ))}
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            alignItems: "center",
            gridGap: "1rem",
          }}
        >
          {kind === "change" && (
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                gridGap: "0.5rem",
                width: "4rem",
                borderLeft: "0.1rem solid",
                borderColor: "rgb(0 0 0 / 25%)",
                padding: "0.4rem 0",
                marginLeft: "-1rem",
              }}
            >
              {(changePercentage > 0
                ? [1 - changePercentage / (1 + changePercentage), 1]
                : [1, 1 + changePercentage]
              ).map((fraction) => (
                <div
                  style={{
                    height: "0.5rem",
                    width: `max(0.2rem, ${fraction * 100}%)`,
                    background: "rgb(0 0 0 / 25%)",
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <div style={{ overflow: "hidden" }}>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              textAlign: "right",
              overflow: "hidden",
            })}
          >
            {kind === "change" && (
              <Text weight="500" truncate style={{ marginRight: "1.2rem" }}>
                {changePercentage > 9.99
                  ? ">"
                  : changePercentage < -9.99
                  ? "<"
                  : ""}
                <FormattedNumber
                  value={Math.max(-9.99, Math.min(changePercentage, 9.99))}
                  style="percent" // eslint-disable-line react/style-prop-object
                  signDisplay={changePercentage > 9.99 ? "auto" : "exceptZero"}
                  locale="en-US"
                />
              </Text>
            )}
            <Text weight="700" truncate>
              {
                // Always show the sign of the change instead of the actual
                // amount. Expenses are confusing as an increase gives a
                // negative change amount, and vice versa.
                kind === "new" || changePercentage === 0
                  ? ""
                  : changePercentage < 0
                  ? "-"
                  : "+"
              }
              <FormattedCurrency
                value={changeAmount}
                currency={currency}
                signDisplay="never"
              />
            </Text>
          </div>
        </div>
      </div>
    </Box>
  );
};

const Breakdown = ({
  categories,
  vendors,
  fetchData,
  primaryRange,
  comparedRange,
  currency,
  renderDate,
}) => {
  const [{ expenses, income }, setData] = React.useState({
    expenses: [],
    income: [],
  });

  React.useEffect(() => {
    for (let kind of ["expenses", "income"])
      fetchData({ kind, primaryRange, comparedRange }).then((res) =>
        setData((state) => ({ ...state, [kind]: res.data }))
      );
  }, [...[...primaryRange, ...comparedRange].map((d) => d.getTime())]); // eslint-disable-line

  return (
    <>
      {[
        { name: "income", title: "Income", items: income },
        { name: "expense", title: "Expenses", items: expenses },
      ].map(({ name, title, items }) => (
        <div
          key={title}
          className={css({ ":not(:last-of-type)": { margin: "0 0 4rem" } })}
        >
          <Text
            block
            variant="label-light"
            style={{ padding: "0.5rem 0 1.4rem" }}
          >
            {title}
          </Text>

          {items
            .filter((i) => i.variance >= VARIANCE_THRESHOLD)
            .map(
              ({
                id: categoryId,
                vendors: itemVendors,
                kind,
                change_amount: changeAmount,
                change_percentage: changePercentage,
                selected_amount: selectedAmount,
                compared_amount: comparedAmount,
              }) => {
                const category = categories.find(
                  (c) => c.id === categoryId
                ) ?? {
                  id: "unknown",
                  description: "Unknown",
                };

                return (
                  <BreakdownItem
                    key={categoryId + kind}
                    primaryRange={primaryRange}
                    comparedRange={comparedRange}
                    name={name}
                    kind={kind}
                    category={category}
                    changeAmount={changeAmount}
                    changePercentage={changePercentage}
                    selectedAmount={selectedAmount}
                    comparedAmount={comparedAmount}
                    currency={currency}
                    categoryLink={[
                      "/dashboard/transactions",
                      new URLSearchParams(
                        Object.entries({
                          from: formatDate(comparedRange[0], "yyyy-MM-dd"),
                          to: formatDate(primaryRange[1], "yyyy-MM-dd"),
                          categories: categoryId,
                        })
                      ).toString(),
                    ].join("?")}
                    vendorIcons={sort(
                      (a, b) => descending(a.variant, b.variant),
                      itemVendors
                    )
                      .map((v) => {
                        const vendor = vendors.find(({ id }) => v.id === id);
                        if (vendor == null) return null;
                        return {
                          id: v.id,
                          description: vendor?.description,
                        };
                      })
                      .filter(Boolean)
                      .slice(0, 4)
                      .map((vendor) => (
                        <Tooltip
                          label={
                            <div>
                              Click to show{" "}
                              <InlineTag dark>
                                {category?.description}
                              </InlineTag>{" "}
                              transactions from{" "}
                              <InlineTag dark>{vendor?.description}</InlineTag>
                            </div>
                          }
                        >
                          <div
                            key={vendor.id}
                            className={css({
                              marginRight: "-0.8rem",
                              position: "relative",
                              zIndex: 0,
                              ":hover": { zIndex: 1 },
                              "& > a": {
                                transition: "0.1s transform",
                                ":hover": { transform: "scale(1.05)" },
                              },
                            })}
                          >
                            <VendorIcon
                              vendor={vendor}
                              component={RouterLink}
                              to={{
                                pathname: "/dashboard/transactions",
                                search: new URLSearchParams(
                                  Object.entries({
                                    from: formatDate(
                                      comparedRange[0],
                                      "yyyy-MM-dd"
                                    ),
                                    to: formatDate(
                                      primaryRange[1],
                                      "yyyy-MM-dd"
                                    ),
                                    vendors: vendor.id,
                                    categories: categoryId,
                                  })
                                ).toString(),
                              }}
                            />
                          </div>
                        </Tooltip>
                      ))}
                    renderDate={renderDate}
                  />
                );
              }
            )}
        </div>
      ))}
    </>
  );
};

export default CashFlow;
