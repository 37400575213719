import { ascending as d3Ascending } from "d3-array";

export const invert = (comparator) => (a, b) => {
  const invertedValue = new Map([
    [1, -1],
    [-1, 1],
    [0, 0],
  ]);
  return invertedValue.get(comparator(a, b));
};

export const ascending = d3Ascending;
export const descending = invert(ascending);
