import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { css } from "emotion";
import { update as updateQueryString } from "../../utils/query-string";
import { selectDismissed, selectNonDismissed } from "../../hooks/insights";
import Box from "../Box";
import Text from "../Text";
import Button from "../Button";
import FormattedCurrency from "../FormattedCurrency";
import FormattedDate from "../FormattedDate";
import FormattedNumber from "../FormattedNumber";
import Icon from "../Icon";
import VendorIcon from "../VendorIcon";
import Select from "../Select";
import { Link as RouterLink } from "react-router-dom";
import { groupBy, sort as sortBy } from "../../utils/array";
import { descending } from "../../utils/comparators";
import Divider from "../Divider";
import { title } from "../../utils/string";

const insightTitleByKind = {
  sharp_cost_increase: "Sharp cost increase",
  sharp_cost_decrease: "Sharp cost decrease",
  reactivated_cost: "Irregular costs",
  new_cost: "New vendor identified",
};

const Insights = ({
  location,
  history,
  vendors,
  companyId,
  archive,
  getInsightsState,
  fetchAll,
  dismissAll,
  dismissById,
}) => {
  const sort = new URLSearchParams(location.search).get("sort") ?? "-date";

  const [
    hasPendingDismissAllRequest,
    setHasPendingDismissAllRequest,
  ] = useState(false);

  React.useEffect(() => {
    fetchAll({ sort });
  }, [fetchAll, companyId, sort]);

  const handleClickDismissAll = () => {
    setHasPendingDismissAllRequest(true);
    dismissAll({ sort }).finally(() => setHasPendingDismissAllRequest(false));
  };

  const nonDismissedInsights = getInsightsState(selectNonDismissed({ sort }));
  const dismissedInsights = getInsightsState(selectDismissed({ sort }));
  const isFetching = getInsightsState((state) => state.isFetching);

  const insights = archive ? dismissedInsights : nonDismissedInsights;

  const groupedInsights = groupBy((insight) => {
    switch (insight.kind) {
      case "sharp_cost": {
        if (insight.change == null) throw new Error(`Missing insight 'change'`);
        return insight.change < 0
          ? "sharp_cost_decrease"
          : "sharp_cost_increase";
      }
      default:
        return insight.kind;
    }
  }, insights);

  const insightKindTitle = (kind) => {
    const title = insightTitleByKind[kind];
    if (title == null) throw new Error(`Unknown insight kind "${kind}"`);
    return title;
  };

  const hasInsights = insights.length !== 0;

  return (
    <>
      <div
        className={css({
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          height: "6rem",
          gap: "2rem",
          padding: "1rem 2rem",
          borderBottom: "0.1rem solid",
          borderColor: "rgb(239, 241, 244)",
        })}
      >
        <div className={css({ display: "flex", alignItems: "center" })}>
          <Text variant="headline" component="h2" size="1.6rem">
            Insights
          </Text>
          <div className={css({ margin: "0 1rem 0 2rem" })}>/</div>
          <div>
            <Button
              exact
              component={NavLink}
              size="small"
              variant="transparent"
              to="/dashboard/insights"
              activeStyle={{ fontWeight: "700" }}
            >
              Inbox
            </Button>
            <Button
              component={NavLink}
              size="small"
              variant="transparent"
              to="/dashboard/insights/archive"
              activeStyle={{ fontWeight: "700" }}
            >
              Archived
            </Button>
            <Button
              component="div"
              size="small"
              variant="transparent"
              // to="/dashboard/insights/subscribed"
              // activeStyle={{ fontWeight: "700" }}
              disabled
            >
              Subscribed
            </Button>
          </div>
        </div>
        <div
          className={css({
            minWidth: 0,
            display: "grid",
            gridAutoFlow: "column",
            gridAutoRows: "auto",
            alignItems: "center",
            gap: "1rem",
          })}
        >
          {hasInsights && (
            <>
              <Select
                value={sort}
                options={[
                  { label: "Sort by date", value: "-date" },
                  // { label: "Vendor", value: "vendor" },
                ]}
                onChange={(e) =>
                  history.push({
                    search: updateQueryString(location.search, {
                      sort: e.target.value,
                    }),
                  })
                }
                size="small"
                variant="transparent"
              />
              {!archive && (
                <Button
                  variant="transparent"
                  size="small"
                  onClick={handleClickDismissAll}
                  disabled={hasPendingDismissAllRequest}
                  style={{ alignItems: "center" }}
                >
                  Archive all
                  <Icon
                    name="checkmark"
                    size="1.4rem"
                    style={{ marginLeft: "0.6rem" }}
                  />
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <div
        className={css({
          flex: "1 1",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        })}
      >
        <div className={css({ padding: "2rem", flex: "1" })}>
          {hasInsights ? (
            <div>
              {[
                "new_cost",
                "sharp_cost_increase",
                "sharp_cost_decrease",
                "reactivated_cost",
              ]
                .filter((kind) => groupedInsights[kind] != null)
                .map((kind) => (
                  <div key={kind}>
                    <Text variant="label-light">{insightKindTitle(kind)}</Text>

                    <Divider size="1.6rem" />

                    <ul>
                      {sortBy(
                        (a, b) =>
                          descending(a.transaction.date, b.transaction.date),
                        groupedInsights[kind]
                      ).map((insight) => {
                        const hasVendor =
                          insight.merchant != null && insight.merchant !== "";
                        const knownVendor = vendors.find(
                          (v) => v.id === insight.merchant
                        );
                        return (
                          <InsightListItem
                            key={insight.id}
                            {...insight}
                            vendor={
                              !hasVendor
                                ? null
                                : {
                                    id: insight.merchant,
                                    description: knownVendor?.description,
                                  }
                            }
                            dismiss={
                              archive
                                ? undefined
                                : () => dismissById(insight.uuid)
                            }
                          />
                        );
                      })}
                    </ul>

                    <Divider size="4rem" />
                  </div>
                ))}
            </div>
          ) : isFetching ? (
            <div>
              {["17rem", "26rem", "22rem"].map((width) => (
                <Box
                  key={width}
                  padding="1.6rem"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    ":not(:first-of-type)": { marginTop: "2rem" },
                  }}
                >
                  <div
                    style={{
                      background: "#eee",
                      height: "2.6rem",
                      width: "2.6rem",
                      borderRadius: "50%",
                      marginRight: "1.6rem",
                    }}
                  />
                  <div
                    style={{
                      background: "#eee",
                      height: "2rem",
                      borderRadius: "0.5rem",
                      marginRight: "3rem",
                      width,
                    }}
                  />
                </Box>
              ))}
            </div>
          ) : (
            <NoItemsAvailablePlaceholder />
          )}
        </div>
      </div>
    </>
  );
};

const NoItemsAvailablePlaceholder = () => (
  <div
    className={css({
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    })}
  >
    <Icon name="checkmark" size="3rem" style={{ margin: "0 0 2rem" }} />
    <Text block size="1.6rem" weight="500">
      Nothing to show. All is good in the hood.
    </Text>
  </div>
);

const InsightIcon = ({ kind, change }) => {
  const iconByKind = {
    sharp_cost: change < 0 ? "arrow-down-right" : "arrow-up-right",
    reactivated_cost: "arrow-turn-up",
    new_cost: "add-tag",
  };

  return <Icon name={iconByKind[kind]} size="1.6rem" />;
};

const InsightDescription = ({ kind, change, vendor }) => {
  switch (kind) {
    case "sharp_cost":
      return (
        <>
          Cost went {change > 0 ? "up" : "down"} by{" "}
          <FormattedNumber
            value={change}
            style="percent" // eslint-disable-line react/style-prop-object
            locale="en-US"
            signDisplay="never"
          />{" "}
          since last payment
        </>
      );
    case "reactivated_cost":
      return <>Charged by {vendor} for first time in a while</>;
    case "new_cost":
      return <>First payment for {vendor} detected</>;
    default:
      throw new Error();
  }
};

const InsightListItem = ({
  id,
  transaction,
  vendor,
  change,
  kind,
  dismiss,
}) => {
  const [isPending, setPending] = useState(false);

  const handleClickDismiss = () => {
    setPending(true);
    dismiss().finally(() => setPending(false));
  };

  return (
    <Box
      key={id}
      padding="1.4rem 2rem"
      style={{
        display: "grid",
        gridTemplateColumns:
          "1.2rem minmax(0,8fr) minmax(0,4fr) minmax(0,3fr) minmax(0,3fr)",
        gap: "1.4rem",
        ":not(:first-of-type)": { marginTop: "2rem" },
        alignItems: "center",
        transition: "0.2s opacity",
        opacity: isPending ? 0.5 : 1,
      }}
    >
      <InsightIcon kind={kind} change={change} />
      <Text weight="500">
        <InsightDescription
          kind={kind}
          change={change}
          vendor={vendor?.description}
        />
      </Text>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          gridGap: "1rem",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "hidden",
        }}
      >
        <VendorIcon size="3rem" vendor={vendor} />
        <RouterLink
          to={{
            pathname: "/dashboard/transactions",
            search: `vendors=${vendor?.id}`,
          }}
          className={css({ ":hover": { color: "rgb(0 0 0 / 70%)" } })}
        >
          <Text weight="700" truncate>
            {vendor?.description ?? title(transaction.description)}
          </Text>
        </RouterLink>
      </div>
      <Text truncate weight="500" lineHeight={1.5}>
        <FormattedDate
          value={new Date(transaction.date * 1000)}
          year="numeric"
          month="short"
          day="numeric"
        />
      </Text>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        })}
      >
        <Text align="right" weight="700" truncate>
          <FormattedCurrency
            value={transaction.original_amount}
            currency={transaction.original_currency}
          />
        </Text>
        {typeof dismiss === "function" && (
          <div className={css({ marginLeft: "1rem" })}>
            <Button
              variant="transparent"
              onClick={handleClickDismiss}
              disabled={isPending}
              style={{
                width: "2.6rem",
                height: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <Icon name="checkmark" size="1.2rem" />
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Insights;
