import { css } from "emotion";
import React from "react";
import Checkbox from "./Checkbox";

const LabelledCheckbox = ({
  label,
  id,
  style,
  isChecked,
  ...checkboxProps
}) => {
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        ":hover": {
          input: { borderColor: isChecked ? undefined : "#bbb" },
        },
      })}
    >
      <Checkbox
        id={id}
        style={{ marginRight: "1rem" }}
        isChecked={isChecked}
        {...checkboxProps}
      />
      <label htmlFor={id} className={css({ cursor: "pointer" })}>
        {label}
      </label>
    </div>
  );
};

export default LabelledCheckbox;
