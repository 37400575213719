import { css } from "emotion";
import React from "react";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT_TINT } from "../constants/colors";

const styleByVariant = {
  primary: {
    borderColor: PRIMARY_COLOR,
    background: PRIMARY_COLOR,
    color: "white",
    boxShadow: undefined,
    fontWeight: "700",
    // borderColor: "rgba(0,0,0,0)",
    // background: "rgba(54,94,235,0.1)",
    // color: PRIMARY_COLOR,
    ":not(:disabled):hover, &[data-hover]": {
      background: PRIMARY_COLOR_LIGHT_TINT,
      borderColor: PRIMARY_COLOR_LIGHT_TINT,
      // borderColor: PRIMARY_COLOR,
      // boxShadow: `0 0.4rem 1.2rem rgba(54, 94, 235, 0.2)`,
    },
  },
  transparent: {
    color: "black",
    background: "transparent",
    borderColor: "transparent",
    fontWeight: "500",
    ":not(:disabled):hover, &[data-hover]": {
      background: "#f6f6f6",
    },
  },
};

const styleBySize = {
  tiny: {
    fontSize: "1.2rem",
    padding: "0.6rem 0.8rem 0.7rem",
    fontWeight: "500",
  },
};

const Button = React.forwardRef(
  (
    {
      variant,
      size,
      onClick,
      type = "button",
      component: Component = "button",
      style,
      isHovered,
      children,
      ...props
    },
    ref
  ) => (
    <Component
      onClick={onClick}
      type={type}
      className={css({
        display: "inline-flex",
        padding:
          size === "small"
            ? "0.8rem 1rem"
            : size === "large"
            ? "1.5rem 3rem"
            : "1rem 2rem",
        border: "0.1rem solid",
        borderColor: "rgb(3 5 8 / 10%)",
        background: "none",
        color: "black",
        borderRadius: "0.5rem",
        fontWeight: "500",
        whiteSpace: "nowrap",
        cursor: "pointer",
        transition: "0.1s all",
        ":not(:disabled):hover, &[data-hover]": {
          borderColor: "#ccc",
        },
        ":disabled, &[data-disabled]": { opacity: 0.5, cursor: "default" },
        ...(styleByVariant[variant] || {}),
        ...(styleBySize[size] || {}),
      })}
      style={style}
      ref={ref}
      {...(props.disabled ? { "data-disabled": "" } : {})}
      {...(isHovered ? { "data-hover": "" } : {})}
      {...props}
    >
      {children}
    </Component>
  )
);

export default Button;
