import { transactionConstants } from "../constants/transaction.constants";

const initialState = {
  data: [],
  isLoading: false,
  count: 0
}

export function data(state = initialState, action) {
  switch (action.type) {
    case transactionConstants.FETCH_ALL_TRANSACTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case transactionConstants.FETCH_ALL_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        data: action.response.data,
        count: action.response.count,
        isLoading: false,
      })
    default:
      return state
  }
}