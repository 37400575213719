import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resize = () =>
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    const delayedResize = () => {
      const timeout = 120;
      return new window.Promise(function (resolve) {
        const checkForChange = (i, height0) => {
          window.innerHeight !== height0 || i >= timeout
            ? resolve()
            : window.requestAnimationFrame(() =>
                checkForChange(i + 1, height0)
              );
        };
        checkForChange(0, window.innerHeight);
      });
    };

    const handleOrientationChange = () => delayedResize().then(resize);

    window.addEventListener("resize", resize);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return dimensions;
};

export default useWindowSize;
