export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
  SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

  FETCH_USER_REQUEST: 'USERS_FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'USERS_FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'USERS_FETCH_USER_FAILURE'
};