import {
  UsersEndpoint,
  LoginEndpoint,
  ForgotPasswordEndpoint,
  RegisterUserEndpoint,
  ResetPasswordEndpoint,
} from "../utils/constants";
import { handleErrorResponse, apiInstance } from "./common";

export const userService = {
  loginUser,
  logout,
  registerUser,
  getUser,
};

export async function addUser(email) {
  let data = {
    email: email,
  };
  return apiInstance.post(UsersEndpoint, data);
}

export async function forgotPassword(email) {
  let data = {
    email: email,
  };
  return apiInstance.post(ForgotPasswordEndpoint, data);
}

export async function registerUser(email, password) {
  let data = {
    email: email,
    password: password,
  };

  return apiInstance
    .post(RegisterUserEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch(handleErrorResponse);
}

export async function resetPassword(password, token) {
  let data = {
    password: password,
    token: token,
  };

  return apiInstance.post(ResetPasswordEndpoint, data);
}

export async function loginUser(email, password) {
  let data = {
    email: email,
    password: password,
  };

  return apiInstance
    .post(LoginEndpoint, data)
    .then((response) => {
      let data = response.data;
      let token = data.auth_token;
      return token;
    })
    .then((token) => {
      return getUser().then(function (user) {
        let userData = {
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          uuid: user.uuid,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        return { user: user, token: token };
      });
    })
    .catch(handleErrorResponse);
}

export async function getUser() {
  let params = {
    expand: "companies",
  };
  return apiInstance
    .get(UsersEndpoint, { params: params })
    .then((response) => {
      let user = response.data;
      return user;
    })
    .catch(handleErrorResponse);
}

export async function fillProfile(
  first_name,
  last_name,
  company_name,
  company_role
) {
  let data = {
    first_name: first_name,
    last_name: last_name,
    company_name: company_name,
    company_role: company_role,
  };

  return apiInstance
    .put(UsersEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch(handleErrorResponse);
}

function logout() {
  return apiInstance.post("/users/logout");
}
