import { css } from "emotion";
import React from "react";

const Checkbox = ({ style, isChecked, ...props }) => (
  <div
    className={css({
      position: "relative",
      svg: {
        display: "block",
        width: "100%",
        color: "white",
        transition: "0.1s all",
      },
    })}
    style={style}
  >
    <input
      type="checkbox"
      className={css({
        display: "block",
        WebkitAppearance: "none",
        width: "2rem",
        height: "2rem",
        border: "0.2rem solid",
        transition: "0.1s all",
        background: isChecked ? "#1FBE7B" : "white",
        borderColor: isChecked ? "#1FBE7B" : "#ccc",
        borderRadius: "0.2rem",
        cursor: "pointer",
      })}
      checked={isChecked}
      {...props}
    />
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        pointerEvents: "none",
      }}
    >
      <svg width="12" height="11" viewBox="0 0 12 11">
        <path
          d="M4.66621 6.89922L1.88371 4.11672L0.116211 5.88422L4.83371 10.6017L11.9612 2.05172L10.0387 0.449219L4.66621 6.89922Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
);

export default Checkbox;
